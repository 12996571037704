import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';
import { Toast } from 'voltron';
import PropTypes from 'prop-types';
import Header from 'containers/partials/header/Header';
import Footer from 'containers/partials/footer/Footer';
import { pageLoading } from 'state/actions/page_loading';
import { setUserInfo } from 'state/actions/appState';
import styles from './Layout.module.scss';

const Layout = ({ children }) => {
  const { oktaAuth, authState } = useOktaAuth();
  const { loading } = useSelector((state) => state.page);
  const { userInfo } = useSelector((state) => state.appState);
  const { isSetEnrollmentsLoading } = useSelector((state) => state.dashboard);
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleLogout = async () => {
    dispatch(pageLoading());
    await oktaAuth.signOut();
  };

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      dispatch(setUserInfo(null));
    } else {
      oktaAuth
        .getUser()
        .then((info) => {
          dispatch(setUserInfo(info));
        })
        .catch((err) => {
          console.error('Sign in error', err);
        });
    }
  }, [authState, oktaAuth]); // Update if authState changes

  return (
    <div className={styles.app}>
      <div className={styles.body}>
        <div className={styles.content}>
          <Header handleLogout={handleLogout} userInfo={userInfo} disableMenu={isSetEnrollmentsLoading} />
          <div className={loading ? styles.loadingContainer : styles.hide}>
            <div className="globalloader">
              <div className="loadertext">Please wait ...</div>
            </div>
          </div>
          <div className={styles.container}>{children}</div>
        </div>
        <Toast />
        <Footer />
      </div>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.any,
};

export default Layout;
