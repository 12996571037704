import React from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { Button } from 'voltron';
import routes from 'constants/routes';

const NotFound = () => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(push(routes.home));
  };

  return (
    <>
      <h4>404 Page Not Found</h4>
      <Button kind="link" data-testid="notfound-link" onClick={handleClick}>
        Go back to homepage
      </Button>
    </>
  );
};

export default NotFound;
