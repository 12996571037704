import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid } from '@mui/material';
import moment from 'moment';
import { DatePicker, Input, Select, CheckBox, Button } from 'voltron';
import { DATE_FORMAT } from 'constants';
import { UPDATE_UPLOAD_CENSUS_DATA } from '../../../state/actions/upload';

const labelKind = 'regular';
const selectLabelPosition = 'top';
const enrollmentTypeField = 'enrollmentType';
const startDateField = 'startDate';
const endDateField = 'endDate';
const assignmentOfOwnershipField = 'assignmentOfOwnership';
const owningCompanyNameField = 'owningCompanyName';
const skipAppQuestionsField = 'skipAppQuestions';
const removeIncomeOptionField = 'removeIncomeOption';
const manualActivationField = 'manualActivation';
const currentDate = moment().startOf('day').toDate();

const validate = (values) => {
  const errors = {};
  const enrollmentStartDate = moment(values.startDate);
  const enrollmentEndDate = moment(values.endDate);
  const isStartDateLaterThanEndDate = enrollmentStartDate.isAfter(enrollmentEndDate);

  if (!values.enrollmentType) {
    errors.enrollmentType = 'employer';
  }
  if (!values.startDate || isStartDateLaterThanEndDate || enrollmentStartDate.isBefore(currentDate)) {
    errors.startDate = startDateField;
  }
  if (!values.endDate || isStartDateLaterThanEndDate || enrollmentEndDate.isBefore(currentDate)) {
    errors.endDate = endDateField;
  }
  return errors;
};

const handleAssignmentOfOwnershipChange = (e, owningCompaniesData, formData, dispatch) => {
  let updatedState;
  const { control, value } = e;
  if (control === assignmentOfOwnershipField && value === 0) {
    updatedState = {
      id: 0,
      name: '',
    };
  } else if (control === assignmentOfOwnershipField && value !== -1) {
    const ownerObj = owningCompaniesData.find((company) => +company.value === +value);
    updatedState = {
      id: +value,
      name: ownerObj?.label,
    };
  } else if (control !== assignmentOfOwnershipField) {
    updatedState = {
      name: e.target.value,
    };
  }

  if (control === assignmentOfOwnershipField && value === -1) {
    dispatch({
      type: UPDATE_UPLOAD_CENSUS_DATA,
      payload: {
        formData: {
          ...formData,
          assignmentOfOwnership: {
            id: -1,
            name: e?.target?.value,
          }, // Clear form values
        },
      },
    });
  } else {
    dispatch({
      type: UPDATE_UPLOAD_CENSUS_DATA,
      payload: {
        formData: {
          ...formData,
          assignmentOfOwnership: {
            ...formData.assignmentOfOwnership,
            ...updatedState,
          },
        },
      },
    });
  }
};

const handleEnrollmentChange = (e, formData, dispatch) => {
  const { value } = e;
  dispatch({
    type: UPDATE_UPLOAD_CENSUS_DATA,
    payload: {
      formData: {
        ...formData,
        enrollment: {
          ...formData.enrollment,
          enrollmentType: value,
        },
      },
    },
  });
};

const handleEnrollmentDateChange = (name, value, formData, dispatch) => {
  const date = value ? moment(value).format(DATE_FORMAT) : null;
  dispatch({
    type: UPDATE_UPLOAD_CENSUS_DATA,
    payload: {
      formData: {
        ...formData,
        enrollment: {
          ...formData.enrollment,
          [name]: date,
        },
      },
    },
  });
};

const checkboxHandler = (e, formData, dispatch) => {
  const { name, checked } = e.target;
  dispatch({
    type: UPDATE_UPLOAD_CENSUS_DATA,
    payload: {
      formData: {
        ...formData,
        [name]: checked,
      },
    },
  });
};

const handleSubmit = (formData, setFormErrors, setIsOnSubmit, proceedToTab) => {
  setIsOnSubmit(true);
  const errors = validate(formData.enrollment);
  setFormErrors(errors);
  if (Object.keys(errors).length === 0) {
    proceedToTab(3);
  }
};

const getDatepickers = ({ formData, formErrors, dispatch }) => {
  const enrollmentStartDate = moment(formData.enrollment.startDate);
  const enrollmentEndDate = moment(formData.enrollment.endDate);
  return (
    <>
      <Grid item xs={6}>
        <DatePicker
          label="Start Date"
          labelProps={{ kind: labelKind }}
          required
          key={`start-date-${formData.enrollment.enrollmentType}`}
          name={startDateField}
          id={startDateField}
          value={formData.enrollment.startDate}
          onChange={(date, isValid, _inRange, _datePickerState, event) =>
            handleEnrollmentDateChange(startDateField, isValid ? date : event.target.value, formData, dispatch)
          }
          minDate={currentDate}
          datePickerProps={{
            disabledDays: {
              before: currentDate,
              after:
                formData.enrollment.endDate && enrollmentEndDate.isAfter(currentDate)
                  ? enrollmentEndDate.toDate()
                  : currentDate,
            },
            modifiers: { today: '' },
          }}
          muteValidation={!formErrors.startDate}
          forceErrorMessage={formErrors.startDate}
          errormessage="Enter a valid date in the format mm/dd/yyyy
              on/after today and on/before enrollment end date"
        />
      </Grid>
      <Grid item xs={6}>
        <DatePicker
          label="End Date"
          labelProps={{ kind: labelKind }}
          required
          key={`end-date-${formData.enrollment.enrollmentType}`}
          name={endDateField}
          id={endDateField}
          value={formData.enrollment.endDate}
          onChange={(date, isValid, _inRange, _datePickerState, event) =>
            handleEnrollmentDateChange(endDateField, isValid ? date : event.target.value, formData, dispatch)
          }
          minDate={currentDate}
          muteValidation={!formErrors.endDate}
          forceErrorMessage={formErrors.endDate}
          errormessage="Enter a valid date in the format mm/dd/yyyy
              on/after today or enrollment start date, whichever comes later"
          datePickerProps={{
            disabledDays: {
              before:
                formData.enrollment.startDate && enrollmentStartDate.isAfter(currentDate)
                  ? enrollmentStartDate.toDate()
                  : currentDate,
            },
            modifiers: { today: '' },
          }}
        />
      </Grid>
    </>
  );
};

const getCheckBoxes = ({ formData, dispatch }) => (
  <>
    <Grid item xs={12}>
      <CheckBox
        defaultChecked={formData?.skipAppQuestions}
        inputProps={{ 'data-testid': 'skip-questions' }}
        name={skipAppQuestionsField}
        id={skipAppQuestionsField}
        label="Skip Gatekeeper Questions (Exception Only)"
        onClick={(e) => checkboxHandler(e, formData, dispatch)}
      />
    </Grid>
    <Grid item xs={12} style={{ paddingTop: 20 }}>
      <CheckBox
        defaultChecked={formData?.removeIncomeOption}
        inputProps={{ 'data-testid': 'remove-income' }}
        name={removeIncomeOptionField}
        id={removeIncomeOptionField}
        label="Remove Incomes"
        onClick={(e) => checkboxHandler(e, formData, dispatch)}
      />
    </Grid>
    <Grid item xs={12} style={{ paddingTop: 20 }}>
      <CheckBox
        defaultChecked={formData?.manualActivation}
        inputProps={{ 'data-testid': 'manual-activation' }}
        name={manualActivationField}
        id={manualActivationField}
        label="Skip Automatic User Activation (Manual activation required)"
        onClick={(e) => checkboxHandler(e, formData, dispatch)}
      />
    </Grid>
  </>
);

const getForm = ({
  formData,
  formErrors,
  enrollmentTypesOptions,
  owningCompaniesData,
  isOnSubmit,
  setFormErrors,
  proceedToTab,
  setIsOnSubmit,
  dispatch,
}) => (
  <>
    <Grid container spacing={0} className="innerHeader">
      <Grid item xs={12}>
        <h3>Enrollment Information</h3>
      </Grid>
      <Grid item xs={12}>
        <p>Select or enter the enrollment information for the census being uploaded</p>
      </Grid>
    </Grid>
    <form>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Select
            label="Select Enrollment Type"
            labelKind={labelKind}
            labelPosition={selectLabelPosition}
            data-testid="enrollment-type"
            name={enrollmentTypeField}
            id={enrollmentTypeField}
            required
            options={enrollmentTypesOptions}
            clearable={false}
            searchable={false}
            material={false}
            selectedValue={enrollmentTypesOptions.find((option) => option.value === formData.enrollment.enrollmentType)}
            onChange={(e) => handleEnrollmentChange(e, formData, dispatch)}
            forceErrorMessage={isOnSubmit && formErrors.enrollmentType}
          />
        </Grid>
        {getDatepickers({ formData, formErrors, dispatch })}
        <Grid item xs={12}>
          <Select
            label="Assignment of Ownership"
            labelKind={labelKind}
            labelPosition={selectLabelPosition}
            data-testid="assignment-ownership"
            name={assignmentOfOwnershipField}
            id={assignmentOfOwnershipField}
            options={owningCompaniesData}
            clearable={false}
            searchable={false}
            material={false}
            selectedValue={owningCompaniesData.find((option) => option.value === formData.assignmentOfOwnership.id)}
            onChange={(e) => handleAssignmentOfOwnershipChange(e, owningCompaniesData, formData, dispatch)}
          />
          <span className="inputLabel">If new, select &apos;New Ownership Company&apos; to enter the name</span>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: 10 }}>
          <Input
            label="Assignment of Ownership - Company Name"
            labelKind={labelKind}
            key={`companyName-${formData.assignmentOfOwnership.id}`}
            data-testid="owning-company-name"
            value={formData.assignmentOfOwnership.name}
            name={owningCompanyNameField}
            id={owningCompanyNameField}
            material={false}
            autoComplete={false}
            placeholder="Company Name"
            onChange={(e) => handleAssignmentOfOwnershipChange(e, owningCompaniesData, formData, dispatch)}
            maxLength={40}
          />
        </Grid>
        <Grid item xs={12}>
          <hr />
        </Grid>
        <Grid item xs={12} className="innerHeader">
          <h4>Exceptions</h4>
        </Grid>
        {getCheckBoxes({ formData, dispatch })}
        <Grid item xs={12} justifyContent="flex-end" container>
          <Button onClick={() => handleSubmit(formData, setFormErrors, setIsOnSubmit, proceedToTab)}>CONTINUE</Button>
        </Grid>
      </Grid>
    </form>
  </>
);

const Enrollment = ({ proceedToTab, enableNextTab }) => {
  const { owningCompanies = [] } = useSelector((state) => state.adminEntities);
  const { enrollmentTypes = [], formData = {} } = useSelector((state) => state.upload);
  const dispatch = useDispatch();
  const [isOnSubmit, setIsOnSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [enrollmentTypesOptions, setEnrollmentTypesOptions] = useState([]);
  const [owningCompaniesData, setOwningCompaniesData] = useState([]);

  useEffect(() => {
    proceedToTab(2);
    if (formData?.enrollment != null) {
      const startDate = formData.enrollment?.startDate ? new Date(formData.enrollment?.startDate) : new Date();
      const endDate = formData.enrollment?.endDate ? new Date(formData.enrollment?.endDate) : new Date();
      if (!formData.enrollment?.endDate) {
        endDate.setMonth(startDate.getMonth() + 1);
      }

      dispatch({
        type: UPDATE_UPLOAD_CENSUS_DATA,
        payload: {
          formData: {
            ...formData,
            enrollment: {
              ...formData.enrollment,
              startDate: moment(startDate).format(DATE_FORMAT),
              endDate: moment(endDate).format(DATE_FORMAT),
            },
          },
        },
      }); // Set default value for dates
    }
    if (enrollmentTypes?.length > 0) {
      const updateEnrollTypes = enrollmentTypes.map((types) => ({
        label: types.name,
        value: types.name,
      }));
      setEnrollmentTypesOptions(updateEnrollTypes);
    }
    let updateOwnerCompanies = [
      { id: -1, name: 'No Ownership Company' },
      { id: 0, name: 'New Ownership Company' },
    ];
    if (owningCompanies?.length > 0) {
      updateOwnerCompanies = [...updateOwnerCompanies, ...owningCompanies];
    }
    const finalData = updateOwnerCompanies.map((types) => ({
      label: types.name,
      value: types.id,
      control: assignmentOfOwnershipField,
    }));
    setOwningCompaniesData(finalData);
  }, []);

  useEffect(() => {
    const errors = validate(formData.enrollment);
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      setFormErrors({});
      enableNextTab(3);
    } else {
      enableNextTab(3, false);
    }
  }, [formData.enrollment]);

  return getForm({
    formData,
    formErrors,
    enrollmentTypesOptions,
    owningCompaniesData,
    isOnSubmit,
    setFormErrors,
    proceedToTab,
    setIsOnSubmit,
    dispatch,
  });
};

export default Enrollment;
