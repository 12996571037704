import { NO_LABEL, YES_LABEL, YES_OPTION, formCodes } from 'constants/index';
import mainStyles from '../Summary.module.scss';

const getHealthComments = ({ formCode, input }) => {
  const isACombinedForm = [formCodes.COMBINED, formCodes.COMBINED_CORNERSTONE].includes(formCode);

  return (
    <>
      {!isACombinedForm && input.healthComments && (
        <tr>
          <td>Health Details</td>
          <td>{input.healthComments}</td>
        </tr>
      )}
      {formCode === formCodes.COMBINED && input.healthCommentsFoundation && (
        <tr>
          <td>Foundation Health Details</td>
          <td>{input.healthCommentsFoundation}</td>
        </tr>
      )}
      {formCode === formCodes.COMBINED_CORNERSTONE && input.healthCommentsCornerStone && (
        <tr>
          <td>Cornerstone Health Details</td>
          <td>{input.healthCommentsCornerStone}</td>
        </tr>
      )}
      {isACombinedForm && input.healthCommentsFundamental && (
        <tr>
          <td>Fundamental Health Details</td>
          <td>{input.healthCommentsFundamental}</td>
        </tr>
      )}
    </>
  );
};

const HealthInfoSummary = ({ formCode, input = {}, healthQuestions = [] }) => {
  const isCornerstoneFormIncluded = [formCodes.CORNERSTONE, formCodes.COMBINED_CORNERSTONE].includes(formCode);
  return (
    <>
      <p className={mainStyles.sectionHeader}>
        <strong>Health Information</strong>
      </p>
      <table className={mainStyles.summaryTable}>
        <tbody>
          {[formCodes.FOUNDATION, formCodes.COMBINED].includes(formCode) && (
            <tr>
              <td>
                Have you used any tobacco or nicotine products (including patches, gum, etc.) in the past 12 months?
              </td>
              <td>{input.useTobacco === YES_OPTION ? YES_LABEL : NO_LABEL}</td>
            </tr>
          )}
          {isCornerstoneFormIncluded && (
            <tr>
              <td>
                In the past 12 months, have you used any tobacco or nicotine products, and/or nicotine delivery systems
                (such as cigarettes, cigars, cigarillos, vape, e-cigarettes, pipe, chewing tobacco, nicotine patches,
                nicotine gum)?
              </td>
              <td>{input.useTobaccoForCornerStone === YES_OPTION ? YES_LABEL : NO_LABEL}</td>
            </tr>
          )}
          {isCornerstoneFormIncluded && input.healthCommentsCornerStoneForTobaco && (
            <tr>
              <td>Nicotine Type and Frequency</td>
              <td>{input.healthCommentsCornerStoneForTobaco}</td>
            </tr>
          )}
          {healthQuestions.map((healthQuestion) => (
            <tr key={`${healthQuestion.productType}-health-question-${healthQuestion.id}`}>
              <td>{healthQuestion.label}</td>
              <td>{input[healthQuestion.fieldName] === YES_OPTION ? YES_LABEL : NO_LABEL}</td>
            </tr>
          ))}
          {getHealthComments({ formCode, input })}
        </tbody>
      </table>
    </>
  );
};

export default HealthInfoSummary;
