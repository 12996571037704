import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { withTransaction } from '@elastic/apm-rum-react';

import routes from 'constants/routes';
import { isApplicantUser } from 'utils/utils';

const Home = () => {
  const { userInfo } = useSelector((state) => state.appState);
  const history = useHistory();

  useEffect(() => {
    if (userInfo) {
      history.push(isApplicantUser(userInfo) ? routes.applicantDashboard : routes.userDashboard);
    }
  }, [userInfo]);

  return null;
};

export default withTransaction('Home', 'component')(Home);
