import { useState, useEffect } from 'react';
import CurrencyFormat from 'react-currency-format';
import { DatePicker, Input, Select, TextArea } from 'voltron';
import { YES_OPTION, yesNoOptionList } from 'constants/index';

import mainFormStyles from '../ExistingDisabilityInsurance.module.scss';
import styles from './ExistingCoverages.module.scss';

const COMPANY_NAME_FIELD_NAME = 'Company Name';
const COVERAGE_TYPE_FIELD_NAME = 'Type of Coverage';
const POLICY_NUMBER_FIELD_NAME = 'Policy Number';
const ADDITIONAL_DETAILS_FIELD_NAME = 'Additional Details';

const DECIMAL_RADIX = 10;

const labelKind = 'regular';
const labelPosition = 'top';

const numCoveragesToIterate = [...Array(3).keys()];
const coverageTypeList = [
  { label: 'Individual', value: 'IND' },
  { label: 'Group', value: 'Group' },
];

const generateFormNames = () => {
  const formNames = {};
  numCoveragesToIterate.forEach((index) => {
    const coverageNum = index + 1;
    formNames[`existingCoverage${coverageNum}CompanyName`] = `existingCoverage-${coverageNum}-companyName`;
    formNames[`existingCoverage${coverageNum}CoverageType`] = `existingCoverage-${coverageNum}-coverageType`;
    formNames[`existingCoverage${coverageNum}MonthlyBenefit`] = `existingCoverage-${coverageNum}-monthlyBenefit`;
    formNames[`existingCoverage${coverageNum}BenefitPeriod`] = `existingCoverage-${coverageNum}-benefitPeriod`;
    formNames[`existingCoverage${coverageNum}CatMonthlyBenefit`] = `existingCoverage-${coverageNum}-catMonthlyBenefit`;
    formNames[`existingCoverage${coverageNum}EmployerPaid`] = `existingCoverage-${coverageNum}-employerPaid`;
    formNames[`existingCoverage${coverageNum}ReplaceCoverage`] = `existingCoverage-${coverageNum}-replaceCoverage`;
    formNames[`existingCoverage${coverageNum}ExistingPolicyNumber`] =
      `existingCoverage-${coverageNum}-existingPolicyNumber`;
    formNames[`existingCoverage${coverageNum}IssueDate`] = `existingCoverage-${coverageNum}-issueDate`;
    formNames[`existingCoverage${coverageNum}AdditionalDetails`] = `existingCoverage-${coverageNum}-AdditionalDetails`;
  });

  return formNames;
};

const getCoverageTypeField = ({
  formType,
  coverageNum,
  companyName,
  coverageType,
  monthlyBenefit,
  replaceCoverage,
  coverageTypeFieldName,
  isOnSubmit,
  invalidInputs,
  onInputChange,
}) => {
  const isCoverageTypeRequired = Boolean(companyName?.trim()) || monthlyBenefit > 0 || replaceCoverage === YES_OPTION;
  const DATA_TEST_ID = `coverage-type-coverage-${coverageNum}`;

  if (formType === 'foundation') {
    return (
      <Input
        label={COVERAGE_TYPE_FIELD_NAME}
        placeholder={COVERAGE_TYPE_FIELD_NAME}
        data-testid={DATA_TEST_ID}
        material={false}
        autoComplete={false}
        required={isCoverageTypeRequired}
        forceErrorMessage={(isOnSubmit || isCoverageTypeRequired) && invalidInputs.includes(coverageTypeFieldName)}
        containerClass={styles.coverageType}
        value={coverageType}
        onChange={(e) => onInputChange(coverageTypeFieldName, e.target.value)}
      />
    );
  }

  return (
    <Select
      label={COVERAGE_TYPE_FIELD_NAME}
      labelPosition={labelPosition}
      name={DATA_TEST_ID}
      options={coverageTypeList}
      clearable={false}
      searchable={false}
      openMenuOnFocus={false}
      material={false}
      inheritInputMargins
      required={isCoverageTypeRequired}
      forceErrorMessage={(isOnSubmit || isCoverageTypeRequired) && invalidInputs.includes(coverageTypeFieldName)}
      containerClassName={`${styles.coverageType} ${styles.coverageTypeSelect}`}
      selectedValue={coverageTypeList.find((option) => option.value === coverageType)}
      onChange={(e) => onInputChange(coverageTypeFieldName, e.value)}
    />
  );
};

const getExistingCoverageDataTableNonRequiredFields = ({
  coverageNum,
  benefitPeriod,
  benefitPeriodFieldName,
  catMonthlyBenefit,
  catMonthlyBenefitFieldName,
  onInputChange,
}) => (
  <>
    <td>
      <Input
        placeholder="Benefit Period"
        data-testid={`benefit-period-coverage-${coverageNum}`}
        material={false}
        autoComplete={false}
        value={benefitPeriod}
        containerClass={styles.benefitPeriod}
        onChange={(e) => onInputChange(benefitPeriodFieldName, e.target.value)}
      />
    </td>
    <td className={mainFormStyles.currencyInput}>
      <CurrencyFormat
        placeholder="CAT Benefit"
        data-testid={`cat-benefit-coverage-${coverageNum}`}
        value={
          catMonthlyBenefit !== undefined && catMonthlyBenefit !== null && Number.isInteger(+catMonthlyBenefit)
            ? +catMonthlyBenefit
            : ''
        }
        thousandSeparator
        allowNegative={false}
        fixedDecimalScale
        decimalScale={2}
        name="catMonthlyBenefit"
        prefix="$"
        className={styles.catMonthlyBenefit}
        onValueChange={({ value }) => onInputChange(catMonthlyBenefitFieldName, parseInt(value, DECIMAL_RADIX))}
      />
      <div className={mainFormStyles.currencyErrorMessage}>&nbsp;</div>
    </td>
  </>
);

const getExistingCoverageDataTableHeader = (
  isMonthlyBenefitRequired,
  isEmployerPaidRequired,
  isReplaceCoverageRequired,
) => (
  <thead>
    <tr>
      <th className={`${styles.monthlyBenefit} ${isMonthlyBenefitRequired ? styles.cellLabelRequired : ''}`}>
        <strong>Monthly Benefit (Base + SIS)</strong>
      </th>
      <th className={styles.benefitPeriod}>
        <strong>Benefit Period</strong>
      </th>
      <th className={styles.catMonthlyBenefit}>
        <strong>CAT Monthly Benefit</strong>
      </th>
      <th className={`${styles.employerPaid} ${isEmployerPaidRequired ? styles.cellLabelRequired : ''}`}>
        <strong>Employer Paid</strong>
      </th>
      <th className={`${styles.replaceCoverage} ${isReplaceCoverageRequired ? styles.cellLabelRequired : ''}`}>
        <strong>Will Coverage be Replaced?</strong>
      </th>
    </tr>
  </thead>
);

const getExistingCoverageDataTableDropdownFields = ({
  coverageNum,
  employerPaidFieldName,
  replaceCoverageFieldName,
  employerPaid,
  replaceCoverage,
  isEmployerPaidRequired,
  isReplaceCoverageRequired,
  invalidInputs,
  isOnSubmit,
  onInputChange,
}) => (
  <>
    <td>
      <Select
        name={`employer-paid-coverage-${coverageNum}`}
        options={yesNoOptionList}
        clearable={false}
        searchable={false}
        openMenuOnFocus={false}
        material={false}
        inheritInputMargins
        required={isEmployerPaidRequired}
        forceErrorMessage={(isOnSubmit || isEmployerPaidRequired) && invalidInputs.includes(employerPaidFieldName)}
        containerClassName={styles.employerPaid}
        selectedValue={yesNoOptionList.find((option) => option.value === employerPaid)}
        onChange={(e) => onInputChange(employerPaidFieldName, e.value)}
      />
    </td>
    <td>
      <Select
        name={`coverage-replace-coverage-${coverageNum}`}
        options={yesNoOptionList}
        clearable={false}
        searchable={false}
        openMenuOnFocus={false}
        material={false}
        inheritInputMargins
        required={isReplaceCoverageRequired}
        forceErrorMessage={
          (isOnSubmit || isReplaceCoverageRequired) && invalidInputs.includes(replaceCoverageFieldName)
        }
        containerClassName={styles.replaceCoverage}
        selectedValue={yesNoOptionList.find((option) => option.value === replaceCoverage)}
        onChange={(e) => onInputChange(replaceCoverageFieldName, e.value)}
      />
    </td>
  </>
);

const getMonthlyBenefitField = ({
  coverageNum,
  monthlyBenefit,
  monthlyBenefitFieldName,
  isMonthlyBenefitRequired,
  isMonthlyBenefitInvalid,
  onInputChange,
  setMonthlyBenefitFieldOnFocus,
}) => (
  <td className={mainFormStyles.currencyInput}>
    <CurrencyFormat
      placeholder="Monthly Benefit"
      data-testid={`monthly-benefit-coverage-${coverageNum}`}
      value={
        monthlyBenefit !== undefined && monthlyBenefit !== null && Number.isInteger(+monthlyBenefit)
          ? +monthlyBenefit
          : ''
      }
      thousandSeparator
      allowNegative={false}
      fixedDecimalScale
      decimalScale={2}
      name="monthlyBenefit"
      prefix="$"
      aria-required={isMonthlyBenefitRequired}
      aria-invalid={isMonthlyBenefitInvalid}
      className={`
        ${styles.monthlyBenefit}
        ${isMonthlyBenefitInvalid ? mainFormStyles.error : ''}
      `}
      onValueChange={({ value }) => onInputChange(monthlyBenefitFieldName, parseInt(value, DECIMAL_RADIX))}
      onFocus={() => setMonthlyBenefitFieldOnFocus(monthlyBenefitFieldName)}
      onBlur={() => setMonthlyBenefitFieldOnFocus('')}
    />
    <div className={mainFormStyles.currencyErrorMessage} aria-live={isMonthlyBenefitInvalid ? 'assertive' : 'off'}>
      {isMonthlyBenefitInvalid ? 'Please enter a value' : '\u00A0'}
    </div>
  </td>
);

const getExistingCoverageDataTable = ({
  coverageNum,
  companyName,
  coverageType,
  monthlyBenefit,
  benefitPeriod,
  catMonthlyBenefit,
  employerPaid,
  replaceCoverage,
  monthlyBenefitFieldName,
  benefitPeriodFieldName,
  catMonthlyBenefitFieldName,
  employerPaidFieldName,
  replaceCoverageFieldName,
  isOnSubmit,
  invalidInputs,
  monthlyBenefitFieldOnFocus,
  onInputChange,
  setMonthlyBenefitFieldOnFocus,
}) => {
  const isReplaceCoverage = replaceCoverage === YES_OPTION;
  const isMonthlyBenefitRequired = Boolean(companyName?.trim() || coverageType?.trim() || isReplaceCoverage);
  const isEmployerPaidRequired = Boolean(
    companyName?.trim() || coverageType?.trim() || monthlyBenefit > 0 || isReplaceCoverage,
  );
  const isReplaceCoverageRequired = Boolean(companyName?.trim() || coverageType?.trim() || monthlyBenefit > 0);

  const isMonthlyBenefitInvalid =
    monthlyBenefitFieldOnFocus !== monthlyBenefitFieldName && invalidInputs.includes(monthlyBenefitFieldName);

  return (
    <div className={styles.formRow}>
      <table className={styles.benefitTable}>
        {getExistingCoverageDataTableHeader(
          isMonthlyBenefitRequired,
          isEmployerPaidRequired,
          isReplaceCoverageRequired,
        )}
        <tbody>
          <tr>
            {getMonthlyBenefitField({
              coverageNum,
              monthlyBenefit,
              monthlyBenefitFieldName,
              isMonthlyBenefitRequired,
              isMonthlyBenefitInvalid,
              onInputChange,
              setMonthlyBenefitFieldOnFocus,
            })}
            {getExistingCoverageDataTableNonRequiredFields({
              coverageNum,
              benefitPeriod,
              benefitPeriodFieldName,
              catMonthlyBenefit,
              catMonthlyBenefitFieldName,
              onInputChange,
            })}
            {getExistingCoverageDataTableDropdownFields({
              coverageNum,
              employerPaidFieldName,
              replaceCoverageFieldName,
              employerPaid,
              replaceCoverage,
              isEmployerPaidRequired,
              isReplaceCoverageRequired,
              invalidInputs,
              isOnSubmit,
              onInputChange,
            })}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const FORM_FIELD_NAMES = generateFormNames();

const getFieldAndFieldNames = (index, inputData) => {
  const coverageNum = index + 1;
  const companyNameFieldName = FORM_FIELD_NAMES[`existingCoverage${coverageNum}CompanyName`];
  const coverageTypeFieldName = FORM_FIELD_NAMES[`existingCoverage${coverageNum}CoverageType`];
  const monthlyBenefitFieldName = FORM_FIELD_NAMES[`existingCoverage${coverageNum}MonthlyBenefit`];
  const benefitPeriodFieldName = FORM_FIELD_NAMES[`existingCoverage${coverageNum}BenefitPeriod`];
  const catMonthlyBenefitFieldName = FORM_FIELD_NAMES[`existingCoverage${coverageNum}CatMonthlyBenefit`];
  const employerPaidFieldName = FORM_FIELD_NAMES[`existingCoverage${coverageNum}EmployerPaid`];
  const replaceCoverageFieldName = FORM_FIELD_NAMES[`existingCoverage${coverageNum}ReplaceCoverage`];
  const existingPolicyNumberFieldName = FORM_FIELD_NAMES[`existingCoverage${coverageNum}ExistingPolicyNumber`];
  const issueDateFieldName = FORM_FIELD_NAMES[`existingCoverage${coverageNum}IssueDate`];
  const additionalDetailsFieldName = FORM_FIELD_NAMES[`existingCoverage${coverageNum}AdditionalDetails`];

  return {
    companyNameFieldName,
    coverageTypeFieldName,
    monthlyBenefitFieldName,
    benefitPeriodFieldName,
    catMonthlyBenefitFieldName,
    employerPaidFieldName,
    replaceCoverageFieldName,
    existingPolicyNumberFieldName,
    issueDateFieldName,
    additionalDetailsFieldName,
    companyName: inputData[companyNameFieldName],
    coverageType: inputData[coverageTypeFieldName],
    monthlyBenefit: inputData[monthlyBenefitFieldName],
    benefitPeriod: inputData[benefitPeriodFieldName],
    catMonthlyBenefit: inputData[catMonthlyBenefitFieldName],
    employerPaid: inputData[employerPaidFieldName],
    replaceCoverage: inputData[replaceCoverageFieldName],
    existingPolicyNumber: inputData[existingPolicyNumberFieldName],
    issueDate: inputData[issueDateFieldName],
    additionalDetails: inputData[additionalDetailsFieldName],
  };
};

const setMonthlyBenefitInvalidField = ({
  monthlyBenefit,
  monthlyBenefitFieldName,
  trimmedCompanyName,
  trimmedCoverageType,
  isReplaceCoverage,
  invalidFormElements,
}) => {
  const isMonthlyBenefitInvalid =
    monthlyBenefit === undefined || monthlyBenefit === null || Number.isNaN(monthlyBenefit);
  if (isMonthlyBenefitInvalid && (trimmedCompanyName || trimmedCoverageType || isReplaceCoverage)) {
    invalidFormElements.push(monthlyBenefitFieldName);
  }
};

const setDropdownInvalidFields = ({
  trimmedCompanyName,
  trimmedCoverageType,
  isMonthlyBenefit,
  isReplaceCoverage,
  employerPaid,
  replaceCoverage,
  employerPaidFieldName,
  replaceCoverageFieldName,
  invalidFormElements,
}) => {
  const isEmployerPaidRequired = trimmedCompanyName || trimmedCoverageType || isMonthlyBenefit || isReplaceCoverage;

  if (!employerPaid && isEmployerPaidRequired) {
    invalidFormElements.push(employerPaidFieldName);
  }
  if (!replaceCoverage && (trimmedCompanyName || trimmedCoverageType || isMonthlyBenefit)) {
    invalidFormElements.push(replaceCoverageFieldName);
  }
};

const setBenefitRelatedInvalidFields = ({
  companyName,
  coverageType,
  monthlyBenefit,
  employerPaid,
  replaceCoverage,
  companyNameFieldName,
  coverageTypeFieldName,
  monthlyBenefitFieldName,
  employerPaidFieldName,
  replaceCoverageFieldName,
  invalidFormElements,
}) => {
  const trimmedCompanyName = companyName?.trim();
  const trimmedCoverageType = coverageType?.trim();
  const isMonthlyBenefit = monthlyBenefit > 0;
  const isReplaceCoverage = replaceCoverage === YES_OPTION;

  if (!trimmedCompanyName && (trimmedCoverageType || isMonthlyBenefit || isReplaceCoverage)) {
    invalidFormElements.push(companyNameFieldName);
  }
  if (!trimmedCoverageType && (trimmedCompanyName || isMonthlyBenefit || isReplaceCoverage)) {
    invalidFormElements.push(coverageTypeFieldName);
  }
  setMonthlyBenefitInvalidField({
    monthlyBenefit,
    monthlyBenefitFieldName,
    trimmedCompanyName,
    trimmedCoverageType,
    isReplaceCoverage,
    invalidFormElements,
  });
  setDropdownInvalidFields({
    trimmedCompanyName,
    trimmedCoverageType,
    isMonthlyBenefit,
    isReplaceCoverage,
    employerPaid,
    replaceCoverage,
    employerPaidFieldName,
    replaceCoverageFieldName,
    invalidFormElements,
  });
};

const getInvalidFormElements = (inputData) => {
  const invalidFormElements = [];

  numCoveragesToIterate.forEach((index) => {
    const {
      companyNameFieldName,
      companyName,
      coverageTypeFieldName,
      coverageType,
      monthlyBenefitFieldName,
      monthlyBenefit,
      employerPaidFieldName,
      employerPaid,
      replaceCoverageFieldName,
      replaceCoverage,
      existingPolicyNumberFieldName,
      existingPolicyNumber,
      issueDateFieldName,
      issueDate,
    } = getFieldAndFieldNames(index, inputData);

    setBenefitRelatedInvalidFields({
      companyName,
      coverageType,
      monthlyBenefit,
      employerPaid,
      replaceCoverage,
      companyNameFieldName,
      coverageTypeFieldName,
      monthlyBenefitFieldName,
      employerPaidFieldName,
      replaceCoverageFieldName,
      invalidFormElements,
    });

    if (replaceCoverage === YES_OPTION) {
      if (!existingPolicyNumber?.trim()) {
        invalidFormElements.push(existingPolicyNumberFieldName);
      }
      if (!issueDate?.trim()) {
        invalidFormElements.push(issueDateFieldName);
      }
    }
  });

  return invalidFormElements;
};

const getExistingPolicyAndAdditionalInfoQuestions = ({
  coverageNum,
  isReplaceCoverage,
  invalidInputs,
  existingPolicyNumberFieldName,
  existingPolicyNumber,
  issueDateFieldName,
  issueDate,
  additionalDetailsFieldName,
  additionalDetails,
  formType,
  isOnSubmit,
  onInputChange,
}) => (
  <>
    <div className={styles.formRow}>
      <Input
        label={POLICY_NUMBER_FIELD_NAME}
        labelKind={labelKind}
        placeholder={POLICY_NUMBER_FIELD_NAME}
        data-testid={`policy-number-coverage-${coverageNum}`}
        material={false}
        autoComplete={false}
        required={isReplaceCoverage}
        forceErrorMessage={(isOnSubmit || isReplaceCoverage) && invalidInputs.includes(existingPolicyNumberFieldName)}
        containerClass={styles.policyNumber}
        value={existingPolicyNumber}
        onChange={(e) => onInputChange(existingPolicyNumberFieldName, e.target.value)}
      />
      <DatePicker
        label="Issue Date"
        labelPosition={labelPosition}
        placeholder="mm/dd/yyyy"
        muteValidation={!isReplaceCoverage}
        required={isReplaceCoverage}
        forceErrorMessage={(isOnSubmit || isReplaceCoverage) && invalidInputs.includes(issueDateFieldName)}
        inputProps={{
          'data-testid': `issue-date-coverage-${coverageNum}`,
        }}
        labelProps={{ className: styles.noBoldLabel }}
        value={issueDate}
        onChange={(date) => onInputChange(issueDateFieldName, date ?? '')}
      />
    </div>
    {formType === 'cornerstone' && (
      <div className={styles.additionalDetails}>
        <TextArea
          groupLabel={ADDITIONAL_DETAILS_FIELD_NAME}
          groupLabelKind={labelKind}
          maxLength={500}
          placeholderText={ADDITIONAL_DETAILS_FIELD_NAME}
          defaultValue={additionalDetails}
          textAreaProps={{
            'data-testid': `additional-details-coverage-${coverageNum}`,
            style: { resize: 'vertical' },
            onChange: (e) => onInputChange(additionalDetailsFieldName, e.target.value),
          }}
        />
      </div>
    )}
  </>
);

const getExistingCoverageFormQuestions = ({
  coverageNum,
  inputData,
  formType,
  invalidInputs,
  isOnSubmit,
  monthlyBenefitFieldOnFocus,
  setMonthlyBenefitFieldOnFocus,
  onInputChange,
}) => {
  const {
    companyNameFieldName,
    companyName,
    coverageTypeFieldName,
    coverageType,
    monthlyBenefitFieldName,
    monthlyBenefit,
    benefitPeriodFieldName,
    benefitPeriod,
    catMonthlyBenefitFieldName,
    catMonthlyBenefit,
    employerPaidFieldName,
    employerPaid,
    replaceCoverageFieldName,
    replaceCoverage,
    existingPolicyNumberFieldName,
    existingPolicyNumber,
    issueDateFieldName,
    issueDate,
    additionalDetailsFieldName,
    additionalDetails,
  } = getFieldAndFieldNames(coverageNum - 1, inputData);

  const isReplaceCoverage = replaceCoverage === YES_OPTION;
  const isCompanyNameRequired = Boolean(coverageType?.trim()) || monthlyBenefit > 0 || isReplaceCoverage;

  return (
    <>
      <div className={styles.formRow}>
        <Input
          label={COMPANY_NAME_FIELD_NAME}
          placeholder={COMPANY_NAME_FIELD_NAME}
          data-testid={`company-name-coverage-${coverageNum}`}
          material={false}
          autoComplete={false}
          required={isCompanyNameRequired}
          forceErrorMessage={(isOnSubmit || isCompanyNameRequired) && invalidInputs.includes(companyNameFieldName)}
          containerClass={styles.companyName}
          value={companyName}
          onChange={(e) => onInputChange(companyNameFieldName, e.target.value)}
        />
        {getCoverageTypeField({
          formType,
          coverageNum,
          companyName,
          coverageType,
          monthlyBenefit,
          replaceCoverage,
          coverageTypeFieldName,
          isOnSubmit,
          invalidInputs,
          onInputChange,
        })}
      </div>
      {getExistingCoverageDataTable({
        coverageNum,
        companyName,
        coverageType,
        monthlyBenefit,
        benefitPeriod,
        catMonthlyBenefit,
        employerPaid,
        replaceCoverage,
        monthlyBenefitFieldName,
        benefitPeriodFieldName,
        catMonthlyBenefitFieldName,
        employerPaidFieldName,
        replaceCoverageFieldName,
        isOnSubmit,
        invalidInputs,
        monthlyBenefitFieldOnFocus,
        onInputChange,
        setMonthlyBenefitFieldOnFocus,
      })}
      {getExistingPolicyAndAdditionalInfoQuestions({
        coverageNum,
        isReplaceCoverage,
        invalidInputs,
        existingPolicyNumberFieldName,
        existingPolicyNumber,
        issueDateFieldName,
        issueDate,
        additionalDetailsFieldName,
        additionalDetails,
        formType,
        isOnSubmit,
        onInputChange,
      })}
    </>
  );
};

const ExistingCoverages = ({
  formType,
  existingInputData,
  setFormInputData,
  setIsFormInvalidMap,
  isOnSubmit = false,
}) => {
  const [inputData, setInputData] = useState(existingInputData);
  const [invalidInputs, setInvalidInputs] = useState([]);
  const [monthlyBenefitFieldOnFocus, setMonthlyBenefitFieldOnFocus] = useState('');

  useEffect(() => {
    const invalidFormElements = getInvalidFormElements(inputData);
    setInvalidInputs(invalidFormElements);
    setIsFormInvalidMap((map) => {
      map.set('existingCoverages', invalidFormElements.length > 0);
      return map;
    });
  }, [inputData]);

  const onInputChange = (inputName, value) => {
    setInputData((data) => ({
      ...data,
      [inputName]: value,
    }));
    setFormInputData((formData) => ({
      ...formData,
      [inputName]: value,
    }));
  };

  return (
    <div className={styles.form}>
      {numCoveragesToIterate.map((index) => {
        const coverageNum = index + 1;
        return (
          <section key={`existingCoverage-${coverageNum}`}>
            <h3>COVERAGE #{coverageNum}</h3>
            <div className={styles.coverageSection}>
              {getExistingCoverageFormQuestions({
                coverageNum,
                inputData,
                formType,
                invalidInputs,
                isOnSubmit,
                monthlyBenefitFieldOnFocus,
                setMonthlyBenefitFieldOnFocus,
                onInputChange,
              })}
            </div>
          </section>
        );
      })}
    </div>
  );
};

export default ExistingCoverages;
