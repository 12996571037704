import { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Container, Grid, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';
import { withTransaction } from '@elastic/apm-rum-react';
import { Button, CheckBox, Select } from 'voltron';

import { runAdminReports } from 'services/Services';
import { getEmployerEnrollmentPeriods } from 'state/actions/employers';
import { pageLoaded, pageLoading } from 'state/actions/page_loading';
import { setCriticalError } from 'state/actions/error';
import { downloadFile, formatAPIMessage } from 'utils/utils';
import styles from './AdminReports.module.scss';

const FORM_FIELD_NAMES = {
  reportIds: 'reportIds',
  employerId: 'employerId',
  enrollmentPeriodIds: 'enrollmentPeriodIds',
};

const reportOptions = [
  { label: 'Actively At Work', value: 1 },
  { label: 'Health Question Details', value: 2 },
  { label: 'Existing Insurance Details & 5b', value: 3 },
  { label: 'US Citizen answered No', value: 4 },
  { label: 'Tobacco answer Yes', value: 5 },
  { label: 'User Id extract', value: 6 },
];

const labelKind = 'regular';
const labelPosition = 'top';

const getInvalidFormElements = (inputData) => {
  const invalidFormElements = [];

  if (!inputData.reportIds.length) {
    invalidFormElements.push(FORM_FIELD_NAMES.reportIds);
  }
  if (!inputData.employerId) {
    invalidFormElements.push(FORM_FIELD_NAMES.employerId);
  }
  if (!inputData.enrollmentPeriodIds?.length) {
    invalidFormElements.push(FORM_FIELD_NAMES.enrollmentPeriodIds);
  }

  return invalidFormElements;
};

const getFormBody = ({
  inputData,
  isReportIdsInvalid,
  recreateEmployerDropdown,
  recreateEnrollmentPeriodsDropdown,
  employerOptionData,
  enrollmentPeriodOptions,
  onCheckboxChange,
  onInputChange,
  setRecreateEnrollmentPeriodsDropdown,
}) => (
  <div className={styles.formBody}>
    <span className={styles.cellLabelRequired}>Select Report</span>
    <Grid container spacing={2} className={`${styles.listContainer} ${isReportIdsInvalid ? styles.invalidList : ''}`}>
      <Grid item xs={12} className={styles.list}>
        <List data-testid="report-options">
          {reportOptions.map((reportOption) => {
            const { label, value } = reportOption;
            return (
              <ListItem disablePadding className={styles.listItem} key={`admin-report-${value}`}>
                <ListItemButton dense>
                  <ListItemText disableTypography primary={label} />
                  <CheckBox
                    checked={inputData.reportIds.includes(value)}
                    onChange={(e) => onCheckboxChange(e, value)}
                    inputProps={{ 'data-testid': `admin-report-${value}-checkbox` }}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Grid>
    </Grid>
    <div className={styles.reportIdsErrorMessage} aria-live={isReportIdsInvalid ? 'assertive' : 'off'}>
      {isReportIdsInvalid ? 'Please select at least one report' : '\u00A0'}
    </div>
    {!recreateEmployerDropdown ? (
      <Select
        label="Select Employer"
        labelKind={labelKind}
        labelPosition={labelPosition}
        name="employer"
        options={employerOptionData}
        clearable={false}
        searchable={false}
        material={false}
        required
        selectedValue={employerOptionData.find((option) => option.value === inputData.employerId) || ''}
        onChange={(e) => {
          onInputChange(FORM_FIELD_NAMES.employerId, e.value);
          onInputChange(FORM_FIELD_NAMES.enrollmentPeriodIds, []);
          setRecreateEnrollmentPeriodsDropdown(true);
        }}
      />
    ) : (
      <div className={styles.employerPlaceholder}>&nbsp;</div>
    )}
    {inputData?.employerId > 0 && !recreateEnrollmentPeriodsDropdown && (
      <Select
        label="Select Enrollment Period"
        labelKind={labelKind}
        labelPosition={labelPosition}
        name="enrollment-period"
        options={enrollmentPeriodOptions}
        multi
        clearable={false}
        searchable={false}
        material={false}
        required
        onChange={(e) => {
          onInputChange(
            FORM_FIELD_NAMES.enrollmentPeriodIds,
            e.map((selected) => selected.value),
          );
        }}
      />
    )}
  </div>
);

const onSubmit = async (inputData, accessToken, dispatch) => {
  try {
    dispatch(pageLoading());
    const { reportIds, employerId, enrollmentPeriodIds } = inputData;
    const { data, headers } = await runAdminReports(accessToken, reportIds, employerId, enrollmentPeriodIds);
    downloadFile(data, headers);
  } catch (error) {
    dispatch(
      setCriticalError({
        title: 'Run admin reports failed',
        body: formatAPIMessage(error),
        button: 'OK',
      }),
    );
  } finally {
    dispatch(pageLoaded());
  }
};

const getButtons = ({
  invalidFormData,
  inputData,
  accessToken,
  dispatch,
  setInputData,
  setIsInitialReportList,
  setRecreateEmployerDropdown,
  setRecreateEnrollmentPeriodsDropdown,
}) => (
  <div className={styles.buttons}>
    <Button
      kind="invert"
      data-testid="cancel-button"
      onClick={() => {
        setInputData({
          reportIds: [],
          employerId: 0,
          enrollmentPeriodId: 0,
        });
        setIsInitialReportList(true);
        setRecreateEmployerDropdown(true);
        setRecreateEnrollmentPeriodsDropdown(true);
      }}
    >
      CANCEL
    </Button>
    <Button
      data-testid="run-button"
      className={styles.runButton}
      disabled={invalidFormData.length > 0}
      onClick={() => onSubmit(inputData, accessToken, dispatch)}
    >
      RUN
    </Button>
  </div>
);

const AdminReports = () => {
  const dispatch = useDispatch();
  const { oktaAuth } = useOktaAuth();
  const { employerEnrollmentPeriods } = useSelector((state) => state.employers);
  const [isInitialReportList, setIsInitialReportList] = useState(true);
  const [inputData, setInputData] = useState({
    reportIds: [],
    employerId: 0,
    enrollmentPeriodId: 0,
  });
  const [invalidFormData, setInvalidFormData] = useState([]);
  // have to recreate the dropdowns to reset their states entirely
  const [recreateEmployerDropdown, setRecreateEmployerDropdown] = useState(false);
  const [recreateEnrollmentPeriodsDropdown, setRecreateEnrollmentPeriodsDropdown] = useState(false);

  const accessToken = oktaAuth.getAccessToken();

  useEffect(() => {
    dispatch(getEmployerEnrollmentPeriods(accessToken));
  }, []);

  const employerOptionData = useMemo(
    () =>
      employerEnrollmentPeriods.map((employer) => ({
        label: employer.name,
        value: employer.employerId,
        enrollmentPeriodOptions: employer.enrollmentPeriods.map((enrollmentPeriod) => ({
          label: enrollmentPeriod.name,
          value: enrollmentPeriod.id,
        })),
      })),
    [employerEnrollmentPeriods],
  );

  const enrollmentPeriodOptions =
    employerOptionData.find((employer) => employer.value === inputData.employerId)?.enrollmentPeriodOptions || [];

  const isReportIdsInvalid = !isInitialReportList && invalidFormData.includes(FORM_FIELD_NAMES.reportIds);

  useEffect(() => {
    setInvalidFormData(getInvalidFormElements(inputData));
  }, [inputData]);

  useEffect(() => {
    if (recreateEmployerDropdown) {
      setRecreateEmployerDropdown(false);
    }
  }, [recreateEmployerDropdown]);

  useEffect(() => {
    if (recreateEnrollmentPeriodsDropdown) {
      setRecreateEnrollmentPeriodsDropdown(false);
    }
  }, [recreateEnrollmentPeriodsDropdown]);

  const onInputChange = (inputName, value) => {
    setInputData((prevInputData) => ({
      ...prevInputData,
      [inputName]: value,
    }));
  };

  const onCheckboxChange = (e, reportId) => {
    setIsInitialReportList(false);
    let selectedReportIds = inputData.reportIds;
    if (e.target.checked) {
      selectedReportIds.push(reportId);
    } else {
      selectedReportIds = selectedReportIds.filter((selectedReportId) => selectedReportId !== reportId);
    }
    onInputChange(FORM_FIELD_NAMES.reportIds, selectedReportIds);
  };

  return (
    <Box sx={{ width: '82vw', marginTop: '4rem' }}>
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className={styles.page}>
              <header>
                <h3>Reports</h3>
              </header>
              <form autoComplete="off">
                {getFormBody({
                  inputData,
                  isReportIdsInvalid,
                  recreateEmployerDropdown,
                  recreateEnrollmentPeriodsDropdown,
                  employerOptionData,
                  enrollmentPeriodOptions,
                  onCheckboxChange,
                  onInputChange,
                  setRecreateEnrollmentPeriodsDropdown,
                })}
                {getButtons({
                  invalidFormData,
                  inputData,
                  accessToken,
                  dispatch,
                  setInputData,
                  setIsInitialReportList,
                  setRecreateEmployerDropdown,
                  setRecreateEnrollmentPeriodsDropdown,
                })}
              </form>
            </div>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default withTransaction('AdminReports', 'component')(AdminReports);
