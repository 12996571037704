const routes = {
  api: '/api',
  home: '/',
  login: '/login',
  notFound: '/404',
  userDashboard: '/dashboard',
  applicantDashboard: '/dashboard/applicant',
  uploads: '/uploads',
  enrollment: '/forms/primary/:id',
  increases: '/forms/increases/:id',
  users: '/users',
  userForm: '/user/:id',
  businesses: '/businesses',
  businessForm: '/admin/business/:id',
  employerApplicantsDashboard: '/dashboard/business/:id',
  employerApplicantsDashboardForUser: '/dashboard/user/:id',
  adminReports: '/adminReports',
  agencies: '/agencies',
  agencyForm: '/agency/:id',
  owningCompanies: '/owners',
  owningCompanyForm: '/owner/:id',
};

export default routes;
