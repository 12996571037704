import React, { useState } from 'react';
import { Button } from 'voltron';
import ReactPlayer from 'react-player';
import { Grid } from '@mui/material';
import Styles from '../navigation/NavigationTabs.module.scss';

function DizTabContent({ Introduction, setCurrentTab }) {
  const [play, setPlay] = useState(false);
  const handlePlay = () => {
    setPlay(true);
  };

  return (
    <div>
      <div className={Styles.content}>
        <p>
          Apply in three easy steps:
          <br />
          1) Watch this brief video to understand the benefits of disability insurance.
        </p>

        {Introduction !== 0 && (
          <div data-testid="videocontainer">
            <ReactPlayer
              controls
              onPlay={handlePlay}
              url={`https://player.vimeo.com/video/${
                Introduction?.vimeoData?.videoUrl?.split('.com').pop().split('/')[1]
              }?h=${Introduction.vimeoData.videoUrl.split('.com').pop().split('/').pop()}&amp;app_id=122963`}
              width="700px"
              height="394px"
              playing={false}
            />
          </div>
        )}
        <p>
          2) View your personalized plan design.
          <br />
          3) Complete a few questions and sign your application(s).
        </p>
        <Grid item xs={12} justifyContent="flex-end" container>
          <Button data-testid="continue" disabled={!play} onClick={() => setCurrentTab(2)}>
            CONTINUE
          </Button>
        </Grid>
      </div>
    </div>
  );
}

export default DizTabContent;
