/* eslint-disable react/no-array-index-key */
import { Box, Tab, Tabs, styled } from '@mui/material';
import { NO_LABEL, YES_LABEL, YES_OPTION } from 'constants/index';

export const formatPhoneNumber = (number) => {
  const cleaned = `${number}`.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return null;
};

export const formatDate = (inputDate) => {
  const date = inputDate.getDate().toString().padStart(2, '0');
  const month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
  const year = inputDate.getFullYear();
  return `${month}/${date}/${year}`;
};

export const formatSSN = (number) => {
  const cleaned = number?.toString().replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{2})(\d{4})$/);
  if (match) {
    return `${match[1]}-${match[2]}-${match[3]}`;
  }
  return null;
};

export const isAdminUser = (userInfo) => userInfo?.digsi_groups[0] === 'DIGSI Admin';
export const isNonApplicantUser = (userInfo) => userInfo?.digsi_groups[0] === 'DIGSI User';
export const isApplicantUser = (userInfo) => userInfo?.digsi_groups[0] === 'DIGSI Applicant';

const extractFileNameFromHeader = (name) => {
  if (name?.includes('attachment; filename=')) {
    return name.replace('attachment; filename=', '');
  }
  return '';
};

export const downloadFile = (data, headers, openInWindow = false) => {
  const fileName = extractFileNameFromHeader(headers['content-disposition']);
  const blob = new Blob([data], { type: headers['content-type'] });

  if (window.navigator?.msSaveOrOpenBlob) {
    // IE
    window.navigator.msSaveOrOpenBlob(blob, fileName);
  } else if (!openInWindow) {
    // all other browsers
    const downloadUrl = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = downloadUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
  } else {
    const downloadUrl = URL.createObjectURL(blob);
    window.open(downloadUrl);
  }
};

const addResponseMessages = (responseModelStateKeyObjects, messages) => {
  for (let i = 0; i < responseModelStateKeyObjects.length; i++) {
    messages.push(responseModelStateKeyObjects[i]);
  }
};

export const formatAPIMessage = (response) => {
  const responseMessages = [];
  const responseModelState = response?.modelState;
  if (responseModelState) {
    Object.keys(responseModelState).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(responseModelState, key)) {
        addResponseMessages(responseModelState[key], responseMessages);
      }
    });
  } else {
    responseMessages.push(response.message);
  }

  return responseMessages.join(' ');
};

export const getApplicantAddressSummary = (formInput) => (
  <>
    <tr>
      <td>Address</td>
      <td>{formInput.address}</td>
    </tr>
    <tr>
      <td>City</td>
      <td>{formInput.city}</td>
    </tr>
    <tr>
      <td>State</td>
      <td>{formInput.stateCd}</td>
    </tr>
    <tr>
      <td>Zip</td>
      <td>{formInput.zipCode}</td>
    </tr>
  </>
);

export const getExistingCompaniesForApplicationSummary = (formInput) => {
  const existingCompanies = [];
  let showMonthlyBenefit = false;
  let showCatMonthlyBenefit = false;

  [...Array(3).keys()].forEach((positionValue) => {
    const existingCompanyIndex = positionValue + 1;
    const companyName = formInput[`existingCoverage-${existingCompanyIndex}-companyName`];
    if (!companyName) {
      return;
    }

    const company = {
      name: companyName,
      monthlyBenefit:
        formInput[`existingCoverage-${existingCompanyIndex}-monthlyBenefit`]?.toString().replace('.00', '') || 0,
      catMonthlyBenefit: formInput[`existingCoverage-${existingCompanyIndex}-catMonthlyBenefit`] || 0,
      replacing: formInput[`existingCoverage-${existingCompanyIndex}-replaceCoverage`],
    };

    if (parseFloat(company.monthlyBenefit) > 0) {
      showMonthlyBenefit = true;
    } else {
      company.monthlyBenefit = 0;
    }

    if (parseFloat(company.catMonthlyBenefit) > 0) {
      showCatMonthlyBenefit = true;
    } else {
      company.catMonthlyBenefit = 0;
    }

    existingCompanies.push(company);
  });

  return { existingCompanies, showMonthlyBenefit, showCatMonthlyBenefit };
};

export const ExistingCompaniesApplicationSummary = ({ existingCompaniesObject, styles }) => {
  const { existingCompanies, showMonthlyBenefit, showCatMonthlyBenefit } = existingCompaniesObject;
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {!existingCompanies.length ? (
        <p>
          <strong>None</strong>
        </p>
      ) : (
        <table className={styles.existingCoverageSummaryTable}>
          <tbody>
            <tr>
              <td>Company Name</td>
              {existingCompanies.map((company, index) => (
                <td key={`existing-company-name-${index}`}>{company.name}</td>
              ))}
            </tr>
            {showMonthlyBenefit ? (
              <tr>
                <td>Monthly Benefit</td>
                {existingCompanies.map((company, index) => (
                  <td key={`existing-company-benefit-${index}`}>{company.monthlyBenefit}</td>
                ))}
              </tr>
            ) : null}
            {showCatMonthlyBenefit ? (
              <tr>
                <td>CAT Monthly Benefit</td>
                {existingCompanies.map((company, index) => (
                  <td key={`existing-company-cat-benefit-${index}`}>{company.catMonthlyBenefit}</td>
                ))}
              </tr>
            ) : null}
            <tr>
              <td>Replacing</td>
              {existingCompanies.map((company, index) => (
                <td key={`existing-company-replacing-${index}`}>
                  {(company.replacing === YES_OPTION ? YES_LABEL : NO_LABEL).toLowerCase()}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      )}
    </>
  );
};

export const ElectronicTransactionsSummary = ({ input = {}, styles, isIncreases = false }) => {
  const isConsentGiven = input.yesOrNoTransactions === YES_OPTION;
  return (
    <>
      <p className={styles.sectionHeader}>
        <strong>Electronic Transactions and Delivery of Documents</strong>
      </p>
      <table className={styles.summaryTable}>
        <tbody>
          <tr>
            <td>Do you consent:</td>
            <td>{isConsentGiven ? YES_LABEL : NO_LABEL}</td>
          </tr>
          {isConsentGiven ? (
            <tr>
              <td>If &quot;yes&quot;, email address provided:</td>
              <td>{isIncreases ? input.email : input.emailForETD}</td>
            </tr>
          ) : null}
        </tbody>
      </table>
    </>
  );
};

export const getInvalidEmailAndEmailVerify = (
  emailAddress,
  emailAddressVerify,
  isValidEmailAddress,
  isValidEmailAddressVerify,
) => {
  const invalidFormElements = [];

  if (!emailAddress?.trim() || !isValidEmailAddress) {
    invalidFormElements.push('emailAddress');
  }
  if (!emailAddressVerify?.trim() || !isValidEmailAddressVerify) {
    invalidFormElements.push('emailAddressVerify');
  }

  return invalidFormElements;
};

export const AntTabs = styled(Tabs)(() => ({
  borderBottom: '1px solid #e8e8e8',
  '& .MuiTabs-indicator': {
    backgroundColor: '#c2359b',
    height: '3px',
  },
}));

export const AntTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
  minWidth: 0,
  [theme.breakpoints.up('sm')]: {
    minWidth: 0,
  },
  marginRight: theme.spacing(1),
  textTransform: 'uppercase',
  color: '#595959',
  paddingLeft: '24px',
  paddingRight: '24px',
  fontSize: '14px',
  fontWeight: '500',
  letterSpacing: 'normal',
  '&:hover': {
    color: '#333333',
    opacity: 1,
  },
  '&:focus': {
    color: '#333333',
    opacity: 1,
    border: '1px solid #000',
    borderStyle: 'dotted',
  },
  '&.Mui-selected': {
    color: '#333333',
    fontWeight: 600,
  },
  '&.Mui-focusVisible': {
    border: '1px solid #000',
    borderStyle: 'dotted',
  },
  '& .MuiTouchRipple-root': {
    display: 'none',
  },
  '&.Mui-disabled': {
    backgroundColor: '#CCCCCC',
    color: '#595959',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
  },
}));

export const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};
