import React, { useEffect, useState } from 'react';
import FormHeader from 'components/form/FormHeader';
import { Input, Select, Tooltip, Button, TextArea } from 'voltron';
import { Grid } from '@mui/material';
import { validateSSN, validateZip } from 'utils/validator';
import { useDispatch, useSelector } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';
import { getApplicantInfo, saveFormInputs } from 'services/Services';
import { updateApplicantData } from 'state/actions/applicantForm';
import { statesData } from 'constants/states';
import HelpIcon from '@mui/icons-material/Help';
import { formCodes } from 'constants/index';
import { setCriticalError } from 'state/actions/error';
import { formatAPIMessage } from 'utils/utils';
import styles from './applicant.module.scss';

const validate = (values) => {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = 'firstName';
  }
  if (!values.lastName) {
    errors.lastName = 'lastName';
  }
  if (!values.address) {
    errors.address = 'address';
  }
  if (!values.city) {
    errors.city = 'city';
  }
  if (!values.dob) {
    errors.dob = 'dob';
  }
  if (!values.stateCd) {
    errors.stateCd = 'stateCd';
  }
  if (!validateSSN(values.ssn)) {
    errors.ssn = 'ssn';
  }
  if (!validateZip(values.zipCode)) {
    errors.zipCode = 'zipCode';
  }
  return errors;
};

const labelKind = 'regular';
function Applicant({ setSelectedTabIndex }) {
  const { applicationId, formCode, input = {} } = useSelector((state) => state.applicantForm.data ?? {});
  const { oktaAuth } = useOktaAuth();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    firstName: '',
    middleInitial: '',
    lastName: '',
    suffix: '',
    address: '',
    city: '',
    stateCd: '',
    zipCode: '',
    ssn: '',
    dob: '',
    existingInsuranceComments: '',
  });
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);
  const [height, setHeight] = useState(500);

  const handleInputChange = (e) => {
    const { name, value, isValid } = e.target;
    setFormData({ ...formData, [name]: value });
    setFormError({
      ...formError,
      [name]: value === '' || !isValid ? name : undefined,
    });
  };

  const getApplicantInfoSave = (accessToken, param) => {
    getApplicantInfo(accessToken, param)
      .then((res) => {
        const apiResp = res;
        if (res) {
          dispatch(updateApplicantData(apiResp));
        } else {
          dispatch(updateApplicantData(null));
        }
      })
      .catch(() => {
        dispatch(updateApplicantData(null));
      });
  };

  const handleSubmit = () => {
    const errors = validate(formData);
    setFormError(errors);
    if (Object.keys(errors).length === 0) {
      const accessToken = oktaAuth.getAccessToken();
      const request = {
        userFormId: applicationId,
        input: formData,
      };
      setLoading(true);
      saveFormInputs(accessToken, request)
        .then(() => {
          setLoading(false);
          getApplicantInfoSave(accessToken, applicationId);
          setSelectedTabIndex(1);
        })
        .catch((err) => {
          dispatch(
            setCriticalError({
              title: 'Save applicant error',
              body: formatAPIMessage(err),
              button: 'OK',
            }),
          );
        });
    }
  };

  useEffect(() => {
    if (Object.keys(input).length) {
      setFormData({
        firstName: input.firstName,
        middleInitial: input.middleInitial ?? '',
        lastName: input.lastName,
        suffix: input.suffix ?? '',
        address: input.address ?? '',
        city: input.city ?? '',
        stateCd: input.stateCd,
        zipCode: input.zipCode ?? '',
        ssn: input.ssn ?? '',
        dob: input.dob ?? '',
        existingInsuranceComments: input.existingInsuranceComments ?? '',
      });
    }
    setHeight(document.body.scrollHeight);
  }, [input]);

  return (
    <div>
      {loading && (
        <div className="globalloader" style={{ height: `${height}px` }}>
          <div className="loadertext">Please wait ...</div>
        </div>
      )}
      <FormHeader />
      <form className={styles.applicantForm}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Input
              name="firstName"
              label="First Name"
              labelKind={labelKind}
              placeholder="First Name"
              material={false}
              autoComplete={false}
              onChange={handleInputChange}
              value={formData?.firstName}
              data-testid="applicant-firstname"
              required
              kind="name"
              forceErrorMessage={formError.firstName}
            />
          </Grid>
          <Grid item xs>
            <Input
              name="middleInitial"
              labelKind={labelKind}
              label="Middle Name"
              placeholder="Middle Name"
              material={false}
              autoComplete={false}
              onChange={handleInputChange}
              value={formData?.middleInitial}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Input
              name="lastName"
              labelKind={labelKind}
              label="Last Name"
              placeholder="Last Name"
              material={false}
              autoComplete={false}
              onChange={handleInputChange}
              value={formData?.lastName}
              required
              kind="name"
              forceErrorMessage={formError.lastName}
            />
          </Grid>
          <Grid item xs>
            <Input
              name="suffix"
              label="Suffix"
              labelKind={labelKind}
              placeholder="Suffix"
              material={false}
              autoComplete={false}
              onChange={handleInputChange}
              value={formData?.suffix}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Input
              name="address"
              label="Address"
              labelKind={labelKind}
              placeholder="Address"
              material={false}
              autoComplete={false}
              onChange={handleInputChange}
              value={formData?.address}
              kind="text"
              required
              forceErrorMessage={formError.address}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Input
              name="city"
              label="City"
              labelKind={labelKind}
              kind="text"
              placeholder="City"
              material={false}
              autoComplete={false}
              onChange={handleInputChange}
              value={formData?.city}
              required
              forceErrorMessage={formError.city}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Tooltip
              position="right"
              text="If the state showing is incorrect, please contact the agent shown on the screen."
              className="customvoltrontol"
            >
              <HelpIcon />
            </Tooltip>
            <Select
              label="State"
              labelPosition="top"
              labelKind={labelKind}
              placeholder="State"
              name="stateCd"
              options={statesData}
              clearable={false}
              searchable={false}
              material={false}
              disabled
              selectedValue={statesData.find((option) => option.label === formData.stateCd)}
              data-testid="applicant-statecd"
              required
              forceErrorMessage={formError.stateCd}
            />
          </Grid>
          <Grid item xs>
            <Input
              name="zipCode"
              label="Zip"
              kind="zip"
              id="zip"
              labelKind={labelKind}
              placeholder="Zip"
              material={false}
              autoComplete={false}
              onChange={handleInputChange}
              value={formData?.zipCode}
              data-testid="applicant-zipcode"
              required
              forceErrorMessage={formError.zipCode}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={8} id="ssnnumber">
            <Input
              kind="ssn"
              labelKind={labelKind}
              name="ssn"
              label="Social Security Number"
              placeholder="Social Security Number"
              material={false}
              autoComplete={false}
              onChange={handleInputChange}
              value={formData?.ssn}
              required
              forceErrorMessage={formError.ssn}
            />
          </Grid>
          <Grid item xs>
            <Input
              label="Date of Birth"
              kind="date"
              name="dob"
              labelKind={labelKind}
              placeholder="mm/dd/yyyy"
              required
              material={false}
              autoComplete={false}
              value={formData.dob}
              forceErrorMessage={formError.dob}
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {[formCodes.FOUNDATION, formCodes.COMBINED].includes(formCode) && (
              <TextArea
                groupLabel={`Provide benefit amount and type (individual, group etc) of any Disability Insurance
                  in force or pending since the last time you applied for coverage with us or for which
                  you will become eligible in the next 12 months. Also, provide the policy number and issue date
                  if replacing coverage.`}
                maxLength={500}
                name="existingInsuranceComments"
                defaultValue={formData.existingInsuranceComments}
                textAreaProps={{
                  'data-testid': 'applied-for-insurance-explain-foundation',
                  style: { resize: 'vertical' },
                  onKeyUp: handleInputChange,
                }}
              />
            )}
          </Grid>
          <Grid item xs={12} justifyContent="flex-end" container>
            <Button onClick={handleSubmit}>SAVE & CONTINUE</Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export default Applicant;
