import { getDashboard } from 'services/Services';
import { formatAPIMessage } from 'utils/utils';
import { setCriticalError } from 'state/actions/error';

export const SET_ENROLLMENTS = 'SET_ENROLLMENTS';
export const SET_ENROLLMENTS_LOADING = 'SET_ENROLLMENTS_LOADING';

export const getEnrollments = (accessToken) => async (dispatch) => {
  dispatch({
    type: SET_ENROLLMENTS_LOADING,
    payload: true,
  });
  try {
    const dashboardInfo = await getDashboard(accessToken);
    dispatch({
      type: SET_ENROLLMENTS,
      payload: dashboardInfo.enrollmentListItems || [],
    });
  } catch (error) {
    dispatch(
      setCriticalError({
        title: 'Get enrollments failed',
        body: formatAPIMessage(error),
        button: 'OK',
      }),
    );
  } finally {
    dispatch({
      type: SET_ENROLLMENTS_LOADING,
      payload: false,
    });
  }
};
