import { Toast } from 'voltron';

import { getAllNonApplicantUsers, toggleActiveUser } from 'services/Services';
import { formatDate, downloadFile, formatAPIMessage } from 'utils/utils';
import { setCriticalError } from 'state/actions/error';
import { pageLoading, pageLoaded } from './page_loading';
import {
  getOwnerCompanies,
  getUserRoles,
  getUserEdit,
  saveNonApplicantUser,
  exportNonApplicants,
} from '../../services/Services';
import { SET_OWNING_COMPANIES } from './adminEntities';

export const SET_NON_APPLICANT_USERS_LOADING = 'SET_NON_APPLICANT_USERS_LOADING';
export const SET_NON_APPLICANT_USERS = 'SET_NON_APPLICANT_USERS';
export const TOGGLE_NON_APPLICANT_USER_IS_ACTIVE = 'TOGGLE_NON_APPLICANT_USER_IS_ACTIVE';
export const UPDATE_USER_FORM_DATA = 'UPDATE_USER_FORM_DATA';
export const CLEAR_USER_FORM_DATA = 'CLEAR_USER_FORM_DATA';

export const getNonApplicantUsers = (accessToken) => async (dispatch) => {
  dispatch({
    type: SET_NON_APPLICANT_USERS_LOADING,
    payload: true,
  });
  try {
    const nonApplicantUsersResponse = await getAllNonApplicantUsers(accessToken);
    dispatch({
      type: SET_NON_APPLICANT_USERS,
      payload: nonApplicantUsersResponse.records || [],
    });
  } catch (error) {
    dispatch(
      setCriticalError({
        title: 'Get all non-applicant users failed',
        body: formatAPIMessage(error),
        button: 'OK',
      }),
    );
  } finally {
    dispatch({
      type: SET_NON_APPLICANT_USERS_LOADING,
      payload: false,
    });
  }
};

export const toggleNonApplicantUserIsActive = (accessToken, userId) => (dispatch) => {
  dispatch(pageLoading());
  toggleActiveUser(accessToken, userId)
    .then(() => {
      dispatch({
        type: TOGGLE_NON_APPLICANT_USER_IS_ACTIVE,
        payload: userId,
      });
    })
    .catch((error) => {
      dispatch(
        setCriticalError({
          title: 'Toggle non-applicant user active status failed',
          body: formatAPIMessage(error),
          button: 'OK',
        }),
      );
    })
    .finally(() => {
      dispatch(pageLoaded());
    });
};

export const getUserFormData = (oktaAuth, userId) => async (dispatch) => {
  dispatch(pageLoading());
  try {
    const [ownerCompaniesResp, userRolesResp] = await Promise.all([
      getOwnerCompanies(oktaAuth),
      getUserRoles(oktaAuth),
    ]);

    let userRoles = userRolesResp?.length ? userRolesResp : [];
    let employers = [];
    let agencies = [];
    let formData = {};
    if (userId === '-1') {
      userRoles = userRoles.filter((role) => role.name !== 'Agent' && role.name !== 'Wholesaler');
    }
    formData = await getUserEdit(oktaAuth, userId);
    if (formData.dob) {
      formData.dob = formatDate(new Date(formData.dob));
    }
    if (formData.employers) {
      employers = [...formData.employers];
      delete formData.employers;
    }
    if (formData.agencies) {
      agencies = [...formData.agencies];
      delete formData.agencies;
    }
    formData.region = null;
    dispatch(pageLoaded());
    dispatch({
      type: UPDATE_USER_FORM_DATA,
      payload: {
        userRoles,
        employers,
        agencies,
        formData,
      },
    });
    dispatch({
      type: SET_OWNING_COMPANIES,
      payload: ownerCompaniesResp?.success ? ownerCompaniesResp?.ownerCompanies : [],
    });
  } catch (error) {
    dispatch(
      setCriticalError({
        title: 'Get user form failed',
        body: formatAPIMessage(error),
        button: 'OK',
      }),
    );
  }
};

export const submitUserFormData = (oktaAuth, formData, history) => async (dispatch) => {
  dispatch(pageLoading());
  try {
    const submitFormData = { ...formData };
    const regexForUnformattedPhone = /[^0-9]+/g;
    submitFormData.phone = formData.phone.replace(regexForUnformattedPhone, '');
    if (formData.altPhone) {
      submitFormData.altPhone = formData.altPhone.replace(regexForUnformattedPhone, '');
    }
    delete submitFormData.validListItem;
    await saveNonApplicantUser(oktaAuth, submitFormData);
    dispatch(pageLoaded());
    Toast.success(`User Has Been ${submitFormData.id === 0 ? 'Added.' : 'Updated.'}`);
    dispatch({
      type: CLEAR_USER_FORM_DATA,
    });
    history.push('/users');
  } catch (error) {
    dispatch(
      setCriticalError({
        title: 'Submit user form failed',
        body: formatAPIMessage(error),
        button: 'OK',
      }),
    );
  }
};

export const exportAllNonApplicants = (oktaAuth) => async (dispatch) => {
  dispatch(pageLoading());
  exportNonApplicants(oktaAuth)
    .then(({ data, headers }) => {
      dispatch(pageLoaded());
      downloadFile(data, headers);
    })
    .catch((error) => {
      dispatch(
        setCriticalError({
          title: 'Export non applicant users failed',
          body: formatAPIMessage(error),
          button: 'OK',
        }),
      );
    });
};
