import { useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useDispatch, useSelector } from 'react-redux';
import { formCodes } from 'constants/index';
import { Button } from 'voltron';

import { pageLoaded, pageLoading } from 'state/actions/page_loading';
import FoundationQuestions from './foundationQuestions/FoundationQuestions';
import FundamentalQuestions from './fundamentalQuestions/FundamentalQuestions';
import CornerstoneQuestions from './cornerstoneQuestions/CornerstoneQuestions';
import styles from './ExistingDisabilityInsurance.module.scss';
import ExistingCoverages from './existingCoverages/ExistingCoverages';
import { saveAndRefreshData } from './formUtils';

const getHeaderMessage = (formCode, isBuyUp, coreBaseAmount) => (
  <>
    {(formCode === formCodes.FOUNDATION || formCode === formCodes.COMBINED) && (
      <p>
        In addition to the disability income you are applying for and the coverage shown below (if any); please list all
        disability income in force, currently applied for, or which you will become eligible for in the next 12 months.
        Include individual, group, or any coverage offered through your employer or an association. Provide policy
        number and issue date if replacing coverage.
      </p>
    )}
    {(formCode === formCodes.CORNERSTONE || formCode === formCodes.COMBINED_CORNERSTONE) && (
      <p>
        List all disability income insurance in force, currently applied for, or for which you will become eligible in
        the next 12 months. Specify the type of coverage as individual (I) or group (G). This includes any coverage
        offered through your employer or an association.
      </p>
    )}
    {isBuyUp && (
      <p>
        The monthly benefit that will be paid for by your employer (core), if approved, is{' '}
        {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }).format(
          coreBaseAmount,
        )}
        .
      </p>
    )}
  </>
);

const produceForm = (formCode, existingInputData, setFormInputData, setIsFormInvalidMap, isOnSubmit, isIncreases) => {
  if (isIncreases) {
    return (
      <ExistingCoverages
        formType="cornerstone"
        existingInputData={existingInputData}
        setFormInputData={setFormInputData}
        setIsFormInvalidMap={setIsFormInvalidMap}
        isOnSubmit={isOnSubmit}
      />
    );
  }

  switch (formCode) {
    case formCodes.FOUNDATION:
      return (
        <FoundationQuestions
          existingInputData={existingInputData}
          setFormInputData={setFormInputData}
          setIsFormInvalidMap={setIsFormInvalidMap}
          isOnSubmit={isOnSubmit}
        />
      );
    case formCodes.FUNDAMENTAL:
      return (
        <FundamentalQuestions
          existingInputData={existingInputData}
          setFormInputData={setFormInputData}
          setIsFormInvalidMap={setIsFormInvalidMap}
          isOnSubmit={isOnSubmit}
        />
      );
    case formCodes.CORNERSTONE:
      return (
        <CornerstoneQuestions
          existingInputData={existingInputData}
          setFormInputData={setFormInputData}
          setIsFormInvalidMap={setIsFormInvalidMap}
          isOnSubmit={isOnSubmit}
        />
      );
    case formCodes.COMBINED:
      return (
        <>
          <FoundationQuestions
            existingInputData={existingInputData}
            setFormInputData={setFormInputData}
            setIsFormInvalidMap={setIsFormInvalidMap}
            isOnSubmit={isOnSubmit}
            isCombinedWithAnotherProduct
          />
          <FundamentalQuestions
            existingInputData={existingInputData}
            setFormInputData={setFormInputData}
            setIsFormInvalidMap={setIsFormInvalidMap}
            isOnSubmit={isOnSubmit}
            isCombinedWithAnotherProduct
          />
        </>
      );
    case formCodes.COMBINED_CORNERSTONE:
      return (
        <>
          <CornerstoneQuestions
            existingInputData={existingInputData}
            setFormInputData={setFormInputData}
            setIsFormInvalidMap={setIsFormInvalidMap}
            isOnSubmit={isOnSubmit}
            isCombinedWithAnotherProduct
          />
          <FundamentalQuestions
            existingInputData={existingInputData}
            setFormInputData={setFormInputData}
            setIsFormInvalidMap={setIsFormInvalidMap}
            isOnSubmit={isOnSubmit}
            isCombinedWithAnotherProduct
          />
        </>
      );
    default:
      return null;
  }
};

const ExistingDisabilityInsurance = ({ setSelectedTabIndex, isIncreases = false }) => {
  const { oktaAuth } = useOktaAuth();
  const {
    data: { applicationId, formCode, input: existingInputData, isBuyUp, coreBaseAmt },
  } = useSelector((state) => state.applicantForm);
  const dispatch = useDispatch();
  const [formInputData, setFormInputData] = useState(existingInputData);
  const [isFormInvalidMap, setIsFormInvalidMap] = useState(new Map());
  const [isOnSubmit, setIsOnSubmit] = useState(false);

  useEffect(() => {
    dispatch(applicationId > 0 ? pageLoaded() : pageLoading());
  }, [applicationId]);

  useEffect(() => {
    setFormInputData(existingInputData);
  }, [existingInputData]);

  const onSubmit = async () => {
    setIsOnSubmit(true);
    if (Array.from(isFormInvalidMap.values()).some((isFormInvalid) => isFormInvalid)) {
      return;
    }
    const formData = {
      userFormId: applicationId,
      input: formInputData,
    };
    const accessToken = oktaAuth.getAccessToken();
    await saveAndRefreshData({ accessToken, formData, applicationId, dispatch, setSelectedTabIndex });
  };

  return (
    <div className={styles.mainForm}>
      <header>
        <h3>Existing Disability Insurance</h3>
        {getHeaderMessage(formCode, isBuyUp, coreBaseAmt)}
      </header>
      <form autoComplete="off">
        <div className={styles.formBody}>
          {produceForm(formCode, existingInputData, setFormInputData, setIsFormInvalidMap, isOnSubmit, isIncreases)}
        </div>
        <div className={styles.submitButton}>
          <Button onClick={onSubmit}>SAVE & CONTINUE</Button>
        </div>
      </form>
    </div>
  );
};

export default ExistingDisabilityInsurance;
