import moment from 'moment';
import { Toast } from 'voltron';
import routes from 'constants/routes';
import { formatAPIMessage } from 'utils/utils';
import { setCriticalError } from './error';
import {
  getBusinesses,
  getOwnerCompanies,
  getAgencies,
  getAgents,
  getClassPlanTypes,
  previewFile,
  validateCensusModel,
  uploadCensus,
  getEnrollmentTypes,
  getUserWholesalers,
} from '../../services/Services';
import { SET_EMPLOYERS } from './employers';
import { SET_AGENCIES, SET_OWNING_COMPANIES } from './adminEntities';
import { pageLoaded, pageLoading } from './page_loading';

export const SET_UPLOAD_LOADING_STATE = 'SET_UPLOAD_LOADING_STATE';
export const UPDATE_UPLOAD_CENSUS_DATA = 'UPDATE_UPLOAD_CENSUS_DATA';
export const UPDATE_CENSUS_CLASS_COUNT = 'UPDATE_CENSUS_CLASS_COUNT';
export const UPDATE_UPLOAD_CENSUS_CLASSES = 'UPDATE_UPLOAD_CENSUS_CLASSES';
export const CANCEL_UPLOAD = 'CANCEL_UPLOAD';
export const CLEAR_FORM_DATA = 'CLEAR_FORM_DATA';

const DEFAULT_ENROLLMENT_TYPES = [
  { id: 1, name: 'New Business' },
  { id: 2, name: 'Additions' },
  { id: 3, name: 'Increases' },
];

const setUpdateLoadingState = (loading) => ({
  type: SET_UPLOAD_LOADING_STATE,
  payload: loading,
});

export const getUploadCensusData = (oktaAuth) => async (dispatch) => {
  try {
    dispatch(setUpdateLoadingState(true));
    const [
      employersResp,
      ownerCompaniesResp,
      agenciesResp,
      agentsResp,
      classPlanTypesResp,
      enrollmentTypesResp,
      wholesalersResp,
    ] = await Promise.all([
      getBusinesses(oktaAuth),
      getOwnerCompanies(oktaAuth),
      getAgencies(oktaAuth),
      getAgents(oktaAuth),
      getClassPlanTypes(oktaAuth),
      getEnrollmentTypes(oktaAuth),
      getUserWholesalers(oktaAuth),
    ]);

    if (wholesalersResp?.userWholesalers) {
      wholesalersResp.userWholesalers = wholesalersResp.userWholesalers.map((data) => ({
        ...data,
        dob: moment(data.dob).format('MM/DD/YYYY'),
      }));
    }

    dispatch({
      type: UPDATE_UPLOAD_CENSUS_DATA,
      payload: {
        inProgress: true,
        agents: agentsResp?.success ? agentsResp?.agents : [],
        classPlanTypes: classPlanTypesResp || [],
        wholesalers: wholesalersResp?.userWholesalers ? wholesalersResp?.userWholesalers : [],
        enrollmentTypes: enrollmentTypesResp || DEFAULT_ENROLLMENT_TYPES,
      },
    });
    dispatch({
      type: SET_AGENCIES,
      payload: agenciesResp?.success ? agenciesResp?.agencies : [],
    });
    dispatch({
      type: SET_EMPLOYERS,
      payload: employersResp?.businesses || [],
    });
    dispatch({
      type: SET_OWNING_COMPANIES,
      payload: ownerCompaniesResp?.success ? ownerCompaniesResp?.ownerCompanies : [],
    });
  } catch (error) {
    dispatch(
      setCriticalError({
        title: 'Census Upload Setup Error',
        body: formatAPIMessage(error),
        button: 'OK',
      }),
    );
  } finally {
    dispatch(setUpdateLoadingState(false));
  }
};

export const handleUploadError = (fileResponse) => async (dispatch) => {
  const message = {
    title: fileResponse?.resultsTitle || 'Error uploading file',
    body: '',
    button: 'OK',
  };
  if (fileResponse?.resultsTitle) {
    const rows = [];
    fileResponse?.fileUploadResultsList?.forEach((fileResults) => {
      rows.push(`<tr><th colspan='2' tabIndex='0'>File: ${fileResults.filename}</th></tr>`);
      if (!fileResults.fileUploadErrors || fileResults.fileUploadErrors.length === 0) {
        rows.push("<tr><td colspan='2' tabIndex='0'>(no errors)</td></tr>");
      }
      fileResults.fileUploadErrors?.forEach((error) => {
        rows.push(`<tr><td tabIndex='0'>${error?.message}</td><td>${error?.detail}</td></tr>`);
      });
      // if we hit our error threshold, show the messaging
      if (fileResults.fileUploadErrors?.length >= fileResults?.errorThreshold) {
        rows.push(
          `<tr>
              <td colspan='2' class='error-summary-table-footer' tabIndex='0'>
                ( first ${fileResults?.errorThreshold} errors displayed... )
              </td>
            </tr>`,
        );
      }
    });
    message.body = `<table class='error-summary-table' id='upload-results'><tbody>${rows.join(
      '',
    )}</tbody></table><br/>`;
  } else {
    const errorMessage = fileResponse?.errorMsg || fileResponse?.message || fileResponse?.error;
    message.body = `${fileResponse?.errCode || 'Error'}: ${errorMessage}`; // just a flat exception
  }
  dispatch(setUpdateLoadingState(false));
  dispatch(setCriticalError(message));
};

export const handlePreviewFile = (oktaAuth, file, activeFileItem, setLocalUploaderFile) => async (dispatch) => {
  try {
    dispatch(pageLoading());
    const formData = new FormData();
    formData.append('files', file);
    const fileResponse = await previewFile(oktaAuth, formData);
    if (fileResponse?.success) {
      setLocalUploaderFile(file);
      const reader = new FileReader();
      reader.onload = () => {
        dispatch({
          type: UPDATE_CENSUS_CLASS_COUNT,
          payload: {
            activeFileItem,
            classCount: fileResponse?.classCount,
            fileName: file.name,
            fileType: file.type,
            lastModified: file.lastModified,
            file: reader.result,
          },
        });
      };
      reader.readAsDataURL(file);
    } else {
      dispatch(handleUploadError(fileResponse));
      setLocalUploaderFile(null);
    }
  } catch (error) {
    dispatch(handleUploadError(error));
    setLocalUploaderFile(null);
  } finally {
    dispatch(pageLoaded());
  }
};

export const handleSubmitForCensus = (oktaAuth, formData, history) => async (dispatch) => {
  try {
    dispatch(setUpdateLoadingState(true));
    const form = {
      ...formData,
      censusFiles: [...formData.censusFiles],
    };
    const uploadedList = [];
    form.censusFiles = form.censusFiles.map((census) => {
      const censusObj = { ...census };
      censusObj.selectedFile = { ...census.selectedFile };
      censusObj.classPlan = { ...census.classPlan };
      uploadedList.push({ ...censusObj.selectedFile });
      delete censusObj.selectedFile.file;
      return censusObj;
    });

    if (!Object.keys(form.agent2).includes('id') || form?.agent2?.id === 'no-agent') {
      delete form.agent2;
    }
    if (!Object.keys(form.agent3).includes('id') || form?.agent3?.id === 'no-agent') {
      delete form.agent3;
    }
    if (!Object.keys(form.assignmentOfOwnership).includes('id')) {
      delete form.assignmentOfOwnership;
    }

    await validateCensusModel(oktaAuth, form);
    let fileCounter = 0;

    const requestData = new FormData();
    requestData.append('model', JSON.stringify(form));

    uploadedList.forEach(async (census) => {
      const dataURL = await fetch(census.file);
      const blob = await dataURL.blob();
      const file = new File([blob], census.name, { type: census.type });
      fileCounter += 1;
      requestData.append('files', file);
      if (+fileCounter === +form.numberOfFiles) {
        try {
          await uploadCensus(oktaAuth, requestData);
          Toast.success('Files have been uploaded');
          dispatch({
            type: CLEAR_FORM_DATA,
          });
          history.push(routes.home);
          dispatch(setUpdateLoadingState(false));
        } catch (error) {
          dispatch(handleUploadError({ message: 'File upload failed' }));
        }
      }
    });
  } catch (error) {
    dispatch(handleUploadError(error));
  }
};
