import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';

import { Input, Select, Button } from 'voltron';
import { validateEmail, validatePhone, validateDOB, validateZip } from 'utils/validator';
import { DATE_FORMAT } from 'constants';
import { formatDate } from 'utils/utils';
import { UPDATE_UPLOAD_CENSUS_DATA } from '../../../state/actions/upload';
import { statesData } from '../../../constants/states';
import styles from './Agent.module.scss';

const labelKind = 'regular';
const EMPTY_AGENT_OPTION = [
  {
    label: 'No Agent',
    value: 'no-agent',
  },
];
const AGENT_OBJ = {
  id: 0,
  firstName: '',
  middleInitial: '',
  lastName: '',
  suffix: '',
  dob: '',
  company: '',
  address: '',
  city: '',
  stateCd: '',
  zipCode: '',
  phone: '',
  altPhone: '',
  email: '',
  agency: {
    id: '',
    name: '',
    code: '',
  },
};

const NEW_AGENCY = [
  {
    value: 0,
    label: 'New Agency',
  },
];
const NEW_AGENT = [
  {
    value: 0,
    label: 'New Agent',
  },
];

const validatePhoneNumbers = (agent, errors) => {
  const formErrors = { ...errors };
  if (!validatePhone(agent?.phone)) {
    formErrors.phone = 'phone';
  }
  if (agent?.altPhone && !validatePhone(agent?.altPhone)) {
    formErrors.altPhone = 'altPhone';
  }
  return formErrors;
};

const validate = (agent) => {
  let errors = {};
  if ([null, '', undefined].includes(agent?.id)) {
    errors.id = 'agent';
  }
  if (!agent?.firstName) {
    errors.firstName = 'firstName';
  }
  if (!agent?.lastName) {
    errors.lastName = 'lastName';
  }
  if (!validateDOB(agent?.dob)) {
    errors.dob = 'dob';
  }
  if (agent?.zipCode && !validateZip(agent?.zipCode)) {
    errors.zipCode = 'zip';
  }
  if (!validateEmail(agent?.email)) {
    errors.email = 'email';
  }
  errors = validatePhoneNumbers(agent, errors);
  return errors;
};

const setErrorsOnForm = (formData, setFormErrors) => {
  const errors = {};
  errors.agent = validate(formData.agent);
  errors.agent2 = +(formData.agent2?.id ?? -1) >= 0 ? validate(formData.agent2) : {};
  errors.agent3 = +(formData.agent3?.id ?? -1) >= 0 ? validate(formData.agent3) : {};
  setFormErrors(errors);
  return (
    Object.keys(errors.agent).length === 0 &&
    Object.keys(errors.agent2).length === 0 &&
    Object.keys(errors.agent3).length === 0
  );
};

const dispatchAgentFormDataUpdates = (id, values, formData, dispatch) => {
  switch (id) {
    case 2:
      dispatch({
        type: UPDATE_UPLOAD_CENSUS_DATA,
        payload: {
          formData: {
            ...formData,
            agent2: {
              ...formData.agent2,
              ...values,
            },
          },
        },
      });
      break;
    case 3:
      dispatch({
        type: UPDATE_UPLOAD_CENSUS_DATA,
        payload: {
          formData: {
            ...formData,
            agent3: {
              ...formData.agent3,
              ...values,
            },
          },
        },
      });
      break;
    default:
      dispatch({
        type: UPDATE_UPLOAD_CENSUS_DATA,
        payload: {
          formData: {
            ...formData,
            agent: {
              ...formData.agent,
              ...values,
            },
          },
        },
      });
  }
};

const getAgentObject = (agentId, agentObj) => ({
  ...agentObj,
  id: +agentId,
  firstName: agentObj.firstName,
  middleInitial: agentObj.middleInitial || '',
  lastName: agentObj.lastName,
  suffix: agentObj.suffix || '',
  dob: agentObj.dob ? formatDate(new Date(agentObj.dob)) : '',
  company: agentObj.company || '',
  address: agentObj.address || '',
  city: agentObj.city || '',
  stateCd: agentObj.stateCd || '',
  zipCode: agentObj.zipCode || '',
  phone: agentObj.phone,
  altPhone: agentObj.altPhone || '',
  email: agentObj.email,
  agency: {},
});

const selectAgentHandler = (id, name, agentId, agents, formData, dispatch) => {
  let values;
  if (agentId === 'no-agent' && ['agent', 'agent2', 'agent3'].includes(name)) {
    dispatch({
      type: UPDATE_UPLOAD_CENSUS_DATA,
      payload: {
        formData: {
          ...formData,
          [name]: {
            id: 'no-agent',
            firstName: '',
            middleInitial: '',
            lastName: '',
            suffix: '',
            dob: '',
            company: '',
            address: '',
            city: '',
            stateCd: '',
            zipCode: '',
            phone: '',
            altPhone: '',
            email: '',
            agency: {},
          },
        },
      },
    });
  } else if (+agentId === 0) {
    values = {
      ...AGENT_OBJ,
      id: 0,
    };
    dispatchAgentFormDataUpdates(id, values, formData, dispatch);
  } else {
    const agentObj = agents?.find((agent) => +agent.id === +agentId);
    dispatchAgentFormDataUpdates(id, getAgentObject(agentId, agentObj), formData, dispatch);
  }
};

const handleInputChange = (id, name, value, formData, dispatch) => {
  const values = {
    [name]: value,
  };
  dispatchAgentFormDataUpdates(id, values, formData, dispatch);
};

const handleAgencyInputChange = (id, name, value, agencies, formData, dispatch) => {
  const prop = name === 'agency' ? 'id' : name;
  let agency = id === 2 ? formData.agent2?.agency || {} : formData.agent?.agency || {};
  if (id === 3) {
    agency = formData.agent3?.agency || {};
  }

  const values = {
    agency: {
      ...agency,
      [prop]: value,
    },
  };

  if (name === 'agency') {
    if (+value === 0) {
      values.agency.name = '';
      values.agency.code = '';
    } else {
      const agencyObj = agencies?.find((agencyItem) => +agencyItem.id === +value);
      values.agency.name = agencyObj.name;
      values.agency.code = agencyObj.code;
    }
  }
  dispatchAgentFormDataUpdates(id, values, formData, dispatch);
};

const handleSubmit = (formData, setFormErrors, setIsOnSubmit, proceedToTab) => {
  setIsOnSubmit(true);
  const hasNoFormErrors = setErrorsOnForm(formData, setFormErrors);
  if (hasNoFormErrors) {
    proceedToTab(2);
  }
};

const getPersonalInfoFields = ({ id, fieldPrefix, formData, formErrors, isOnSubmit, dispatch }) => (
  <>
    <Grid item xs={12} container justifyContent="space-between">
      <Grid item xs={8.8}>
        <Input
          name={`${fieldPrefix}-firstName`}
          id={`${fieldPrefix}-firstName`}
          key={`${fieldPrefix}-firstName-${formData?.[fieldPrefix]?.id}`}
          label="First Name"
          labelKind={labelKind}
          placeholder="First Name"
          material={false}
          autoComplete={false}
          onChange={(e) => handleInputChange(id, 'firstName', e.target.value, formData, dispatch)}
          value={formData[fieldPrefix].firstName}
          data-testid={`${fieldPrefix}-firstName`}
          required
          kind="name"
          forceErrorMessage={isOnSubmit && formErrors?.[fieldPrefix]?.firstName}
          maxLength={40}
        />
      </Grid>
      <Grid item xs={2.8}>
        <Input
          name={`${fieldPrefix}-middleInitial`}
          id={`${fieldPrefix}-middleInitial`}
          label="Middle Name"
          labelKind={labelKind}
          placeholder="Middle Name"
          material={false}
          autoComplete={false}
          onChange={(e) => handleInputChange(id, 'middleInitial', e.target.value, formData, dispatch)}
          value={formData[fieldPrefix].middleInitial}
          data-testid={`${fieldPrefix}-middleInitial`}
          kind="name"
          forceErrorMessage={isOnSubmit && formErrors?.[fieldPrefix]?.middleInitial}
          maxLength={25}
        />
      </Grid>
    </Grid>
    <Grid item xs={12} container justifyContent="space-between">
      <Grid item xs={8.8}>
        <Input
          name={`${fieldPrefix}-lastName`}
          id={`${fieldPrefix}-lastName`}
          key={`${fieldPrefix}-lastName-${formData?.[fieldPrefix]?.id}`}
          label="Last Name"
          labelKind={labelKind}
          placeholder="Last Name"
          material={false}
          autoComplete={false}
          onChange={(e) => handleInputChange(id, 'lastName', e.target.value, formData, dispatch)}
          value={formData[fieldPrefix].lastName}
          data-testid={`${fieldPrefix}-lastName`}
          required
          kind="name"
          forceErrorMessage={isOnSubmit && formErrors?.[fieldPrefix]?.lastName}
          maxLength={40}
        />
      </Grid>
      <Grid item xs={2.8}>
        <Input
          name={`${fieldPrefix}-suffix`}
          id={`${fieldPrefix}-suffix`}
          label="Suffix"
          labelKind={labelKind}
          placeholder="Suffix"
          material={false}
          autoComplete={false}
          onChange={(e) => handleInputChange(id, 'suffix', e.target.value, formData, dispatch)}
          value={formData[fieldPrefix].suffix}
          data-testid={`${fieldPrefix}-suffix`}
          kind="name"
          forceErrorMessage={isOnSubmit && formErrors?.[fieldPrefix]?.suffix}
          maxLength={40}
        />
      </Grid>
    </Grid>
    <Grid item xs={12}>
      <Input
        kind="date"
        data-testid={`${fieldPrefix}-dob`}
        name={`${fieldPrefix}-dob`}
        id={`${fieldPrefix}-dob`}
        labelKind={labelKind}
        label="Date of Birth"
        material={false}
        autoComplete={false}
        placeholder={DATE_FORMAT}
        value={formData[fieldPrefix].dob}
        onChange={(e) => handleInputChange(id, 'dob', e.target.value, formData, dispatch)}
        required
        forceErrorMessage={isOnSubmit && formErrors?.[fieldPrefix]?.dob}
      />
    </Grid>
  </>
);

const getCompanyAddressFields = ({ id, fieldPrefix, formData, formErrors, isOnSubmit, dispatch }) => (
  <>
    <Grid item xs={12}>
      <Input
        name={`${fieldPrefix}-company`}
        id={`${fieldPrefix}-company`}
        label="Agent Company"
        labelKind={labelKind}
        placeholder="Agent Company"
        material={false}
        autoComplete={false}
        onChange={(e) => handleInputChange(id, 'company', e.target.value, formData, dispatch)}
        value={formData[fieldPrefix].company}
        data-testid={`${fieldPrefix}-company`}
        kind="text"
        forceErrorMessage={isOnSubmit && formErrors?.[fieldPrefix]?.company}
        maxLength={40}
      />
    </Grid>
    <Grid item xs={12}>
      <Input
        name={`${fieldPrefix}-address`}
        id={`${fieldPrefix}-address`}
        label="Address"
        labelKind={labelKind}
        placeholder="Address"
        material={false}
        autoComplete={false}
        onChange={(e) => handleInputChange(id, 'address', e.target.value, formData, dispatch)}
        value={formData[fieldPrefix].address}
        data-testid={`${fieldPrefix}-address`}
        kind="text"
        forceErrorMessage={isOnSubmit && formErrors?.[fieldPrefix]?.address}
        maxLength={40}
      />
    </Grid>
    <Grid item xs={12}>
      <Input
        name={`${fieldPrefix}-city`}
        id={`${fieldPrefix}-city`}
        label="City"
        labelKind={labelKind}
        placeholder="City"
        material={false}
        autoComplete={false}
        onChange={(e) => handleInputChange(id, 'city', e.target.value, formData, dispatch)}
        value={formData[fieldPrefix].city}
        data-testid={`${fieldPrefix}-city`}
        kind="text"
        forceErrorMessage={isOnSubmit && formErrors?.[fieldPrefix]?.city}
        maxLength={30}
      />
    </Grid>
    <Grid item xs={12} container justifyContent="space-between">
      <Grid item xs={8.8}>
        <Select
          label="State"
          labelKind={labelKind}
          id={`${fieldPrefix}-stateCd`}
          key={`${fieldPrefix}-stateCd-${formData?.[fieldPrefix]?.id}`}
          labelPosition="top"
          name={`${fieldPrefix}-stateCd`}
          options={statesData}
          clearable={false}
          searchable={false}
          material={false}
          selectedValue={statesData.find((option) => option.label === formData[fieldPrefix].stateCd)}
          onChange={(state) => handleInputChange(id, 'stateCd', state.label, formData, dispatch)}
          forceErrorMessage={isOnSubmit && formErrors?.[fieldPrefix]?.stateCd}
        />
      </Grid>
      <Grid item xs={2.8}>
        <Input
          name={`${fieldPrefix}-zipCode`}
          kind="zip"
          id={`${fieldPrefix}-zipCode`}
          label="Zip"
          labelKind={labelKind}
          placeholder="Zip"
          material={false}
          autoComplete={false}
          onChange={(e) => handleInputChange(id, 'zipCode', e.target.value, formData, dispatch)}
          value={formData[fieldPrefix].zipCode}
          data-testid={`${fieldPrefix}-zipCode`}
          forceErrorMessage={isOnSubmit && formErrors?.[fieldPrefix]?.zipCode}
        />
      </Grid>
    </Grid>
  </>
);

const getContactInfoFields = ({ id, fieldPrefix, formData, formErrors, isOnSubmit, dispatch }) => (
  <>
    <Grid item xs={12}>
      <Input
        name={`${fieldPrefix}-phone`}
        id={`${fieldPrefix}-phone`}
        key={`${fieldPrefix}-phone-${formData?.[fieldPrefix]?.id}`}
        required
        label="Phone"
        labelKind={labelKind}
        placeholder="Phone"
        material={false}
        autoComplete={false}
        onChange={(e) => handleInputChange(id, 'phone', e.target.value, formData, dispatch)}
        value={formData[fieldPrefix].phone}
        kind="phone"
        data-testid={`${fieldPrefix}-phone`}
        forceErrorMessage={isOnSubmit && formErrors?.[fieldPrefix]?.phone}
      />
    </Grid>
    <Grid item xs={12}>
      <Input
        name={`${fieldPrefix}-altPhone`}
        id={`${fieldPrefix}-altPhone`}
        label="Alternate Phone"
        labelKind={labelKind}
        placeholder="Alternate Phone"
        material={false}
        autoComplete={false}
        onChange={(e) => handleInputChange(id, 'altPhone', e.target.value, formData, dispatch)}
        value={formData[fieldPrefix].altPhone}
        kind="phone"
        data-testid={`${fieldPrefix}-altPhone`}
        forceErrorMessage={isOnSubmit && formErrors?.[fieldPrefix]?.altPhone}
      />
    </Grid>
    <Grid item xs={12}>
      <Input
        name={`${fieldPrefix}-email`}
        id={`${fieldPrefix}-email`}
        key={`${fieldPrefix}-email-${formData?.[fieldPrefix]?.id}`}
        label="Email Address"
        labelKind={labelKind}
        placeholder="Email"
        material={false}
        autoComplete={false}
        onChange={(e) => handleInputChange(id, 'email', e.target.value, formData, dispatch)}
        value={formData[fieldPrefix].email}
        data-testid={`${fieldPrefix}-email`}
        kind="email"
        required
        forceErrorMessage={isOnSubmit && formErrors?.[fieldPrefix]?.email}
        maxLength={50}
      />
    </Grid>
  </>
);

const getAgencyInfoFields = ({ id, fieldPrefix, agencies, formData, formErrors, isOnSubmit, dispatch }) => {
  const agencyList = agencies.map((agency) => ({
    label: agency.name,
    value: agency.id,
  }));
  return (
    <>
      <Grid item xs={12}>
        <Select
          label="Select Agency"
          labelKind={labelKind}
          id={`${fieldPrefix}-agency`}
          labelPosition="top"
          name={`${fieldPrefix}-agency`}
          options={[...NEW_AGENCY, ...agencyList]}
          clearable={false}
          searchable={false}
          material={false}
          selectedValue={[...NEW_AGENCY, ...agencyList].find(
            (option) => option.value === formData[fieldPrefix].agency?.id,
          )}
          onChange={(agency) => handleAgencyInputChange(id, 'agency', agency.value, agencies, formData, dispatch)}
          forceErrorMessage={isOnSubmit && formErrors?.[fieldPrefix]?.agency}
        />
        <span>If new agency, select &apos;New Agency&apos; to enter agent information</span>
      </Grid>
      <Grid item xs={12}>
        <Input
          name={`${fieldPrefix}-agency-name`}
          id={`${fieldPrefix}-agency-name`}
          label="Agency Name"
          labelKind={labelKind}
          placeholder="Agency Name"
          material={false}
          autoComplete={false}
          onChange={(e) => handleAgencyInputChange(id, 'name', e.target.value, agencies, formData, dispatch)}
          value={formData[fieldPrefix].agency?.name}
          data-testid={`${fieldPrefix}-agency-name`}
          kind="text"
          forceErrorMessage={isOnSubmit && formErrors?.[fieldPrefix]?.agencyName}
          maxLength={40}
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          name={`${fieldPrefix}-agency-code`}
          id={`${fieldPrefix}-agency-code`}
          label="Agency Number"
          labelKind={labelKind}
          placeholder="Agency Number"
          material={false}
          autoComplete={false}
          onChange={(e) => handleAgencyInputChange(id, 'code', e.target.value, agencies, formData, dispatch)}
          value={formData[fieldPrefix].agency?.code}
          data-testid={`${fieldPrefix}-agency-code`}
          kind="text"
          forceErrorMessage={isOnSubmit && formErrors?.[fieldPrefix]?.agencyCode}
          maxLength={40}
        />
      </Grid>
    </>
  );
};

const agentDetailsForm = ({ id, formData, formErrors, agencies, isOnSubmit, dispatch }) => {
  let fieldPrefix = id === 2 ? 'agent2' : 'agent';
  if (id === 3) {
    fieldPrefix = 'agent3';
  }
  return (
    <>
      {getPersonalInfoFields({ id, fieldPrefix, formData, formErrors, isOnSubmit, dispatch })}
      {getCompanyAddressFields({ id, fieldPrefix, formData, formErrors, isOnSubmit, dispatch })}
      {getContactInfoFields({ id, fieldPrefix, formData, formErrors, isOnSubmit, dispatch })}
      {getAgencyInfoFields({ id, fieldPrefix, agencies, formData, formErrors, isOnSubmit, dispatch })}
    </>
  );
};

const resetPhoneFields = (formData, agentId = '') => {
  let phoneTimeout;
  if (+(formData?.[`agent${agentId}`]?.id || 0)) {
    phoneTimeout = setTimeout(() => {
      const phoneInput = document.querySelectorAll(`[data-testid="agent${agentId}-phone"]`)[0];
      const altPhoneInput = document.querySelectorAll(`[data-testid="agent${agentId}-altPhone"]`)[0];
      phoneInput.focus();
      phoneInput.blur();
      altPhoneInput.focus();
      altPhoneInput.blur();
      window.scrollTo(0, 0);
    }, 500);
  }
  return phoneTimeout;
};

const Agent = ({ proceedToTab, enableNextTab }) => {
  const { agents = [], formData = {} } = useSelector((state) => state.upload);
  const { agencies = [] } = useSelector((state) => state.adminEntities);
  const dispatch = useDispatch();
  const [formErrors, setFormErrors] = useState({});
  const [isOnSubmit, setIsOnSubmit] = useState(false);

  const agentList = agents.map((agent) => ({
    label: agent.name,
    value: agent.id,
  }));

  useEffect(() => {
    proceedToTab(1);
  }, []);

  useEffect(() => {
    const hasNoFormErrors = setErrorsOnForm(formData, setFormErrors);
    enableNextTab(2, hasNoFormErrors ? undefined : false);
  }, [formData.agent, formData.agent2, formData.agent3]);

  // need to prevent Voltron from treating potential valid phone values as invalid initially
  useEffect(() => {
    const phoneTimeout = resetPhoneFields(formData);
    return () => clearTimeout(phoneTimeout);
  }, [formData?.agent?.id]);

  useEffect(() => {
    const phoneTimeout = resetPhoneFields(formData, '2');
    return () => clearTimeout(phoneTimeout);
  }, [formData?.agent2?.id]);

  useEffect(() => {
    const phoneTimeout = resetPhoneFields(formData, '3');
    return () => clearTimeout(phoneTimeout);
  }, [formData?.agent3?.id]);

  return (
    <div>
      <Grid container spacing={0} className="innerHeader">
        <Grid item xs={12}>
          <h3>Agent Information</h3>
        </Grid>
        <Grid item xs={12}>
          <p>Select or enter the agent information for the census being uploaded</p>
        </Grid>
      </Grid>
      <form className={styles.agentsForm}>
        <Grid container spacing={1}>
          {[1, 2, 3].map((agentNum) => {
            const isFirstAgent = agentNum === 1;
            const agentField = `agent${isFirstAgent ? '' : agentNum}`;
            let agentOptions = isFirstAgent ? [] : [...EMPTY_AGENT_OPTION];
            agentOptions = [...agentOptions, ...NEW_AGENT, ...agentList];
            return (
              <React.Fragment key={`agent-${agentNum}-form`}>
                {!isFirstAgent && (
                  <Grid item xs={12}>
                    <div className={styles.padTitle}>Agent {agentNum} (Optional)</div>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Select
                    label="Select Agent"
                    labelKind={labelKind}
                    name={agentField}
                    id={agentField}
                    labelPosition="top"
                    options={agentOptions}
                    required={isFirstAgent}
                    clearable={false}
                    searchable={false}
                    material={false}
                    selectedValue={agentOptions.find((option) => option.value === formData[agentField]?.id)}
                    onChange={(agent) =>
                      selectAgentHandler(agentNum, agentField, agent.value, agents, formData, dispatch)
                    }
                    forceErrorMessage={isOnSubmit && formErrors[agentField]?.id}
                  />
                  <span>If new agent, select &apos;New Agent&apos; to enter agent information</span>
                </Grid>
                {(isFirstAgent || formData?.[agentField]?.id >= 0) &&
                  agentDetailsForm({
                    id: agentNum,
                    formData,
                    formErrors,
                    agencies,
                    isOnSubmit,
                    dispatch,
                  })}
              </React.Fragment>
            );
          })}
          <Grid item xs={12} justifyContent="flex-end" container>
            <Button kind="primary" onClick={() => handleSubmit(formData, setFormErrors, setIsOnSubmit, proceedToTab)}>
              CONTINUE
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default Agent;
