import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { withTransaction } from '@elastic/apm-rum-react';
import { Box, Container, Grid } from '@mui/material';
import { Edit, NotInterested, Delete } from '@mui/icons-material';
import { Button, Modal } from 'voltron';

import { fetchOwningCompanies, removeOwningCompany } from 'state/actions/adminEntities';
import PaginatedTable from 'components/PaginatedTable/PaginatedTable';
import { exportOwningCompanies } from 'services/Services';
import { downloadFile } from 'utils/utils';
import { pageLoaded, pageLoading } from 'state/actions/page_loading';

const actionButtonClass = 'actionButton';
const modalButtonClass = 'modalButton';
const linkButton = 'link';

const EditComponent =
  (history) =>
  ({ id, canEdit }) => (
    <Button
      kind="link"
      data-testid={`edit-owning-company-${id}`}
      aria-label="edit-owning-company"
      className={actionButtonClass}
      disabled={!canEdit}
      onClick={() => history.push(`/owner/${id}`)}
    >
      {canEdit ? <Edit /> : <NotInterested />}
    </Button>
  );

const DeleteComponent = (setIsDeleteConfirmModalOpen, setOwningCompanyToBeDeleted) => (owningCompany) => (
  <Button
    kind="link"
    data-testid={`delete-owning-company-${owningCompany.id}`}
    aria-label="delete-owning-company"
    className={actionButtonClass}
    disabled={!owningCompany.canDelete}
    onClick={() => {
      setIsDeleteConfirmModalOpen(true);
      setOwningCompanyToBeDeleted(owningCompany);
    }}
  >
    {owningCompany.canDelete ? <Delete /> : <NotInterested />}
  </Button>
);

const resetDeleteModalState = (setIsDeleteConfirmModalOpen, setOwningCompanyToBeDeleted) => {
  setIsDeleteConfirmModalOpen(false);
  setOwningCompanyToBeDeleted({});
};

const OwningCompaniesDashboard = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { oktaAuth } = useOktaAuth();
  const { owningCompanies, isSetOwningCompaniesLoading } = useSelector((state) => state.adminEntities);
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] = useState(false);
  const [owningCompanyToBeDeleted, setOwningCompanyToBeDeleted] = useState({});

  const accessToken = oktaAuth.getAccessToken();

  useEffect(() => {
    dispatch(fetchOwningCompanies(accessToken));
  }, []);

  const tableColumns = [
    {
      Header: 'Companies',
      width: 80,
      accessor: 'name',
      isSearchable: true,
    },
    {
      Header: 'Edit',
      width: 10,
      isAction: true,
      component: EditComponent(history),
    },
    {
      Header: 'Delete',
      width: 10,
      isAction: true,
      component: DeleteComponent(setIsDeleteConfirmModalOpen, setOwningCompanyToBeDeleted),
    },
  ];

  return (
    <Box sx={{ width: '82vw', marginTop: '4rem' }}>
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="adminDashboardPage">
              <h2 className="pageTitle">Owning Companies</h2>
              <div className="contentBody">
                <div className="actions">
                  <p className="actionsTitle">Actions</p>
                  <Button
                    data-testid="export-owning-companies"
                    className={actionButtonClass}
                    onClick={async () => {
                      dispatch(pageLoading());
                      const { data, headers } = await exportOwningCompanies(accessToken);
                      dispatch(pageLoaded());
                      downloadFile(data, headers);
                    }}
                  >
                    EXPORT
                  </Button>
                </div>
                <div className="tableContainer">
                  <PaginatedTable
                    className="dashboardTable"
                    sortColumn="name"
                    sortOrder="ASC"
                    columns={tableColumns}
                    data={owningCompanies}
                    isDataLoading={isSetOwningCompaniesLoading}
                  />
                  <Modal
                    open={isDeleteConfirmModalOpen}
                    title={`Would you like to delete ${owningCompanyToBeDeleted.name}?`}
                    handleToggle={() => resetDeleteModalState(setIsDeleteConfirmModalOpen, setOwningCompanyToBeDeleted)}
                  >
                    <p tabIndex="0">Are you sure you want to delete {owningCompanyToBeDeleted.name}?</p>
                    <div className="modalButtons">
                      <Button
                        kind={linkButton}
                        className={modalButtonClass}
                        data-testid="delete-owning-company-no"
                        onClick={() => resetDeleteModalState(setIsDeleteConfirmModalOpen, setOwningCompanyToBeDeleted)}
                      >
                        NO
                      </Button>
                      <Button
                        kind={linkButton}
                        className={modalButtonClass}
                        data-testid="delete-owning-company-confirm"
                        onClick={() => {
                          dispatch(removeOwningCompany(accessToken, owningCompanyToBeDeleted));
                          resetDeleteModalState(setIsDeleteConfirmModalOpen, setOwningCompanyToBeDeleted);
                        }}
                      >
                        YES
                      </Button>
                    </div>
                  </Modal>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default withTransaction('OwningCompaniesDashboard', 'component')(OwningCompaniesDashboard);
