import { useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useDispatch } from 'react-redux';
import { Button, DatePicker, Icon, Toast } from 'voltron';
import { PriorityHigh } from '@mui/icons-material';
import { DATE_FORMAT } from 'constants';
import { modifyEnrollmentDates } from 'state/actions/employers';
import { FocusScope } from 'react-aria';
import styles from './EditEnrollmentPeriods.module.scss';
import mainStyles from '../EmployerApplicantsDashboard.module.scss';

const FORM_FIELD_NAMES = {
  startDate: 'startDate',
  endDate: 'endDate',
};

const TAB_KEY = 'Tab';
const MODAL_TITLE_ID = 'modal-title';
const CLOSE_ICON_ID = 'close-icon';
const EDIT_BUTTON_ID = 'edit-button';
const ENROLLMENT_START_DATE_ID = 'enrollment-start-date';
const ENROLLMENT_END_DATE_ID = 'enrollment-end-date';

const labelPosition = 'top';

const today = new Date(new Date().toDateString());
let tomorrow = new Date();
tomorrow.setDate(today.getDate() + 1);
tomorrow = new Date(tomorrow.toDateString());

const formatDate = (date) =>
  date?.toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });

const getInvalidFormElements = (enrollmentStartDate, enrollmentEndDate, isStartDateDisabled) => {
  const invalidFormElements = [];
  const isStartDateLaterThanEndDate = enrollmentStartDate > enrollmentEndDate;

  if (!isStartDateDisabled && (!enrollmentStartDate || enrollmentStartDate <= today || isStartDateLaterThanEndDate)) {
    invalidFormElements.push(FORM_FIELD_NAMES.startDate);
  }
  if (
    !enrollmentEndDate ||
    enrollmentEndDate < (isStartDateDisabled ? today : tomorrow) ||
    isStartDateLaterThanEndDate
  ) {
    invalidFormElements.push(FORM_FIELD_NAMES.endDate);
  }
  return invalidFormElements;
};

const onModalKeyDown = (e, resetEditModal) => {
  if (e.key === 'Escape') {
    e.preventDefault();
    resetEditModal();
  }
};

const onCloseIconKeyDown = (e, isStartDateDisabled) => {
  if (e.key === TAB_KEY) {
    let upcomingElementToFocusId = EDIT_BUTTON_ID;
    if (!e.shiftKey) {
      upcomingElementToFocusId = isStartDateDisabled ? ENROLLMENT_END_DATE_ID : ENROLLMENT_START_DATE_ID;
    }
    e.preventDefault();
    document.getElementById(upcomingElementToFocusId).focus();
  }
};

const onEditButtonKeyDown = (e) => {
  if (e.key === TAB_KEY && !e.shiftKey) {
    e.preventDefault();
    document.getElementById(CLOSE_ICON_ID).children[0].focus();
  }
};

const getModal = ({ isStartDateDisabled, formInputData, invalidInputs, resetEditModal, onInputChange, onSubmit }) => {
  const { startDate, endDate } = formInputData;
  let minEndDate = today;
  if (!isStartDateDisabled) {
    minEndDate = !startDate || tomorrow > startDate ? tomorrow : startDate;
  }
  /* There's an issue with the `contain` flag for FocusScope (used by Voltron Modal)
    that prevents the DatePicker component to lose focus for a while. Therefore, a hack
    has to be implemented to maintain focus within the modal manually */
  return (
    <FocusScope restoreFocus autoFocus>
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <div
        role="dialog"
        className="modalContainerInitialCenter modalContainerCenter"
        onClick={(e) => e.stopPropagation()}
        onKeyDown={(e) => onModalKeyDown(e, resetEditModal)}
        aria-labelledby={MODAL_TITLE_ID}
      >
        <div className="modalTitleContainer">
          <div className="modalTitle" role="heading" aria-level="1" id={MODAL_TITLE_ID}>
            Change Enrollment Dates
          </div>
          <div tabIndex={0} />
          <div id={CLOSE_ICON_ID} tabIndex={0} className="modalClose">
            <Icon kind="close" className="modalIcon" clickable onClick={resetEditModal} />
          </div>
        </div>
        <div className="modalContentInitial modalContentOpen">
          <div className="modalChildren">
            <div className={styles.enrollmentDate}>
              <DatePicker
                label="Enrollment Start Date"
                labelPosition={labelPosition}
                placeholder={DATE_FORMAT}
                required
                disabled={isStartDateDisabled}
                showCalendarIcon={!isStartDateDisabled}
                datePickerProps={{
                  disabledDays: {
                    before: tomorrow,
                    after: endDate && endDate > today ? endDate : tomorrow,
                  },
                }}
                muteValidation={!invalidInputs.includes(FORM_FIELD_NAMES.startDate)}
                forceErrorMessage={invalidInputs.includes(FORM_FIELD_NAMES.startDate)}
                errormessage="Enter a valid date in the format mm/dd/yyyy after today and on/before enrollment end date"
                inputProps={{ id: ENROLLMENT_START_DATE_ID, 'data-testid': ENROLLMENT_START_DATE_ID }}
                value={startDate}
                onChange={(date, isValid, _inRange, _datePickerState, event) => {
                  onInputChange(FORM_FIELD_NAMES.startDate, isValid ? new Date(date) : event.target.date);
                }}
              />
            </div>
            <div className={styles.enrollmentDate}>
              <DatePicker
                label="Enrollment End Date"
                labelPosition={labelPosition}
                placeholder={DATE_FORMAT}
                required
                datePickerProps={{
                  disabledDays: {
                    before: minEndDate,
                  },
                }}
                muteValidation={!invalidInputs.includes(FORM_FIELD_NAMES.endDate)}
                forceErrorMessage={invalidInputs.includes(FORM_FIELD_NAMES.endDate)}
                errormessage={`Enter a valid date in the format mm/dd/yyyy ${
                  isStartDateDisabled ? 'on/' : ''
                }after today or enrollment start date, whichever comes later`}
                inputProps={{ id: ENROLLMENT_END_DATE_ID, 'data-testid': ENROLLMENT_END_DATE_ID }}
                value={endDate}
                onChange={(date, isValid, _inRange, _datePickerState, event) => {
                  onInputChange(FORM_FIELD_NAMES.endDate, isValid ? new Date(date) : event.target.date);
                }}
              />
            </div>
            <div className={styles.modalButtons}>
              <Button kind="invert" data-testid="cancel-edit-button" onClick={resetEditModal}>
                CANCEL
              </Button>
              <Button
                id={EDIT_BUTTON_ID}
                data-testid={EDIT_BUTTON_ID}
                onClick={onSubmit}
                onKeyDown={onEditButtonKeyDown}
              >
                SAVE CHANGES
              </Button>
              <div tabIndex={0} />
            </div>
          </div>
        </div>
      </div>
      <div role="presentation" className="modal" onClick={resetEditModal} />
    </FocusScope>
  );
};

const getEnrollmentPeriodButton = (enrollment, setEditEnrollmentId, setIsEditEnrollmentDatesModalOpen) => (
  <Button
    kind="link"
    key={`enrollment-${enrollment.id}-period`}
    data-testid={`edit-enrollment-${enrollment.id}-dates-modal-button`}
    className={mainStyles.itemButton}
    onClick={() => {
      setEditEnrollmentId(enrollment.id);
      setIsEditEnrollmentDatesModalOpen(true);
    }}
  >
    <div className={mainStyles.itemButtonContent}>
      <PriorityHigh />
      <div className={mainStyles.buttonText}>
        <h3>{enrollment.periodRange}</h3>
        <h4>{enrollment.enrollmentType}</h4>
        <p>Apps Submitted: {enrollment.appsSubmitted}</p>
      </div>
    </div>
  </Button>
);

const EditEnrollmentPeriods = ({ isAdmin, employerId, futureCurrentEnrollments = [] }) => {
  const { oktaAuth } = useOktaAuth();
  const dispatch = useDispatch();
  const [isEditEnrollmentDatesModalOpen, setIsEditEnrollmentDatesModalOpen] = useState(false);
  const [editEnrollmentId, setEditEnrollmentId] = useState(0);
  const [originalStartDate, setOriginalStartDate] = useState('');
  const [formInputData, setFormInputData] = useState({ startDate: '', endDate: '' });
  const [invalidInputs, setInvalidInputs] = useState([]);

  const isStartDateDisabled = originalStartDate <= today;
  const accessToken = oktaAuth.getAccessToken();

  const resetEditModal = (noChanges = true) => {
    setIsEditEnrollmentDatesModalOpen(false);
    setEditEnrollmentId(0);
    setOriginalStartDate('');
    setFormInputData({});
    setInvalidInputs([]);
    if (noChanges) {
      Toast.info('No changes were made');
    }
  };

  const onInputChange = (inputName, value) => {
    setFormInputData((prevFormData) => ({
      ...prevFormData,
      [inputName]: value,
    }));
  };

  const onSubmit = () => {
    if (invalidInputs.length) {
      return;
    }
    dispatch(
      modifyEnrollmentDates(
        isAdmin,
        accessToken,
        editEnrollmentId,
        formatDate(formInputData.startDate),
        formatDate(formInputData.endDate),
        employerId,
      ),
    );
    resetEditModal(false);
  };

  useEffect(() => {
    if (editEnrollmentId) {
      const enrollment = futureCurrentEnrollments.find((en) => en.id === editEnrollmentId);
      const enrollmentBeginDateEndIndex = enrollment?.periodRange.indexOf(' to');
      const enrollmentBeginDate = new Date(enrollment?.periodRange.slice(0, enrollmentBeginDateEndIndex));
      const enrollmentEndDate = new Date(enrollment?.periodRange.slice(enrollmentBeginDateEndIndex + 3));
      setOriginalStartDate(enrollmentBeginDate);
      setFormInputData({
        startDate: enrollmentBeginDate,
        endDate: enrollmentEndDate,
      });
    }
  }, [editEnrollmentId]);

  useEffect(() => {
    const invalidFormElements = getInvalidFormElements(
      formInputData.startDate,
      formInputData.endDate,
      isStartDateDisabled,
    );
    setInvalidInputs(invalidFormElements);
  }, [formInputData, isStartDateDisabled]);

  useEffect(() => {
    if (isEditEnrollmentDatesModalOpen) {
      const keyDownEvent = 'keydown';
      const closeButton = document.getElementById(CLOSE_ICON_ID);
      if (closeButton) {
        closeButton.children[0].focus();
        closeButton.addEventListener(keyDownEvent, (e) => onCloseIconKeyDown(e, isStartDateDisabled));
        return () => closeButton.removeEventListener(keyDownEvent, (e) => onCloseIconKeyDown(e, isStartDateDisabled));
      }
    }
    return () => {};
  }, [isEditEnrollmentDatesModalOpen, isStartDateDisabled]);

  return (
    <>
      <header className={mainStyles.header}>Edit Enrollment Periods</header>
      <div className={mainStyles.itemButtons}>
        {futureCurrentEnrollments.map((enrollment) =>
          getEnrollmentPeriodButton(enrollment, setEditEnrollmentId, setIsEditEnrollmentDatesModalOpen),
        )}
        {isEditEnrollmentDatesModalOpen &&
          getModal({ isStartDateDisabled, formInputData, invalidInputs, resetEditModal, onInputChange, onSubmit })}
      </div>
    </>
  );
};

export default EditEnrollmentPeriods;
