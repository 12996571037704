import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { Button } from 'voltron';
import { getApplicantInfo } from 'services/Services';
import { useOktaAuth } from '@okta/okta-react';
import { useSelector, useDispatch } from 'react-redux';
import { updateApplicantData } from 'state/actions/applicantForm';
import { setCriticalError } from 'state/actions/error';
import { formatAPIMessage } from 'utils/utils';
import Styles from '../navigation/NavigationTabs.module.scss';

function Introduction({ setData, setCurrentTab }) {
  const { oktaAuth } = useOktaAuth();
  const { data } = useSelector((state) => state.applicantForm);
  const [applicantInfo, setApplicantInfo] = useState(data);
  const dispatch = useDispatch();

  useEffect(() => {
    if (applicantInfo == null) {
      const accessToken = oktaAuth.getAccessToken();
      getApplicantInfo(accessToken)
        .then((res) => {
          const apiResp = res;
          if (res) {
            if (apiResp.applicationId > 0) {
              if (!apiResp.alreadySigned && !apiResp.applicationCompleted) {
                window.location.href = `/forms/${apiResp.enrollmentType === 'Increases' ? 'increases' : 'primary'}/${
                  apiResp.applicationId
                }`;
              }
            } else {
              apiResp.formData = { allowEnroll: false };
              apiResp.formData.allowEnroll = apiResp.offers.length === 1;
              apiResp.formData.accept1 = apiResp.offers.length === 1;

              if (apiResp.offers.length > 1) {
                apiResp.formData.accept1 = !apiResp.offers[0].canRefuse;
                apiResp.formData.accept2 = false;
                apiResp.formData.refuse1 = false;
                apiResp.formData.refuse2 = false;

                apiResp.formData.offer1 = apiResp.offers[0].importDataId;
                apiResp.formData.offer2 = apiResp.offers[1].importDataId;
              } else if (
                apiResp.offers[0].classPlanName === 'Employer Paid' ||
                apiResp.offers[0].classPlanName === 'Reverse Combo'
              ) {
                apiResp.formData.showRefuseOverall = false;
              } else {
                apiResp.formData.showRefuseOverall = true;
              }
            }
            setApplicantInfo(apiResp);
            setData(apiResp);
            dispatch(updateApplicantData(apiResp));
          } else {
            setApplicantInfo(null);
            setData(null);
            dispatch(updateApplicantData(null));
          }
        })
        .catch((err) => {
          dispatch(
            setCriticalError({
              title: 'Error',
              body: formatAPIMessage(err),
              button: 'OK',
            }),
          );
          setApplicantInfo(null);
          setData(null);
          dispatch(updateApplicantData(null));
        });
    }
  }, []);

  return (
    <div className={Styles.content} id="content">
      <p className={Styles.head}>Introduction</p>

      {applicantInfo?.alreadySigned && (
        <p>
          <strong>You have already completed the enrollment process on {applicantInfo?.dateSigned}</strong>
        </p>
      )}
      {applicantInfo?.applicationCompleted && (
        <p>
          <strong>
            You have only partially completed the enrollment process as one or more forms is awaiting your signature.
            Please look for an email sent to: {applicantInfo.applicantEmail} to complete the enrollment process.
          </strong>
        </p>
      )}
      {applicantInfo == null && (
        <p>
          <strong>There are no active applications for you to fill out</strong>
        </p>
      )}
      {applicantInfo !== null && applicantInfo?.welcomeMessage > 0 && (
        <p style={{ textDecoration: 'underline' }}>Important Employee Benefit</p>
      )}
      {applicantInfo !== null && applicantInfo?.welcomeMessage === 2 && (
        <div>
          <p>
            What if you were unable to work due to an accident or illness? Could you maintain your current lifestyle
            without an income? How would you pay your monthly expenses?
          </p>
          <p>
            {applicantInfo?.employerName} understands this need and is offering you the opportunity to purchase
            disability income protection. If approved, you will receive an individual policy.
          </p>
        </div>
      )}
      {applicantInfo !== null && applicantInfo?.welcomeMessage === 3 && (
        <div>
          <p>
            What if you were unable to work due to an accident or illness? Could you maintain your current lifestyle
            without an income? How would you pay your monthly expenses?
          </p>
          <p>
            {applicantInfo.employerName} understands this need and is providing disability income protection for you.
            Since {applicantInfo.employerName} is paying the cost for this coverage, you must apply. If approved, you
            will receive an individual policy.
          </p>
        </div>
      )}
      {applicantInfo !== null && applicantInfo?.welcomeMessage === 4 && (
        <div>
          <p>
            What if you were unable to work due to an accident or illness? Could you maintain your current lifestyle
            without an income? How would you pay your monthly expenses?
          </p>
          <p>
            Your employer is providing you with a valuable disability income protection plan in which individual
            disability income insurance forms the foundation and Group LTD supplements the individual coverage. This
            innovative plan design allows you to maximize the amount of coverage provided through an individually owned
            disability policy.
          </p>
          <p>
            Since {applicantInfo.employerName} is paying the cost for the income protection, you must apply. If
            approved, you will receive an individual policy.
          </p>
        </div>
      )}
      {applicantInfo !== null && applicantInfo?.welcomeMessage === 5 && (
        <div>
          <p>
            What if you were unable to work due to an accident or illness? Could you maintain your current lifestyle
            without an income? How would you pay your monthly expenses?
          </p>

          <p>
            {applicantInfo.employerName} understands this need and is providing you with Group Long Term Disability
            Insurance and:
          </p>
          <ul>
            <li>an opportunity to purchase additional coverage with a supplemental disability income policy.</li>
          </ul>

          <p>If approved, you will receive an individual policy.</p>
        </div>
      )}
      {applicantInfo !== null && applicantInfo?.welcomeMessage === 6 && (
        <div>
          <p>
            What if you were unable to work due to an accident or illness? Could you maintain your current lifestyle
            without an income? How would you pay your monthly expenses?
          </p>
          <p>
            {applicantInfo.employerName} understands this need and is providing you with Group Long Term Disability
            Insurance and:
          </p>
          <ul>
            <li>additional coverage with a supplemental disability income protection policy</li>
          </ul>
          <p>
            Since {applicantInfo.employerName} is paying the cost for this supplemental coverage, you must apply. If
            approved, you will receive an individual policy.
          </p>
        </div>
      )}
      {applicantInfo !== null && applicantInfo?.welcomeMessage === 7 && (
        <div>
          <p>
            What if you were unable to work due to an accident or illness? Could you maintain your current lifestyle
            without an income? How would you pay your monthly expenses?
          </p>

          <p>
            {applicantInfo.employerName} understands this need and is providing you with Group Long Term Disability
            Insurance and the following:
          </p>
          <ul>
            <li>
              additional coverage with supplemental disability income protection. Since {applicantInfo.employerName} is
              paying for this coverage, you must apply.
            </li>
            <li>an opportunity to purchase additional coverage to further protect your income.</li>
          </ul>
          <p>
            If approved, you will receive a policy for the employer paid coverage and one for the coverage you will
            purchase.
          </p>
        </div>
      )}
      {applicantInfo !== null && applicantInfo?.welcomeMessage === 8 && (
        <div>
          <p>
            What if you were unable to work due to an accident or illness? Could you maintain your current lifestyle
            without an income? How would you pay your monthly expenses?
          </p>
          <p>
            {applicantInfo.employerName} understands this need and is providing disability income protection for you.
            Since {applicantInfo.employerName} is paying the cost for this coverage, you must apply. You will also have
            the opportunity to purchase additional coverage to further protect your income.
          </p>
          <p>
            If approved, you will receive a policy for the employer paid coverage and one for the coverage you will
            purchase.
          </p>
        </div>
      )}
      {applicantInfo !== null && applicantInfo?.welcomeMessage === 9 && (
        <div>
          <p>
            What if you were unable to work due to an accident or illness? Could you maintain your current lifestyle
            without an income? How would you pay your expenses?
          </p>

          <p>
            {applicantInfo.employerName} understands this need and is providing you with Group Long Term Disability
            Insurance and:
          </p>
          <ul>
            <li>a disability insurance policy that provides a lump sum benefit.</li>
          </ul>
          <p>
            Since {applicantInfo.employerName} is paying the cost for this coverage, you must apply. If approved, you
            will receive an individual policy.
          </p>
        </div>
      )}
      {applicantInfo !== null && applicantInfo?.welcomeMessage === 10 && (
        <div>
          <p>
            What if you were unable to work due to an accident or illness? Could you maintain your current lifestyle
            without an income? How would you pay your expenses?
          </p>
          <p>
            {applicantInfo.employerName} understands this need and is providing you with Group Long Term Disability
            Insurance and:
          </p>
          <ul>
            <li>An opportunity to purchase disability insurance that provides a lump sum benefit.</li>
          </ul>
          <p>If approved, you will receive an individual policy.</p>
        </div>
      )}
      {applicantInfo !== null && applicantInfo?.welcomeMessage === 11 && (
        <div>
          <p>
            What if you were unable to work due to an accident or illness? Could you maintain your current lifestyle
            without an income? How would you pay your expenses?
          </p>
          <p>
            {applicantInfo.employerName} understands this need and is providing disability insurance for you that
            provides a lump sum benefit. Since {applicantInfo.employerName} is paying the cost for this coverage, you
            must apply. If approved, you will receive an individual policy.
          </p>
        </div>
      )}
      {applicantInfo !== null && applicantInfo?.welcomeMessage === 12 && (
        <div>
          <p>
            What if you were unable to work due to an accident or illness? Could you maintain your current lifestyle
            without an income? How would you pay your expenses?
          </p>

          <p>
            {applicantInfo.employerName} understands this need and is giving you the opportunity to purchase disability
            insurance that provides a lump sum benefit.
          </p>
          <p>If approved, you will receive an individual policy.</p>
        </div>
      )}
      {applicantInfo !== null && applicantInfo?.welcomeMessage === 13 && (
        <div>
          <p>
            What if you were unable to work due to an accident or illness? Could you maintain your current lifestyle
            without an income? How would you pay your expenses?
          </p>
          <p>
            {applicantInfo.employerName} understands this need and is providing you with an opportunity to purchase the
            following:
            <ul>
              <li>disability income protection AND/OR</li>
              <li>disability insurance that provides a lump sum benefit</li>
            </ul>
          </p>
          <p>If approved, you will receive an individual policy or policies.</p>
        </div>
      )}
      {applicantInfo !== null && applicantInfo?.welcomeMessage === 14 && (
        <div>
          <p>
            What if you were unable to work due to an accident or illness? Could you maintain your current lifestyle
            without an income? How would you pay your expenses?
          </p>
          <p>{applicantInfo.employerName} understands this need and is providing you with the following:</p>
          <ul>
            <li>disability income protection AND</li>
            <li>disability insurance that provides a lump sum benefit.</li>
          </ul>
          <p>
            Since {applicantInfo.employerName} is paying the cost for this coverage, you must apply for both. If
            approved, you will receive individual policies.
          </p>
        </div>
      )}
      {applicantInfo !== null && applicantInfo?.welcomeMessage === 15 && (
        <div>
          <p>
            What if you were unable to work due to an accident or illness? Could you maintain your current lifestyle
            without an income? How would you pay your expenses?
          </p>
          <p>
            {applicantInfo.employerName} understands this need and is providing you with Group Long Term Disability
            Insurance and the opportunity to purchase the following:
            <ul>
              <li>additional coverage with supplemental disability income protection AND/OR</li>
              <li>disability insurance that provides a lump sum benefit.</li>
            </ul>
          </p>
          <p>If approved, you will receive an individual policy or policies.</p>
        </div>
      )}
      {applicantInfo !== null && applicantInfo?.welcomeMessage === 16 && (
        <div>
          <p>
            What if you were unable to work due to an accident or illness? Could you maintain your current lifestyle
            without an income? How would you pay your expenses?
          </p>
          <p>
            {applicantInfo.employerName} understands this need and is providing you with Group Long Term Disability
            Insurance and:
          </p>
          <ul>
            <li>additional coverage with supplemental disability income protection AND</li>
            <li>disability insurance that provides a lump sum benefit.</li>
          </ul>
          <p>
            Since {applicantInfo.employerName} is paying the cost for this coverage, you must apply for both. If
            approved, you will receive individual policies.
          </p>
        </div>
      )}
      {applicantInfo !== null && applicantInfo?.welcomeMessage === 17 && (
        <div>
          <p>
            What if you were unable to work due to an accident or illness? Could you maintain your current lifestyle
            without an income? How would you pay your monthly expenses?
          </p>
          <p>{applicantInfo.employerName} understands this need and is providing you with the following:</p>
          <ul>
            <li>disability income protection AND</li>
            <li>disability insurance that provides a lump sum benefit.</li>
          </ul>
          <p>
            You must apply for the coverage being paid for by {applicantInfo.employerName}. If approved, you will
            receive an individual policy or policies.
          </p>
        </div>
      )}
      {applicantInfo !== null && applicantInfo?.welcomeMessage === 18 && (
        <div>
          <p>
            What if you were unable to work due to an accident or illness? Could you maintain your current lifestyle
            without an income? How would you pay your expenses?
          </p>
          <p>
            {applicantInfo.employerName} understands this need and is providing you with Group Long Term Disability
            Insurance and the following:
            <ul>
              <li>supplemental disability income protection AND</li>
              <li>disability insurance that provides a lump sum benefit.</li>
            </ul>
          </p>
          <p>
            You must apply for the coverage being paid for by {applicantInfo.employerName}. If approved, you will
            receive an individual policy or policies.
          </p>
        </div>
      )}
      {applicantInfo !== null && applicantInfo?.welcomeMessage === 19 && (
        <div>
          <p>
            Congratulations! Due to an increase in your income, you qualify for additional disability income protection.
            You must either apply or waive this coverage. If you do not apply, you will not be eligible for future
            increases in coverage.
          </p>
        </div>
      )}
      {applicantInfo !== null && applicantInfo?.welcomeMessage > 0 && (
        <p>
          The enrollment process is simple – apply in 3 easy steps!
          <br />
          <br />
          Click “Continue” below to begin.
        </p>
      )}
      <Grid item xs={12} justifyContent="flex-end" container>
        {!(applicantInfo?.alreadySigned || applicantInfo?.applicationCompleted) && (
          <Button data-testid="continue" onClick={() => setCurrentTab(1)}>
            CONTINUE
          </Button>
        )}
      </Grid>
    </div>
  );
}

export default Introduction;
