import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { statesData } from 'constants/states';
import { validateEmail, validatePhone, validateSSN, validateZip } from 'utils/validator';
import { useDispatch, useSelector } from 'react-redux';
import HelpIcon from '@mui/icons-material/Help';
import { getApplicantInfo, saveFormInputs } from 'services/Services';
import { useOktaAuth } from '@okta/okta-react';
import { updateApplicantData } from 'state/actions/applicantForm';
import { Input, Select, Button, Tooltip } from 'voltron';
import { yesNoOptionList, genderOptionList, formCodes } from 'constants/index';
import { setCriticalError } from 'state/actions/error';
import { formatAPIMessage } from 'utils/utils';
import style from './ApplicantForm.module.scss';

const labelKind = 'regular';

const validateDemographicFields = (values) => {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = 'firstName';
  }
  if (!values.lastName) {
    errors.lastName = 'lastName';
  }
  if (!values.gender) {
    errors.gender = 'gender';
  }
  if (!values.address) {
    errors.address = 'address';
  }
  if (!values.city) {
    errors.city = 'city';
  }
  if (!values.birthPlace) {
    errors.birthPlace = 'birthPlace';
  }
  if (!values.dateOfBirth) {
    errors.dateOfBirth = 'dateOfBirth';
  }
  if (!values.state) {
    errors.state = 'state';
  }
  if (!validateZip(values.zip)) {
    errors.zip = 'zip';
  }
  return errors;
};

const validateFundamentalOnlyQuestions = (values, isFundamentalIncluded, errorsObject = {}) => {
  const errors = { ...errorsObject };
  if (isFundamentalIncluded) {
    if (!values.beneficiary) {
      errors.beneficiary = 'beneficiary';
    }
    if (!values.beneficiaryRelationship) {
      errors.beneficiaryRelationship = 'beneficiaryRelationship';
    }
  }
  return errors;
};

const validate = (values, isFundamentalIncluded, isUSCitizen) => {
  let errors = validateDemographicFields(values);
  errors = validateFundamentalOnlyQuestions(values, isFundamentalIncluded, errors);

  if (values.phone !== '' && values.phone !== null && !validatePhone(values.phone)) {
    errors.phone = 'phone';
  }

  if (!validateSSN(values.securityNumber)) {
    errors.securityNumber = 'securityNumber';
  }

  if (!validateEmail(values.email)) {
    errors.email = 'email';
  }

  if (!values.isQueasion) {
    errors.usCitizen = 'usCitizen';
  }

  if (!isUSCitizen) {
    if (!values.citizenCountry) {
      errors.citizenCountry = 'citizenCountry';
    }
    if (!values.visaType) {
      errors.visaType = 'visaType';
    }
  }
  return errors;
};

const ApplicantForm = ({ setSelectedTabIndex }) => {
  const { data } = useSelector((state) => state.applicantForm);
  const { oktaAuth } = useOktaAuth();
  const [formData, setFormData] = useState({
    firstName: '',
    middelName: '',
    lastName: '',
    suffix: '',
    gender: '',
    email: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    phone: '',
    securityNumber: '',
    birthPlace: '',
    dateOfBirth: '',
    usCitizen: '',
    citizenCountry: '',
    visaType: '',
    beneficiary: '',
    beneficiaryRelationship: '',
    isQueasion: false,
    maskFormat: '',
  });
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [height, setHeight] = useState(500);
  const [isSubmit, setIsSubmit] = useState(false);

  const isUSCitizen = formData.usCitizen === 'Y';
  const isFundamentalIncluded = [formCodes.FUNDAMENTAL, formCodes.COMBINED, formCodes.COMBINED_CORNERSTONE].includes(
    data?.formCode,
  );

  const handleInputChange = (e) => {
    const { name, value, isValid } = e.target;
    setFormData({ ...formData, [name]: value });
    setFormErrors({
      ...formErrors,
      [name]: value === '' || !isValid ? name : undefined,
    });
  };

  const getApplicantInfoSave = (accessToken, param) => {
    getApplicantInfo(accessToken, param)
      .then((res) => {
        if (res) {
          dispatch(updateApplicantData(res));
        } else {
          dispatch(updateApplicantData(null));
        }
      })
      .catch(() => {
        dispatch(updateApplicantData(null));
      });
  };

  const handleSubmit = () => {
    const errors = validate(formData, isFundamentalIncluded, isUSCitizen);
    setIsSubmit(true);
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      const accessToken = oktaAuth.getAccessToken();
      const request = {
        userFormId: data.applicationId,
        input: {
          firstName: formData.firstName,
          middleInitial: formData.middelName,
          lastName: formData.lastName,
          suffix: formData.suffix,
          gender: formData.gender,
          email: formData.email,
          emailForETD: formData.email,
          address: formData.address,
          city: formData.city,
          stateCd: formData.state,
          zipCode: formData.zip,
          phone: formData.phone,
          ssn: formData.securityNumber,
          birthPlace: formData.birthPlace,
          dob: formData.dateOfBirth,
          usCitizen: formData.usCitizen,
          citizenCountry: formData.citizenCountry,
          visaType: formData.visaType,
          beneficiary: formData.beneficiary,
          beneficiaryRelationship: formData.beneficiaryRelationship,
        },
      };
      setLoading(true);
      saveFormInputs(accessToken, request)
        .then(() => {
          setSelectedTabIndex(1);
          getApplicantInfoSave(accessToken, data.applicationId);
        })
        .catch((err) => {
          dispatch(
            setCriticalError({
              title: 'Save applicant error',
              body: formatAPIMessage(err),
              button: 'OK',
            }),
          );
        });
    }
  };

  useEffect(() => {
    setFormData({
      firstName: data?.input?.firstName,
      middelName: data?.input?.middleInitial,
      lastName: data?.input?.lastName,
      suffix: data?.input?.suffix,
      gender: data?.input?.gender,
      email: data?.input?.email,
      address: data?.input?.address,
      city: data?.input?.city,
      state: data?.input?.stateCd,
      zip: data?.input?.zipCode,
      phone: data?.input?.phone?.split('-')?.join(''),
      securityNumber: data?.input?.ssn,
      birthPlace: data?.input?.birthPlace,
      dateOfBirth: data?.input?.dob,
      usCitizen: data?.input?.usCitizen ? data?.input?.usCitizen : 'Y',
      citizenCountry: data?.input?.citizenCountry,
      visaType: data?.input?.visaType,
      beneficiary: data?.input?.beneficiary,
      beneficiaryRelationship: data?.input?.beneficiaryRelationship,
      isQueasion: !!data?.input?.usCitizen,
    });
    setHeight(document.body.scrollHeight);
    if (data?.applicationId) {
      setLoading(false);
    }
  }, [data]);

  return (
    <>
      {loading && (
        <div className="globalloader" style={{ height: `${height}px` }}>
          <div className="loadertext">Please wait ...</div>
        </div>
      )}
      <div>
        <Grid container spacing={0} className="innerHeader">
          <Grid item xs={12}>
            <h3>Applicant Information</h3>
          </Grid>
          {data?.isVoluntaryOfferedAndRefused || data?.isBuyUpOfferedAndRefused ? (
            <Grid item xs={12}>
              <p>
                Please verify the information below and make any necessary updates to complete the application
                <br /> for the coverage your Employer has chosen to provide for you.
              </p>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <p>
                Please verify the information below and make any necessary updates.
                <br /> You will also need to complete any blank fields.
              </p>
            </Grid>
          )}
        </Grid>
        <form className={isSubmit ? style.employerForm : `${style.employerForm} nonsubmit`}>
          <Grid container spacing={1}>
            <Grid item xs={8}>
              <Input
                name="firstName"
                labelKind={labelKind}
                label="First Name"
                placeholder="First Name"
                material={false}
                autoComplete={false}
                maxLength={40}
                data-testid="firstName"
                required
                value={formData.firstName}
                onChange={handleInputChange}
                kind="name"
                forceErrorMessage={formErrors.firstName}
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                label="Middle Name"
                labelKind={labelKind}
                material={false}
                autoComplete={false}
                kind="name"
                placeholder="Middle Name"
                maxLength={25}
                id="middleName"
                value={formData.middelName}
                name="middelName"
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={8}>
              <Input
                label="Last Name"
                material={false}
                labelKind={labelKind}
                autoComplete={false}
                placeholder="Last Name"
                maxLength={40}
                id="lastName"
                required
                value={formData.lastName}
                name="lastName"
                onChange={handleInputChange}
                kind="name"
                forceErrorMessage={formErrors.lastName}
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                label="Suffix"
                labelKind={labelKind}
                material={false}
                autoComplete={false}
                placeholder="Suffix"
                maxLength={40}
                id="suffix"
                value={formData.suffix}
                name="suffix"
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                name="gender"
                required
                label="Gender"
                labelKind={labelKind}
                labelPosition="top"
                options={genderOptionList}
                clearable={false}
                searchable={false}
                material={false}
                selectedValue={genderOptionList.find((option) => option.value === formData.gender)}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    gender: e.value,
                  });
                  setFormErrors({
                    ...formErrors,
                    gender: e.value === '' && 'gender',
                  });
                }}
                forceErrorMessage={formErrors.gender}
              />
            </Grid>
            {(data?.formCode === 'CornerStone' || data?.formCode === 'CombinedCorner') && (
              <Grid item xs={12}>
                <Input
                  material={false}
                  autoComplete={false}
                  labelKind={labelKind}
                  data-testid="applicant-email"
                  value={formData.email}
                  name="email"
                  kind="email"
                  label="Email"
                  id="email"
                  placeholder="Email"
                  onChange={handleInputChange}
                  required
                  maxLength={40}
                  forceErrorMessage={formErrors.email}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Input
                data-testid="applicant-address"
                value={formData.address}
                name="address"
                labelKind={labelKind}
                label="Address"
                id="address"
                material={false}
                autoComplete={false}
                kind="text"
                placeholder="Address"
                onChange={handleInputChange}
                required
                maxLength={40}
                forceErrorMessage={formErrors.address}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                id="city"
                label="City"
                labelKind={labelKind}
                data-testid="applicant-city"
                value={formData.city}
                material={false}
                autoComplete={false}
                name="city"
                placeholder="City"
                kind="text"
                onChange={handleInputChange}
                required
                maxLength={30}
                forceErrorMessage={formErrors.city}
              />
            </Grid>
            <Grid item xs={12} container justifyContent="space-between">
              <Grid item xs={8.8}>
                <Tooltip
                  position="right"
                  text="If the state showing is incorrect, please contact the agent shown on the screen."
                  className="customvoltrontol"
                >
                  <HelpIcon />
                </Tooltip>
                <Select
                  id="state"
                  label="State"
                  labelPosition="top"
                  labelKind={labelKind}
                  name="state"
                  placeholder="State"
                  clearable={false}
                  searchable={false}
                  material={false}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      state: e.value,
                    });
                    setFormErrors({
                      ...formErrors,
                      state: e.value === '' && 'state',
                    });
                  }}
                  required
                  selectedValue={statesData.find((option) => option.label === formData.state)}
                  disabled
                  forceErrorMessage={formErrors.state}
                />
              </Grid>
              <Grid item xs={2.8}>
                <Input
                  id="zip"
                  kind="zip"
                  labelKind={labelKind}
                  data-testid="applicant-zip"
                  name="zip"
                  label="Zip"
                  material={false}
                  autoComplete={false}
                  value={formData.zip}
                  placeholder="Zip"
                  onChange={handleInputChange}
                  required
                  forceErrorMessage={formErrors.zip}
                />
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Input
                id="phone"
                kind="phone"
                label="Phone"
                labelKind={labelKind}
                testid="applicant-phone"
                name="phone"
                value={formData.phone}
                material={false}
                autoComplete={false}
                placeholder="Phone"
                onChange={handleInputChange}
                forceErrorMessage={formErrors.phone}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                id="securityNumber"
                kind="ssn"
                labelKind={labelKind}
                label="Social Security Number"
                testid="applicant-securityNumber"
                name="securityNumber"
                placeholder="Social Security Number"
                material={false}
                autoComplete={false}
                value={formData.securityNumber}
                onChange={handleInputChange}
                required
                forceErrorMessage={formErrors.securityNumber}
              />
            </Grid>
            {[formCodes.FOUNDATION, formCodes.FUNDAMENTAL, formCodes.COMBINED].includes(data?.formCode) && (
              <Grid item xs={6}>
                <Input
                  kind="text"
                  labelKind={labelKind}
                  label="Place of Birth"
                  placeholder="Place of Birth"
                  maxLength={40}
                  id="birthPlace"
                  material={false}
                  autoComplete={false}
                  value={formData.birthPlace}
                  name="birthPlace"
                  onChange={handleInputChange}
                  required={data?.input.issueState !== 'NY'}
                  disabled={data?.input.issueState === 'NY'}
                  forceErrorMessage={formErrors.birthPlace}
                />
              </Grid>
            )}
            {[formCodes.CORNERSTONE, formCodes.COMBINED_CORNERSTONE].includes(data?.formCode) && (
              <Grid item xs={6}>
                <Input
                  kind="text"
                  labelKind={labelKind}
                  label="State of Birth (or Country if other than USA)"
                  placeholder="State of Birth (or Country if other than USA)"
                  maxLength={40}
                  id="birthPlace"
                  material={false}
                  autoComplete={false}
                  value={formData.birthPlace}
                  name="birthPlace"
                  onChange={handleInputChange}
                  required={data?.input.issueState !== 'NY'}
                  disabled={data?.input.issueState === 'NY'}
                  forceErrorMessage={formErrors.birthPlace}
                />
              </Grid>
            )}
            <Grid item xs={6}>
              <Input
                kind="date"
                id="dateOfBirth"
                labelKind={labelKind}
                label="Date of Birth"
                testid="applicant-dateOfBirth"
                name="dateOfBirth"
                material={false}
                autoComplete={false}
                placeholder="mm/dd/yyyy"
                value={formData.dateOfBirth}
                onChange={handleInputChange}
                required
                forceErrorMessage={formErrors.dateOfBirth}
              />
            </Grid>
            <Grid item xs={12} container justifyContent="space-between">
              <Grid item xs={5.9}>
                <Select
                  label="Are you a U.S. citizen or permanent resident?"
                  labelPosition="top"
                  labelKind={labelKind}
                  clearable={false}
                  searchable={false}
                  material={false}
                  name="usCitizen"
                  options={yesNoOptionList}
                  placeholder="Are you a U.S. citizen or permanent resident?"
                  onChange={(e) => {
                    if (e.value === 'Y') {
                      setFormData({
                        ...formData,
                        usCitizen: e.value,
                        citizenCountry: '',
                        visaType: '',
                        isQueasion: true,
                      });
                    } else {
                      setFormData({
                        ...formData,
                        usCitizen: e.value,
                        isQueasion: true,
                      });
                    }
                    setFormErrors({
                      ...formErrors,
                      usCitizen: e.value === '' && 'usCitizen',
                    });
                  }}
                  required
                  selectedValue={
                    formData.isQueasion ? yesNoOptionList.find((option) => option.value === formData.usCitizen) : ''
                  }
                  forceErrorMessage={formErrors.usCitizen}
                />
              </Grid>
              <Grid item xs={5.9}>
                <Input
                  id="citizenCountry"
                  name="citizenCountry"
                  labelKind={labelKind}
                  label="Citizenship"
                  kind="text"
                  placeholder="Country"
                  material={false}
                  autoComplete={false}
                  value={formData.citizenCountry}
                  disabled={isUSCitizen}
                  onChange={handleInputChange}
                  required={!isUSCitizen}
                  forceErrorMessage={!isUSCitizen && formErrors.citizenCountry}
                />
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Input
                id="visaType"
                label="Visa Type"
                labelKind={labelKind}
                name="visaType"
                placeholder="Visa Type"
                material={false}
                autoComplete={false}
                value={formData.visaType}
                kind="text"
                disabled={isUSCitizen}
                onChange={handleInputChange}
                required={!isUSCitizen}
                forceErrorMessage={!isUSCitizen && formErrors.visaType}
              />
            </Grid>
            {isFundamentalIncluded && (
              <>
                <Grid item xs={12}>
                  <Input
                    id="beneficiary"
                    label="Beneficiary (in the event benefits are payable after death) For Fundamental Application"
                    name="beneficiary"
                    labelKind={labelKind}
                    data-testid="applicant-beneficiary"
                    kind="text"
                    material={false}
                    autoComplete={false}
                    value={formData.beneficiary}
                    placeholder="Beneficiary"
                    onChange={handleInputChange}
                    required
                    forceErrorMessage={formErrors.beneficiary}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input
                    id="beneficiaryRelationship"
                    kind="text"
                    material={false}
                    labelKind={labelKind}
                    autoComplete={false}
                    label="Relationship of Beneficiary to Proposed Insured For Fundamental Application"
                    name="beneficiaryRelationship"
                    value={formData.beneficiaryRelationship}
                    placeholder="Relationship of Beneficiary"
                    onChange={handleInputChange}
                    required
                    forceErrorMessage={formErrors.beneficiaryRelationship}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} justifyContent="flex-end" container>
              <Button onClick={handleSubmit}>SAVE & CONTINUE</Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </>
  );
};

export default ApplicantForm;
