import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { Box, Container, Grid } from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';
import { withTransaction } from '@elastic/apm-rum-react';
import { Input, Select, Button, Toast } from 'voltron';

import { getBusiness, saveBusiness } from 'services/Services';
import { setCriticalError } from 'state/actions/error';
import { states } from 'constants/states';
import routes from 'constants/routes';
import { validateEmail, validatePhone, validateZip } from 'utils/validator';
import { formatAPIMessage, isAdminUser } from 'utils/utils';
import styles from './EditEmployer.module.scss';

const inputRowClass = 'formRow';
const labelKind = 'regular';

const FORM_FIELD_NAMES = {
  name: 'name',
  address: 'address',
  city: 'city',
  stateCd: 'stateCd',
  zipCode: 'zipCode',
  phone: 'phone',
  email: 'email',
};

const getNonRequiredInvalidFormElements = (invalidFormElements, inputData, isEmailValid, isPhoneValid) => {
  if (inputData.email?.trim() && (!isEmailValid || !validateEmail(inputData.email.trim()))) {
    invalidFormElements.push(FORM_FIELD_NAMES.email);
  }
  if (inputData.phone?.trim() && (!isPhoneValid || !validatePhone(inputData.phone.trim()))) {
    invalidFormElements.push(FORM_FIELD_NAMES.phone);
  }
};

const getInvalidFormElements = (isZipValid, isEmailValid, isPhoneValid, inputData = {}) => {
  const invalidFormElements = [];

  if (!inputData.name?.trim()) {
    invalidFormElements.push(FORM_FIELD_NAMES.name);
  }
  if (!inputData.address?.trim()) {
    invalidFormElements.push(FORM_FIELD_NAMES.address);
  }
  if (!inputData.city?.trim()) {
    invalidFormElements.push(FORM_FIELD_NAMES.city);
  }
  if (!inputData.stateCd) {
    invalidFormElements.push(FORM_FIELD_NAMES.stateCd);
  }
  if (!isZipValid || !validateZip(inputData.zipCode?.trim())) {
    invalidFormElements.push(FORM_FIELD_NAMES.zipCode);
  }
  getNonRequiredInvalidFormElements(invalidFormElements, inputData, isEmailValid, isPhoneValid);

  return invalidFormElements;
};

const getRequiredFields = ({ invalidFormData, employerFormObject, stateOptionList, setIsValidZip, onInputChange }) => (
  <>
    <Input
      label="Employer Name"
      labelKind={labelKind}
      placeholder="Employer Name"
      maxLength={40}
      required
      data-testid="employer-name"
      material={false}
      autoComplete={false}
      containerClass={inputRowClass}
      forceErrorMessage={invalidFormData.includes(FORM_FIELD_NAMES.name)}
      value={employerFormObject.name}
      onChange={(e) => onInputChange(FORM_FIELD_NAMES.name, e.target.value)}
    />
    <Input
      label="Address"
      labelKind={labelKind}
      placeholder="Address"
      maxLength={40}
      required
      data-testid="employer-address"
      material={false}
      autoComplete={false}
      containerClass={inputRowClass}
      forceErrorMessage={invalidFormData.includes(FORM_FIELD_NAMES.address)}
      value={employerFormObject.address}
      onChange={(e) => onInputChange(FORM_FIELD_NAMES.address, e.target.value)}
    />
    <Input
      label="City"
      labelKind={labelKind}
      placeholder="City"
      maxLength={30}
      required
      data-testid="employer-city"
      material={false}
      autoComplete={false}
      containerClass={inputRowClass}
      forceErrorMessage={invalidFormData.includes(FORM_FIELD_NAMES.city)}
      value={employerFormObject.city}
      onChange={(e) => onInputChange(FORM_FIELD_NAMES.city, e.target.value)}
    />
    <div className={inputRowClass}>
      <Select
        label="State"
        labelKind={labelKind}
        labelPosition="top"
        name="employer-state"
        options={stateOptionList}
        clearable={false}
        searchable={false}
        material={false}
        containerClassName={styles.state}
        required
        forceErrorMessage={invalidFormData.includes(FORM_FIELD_NAMES.stateCd)}
        selectedValue={stateOptionList.find((option) => option.value === employerFormObject.stateCd)}
        onChange={(e) => onInputChange(FORM_FIELD_NAMES.stateCd, e.value)}
      />
      <Input
        label="Zip"
        labelKind={labelKind}
        placeholder="Zip"
        kind="zip"
        data-testid="employer-zip"
        material={false}
        autoComplete={false}
        containerClass={styles.zip}
        required
        forceErrorMessage={invalidFormData.includes(FORM_FIELD_NAMES.zipCode)}
        value={employerFormObject.zipCode}
        onChange={(e) => {
          setIsValidZip(e.target.isValid);
          onInputChange(FORM_FIELD_NAMES.zipCode, e.target.value);
        }}
      />
    </div>
  </>
);

const getOptionalFields = ({
  invalidFormData,
  employerFormObject,
  setIsValidPhone,
  setIsValidEmail,
  onInputChange,
}) => (
  <>
    <Input
      label="Phone"
      labelKind={labelKind}
      placeholder="Phone"
      kind="phone"
      data-testid="employer-phone"
      material={false}
      autoComplete={false}
      containerClass={inputRowClass}
      forceErrorMessage={invalidFormData.includes(FORM_FIELD_NAMES.phone)}
      value={employerFormObject.phone}
      onChange={(e) => {
        setIsValidPhone(e.target.isValid);
        onInputChange(FORM_FIELD_NAMES.phone, e.target.value);
      }}
    />
    <Input
      label="Email Address"
      labelKind={labelKind}
      placeholder="Email"
      kind="email"
      maxLength={50}
      data-testid="employer-email"
      material={false}
      autoComplete={false}
      containerClass={inputRowClass}
      forceErrorMessage={invalidFormData.includes(FORM_FIELD_NAMES.email)}
      value={employerFormObject.email}
      onChange={(e) => {
        setIsValidEmail(e.target.isValid);
        onInputChange(FORM_FIELD_NAMES.email, e.target.value);
      }}
    />
  </>
);

const onError = (error, dispatch) => {
  dispatch(
    setCriticalError({
      title: 'Save employer failed',
      body: formatAPIMessage(error),
      button: 'OK',
    }),
  );
};

const onSubmit = async (accessToken, employerFormObject, history, setLoading, dispatch) => {
  try {
    setLoading(true);
    await saveBusiness(accessToken, employerFormObject);
    Toast.success('Employer Has Been Updated.');
    history.push(routes.businesses);
  } catch (error) {
    onError(error, dispatch);
  }
};

const getButtons = ({ history, dispatch, accessToken, invalidFormData, employerFormObject, setLoading }) => (
  <div className="buttons">
    <Button kind="invert" onClick={() => history.push(routes.businesses)}>
      CANCEL
    </Button>
    <Button
      disabled={invalidFormData.length > 0}
      onClick={() => onSubmit(accessToken, employerFormObject, history, setLoading, dispatch)}
    >
      SAVE CHANGES
    </Button>
  </div>
);

const getLoader = ({ loadingHeight }) => (
  <div className="globalloader" style={{ height: `${loadingHeight}px` }}>
    <div className="loadertext">Please wait...</div>
  </div>
);

const EditEmployer = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.appState);
  const { oktaAuth } = useOktaAuth();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [loadingHeight, setLoadingHeight] = useState(500);
  const [employerToEdit, setEmployerToEdit] = useState();
  const [employerFormObject, setEmployerFormObject] = useState();
  const [employerAccessible, setEmployerAccessible] = useState(true);
  const [invalidFormData, setInvalidFormData] = useState([]);
  const [isValidZip, setIsValidZip] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPhone, setIsValidPhone] = useState(true);
  const stateOptionList = states.map((state) => ({ label: state.abbreviation, value: state.abbreviation }));
  const accessToken = oktaAuth.getAccessToken();

  useEffect(async () => {
    try {
      const employerResponse = await getBusiness(accessToken, params.id);
      setEmployerToEdit(employerResponse);
      setEmployerFormObject(employerResponse);
    } catch (error) {
      setEmployerAccessible(false);
      setLoading(false);
      dispatch(
        setCriticalError({
          title: 'Get employer failed',
          body: formatAPIMessage(error),
          button: 'OK',
        }),
      );
    }
  }, []);

  useEffect(() => {
    setLoadingHeight(document.body.scrollHeight);
    let getPhoneTimeout;
    if (employerToEdit) {
      // need to prevent Voltron from treating potential valid phone values as invalid initially
      getPhoneTimeout = setTimeout(() => {
        const phoneInput = document.querySelectorAll('[data-testid="employer-phone"]')[0];
        phoneInput.focus();
        phoneInput.blur();
        window.scrollTo(0, 0);
        setLoading(false);
      }, 500);
    }
    return () => clearTimeout(getPhoneTimeout);
  }, [employerToEdit]);

  useEffect(() => {
    setInvalidFormData(getInvalidFormElements(isValidZip, isValidEmail, isValidPhone, employerFormObject));
  }, [employerFormObject]);

  const onInputChange = (inputName, value) => {
    setEmployerFormObject((formObject) => ({
      ...formObject,
      [inputName]: value,
    }));
  };

  const isCannotEdit = employerToEdit && !employerToEdit.canEdit;

  if ((userInfo && !isAdminUser(userInfo)) || !employerAccessible || isCannotEdit) {
    return <>Employer cannot be edited.</>;
  }

  return (
    <Box sx={{ width: '82vw', marginTop: '4rem' }}>
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {loading && getLoader({ loadingHeight })}
            {employerFormObject && (
              <div className="adminEditPage">
                <header>
                  <h3>Employer Information</h3>
                  <p>Edit {employerToEdit?.name}</p>
                </header>
                <form autoComplete="off">
                  <div className="formBody">
                    {getRequiredFields({
                      invalidFormData,
                      employerFormObject,
                      stateOptionList,
                      setIsValidZip,
                      onInputChange,
                    })}
                    {getOptionalFields({
                      invalidFormData,
                      employerFormObject,
                      setIsValidPhone,
                      setIsValidEmail,
                      onInputChange,
                    })}
                  </div>
                  {getButtons({ history, dispatch, accessToken, invalidFormData, employerFormObject, setLoading })}
                </form>
              </div>
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default withTransaction('EditEmployerForm', 'component')(EditEmployer);
