import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';
import { withTransaction } from '@elastic/apm-rum-react';
import { Box, Card, CardContent, Container, Grid } from '@mui/material';

import { getApplicantInfo } from 'services/Services';
import { pageLoaded, pageLoading } from 'state/actions/page_loading';
import { updateApplicantData } from 'state/actions/applicantForm';
import AgentInformation from 'components/agent-information/AgentInformation';
import IncreasesNavigationTabs from './increases/navigation/NavigationTabs';
import PrimaryNavigationTabs from './primary/navigation/NavigationTabs';

const EnrollmentForm = ({ isIncreases = false }) => {
  const { oktaAuth } = useOktaAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(pageLoading());
    const accessToken = oktaAuth.getAccessToken();
    const userFormId = window.location.pathname.split('/').pop();

    getApplicantInfo(accessToken, userFormId)
      .then((applicantInfo) => {
        dispatch(updateApplicantData(applicantInfo || null));
        dispatch(pageLoaded());
      })
      .catch(() => dispatch(updateApplicantData(null)));
  }, []);

  return (
    <Box sx={{ width: '100%' }}>
      <Container maxWidth="xl">
        <h1>Application</h1>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Card>
              <CardContent className="paddingHorizontal">
                {isIncreases ? <IncreasesNavigationTabs /> : <PrimaryNavigationTabs />}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <AgentInformation />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default withTransaction('EnrollmentForm', 'component')(EnrollmentForm);
