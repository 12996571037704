import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'voltron';

import {
  ElectronicTransactionsSummary,
  ExistingCompaniesApplicationSummary,
  formatDate,
  formatSSN,
  getApplicantAddressSummary,
  getExistingCompaniesForApplicationSummary,
} from 'utils/utils';
import { formCodes } from 'constants/index';
import { pageLoaded, pageLoading } from 'state/actions/page_loading';
import styles from './Summary.module.scss';

const ApplicantSummary = ({ input = { ssn: '' } }) => (
  <>
    <p className={styles.sectionHeader}>
      <strong>Applicant</strong>
    </p>
    <table className={styles.summaryTable}>
      <tbody>
        <tr>
          <td>First Name</td>
          <td>{input.firstName}</td>
        </tr>
        {input.middleInitial ? (
          <tr>
            <td>Middle Name</td>
            <td>{input.middleInitial}</td>
          </tr>
        ) : null}
        <tr>
          <td>Last Name</td>
          <td>{input.lastName}</td>
        </tr>
        {input.suffix ? (
          <tr>
            <td>Suffix</td>
            <td>{input.suffix}</td>
          </tr>
        ) : null}
        {getApplicantAddressSummary(input)}
        <tr>
          <td>Social Security Number</td>
          <td>{formatSSN(input.ssn)}</td>
        </tr>
        <tr>
          <td>Date of Birth</td>
          <td>{formatDate(new Date(input.dob))}</td>
        </tr>
        {input.existingInsuranceComments ? (
          <tr>
            <td>
              Provide benefit amount and type (individual, group etc) of any Disability Insurance in force or pending
              since the last time you applied for coverage with us or for which you will become eligible in the next 12
              months. Also, provide the policy number and issue date if replacing coverage.
            </td>
            <td>{input.existingInsuranceComments}</td>
          </tr>
        ) : null}
      </tbody>
    </table>
  </>
);

const Summary = ({ setSelectedTabIndex }) => {
  const dispatch = useDispatch();
  const {
    data: { applicationId, formCode, input },
  } = useSelector((state) => state.applicantForm);

  useEffect(() => {
    dispatch(applicationId > 0 ? pageLoaded() : pageLoading());
  }, [applicationId]);

  if (!input) {
    return null;
  }

  return (
    <div className={styles.summary}>
      <header>
        <h3>Application Summary</h3>
        <p>Please review the information you have entered. If all information is correct, click continue.</p>
      </header>
      <main>
        <div className={styles.mainContent}>
          <ApplicantSummary input={input} />
          {[formCodes.CORNERSTONE, formCodes.COMBINED_CORNERSTONE].includes(formCode) && (
            <>
              <p className={styles.sectionHeader}>
                <strong>Existing Disability Insurance</strong>
              </p>
              <ExistingCompaniesApplicationSummary
                existingCompaniesObject={getExistingCompaniesForApplicationSummary(input)}
                styles={styles}
              />
            </>
          )}
          <ElectronicTransactionsSummary input={input} styles={styles} isIncreases />
          <br />
        </div>
        <div className={styles.submitButton}>
          <Button onClick={setSelectedTabIndex}>CONTINUE</Button>
        </div>
      </main>
    </div>
  );
};

export default Summary;
