import { SET_USER_INFO } from 'state/actions/appState';

const initialState = {
  userInfo: null,
};

const appStateReducer = (state = initialState, action) => {
  if (action.type === SET_USER_INFO) {
    return {
      ...state,
      userInfo: action.payload,
    };
  }
  return state;
};

export default appStateReducer;
