import { useState, useEffect } from 'react';
import CurrencyFormat from 'react-currency-format';
import { Input, Select, TextArea } from 'voltron';
import { yesNoOptionList, YES_OPTION } from 'constants/index';

import mainFormStyles from '../ExistingDisabilityInsurance.module.scss';
import styles from './FundamentalQuestions.module.scss';
import { onInputChange, onInputDataChange } from '../formUtils';

const DECIMAL_RADIX = 10;

const labelPosition = 'top';

const FORM_FIELD_NAMES = {
  existingDisabilityWithLumpSum: 'existingDisabilityWithLumpSum',
  companyAndBenefitAmount: 'companyAndBenefitAmount',
  disabilityInsuranceChanged: 'disabilityInsuranceChanged',
  company: 'company',
  policyNumber: 'policyNumber',
  amountToBeReplaced: 'amountToBeReplaced',
  otherChanges: 'otherChanges',
  appliedForInsurance: 'appliedForInsurance',
  appliedForInsuranceExplain: 'appliedForInsuranceExplain',
};

const setInvalidDetailFormElements = (inputData, invalidFormElements) => {
  const replaceAmount = inputData.amountToBeReplaced;
  const isReplaceAmountInvalid = replaceAmount === undefined || replaceAmount === null || Number.isNaN(replaceAmount);

  if (!inputData.company?.trim()) {
    invalidFormElements.push(FORM_FIELD_NAMES.company);
  }
  if (!inputData.policyNumber?.trim()) {
    invalidFormElements.push(FORM_FIELD_NAMES.policyNumber);
  }
  if (isReplaceAmountInvalid) {
    invalidFormElements.push(FORM_FIELD_NAMES.amountToBeReplaced);
  }
  if (!inputData.otherChanges?.trim()) {
    invalidFormElements.push(FORM_FIELD_NAMES.otherChanges);
  }
};

const getInvalidFormElements = (inputData) => {
  const invalidFormElements = [];

  if (!inputData.existingDisabilityWithLumpSum) {
    invalidFormElements.push(FORM_FIELD_NAMES.existingDisabilityWithLumpSum);
  } else if (inputData.existingDisabilityWithLumpSum === YES_OPTION && !inputData.companyAndBenefitAmount?.trim()) {
    invalidFormElements.push(FORM_FIELD_NAMES.companyAndBenefitAmount);
  }

  if (!inputData.disabilityInsuranceChanged) {
    invalidFormElements.push(FORM_FIELD_NAMES.disabilityInsuranceChanged);
  } else if (inputData.disabilityInsuranceChanged === YES_OPTION) {
    setInvalidDetailFormElements(inputData, invalidFormElements);
  }

  if (!inputData.appliedForInsurance) {
    invalidFormElements.push(FORM_FIELD_NAMES.appliedForInsurance);
  } else if (inputData.appliedForInsurance === YES_OPTION && !inputData.appliedForInsuranceExplain?.trim()) {
    invalidFormElements.push(FORM_FIELD_NAMES.appliedForInsuranceExplain);
  }

  return invalidFormElements;
};

const getAdditionalFieldChanges = (inputName, value) => {
  let additionalFieldChanges = {};
  if (inputName === FORM_FIELD_NAMES.existingDisabilityWithLumpSum && value !== YES_OPTION) {
    additionalFieldChanges = {
      companyAndBenefitAmount: '',
    };
  } else if (inputName === FORM_FIELD_NAMES.disabilityInsuranceChanged && value !== YES_OPTION) {
    additionalFieldChanges = {
      company: '',
      policyNumber: '',
      amountToBeReplaced: 0,
      otherChanges: '',
    };
  } else if (inputName === FORM_FIELD_NAMES.appliedForInsurance && value !== YES_OPTION) {
    additionalFieldChanges = {
      appliedForInsuranceExplain: '',
    };
  }
  return additionalFieldChanges;
};

const getReplaceAmountField = ({
  amountToBeReplaced,
  isCurrencyFieldOnFocus,
  invalidInputs,
  setInputData,
  setFormInputData,
  setIsCurrencyFieldOnFocus,
}) => {
  const isReplaceAmountFieldInvalid =
    !isCurrencyFieldOnFocus && invalidInputs.includes(FORM_FIELD_NAMES.amountToBeReplaced);
  return (
    <div className={`${styles.questionItem} ${styles.replaceAmountField}`}>
      <span className={styles.replaceAmountLabel}>Amount to be replaced</span>
      <span className={mainFormStyles.currencyInput}>
        <CurrencyFormat
          value={Number.isInteger(+amountToBeReplaced) ? +amountToBeReplaced : ''}
          data-testid="amount-to-be-replaced-fundamental"
          thousandSeparator
          allowNegative={false}
          decimalScale={0}
          name="amountToBeReplaced"
          prefix="$"
          onValueChange={({ value }) =>
            onInputChange(
              FORM_FIELD_NAMES.amountToBeReplaced,
              parseInt(value, DECIMAL_RADIX),
              getAdditionalFieldChanges,
              setInputData,
              setFormInputData,
            )
          }
          onFocus={() => setIsCurrencyFieldOnFocus(true)}
          onBlur={() => setIsCurrencyFieldOnFocus(false)}
          className={isReplaceAmountFieldInvalid ? mainFormStyles.error : ''}
          aria-required
          aria-invalid={isReplaceAmountFieldInvalid}
        />
        <div
          className={mainFormStyles.currencyErrorMessage}
          aria-live={isReplaceAmountFieldInvalid ? 'assertive' : 'off'}
        >
          {isReplaceAmountFieldInvalid ? 'Please enter a value' : '\u00A0'}
        </div>
      </span>
    </div>
  );
};

const getDisabilityChangedDetailFields = ({
  company,
  policyNumber,
  amountToBeReplaced,
  otherChanges,
  isOnSubmit,
  invalidInputs,
  setInputData,
  setFormInputData,
  isCurrencyFieldOnFocus,
  setIsCurrencyFieldOnFocus,
}) => {
  const labelKind = 'regular';
  return (
    <div className={styles.detailSection}>
      <p className={styles.detailQuestionsHeader}>If yes, give details.</p>
      <div className={styles.questionsRow}>
        <Input
          label="Company Name"
          labelKind={labelKind}
          data-testid="company-name-fundamental"
          material={false}
          autoComplete={false}
          required
          forceErrorMessage={isOnSubmit && invalidInputs.includes(FORM_FIELD_NAMES.company)}
          containerClass={styles.questionItem}
          value={company}
          onChange={(e) =>
            onInputChange(
              FORM_FIELD_NAMES.company,
              e.target.value,
              getAdditionalFieldChanges,
              setInputData,
              setFormInputData,
            )
          }
        />
        <Input
          label="Policy Number"
          labelKind={labelKind}
          data-testid="policy-number-fundamental"
          material={false}
          autoComplete={false}
          required
          forceErrorMessage={isOnSubmit && invalidInputs.includes(FORM_FIELD_NAMES.policyNumber)}
          containerClass={styles.questionItem}
          value={policyNumber}
          onChange={(e) =>
            onInputChange(
              FORM_FIELD_NAMES.policyNumber,
              e.target.value,
              getAdditionalFieldChanges,
              setInputData,
              setFormInputData,
            )
          }
        />
      </div>
      <div className={styles.questionsRow}>
        {getReplaceAmountField({
          amountToBeReplaced,
          isCurrencyFieldOnFocus,
          invalidInputs,
          setInputData,
          setFormInputData,
          setIsCurrencyFieldOnFocus,
        })}
        <Input
          label="Other Changes"
          labelKind={labelKind}
          data-testid="other-changes-fundamental"
          material={false}
          autoComplete={false}
          required
          forceErrorMessage={isOnSubmit && invalidInputs.includes(FORM_FIELD_NAMES.otherChanges)}
          containerClass={styles.questionItem}
          value={otherChanges}
          onChange={(e) =>
            onInputChange(
              FORM_FIELD_NAMES.otherChanges,
              e.target.value,
              getAdditionalFieldChanges,
              setInputData,
              setFormInputData,
            )
          }
        />
      </div>
    </div>
  );
};

const getMainForm = ({
  existingDisabilityWithLumpSum,
  companyAndBenefitAmount,
  disabilityInsuranceChanged,
  company,
  policyNumber,
  amountToBeReplaced,
  otherChanges,
  isOnSubmit,
  invalidInputs,
  setInputData,
  setFormInputData,
  isCurrencyFieldOnFocus,
  setIsCurrencyFieldOnFocus,
}) => (
  <>
    <Select
      label={`Do you have any other disability insurance that pays: (i) a catastrophic disability benefit,
        in force or pending with this company; or (ii) a lump sum benefit, in force or pending with this company
        or any other insurance company?`}
      labelPosition={labelPosition}
      name="existing-disability-with-lump-sum-fundamental"
      options={yesNoOptionList}
      clearable={false}
      searchable={false}
      material={false}
      required
      forceErrorMessage={isOnSubmit && invalidInputs.includes(FORM_FIELD_NAMES.existingDisabilityWithLumpSum)}
      selectedValue={yesNoOptionList.find((option) => option.value === existingDisabilityWithLumpSum)}
      onChange={(e) =>
        onInputChange(
          FORM_FIELD_NAMES.existingDisabilityWithLumpSum,
          e.value,
          getAdditionalFieldChanges,
          setInputData,
          setFormInputData,
        )
      }
    />
    {existingDisabilityWithLumpSum === YES_OPTION && (
      <Input
        label="If yes, give name of Company, Type (catastrophic or lump sum), and Benefit Amount."
        data-testid="company-and-benefit-amount-fundamental"
        material={false}
        autoComplete={false}
        required
        forceErrorMessage={isOnSubmit && invalidInputs.includes(FORM_FIELD_NAMES.companyAndBenefitAmount)}
        value={companyAndBenefitAmount}
        onChange={(e) =>
          onInputChange(
            FORM_FIELD_NAMES.companyAndBenefitAmount,
            e.target.value,
            getAdditionalFieldChanges,
            setInputData,
            setFormInputData,
          )
        }
      />
    )}
    <Select
      label="Will any disability insurance with this company or any other insurance company be replaced,
         reduced, or changed if the insurance now being applied for is issued?"
      labelPosition={labelPosition}
      name="disability-insurance-changed-fundamental"
      options={yesNoOptionList}
      clearable={false}
      searchable={false}
      material={false}
      required
      forceErrorMessage={isOnSubmit && invalidInputs.includes(FORM_FIELD_NAMES.disabilityInsuranceChanged)}
      selectedValue={yesNoOptionList.find((option) => option.value === disabilityInsuranceChanged)}
      onChange={(e) =>
        onInputChange(
          FORM_FIELD_NAMES.disabilityInsuranceChanged,
          e.value,
          getAdditionalFieldChanges,
          setInputData,
          setFormInputData,
        )
      }
    />
    {disabilityInsuranceChanged === YES_OPTION &&
      getDisabilityChangedDetailFields({
        company,
        policyNumber,
        amountToBeReplaced,
        otherChanges,
        isOnSubmit,
        invalidInputs,
        setInputData,
        setFormInputData,
        isCurrencyFieldOnFocus,
        setIsCurrencyFieldOnFocus,
      })}
  </>
);

const FundamentalQuestions = ({
  existingInputData,
  setFormInputData,
  setIsFormInvalidMap,
  isOnSubmit = false,
  isCombinedWithAnotherProduct = false,
}) => {
  const [inputData, setInputData] = useState(existingInputData);
  const [invalidInputs, setInvalidInputs] = useState([]);
  const [isCurrencyFieldOnFocus, setIsCurrencyFieldOnFocus] = useState(false);
  const {
    existingDisabilityWithLumpSum,
    companyAndBenefitAmount,
    disabilityInsuranceChanged,
    company,
    policyNumber,
    amountToBeReplaced,
    otherChanges,
    appliedForInsurance,
    appliedForInsuranceExplain,
  } = inputData;

  useEffect(() => {
    onInputDataChange(inputData, 'fundamental', getInvalidFormElements, setInvalidInputs, setIsFormInvalidMap);
  }, [inputData]);

  return (
    <div className={styles.form}>
      {isCombinedWithAnotherProduct && (
        <p data-testid="combined-text-fundamental">
          <strong>For Fundamental Application</strong>
        </p>
      )}
      {getMainForm({
        existingDisabilityWithLumpSum,
        companyAndBenefitAmount,
        disabilityInsuranceChanged,
        company,
        policyNumber,
        amountToBeReplaced,
        otherChanges,
        isOnSubmit,
        invalidInputs,
        setInputData,
        setFormInputData,
        isCurrencyFieldOnFocus,
        setIsCurrencyFieldOnFocus,
      })}
      <Select
        label="Other than noted above, have you applied for disability insurance in the past 12 months?"
        labelPosition={labelPosition}
        name="applied-for-insurance-fundamental"
        options={yesNoOptionList}
        clearable={false}
        searchable={false}
        material={false}
        required
        forceErrorMessage={isOnSubmit && invalidInputs.includes(FORM_FIELD_NAMES.appliedForInsurance)}
        selectedValue={yesNoOptionList.find((option) => option.value === appliedForInsurance)}
        onChange={(e) =>
          onInputChange(
            FORM_FIELD_NAMES.appliedForInsurance,
            e.value,
            getAdditionalFieldChanges,
            setInputData,
            setFormInputData,
          )
        }
      />
      {appliedForInsurance === YES_OPTION && (
        <TextArea
          groupLabel="If Yes, explain."
          maxLength={500}
          required
          forceErrorMessage={isOnSubmit && invalidInputs.includes(FORM_FIELD_NAMES.appliedForInsuranceExplain)}
          defaultValue={appliedForInsuranceExplain}
          placeholderText="Explanation"
          textAreaProps={{
            'data-testid': 'applied-for-insurance-explain-fundamental',
            style: { resize: 'vertical' },
            onChange: (e) =>
              onInputChange(
                FORM_FIELD_NAMES.appliedForInsuranceExplain,
                e.target.value,
                getAdditionalFieldChanges,
                setInputData,
                setFormInputData,
              ),
          }}
        />
      )}
    </div>
  );
};

export default FundamentalQuestions;
