import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Box, Container, Grid } from '@mui/material';
import { Edit, NotInterested, Delete } from '@mui/icons-material';
import { useOktaAuth } from '@okta/okta-react';
import { withTransaction } from '@elastic/apm-rum-react';
import { Button, Modal } from 'voltron';

import { fetchAgencies, removeAgency } from 'state/actions/adminEntities';
import PaginatedTable from 'components/PaginatedTable/PaginatedTable';
import { exportAgencies } from 'services/Services';
import { downloadFile } from 'utils/utils';
import { pageLoaded, pageLoading } from 'state/actions/page_loading';

const actionButtonClass = 'actionButton';
const modalButtonClass = 'modalButton';
const linkButton = 'link';

const EditComponent =
  (history) =>
  ({ id, canEdit }) => (
    <Button
      kind="link"
      data-testid={`edit-agency-${id}`}
      aria-label="edit-agency"
      className={actionButtonClass}
      disabled={!canEdit}
      onClick={() => history.push(`/agency/${id}`)}
    >
      {canEdit ? <Edit /> : <NotInterested />}
    </Button>
  );

const DeleteComponent = (setIsDeleteConfirmModalOpen, setAgencyToBeDeleted) => (agency) => (
  <Button
    kind="link"
    data-testid={`delete-agency-${agency.id}`}
    aria-label="delete-agency"
    className={actionButtonClass}
    disabled={!agency.canDelete}
    onClick={() => {
      setIsDeleteConfirmModalOpen(true);
      setAgencyToBeDeleted(agency);
    }}
  >
    {agency.canDelete ? <Delete /> : <NotInterested />}
  </Button>
);

const getTableColumns = (history, setIsDeleteConfirmModalOpen, setAgencyToBeDeleted) => [
  {
    Header: 'Agencies',
    width: 45,
    accessor: 'name',
    isSearchable: true,
  },
  {
    Header: 'Number',
    width: 35,
    accessor: 'code',
    isSearchable: true,
  },
  {
    Header: 'Edit',
    width: 10,
    isAction: true,
    component: EditComponent(history),
  },
  {
    Header: 'Delete',
    width: 10,
    isAction: true,
    component: DeleteComponent(setIsDeleteConfirmModalOpen, setAgencyToBeDeleted),
  },
];

const AgenciesDashboard = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { oktaAuth } = useOktaAuth();
  const { agencies, isSetAgenciesLoading } = useSelector((state) => state.adminEntities);
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] = useState(false);
  const [agencyToBeDeleted, setAgencyToBeDeleted] = useState({});

  const accessToken = oktaAuth.getAccessToken();
  const tableColumns = getTableColumns(history, setIsDeleteConfirmModalOpen, setAgencyToBeDeleted);

  useEffect(() => {
    dispatch(fetchAgencies(accessToken));
  }, []);

  const resetDeleteModalState = () => {
    setIsDeleteConfirmModalOpen(false);
    setAgencyToBeDeleted({});
  };

  return (
    <Box sx={{ width: '82vw', marginTop: '4rem' }}>
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="adminDashboardPage">
              <h2 className="pageTitle">Agencies</h2>
              <div className="contentBody">
                <div className="actions">
                  <p className="actionsTitle">Actions</p>
                  <Button
                    data-testid="export-agencies"
                    className={actionButtonClass}
                    onClick={async () => {
                      dispatch(pageLoading());
                      const { data, headers } = await exportAgencies(accessToken);
                      dispatch(pageLoaded());
                      downloadFile(data, headers);
                    }}
                  >
                    EXPORT
                  </Button>
                </div>
                <div className="tableContainer">
                  <PaginatedTable
                    className="dashboardTable"
                    sortColumn="name"
                    sortOrder="ASC"
                    columns={tableColumns}
                    data={agencies}
                    isDataLoading={isSetAgenciesLoading}
                  />
                  <Modal
                    open={isDeleteConfirmModalOpen}
                    title={`Would you like to delete ${agencyToBeDeleted.name}?`}
                    handleToggle={resetDeleteModalState}
                  >
                    <p tabIndex={0}>Are you sure you want to delete {agencyToBeDeleted.name}?</p>
                    <div className="modalButtons">
                      <Button
                        kind={linkButton}
                        className={modalButtonClass}
                        data-testid="delete-agency-no"
                        onClick={resetDeleteModalState}
                      >
                        NO
                      </Button>
                      <Button
                        kind={linkButton}
                        className={modalButtonClass}
                        data-testid="delete-agency-confirm"
                        onClick={() => {
                          dispatch(removeAgency(accessToken, agencyToBeDeleted));
                          resetDeleteModalState();
                        }}
                      >
                        YES
                      </Button>
                    </div>
                  </Modal>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default withTransaction('AgenciesDashboard', 'component')(AgenciesDashboard);
