import {
  SET_NON_APPLICANT_USERS,
  SET_NON_APPLICANT_USERS_LOADING,
  TOGGLE_NON_APPLICANT_USER_IS_ACTIVE,
  UPDATE_USER_FORM_DATA,
  CLEAR_USER_FORM_DATA,
} from 'state/actions/nonApplicantUsers';

const initialState = {
  nonApplicants: [],
  nonApplicantsLoading: false,
  updateUserInProgress: false,
  formData: {},
  userRoles: [],
  employers: [],
  agencies: [],
};

const nonApplicantUsersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NON_APPLICANT_USERS_LOADING:
      return {
        ...state,
        nonApplicantsLoading: action.payload,
      };
    case SET_NON_APPLICANT_USERS:
      return {
        ...state,
        nonApplicants: action.payload,
      };
    case TOGGLE_NON_APPLICANT_USER_IS_ACTIVE: {
      const userId = action.payload;
      const toggledNonApplicantIndex = state.nonApplicants.findIndex((nonApplicant) => nonApplicant.id === userId);
      let newNonApplicantList = state.nonApplicants;
      if (toggledNonApplicantIndex > -1) {
        const toggledNonApplicant = state.nonApplicants[toggledNonApplicantIndex];
        newNonApplicantList = [
          ...state.nonApplicants.slice(0, toggledNonApplicantIndex),
          {
            ...toggledNonApplicant,
            isActive: !toggledNonApplicant.isActive,
          },
          ...state.nonApplicants.slice(toggledNonApplicantIndex + 1),
        ];
      }
      return {
        ...state,
        nonApplicants: newNonApplicantList,
      };
    }
    case UPDATE_USER_FORM_DATA:
      return {
        ...state,
        updateUserInProgress: true,
        ...action.payload,
      };
    case CLEAR_USER_FORM_DATA: {
      return {
        ...state,
        updateUserInProgress: false,
        employers: [],
        agencies: [],
        formData: {},
      };
    }
    default:
      return state;
  }
};

export default nonApplicantUsersReducer;
