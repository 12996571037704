import { useOktaAuth } from '@okta/okta-react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input } from 'voltron';

import { sendToAdobe } from 'services/Services';
import { formatAPIMessage, getInvalidEmailAndEmailVerify } from 'utils/utils';
import { setCriticalError } from 'state/actions/error';
import { pageLoaded, pageLoading } from 'state/actions/page_loading';
import styles from './Submission.module.scss';

const FORM_FIELD_NAMES = {
  emailAddress: 'emailAddress',
  emailAddressVerify: 'emailAddressVerify',
};

const EMAIL_PLACEHOLDER = 'Email';
const emailInputKind = 'email';
const emailMaxLength = 40;

const getSubmissionContent = ({
  emailAddress,
  emailAddressVerify,
  invalidInputs,
  isOnSubmit,
  cannotSubmit,
  setIsValidEmailAddress,
  setIsValidEmailAddressVerify,
  onInputChange,
  onSubmit,
}) => (
  <div className={styles.submitForm}>
    <div>
      <h2>NEXT STEPS:</h2>
      <ul>
        <li>
          You are required to sign any applications for insurance or refusals of coverage through use of the e-signature
          feature of this website in order to complete the enrollment process. Please see the &quot;SIGNATURE&quot;
          section below for more information.
        </li>
        <li>
          Once any applications for insurance are completed and signed, if additional information or clarification is
          needed in order to process the applications, Ameritas will contact your agent. If nothing further is needed or
          once any additional information has been received, applications will be processed and, if approved, policies
          will be issued with no medical exclusions.
        </li>
      </ul>
    </div>
    <div>
      <h2>SIGNATURE:</h2>
      <p>By clicking &quot;Signature&quot; below:</p>
      <ul>
        <li>
          You will be directed to Adobe Document Cloud, a third party electronic signature provider, where you will sign
          any applications for insurance or refusals of coverage and have the option to print copies for your records;
          and
        </li>
        <li>
          You agree that your type written signature is the legal equivalent of a physical, hand written signature and
          that you will be legally bound by the terms and conditions of the documents.
        </li>
      </ul>
    </div>
    <div>
      <p>
        <strong>
          It is important that you read the &quot;Notice of Insurance Information Practices&quot; page before signing
          any applications.
        </strong>{' '}
        A copy of this page will print along with the application for your records.
      </p>
      <p>
        Please provide your email address below to receive a confirmation email from Adobe Document Cloud and click
        &quot;Signature&quot; to continue.
      </p>
    </div>
    <form autoComplete="off">
      <div className={styles.formBody}>
        <Input
          label="Your email address"
          placeholder={EMAIL_PLACEHOLDER}
          kind={emailInputKind}
          maxLength={emailMaxLength}
          data-testid="email-address"
          material={false}
          autoComplete={false}
          required
          forceErrorMessage={isOnSubmit && invalidInputs.includes(FORM_FIELD_NAMES.emailAddress)}
          value={emailAddress}
          onChange={(e) => {
            setIsValidEmailAddress(e.target.isValid);
            onInputChange(FORM_FIELD_NAMES.emailAddress, e.target.value);
          }}
        />
        <Input
          label="Verify email address"
          placeholder={EMAIL_PLACEHOLDER}
          kind={emailInputKind}
          maxLength={emailMaxLength}
          data-testid="email-address-verify"
          material={false}
          autoComplete={false}
          required
          forceErrorMessage={isOnSubmit && invalidInputs.includes(FORM_FIELD_NAMES.emailAddressVerify)}
          value={emailAddressVerify}
          onChange={(e) => {
            setIsValidEmailAddressVerify(e.target.isValid);
            onInputChange(FORM_FIELD_NAMES.emailAddressVerify, e.target.value);
          }}
        />
      </div>
      <div className={styles.submitButton}>
        <Button disabled={cannotSubmit} onClick={onSubmit}>
          SIGNATURE
        </Button>
      </div>
    </form>
  </div>
);

const Submission = () => {
  const { oktaAuth } = useOktaAuth();
  const {
    data: { applicationId, input: existingInputData },
  } = useSelector((state) => state.applicantForm);
  const [formInputData, setFormInputData] = useState(existingInputData);
  const [invalidInputs, setInvalidInputs] = useState([]);
  const [isValidEmailAddress, setIsValidEmailAddress] = useState(true);
  const [isValidEmailAddressVerify, setIsValidEmailAddressVerify] = useState(true);
  const [isOnSubmit, setIsOnSubmit] = useState(false);
  const dispatch = useDispatch();

  const { emailAddress, emailAddressVerify } = formInputData;

  const cannotSubmit = invalidInputs.length > 0 || emailAddress?.trim() !== emailAddressVerify?.trim();

  useEffect(() => {
    dispatch(applicationId > 0 ? pageLoaded() : pageLoading());
  }, [applicationId]);

  useEffect(() => {
    const invalidFormElements = getInvalidEmailAndEmailVerify(
      emailAddress,
      emailAddressVerify,
      isValidEmailAddress,
      isValidEmailAddressVerify,
    );
    setInvalidInputs(invalidFormElements);
  }, [emailAddress, emailAddressVerify, isValidEmailAddress, isValidEmailAddressVerify]);

  const onInputChange = (inputName, value) => {
    setFormInputData((formData) => ({
      ...formData,
      [inputName]: value,
    }));
  };

  const onSubmit = async () => {
    setIsOnSubmit(true);
    if (cannotSubmit) {
      return;
    }

    const formData = {
      userFormId: applicationId,
      input: {
        ...formInputData,
        phone: formInputData.phone?.replace(/\(|\)|-| /g, ''),
      },
    };
    const accessToken = oktaAuth.getAccessToken();
    let signingUrl;
    try {
      dispatch(pageLoading());
      const adobeResponse = await sendToAdobe(accessToken, formData);
      signingUrl = adobeResponse.signingUrl;
      oktaAuth.tokenManager.clear();
      await oktaAuth.signOut();
      sessionStorage.clear();
    } catch (error) {
      dispatch(
        setCriticalError({
          title: 'Error sending form to Adobe and signing out',
          body: formatAPIMessage(error),
          button: 'OK',
        }),
      );
    } finally {
      dispatch(pageLoaded());
      if (signingUrl) {
        window.location = signingUrl;
      }
    }
  };

  return getSubmissionContent({
    emailAddress,
    emailAddressVerify,
    invalidInputs,
    isOnSubmit,
    cannotSubmit,
    setIsValidEmailAddress,
    setIsValidEmailAddressVerify,
    onInputChange,
    onSubmit,
  });
};

export default Submission;
