import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { withTransaction } from '@elastic/apm-rum-react';
import { Box, Container, Grid } from '@mui/material';
import { Input, Button } from 'voltron';

import { getOwningCompany } from 'services/Services';
import routes from 'constants/routes';
import { formatAPIMessage } from 'utils/utils';
import { editOwningCompany } from 'state/actions/adminEntities';
import { setCriticalError } from 'state/actions/error';

const EditOwningCompany = () => {
  const params = useParams();
  const { oktaAuth } = useOktaAuth();
  const dispatch = useDispatch();
  const history = useHistory();
  const [owningCompanyToEdit, setOwningCompanyToEdit] = useState();
  const [owningCompanyName, setOwningCompanyName] = useState('');

  const accessToken = oktaAuth.getAccessToken();

  useEffect(async () => {
    try {
      const owningCompanyResponse = await getOwningCompany(accessToken, params.id);
      setOwningCompanyToEdit(owningCompanyResponse);
      setOwningCompanyName(owningCompanyResponse?.name || '');
    } catch (error) {
      dispatch(
        setCriticalError({
          title: 'Get owning company failed',
          body: formatAPIMessage(error),
          button: 'OK',
        }),
      );
    }
  }, []);

  const onSubmit = () => {
    dispatch(
      editOwningCompany(
        accessToken,
        {
          ...owningCompanyToEdit,
          name: owningCompanyName,
        },
        history,
      ),
    );
  };

  if (!owningCompanyToEdit) {
    return null;
  }

  if (!owningCompanyToEdit.canEdit) {
    return <>Owning company cannot be edited.</>;
  }

  return (
    <Box sx={{ width: '82vw', marginTop: '4rem' }}>
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="adminEditPage">
              <header>
                <h3>Owning Company Information</h3>
                <p>Edit {owningCompanyToEdit.name}</p>
              </header>
              <form autoComplete="off">
                <div className="formBody">
                  <Input
                    label="Company Name"
                    labelKind="regular"
                    placeholder="Owning Company Name"
                    required
                    data-testid="owning-company-name"
                    material={false}
                    autoComplete={false}
                    containerClass="formRow"
                    value={owningCompanyName}
                    onChange={(e) => setOwningCompanyName(e.target.value)}
                  />
                </div>
                <div className="buttons">
                  <Button kind="invert" onClick={() => history.push(routes.owningCompanies)}>
                    CANCEL
                  </Button>
                  <Button disabled={!owningCompanyName.trim()} onClick={onSubmit}>
                    SAVE CHANGES
                  </Button>
                </div>
              </form>
            </div>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default withTransaction('EditOwningCompanyForm', 'component')(EditOwningCompany);
