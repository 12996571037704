import React from 'react';
import Container from '@mui/material/Container';
import UserOptionsMenu from 'components/UserOptionsMenu/UserOptionsMenu';
import PropTypes from 'prop-types';
import Styles from './header.module.scss';

function Header({ handleLogout, userInfo, disableMenu = false }) {
  return (
    <div className={Styles.Header}>
      <Container maxWidth="xl">
        <img src="/Images/logo.png" alt="" />
        <div className={Styles.floatRight}>
          <UserOptionsMenu userInfo={userInfo} handleLogout={handleLogout} disabled={disableMenu} />
        </div>
      </Container>
    </div>
  );
}

Header.propTypes = {
  handleLogout: PropTypes.any,
};
export default Header;
