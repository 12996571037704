import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { Spinner } from 'voltron';
import { AntTab, AntTabs, TabPanel } from 'utils/utils';

function NavigationTab({ tabs, activeTab, showSpinner = false }) {
  const [currentTab, setCurrentTab] = useState(0);

  const handleChange = (_e, newValue) => {
    setCurrentTab(newValue);
  };

  useEffect(() => {
    if (activeTab) {
      setCurrentTab(activeTab);
    }
  }, [activeTab]);

  return (
    <>
      <Box data-testid="tabs" sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <AntTabs value={currentTab} onChange={handleChange} variant="scrollable" aria-label="upload census tabs">
          {tabs.map((item) => (
            <AntTab
              data-testid={`tab-${item.label}`}
              label={item.label}
              key={`tabs-${item.label}`}
              disabled={item.disabled}
              tabIndex={0}
            />
          ))}
        </AntTabs>
      </Box>
      {showSpinner ? (
        <div data-testid="tab-panel-spinner">
          <Spinner position="center" />
        </div>
      ) : (
        tabs.map((item, index) => (
          <TabPanel
            data-testid={`tab-panel-${item.label}`}
            value={currentTab}
            index={index}
            key={`tab-panel-${item.label}`}
          >
            {item.content}
          </TabPanel>
        ))
      )}
    </>
  );
}

export default NavigationTab;
