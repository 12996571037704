import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { Box, Container, Grid } from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';
import { withTransaction } from '@elastic/apm-rum-react';
import { Input, Button } from 'voltron';

import { getAgency } from 'services/Services';
import routes from 'constants/routes';
import { formatAPIMessage } from 'utils/utils';
import { editAgency } from 'state/actions/adminEntities';
import { setCriticalError } from 'state/actions/error';

const inputRowClass = 'formRow';
const labelKind = 'regular';
const formErrorMessage = 'Please enter at least one of Agency Name and Agency Number';

const FORM_FIELD_NAMES = {
  name: 'name',
  number: 'code',
};

const getInvalidFormElements = (inputData = {}) => {
  const invalidFormElements = [];

  if (
    Object.keys(inputData).length > 0 &&
    !inputData[FORM_FIELD_NAMES.name]?.trim() &&
    !inputData[FORM_FIELD_NAMES.number]?.trim()
  ) {
    invalidFormElements.push(FORM_FIELD_NAMES.name);
    invalidFormElements.push(FORM_FIELD_NAMES.number);
  }

  return invalidFormElements;
};

const onSubmit = (accessToken, agencyToEdit, agencyFormObject, history, dispatch) => {
  dispatch(
    editAgency(
      accessToken,
      {
        ...agencyToEdit,
        ...agencyFormObject,
      },
      history,
    ),
  );
};

const getButtons = ({ history, dispatch, accessToken, invalidFormData, agencyToEdit, agencyFormObject }) => (
  <div className="buttons">
    <Button kind="invert" onClick={() => history.push(routes.agencies)}>
      CANCEL
    </Button>
    <Button
      disabled={invalidFormData.length > 0}
      onClick={() => onSubmit(accessToken, agencyToEdit, agencyFormObject, history, dispatch)}
    >
      SAVE CHANGES
    </Button>
  </div>
);

const EditAgency = () => {
  const params = useParams();
  const { oktaAuth } = useOktaAuth();
  const dispatch = useDispatch();
  const history = useHistory();
  const [agencyToEdit, setAgencyToEdit] = useState();
  const [agencyFormObject, setAgencyFormObject] = useState({});
  const [invalidFormData, setInvalidFormData] = useState([]);
  const accessToken = oktaAuth.getAccessToken();

  useEffect(async () => {
    try {
      const agencyResponse = await getAgency(accessToken, params.id);
      setAgencyToEdit(agencyResponse);
      setAgencyFormObject({
        name: agencyResponse.name,
        code: agencyResponse.code,
      });
    } catch (error) {
      dispatch(
        setCriticalError({
          title: 'Get agency failed',
          body: formatAPIMessage(error),
          button: 'OK',
        }),
      );
    }
  }, []);

  useEffect(() => {
    setInvalidFormData(getInvalidFormElements(agencyFormObject));
  }, [agencyFormObject]);

  const onInputChange = (inputName, value) => {
    setAgencyFormObject((formObject) => ({
      ...formObject,
      [inputName]: value,
    }));
  };

  if (!agencyToEdit) {
    return null;
  }

  if (agencyToEdit && !agencyToEdit.canEdit) {
    return <>Agency cannot be edited.</>;
  }

  return (
    <Box sx={{ width: '82vw', marginTop: '4rem' }}>
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="adminEditPage">
              <header>
                <h3>Agency Information</h3>
                <p>Edit {agencyToEdit?.name}</p>
              </header>
              <form autoComplete="off">
                <div className="formBody">
                  <Input
                    label="Agency Name"
                    labelKind={labelKind}
                    placeholder="Agency Name"
                    required={!agencyFormObject[FORM_FIELD_NAMES.number]?.trim()}
                    data-testid="agency-name"
                    material={false}
                    autoComplete={false}
                    containerClass={inputRowClass}
                    forceErrorMessage={invalidFormData.includes(FORM_FIELD_NAMES.name)}
                    errormessage={formErrorMessage}
                    value={agencyFormObject[FORM_FIELD_NAMES.name]}
                    onChange={(e) => onInputChange(FORM_FIELD_NAMES.name, e.target.value)}
                  />
                  <Input
                    label="Agency Number"
                    labelKind={labelKind}
                    placeholder="Agency Number"
                    maxLength={15}
                    required={!agencyFormObject[FORM_FIELD_NAMES.name]?.trim()}
                    data-testid="agency-number"
                    material={false}
                    autoComplete={false}
                    containerClass={inputRowClass}
                    forceErrorMessage={invalidFormData.includes(FORM_FIELD_NAMES.number)}
                    errormessage={formErrorMessage}
                    value={agencyFormObject[FORM_FIELD_NAMES.number]}
                    onChange={(e) => onInputChange(FORM_FIELD_NAMES.number, e.target.value)}
                  />
                </div>
                {getButtons({
                  history,
                  dispatch,
                  accessToken,
                  invalidFormData,
                  agencyToEdit,
                  agencyFormObject,
                })}
              </form>
            </div>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default withTransaction('EditAgencyForm', 'component')(EditAgency);
