const OKTA_CLIENT_ID = window.OKTA_CLIENT_ID || process.env.OKTA_CLIENT_ID || '0oa6x63b09apsl3Pl1d7';
const OKTA_ISSUER =
  window.OKTA_ISSUER || process.env.OKTA_ISSUER || 'https://ameritas-d.oktapreview.com/oauth2/aus1u7l73rZH8O9qi1d7';
const OKTA_TESTING_DISABLEHTTPSCHECK = process.env.OKTA_TESTING_DISABLEHTTPSCHECK || false;
const USE_INTERACTION_CODE = 'true';

export default {
  oidc: {
    issuer: OKTA_ISSUER,
    clientId: OKTA_CLIENT_ID,
    scopes: ['openid', 'profile', 'email', 'digsi'],
    redirectUri: `${window.location.origin}/login/callback`,
  },
  widget: {
    issuer: OKTA_ISSUER,
    clientId: OKTA_CLIENT_ID,
    redirectUri: `${window.location.origin}/login/callback`,
    scopes: ['openid', 'profile', 'email', 'digsi'],
    pkce: true,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
    useInteractionCode: USE_INTERACTION_CODE,
    useClassicEngine: false,
  },
};
