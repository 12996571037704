import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Box, Container, Grid } from '@mui/material';
import { Edit, NotInterested, Delete } from '@mui/icons-material';
import { useOktaAuth } from '@okta/okta-react';
import { withTransaction } from '@elastic/apm-rum-react';
import { Button, Modal } from 'voltron';

import { deleteEmployer, getEmployers } from 'state/actions/employers';
import PaginatedTable from 'components/PaginatedTable/PaginatedTable';
import { exportBusinesses } from 'services/Services';
import { downloadFile, isAdminUser } from 'utils/utils';
import { pageLoaded, pageLoading } from 'state/actions/page_loading';

const actionButtonClass = 'actionButton';
const modalButtonClass = 'modalButton';
const linkButton = 'link';

const NameAddressComponent = ({ name, fullAddress }) => (
  <>
    <div>{name}</div>
    <div>{fullAddress}</div>
  </>
);

const getNameAddressComponent = (_name, _disabled, { name, fullAddress }) => (
  <NameAddressComponent name={name} fullAddress={fullAddress} />
);

const EditComponent =
  (history, isAdmin) =>
  ({ id, canEdit }) => (
    <Button
      kind="link"
      data-testid={`edit-employer-${id}`}
      aria-label="edit-employer"
      className={actionButtonClass}
      disabled={!canEdit || !isAdmin}
      onClick={() => history.push(`admin/business/${id}`)}
    >
      {canEdit && isAdmin ? <Edit /> : <NotInterested />}
    </Button>
  );

const DeleteComponent = (isAdmin, setIsDeleteConfirmModalOpen, setEmployerToBeDeleted) => (employer) => (
  <Button
    kind="link"
    data-testid={`delete-employer-${employer.id}`}
    aria-label="delete-employer"
    className={actionButtonClass}
    disabled={!employer.canDelete || !isAdmin}
    onClick={() => {
      setIsDeleteConfirmModalOpen(true);
      setEmployerToBeDeleted(employer);
    }}
  >
    {employer.canDelete && isAdmin ? <Delete /> : <NotInterested />}
  </Button>
);

const getTableColumns = (history, isAdmin, setIsDeleteConfirmModalOpen, setEmployerToBeDeleted) => [
  {
    Header: 'Employers',
    width: 45,
    accessor: 'name',
    isSearchable: true,
    component: getNameAddressComponent,
  },
  {
    Header: 'Phone',
    width: 20,
    accessor: 'phone',
    isSearchable: true,
  },
  {
    Header: 'Email',
    width: 35,
    accessor: 'email',
    isSearchable: true,
  },
  {
    Header: 'Edit',
    width: 12,
    isAction: true,
    component: EditComponent(history, isAdmin),
  },
  {
    Header: 'Delete',
    width: 15,
    isAction: true,
    component: DeleteComponent(isAdmin, setIsDeleteConfirmModalOpen, setEmployerToBeDeleted),
  },
];

const EmployersDashboard = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { oktaAuth } = useOktaAuth();
  const { employers, isSetEmployersLoading } = useSelector((state) => state.employers);
  const { userInfo } = useSelector((state) => state.appState);
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] = useState(false);
  const [employerToBeDeleted, setEmployerToBeDeleted] = useState({});

  const isAdmin = isAdminUser(userInfo);
  const tableColumns = getTableColumns(history, isAdmin, setIsDeleteConfirmModalOpen, setEmployerToBeDeleted);

  const accessToken = oktaAuth.getAccessToken();

  useEffect(() => {
    dispatch(getEmployers(accessToken));
  }, []);

  return (
    <Box sx={{ width: '82vw', marginTop: '4rem' }}>
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="adminDashboardPage">
              <h2 className="pageTitle">Employers</h2>
              <div className="contentBody">
                <div className="actions">
                  <p className="actionsTitle">Actions</p>
                  <Button
                    data-testid="export-employers"
                    className={actionButtonClass}
                    onClick={async () => {
                      dispatch(pageLoading());
                      const { data, headers } = await exportBusinesses(accessToken);
                      dispatch(pageLoaded());
                      downloadFile(data, headers);
                    }}
                  >
                    EXPORT
                  </Button>
                </div>
                <div className="tableContainer">
                  <PaginatedTable
                    className="dashboardTable"
                    bubbleOnClick={(item) => history.push(`/dashboard/business/${item.id}`)}
                    sortColumn="name"
                    sortOrder="ASC"
                    columns={tableColumns}
                    data={employers}
                    isDataLoading={isSetEmployersLoading || !userInfo}
                  />
                  <Modal
                    open={isDeleteConfirmModalOpen}
                    title={`Would you like to delete ${employerToBeDeleted.name}?`}
                  >
                    <p tabIndex="0">Are you sure you want to delete {employerToBeDeleted.name}?</p>
                    <div className="modalButtons">
                      <Button
                        kind={linkButton}
                        className={modalButtonClass}
                        data-testid="delete-employer-no"
                        onClick={() => {
                          setIsDeleteConfirmModalOpen(false);
                          setEmployerToBeDeleted({});
                        }}
                      >
                        NO
                      </Button>
                      <Button
                        kind={linkButton}
                        className={modalButtonClass}
                        data-testid="delete-employer-confirm"
                        onClick={() => {
                          dispatch(deleteEmployer(accessToken, employerToBeDeleted));
                          setIsDeleteConfirmModalOpen(false);
                          setEmployerToBeDeleted({});
                        }}
                      >
                        YES
                      </Button>
                    </div>
                  </Modal>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default withTransaction('EmployersDashboard', 'component')(EmployersDashboard);
