import {
  CLEAR_CURRENT_EMPLOYER,
  SET_CURRENT_EMPLOYER,
  SET_CURRENT_EMPLOYER_LOADING,
  SET_EMPLOYERS,
  SET_EMPLOYERS_LOADING,
  SET_EMPLOYER_ENROLLMENT_PERIODS,
} from 'state/actions/employers';

const initialCurrentEmployer = {
  name: '',
  applicants: [],
  futureCurrentEnrollments: [],
  nonEmptyEnrollments: [],
  pastEnrollments: [],
  canSign: false,
  canDownload: false,
};

const initialState = {
  employers: [],
  isSetEmployersLoading: false,
  currentEmployer: { ...initialCurrentEmployer },
  isSetCurrentEmployerLoading: false,
  employerEnrollmentPeriods: [],
};

const employersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_EMPLOYERS:
      return {
        ...state,
        employers: action.payload,
      };
    case SET_EMPLOYERS_LOADING:
      return {
        ...state,
        isSetEmployersLoading: action.payload,
      };
    case SET_CURRENT_EMPLOYER: {
      const { name, applicants, futureCurrentEnrollments, nonEmptyEnrollments, pastEnrollments, canSign, canDownload } =
        action.payload;
      return {
        ...state,
        currentEmployer: {
          name,
          applicants,
          futureCurrentEnrollments,
          nonEmptyEnrollments,
          pastEnrollments,
          canSign,
          canDownload,
        },
      };
    }
    case SET_CURRENT_EMPLOYER_LOADING:
      return {
        ...state,
        isSetCurrentEmployerLoading: action.payload,
      };
    case CLEAR_CURRENT_EMPLOYER:
      return {
        ...state,
        currentEmployer: { ...initialCurrentEmployer },
      };
    case SET_EMPLOYER_ENROLLMENT_PERIODS:
      return {
        ...state,
        employerEnrollmentPeriods: action.payload,
      };
    default:
      return state;
  }
};

export default employersReducer;
