import { useCallback } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useDispatch } from 'react-redux';
import { Button, Modal } from 'voltron';

const buttonKind = 'link';
const buttonClass = 'modalButton';

const ToggleUserActiveStatusConfirmModal = ({
  isToggleActiveConfirmModalOpen,
  userToBeToggledActive,
  setIsToggleActiveConfirmModalOpen,
  setUserToBeToggledActive,
  toggleAction,
  employerId,
}) => {
  const dispatch = useDispatch();
  const { oktaAuth } = useOktaAuth();

  const accessToken = oktaAuth.getAccessToken();

  const closeModal = useCallback(() => {
    setIsToggleActiveConfirmModalOpen(false);
    setUserToBeToggledActive({ id: 0 });
  }, []);

  return (
    <Modal
      open={isToggleActiveConfirmModalOpen}
      title={`Are you sure you want to make this user ${userToBeToggledActive.isActive ? 'inactive' : 'active'}?`}
      handleToggle={closeModal}
    >
      <p tabIndex="0">
        {userToBeToggledActive.isActive
          ? 'This will immediately prevent the user from logging into the application.'
          : 'This will immediately trigger an email to the user with instructions on how to log in to the application.'}
      </p>
      <div className="modalButtons">
        <Button kind={buttonKind} className={buttonClass} data-testid="toggle-active-no" onClick={closeModal}>
          NO
        </Button>
        <Button
          kind={buttonKind}
          className={buttonClass}
          data-testid="toggle-active-confirm"
          onClick={() => {
            dispatch(toggleAction(accessToken, userToBeToggledActive.id, employerId));
            closeModal();
          }}
        >
          YES
        </Button>
      </div>
    </Modal>
  );
};

export default ToggleUserActiveStatusConfirmModal;
