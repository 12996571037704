import { ExistingCompaniesApplicationSummary, getExistingCompaniesForApplicationSummary } from 'utils/utils';
import { NO_LABEL, NO_OPTION, YES_LABEL, YES_OPTION, formCodes } from 'constants/index';

import mainStyles from '../Summary.module.scss';

const getCornerstoneRelatedQuestions = ({ formCode, input }) => {
  const isCornerstoneFormIncluded = [formCodes.CORNERSTONE, formCodes.COMBINED_CORNERSTONE].includes(formCode);
  if (!isCornerstoneFormIncluded) {
    return null;
  }

  const isAppliedForInsuranceCornerstone12Months = input.appliedForInsuranceCornerStone12Months === YES_OPTION;
  const isReceivedDisabilityBenefitsForCornerstone6Months =
    input.receiveddisabilitybenefitsForCornerStone6Months === YES_OPTION;

  return (
    <table className={mainStyles.summaryTable}>
      <tbody>
        <tr>
          <td>In the past 12 months, have you been declined or postponed for disability insurance?</td>
          <td>{isAppliedForInsuranceCornerstone12Months ? YES_LABEL : NO_LABEL}</td>
        </tr>
        {isAppliedForInsuranceCornerstone12Months && (
          <tr>
            <td>If Yes, explain.</td>
            <td>{input.appliedForInsuranceExplainCornerStone12Months}</td>
          </tr>
        )}
        <tr>
          <td>
            In the past 6 months, have you received disability benefits from any source, or do you currently have a
            claim for disability benefits pending?
          </td>
          <td>{isReceivedDisabilityBenefitsForCornerstone6Months ? YES_LABEL : NO_LABEL}</td>
        </tr>
        {isReceivedDisabilityBenefitsForCornerstone6Months && (
          <tr>
            <td>If Yes, explain.</td>
            <td>{input.receiveddisabilitybenefitsExplainCornerStone6Months}</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

const getAppliedForQuestions = ({ formCode, input }) => {
  const isFundamentalFormIncluded = [
    formCodes.FUNDAMENTAL,
    formCodes.COMBINED,
    formCodes.COMBINED_CORNERSTONE,
  ].includes(formCode);
  const isFoundationFormIncluded = [formCodes.FOUNDATION, formCodes.COMBINED].includes(formCode);

  if (formCode === formCodes.CORNERSTONE) {
    return null;
  }

  const getAppliedForQuestionsSection = (isAppliedForInsurance, explainValue) => (
    <table className={mainStyles.summaryTable}>
      <tbody>
        <tr>
          <td>Other than noted above, have you applied for disability insurance in the past 12 months?</td>
          <td>{isAppliedForInsurance ? YES_LABEL : NO_LABEL}</td>
        </tr>
        {isAppliedForInsurance && (
          <tr>
            <td>If Yes, explain.</td>
            <td>{explainValue}</td>
          </tr>
        )}
      </tbody>
    </table>
  );

  return (
    <>
      {isFundamentalFormIncluded &&
        getAppliedForQuestionsSection(input.appliedForInsurance === YES_OPTION, input.appliedForInsuranceExplain)}
      {isFoundationFormIncluded &&
        getAppliedForQuestionsSection(
          input.appliedForInsuranceFoundation === YES_OPTION,
          input.appliedForInsuranceExplainFoundation,
        )}
    </>
  );
};

const getNoneText = ({ formCode, input, haveExistingCompanies }) => {
  const isFoundationFormIncluded = [formCodes.FOUNDATION, formCodes.COMBINED].includes(formCode);
  const onlyNoOnFundamentalQuestions =
    input.existingDisabilityWithLumpSum === NO_OPTION &&
    input.appliedForInsurance === NO_OPTION &&
    input.disabilityInsuranceChanged === NO_OPTION;

  if ((isFoundationFormIncluded && !haveExistingCompanies) || !onlyNoOnFundamentalQuestions) {
    return null;
  }

  return (
    <p>
      <strong>None</strong>
    </p>
  );
};

const getFundamentalRelatedQuestions = ({ formCode, input }) => {
  const yesOnAFundamentalQuestion =
    input.existingDisabilityWithLumpSum === YES_OPTION ||
    input.appliedForInsurance === YES_OPTION ||
    input.disabilityInsuranceChanged === YES_OPTION;

  if (formCode === formCodes.FUNDAMENTAL || !yesOnAFundamentalQuestion) {
    return null;
  }

  return (
    <table className={mainStyles.summaryTable}>
      <tbody>
        <tr>
          <td>If Yes, give name of Company, Type (catastrophic or lump sum), and Benefit Amount.</td>
          <td>{input.companyAndBenefitAmount}</td>
        </tr>
        <tr>
          <td>
            Will any disability insurance with this company or any other insurance company be replaced, reduced, or
            changed if the insurance now being applied for is issued?
          </td>
          <td>{input.disabilityInsuranceChanged === YES_OPTION ? YES_LABEL : NO_LABEL}</td>
        </tr>
      </tbody>
    </table>
  );
};

const ExistingDisabilityInsuranceSummary = ({ formCode, input = {} }) => {
  const isFundamentalForm = formCode === formCodes.FUNDAMENTAL;
  const existingCompaniesObject = isFundamentalForm
    ? { existingCompanies: [] }
    : getExistingCompaniesForApplicationSummary(input);

  return (
    <>
      <p className={mainStyles.sectionHeader}>
        <strong>Existing Disability Insurance</strong>
      </p>
      {formCode === formCodes.COMBINED && (
        <p>
          <strong>Foundation</strong>
        </p>
      )}
      {formCode === formCodes.COMBINED_CORNERSTONE && (
        <p>
          <strong>Cornerstone</strong>
        </p>
      )}
      {!isFundamentalForm && (
        <ExistingCompaniesApplicationSummary existingCompaniesObject={existingCompaniesObject} styles={mainStyles} />
      )}
      {getCornerstoneRelatedQuestions({ formCode, input })}
      {getAppliedForQuestions({ formCode, input })}
      {[formCodes.COMBINED, formCodes.COMBINED_CORNERSTONE].includes(formCode) && (
        <p>
          <strong>Fundamental</strong>
        </p>
      )}
      {getNoneText({ formCode, input, haveExistingCompanies: existingCompaniesObject.existingCompanies?.length > 0 })}
      {getFundamentalRelatedQuestions({ formCode, input })}
    </>
  );
};

export default ExistingDisabilityInsuranceSummary;
