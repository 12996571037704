import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import CurrencyFormat from 'react-currency-format';
import { getApplicantInfo, saveFormInputs } from 'services/Services';
import { useOktaAuth } from '@okta/okta-react';
import { updateApplicantData } from 'state/actions/applicantForm';
import { DatePicker, Select, Input, TextArea, Modal, Button } from 'voltron';
import { formatAPIMessage, formatDate } from 'utils/utils';
import { yesNoOptionList } from 'constants/index';
import { setCriticalError } from 'state/actions/error';
import style from '../applicant/ApplicantForm.module.scss';

const labelKind = 'regular';

const Work = ({ setSelectedTabIndex }) => {
  const { data } = useSelector((state) => state.applicantForm);
  const [formData, setFormData] = React.useState({
    fullTimeWork: '',
    dateOfHire: formatDate(new Date()),
    occupation: '',
    annualBaseIncome: '',
    averageVariableIncome: '',
    isEditIncome: false,
    isEditBaseIncom: false,
    isEditIncomeMOdal: false,
    isEditOccupation: false,
    enable: '1',
    details: '',
    isValid: true,
  });
  const [formErrors, setFormErrors] = React.useState({});
  const [isSubmit, setIsSubmit] = React.useState(false);
  const { oktaAuth } = useOktaAuth();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [height, setHeight] = useState(500);

  let modalTitle = 'Annual Base Income Changes';
  if (formData.enable === '1') {
    modalTitle = 'Average Variable Income Changes';
  } else if (formData.enable === '2') {
    modalTitle = 'Occupation Changes';
  }

  const getApplicantInfoSave = (accessToken, param) => {
    getApplicantInfo(accessToken, param)
      .then((apiResp) => {
        if (apiResp) {
          dispatch(updateApplicantData(apiResp));
        } else {
          dispatch(updateApplicantData(null));
        }
      })
      .catch(() => {
        dispatch(updateApplicantData(null));
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'occupation') {
      if (!formData.isEditOccupation) {
        setFormData({
          ...formData,
          occupation: value,
          isEditOccupation: true,
          isEditIncomeMOdal: true,
          enable: '2',
        });
      } else {
        setFormData({
          ...formData,
          occupation: value,
          isEditOccupation: true,
        });
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const validate = (values) => {
    const errors = {};
    if (!values.fullTimeWork) {
      errors.fullTimeWork = 'fullTimeWork';
    }
    if (!values.dateOfHire) {
      errors.dateOfHire = 'dateOfHire';
    }
    if (!values.occupation) {
      errors.occupation = 'occupation';
    }
    if (!values.annualBaseIncome && values?.annualBaseIncome !== 0) {
      errors.annualBaseIncome = 'annualBaseIncome';
    }
    if (!values.averageVariableIncome && values?.averageVariableIncome !== 0) {
      errors.averageVariableIncome = 'averageVariableIncome';
    }
    if (values.fullTimeWork === 'N' && !values.details) {
      errors.details = 'details';
    }
    if (!values.isValid) {
      errors.isValid = 'isValid';
    }
    return errors;
  };

  const handleSubmit = () => {
    const errors = validate(formData);
    setFormErrors(errors);
    setIsSubmit(true);
    if (Object.keys(errors).length === 0) {
      const accessToken = oktaAuth.getAccessToken();
      const request = {
        userFormId: data.applicationId,
        input: {
          fullTimeWork: formData.fullTimeWork,
          dateOfHire: formData.dateOfHire,
          occupation: formData.occupation,
          annualBaseIncome: Math.round(formData.annualBaseIncome),
          averageVariableIncome: Math.round(formData.averageVariableIncome),
          fullTimeWorkDetails: formData.details,
        },
      };
      setLoading(true);
      saveFormInputs(accessToken, request)
        .then(() => {
          setSelectedTabIndex(2);
          getApplicantInfoSave(accessToken, data.applicationId);
        })
        .catch((err) => {
          dispatch(
            setCriticalError({
              title: 'Save applicant error',
              body: formatAPIMessage(err),
              button: 'OK',
            }),
          );
        });
    }
  };

  React.useEffect(() => {
    const dateOfHire = data?.input?.dateOfHire;
    setFormData({
      fullTimeWork: data?.input?.fullTimeWork,
      dateOfHire: Number.isNaN(dateOfHire) ? formatDate(new Date()) : dateOfHire,
      occupation: data?.input?.occupation,
      annualBaseIncome: Math.round(data?.input?.annualBaseIncome),
      averageVariableIncome: Math.round(data?.input?.averageVariableIncome),
      details: data?.input?.fullTimeWorkDetails,
      isValid: true,
    });
    setHeight(document.body.scrollHeight);
    if (data?.applicationId) {
      setLoading(false);
    }
  }, [data]);

  return (
    <>
      {loading && (
        <div className="globalloader" style={{ height: `${height}px` }}>
          <div className="loadertext">Please wait ...</div>
        </div>
      )}
      <div className="formLabelfomr">
        <Grid container spacing={0} className="innerHeader">
          <Grid item xs={12}>
            <h3>Employment Information</h3>
            <br />
          </Grid>
        </Grid>
        <form className={isSubmit ? style.employerForm : `${style.employerForm} nonsubmit`}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Select
                labelKind={labelKind}
                name="fullTimeWork"
                required
                onChange={(e) => {
                  if (e.value === 'Y') {
                    setFormData({
                      ...formData,
                      fullTimeWork: e.value,
                      details: '',
                    });
                  } else {
                    setFormData({ ...formData, fullTimeWork: e.value });
                  }
                }}
                label="Are you actively at work on a full-time basis? (30 hours or more
                  per week)"
                labelPosition="top"
                clearable={false}
                searchable={false}
                material={false}
                options={yesNoOptionList}
                selectedValue={yesNoOptionList.find((option) => option.value === formData.fullTimeWork)}
                forceErrorMessage={formErrors.fullTimeWork}
              />
            </Grid>
          </Grid>
          {formData.fullTimeWork === 'N' && (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextArea
                  groupLabel="Please provide details"
                  name="details"
                  placeholder="Details"
                  defaultValue={formData.details}
                  labelKind={labelKind}
                  required
                  minRows={3}
                  textAreaProps={{
                    style: { resize: 'vertical' },
                    onChange: handleInputChange,
                  }}
                  forceErrorMessage={formErrors.details}
                />
              </Grid>
            </Grid>
          )}
          <br />
          <span className="formLabel" style={{ fontSize: '1rem' }}>
            Please verify the following information provided by your employer and complete any blank fields.
          </span>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <div className="mt-2" />
              <DatePicker
                label="Date of Hire"
                labelProps={{ kind: 'regular' }}
                required
                key={`dateOfHire-${formData.dateOfHire}`}
                name="dateOfHire"
                id="dateOfHire"
                inputProps={{
                  'data-testid': `date-of-hire`,
                }}
                value={formData.dateOfHire}
                onChange={(date, isValid) => {
                  if (isValid) {
                    setFormData({
                      ...formData,
                      dateOfHire: date,
                      isValid,
                    });
                  } else {
                    setFormData({ ...formData, isValid });
                  }
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <div className="mt-2" />
              <Input
                placeholder="Occupation"
                labelKind={labelKind}
                maxLength={40}
                material={false}
                autoComplete={false}
                label="Occupation"
                id="occupation"
                data-testid="occupation"
                name="occupation"
                required
                onChange={handleInputChange}
                value={formData.occupation}
                kind="text"
                forceErrorMessage={formErrors.occupation}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} id="formDisabled">
            <Grid item xs={6}>
              <span className="formLabel currencyinvalid" htmlFor="annualBaseIncome">
                Annual Base Income
              </span>
              <span className={style.currencyInput}>
                <CurrencyFormat
                  value={Number.isInteger(+formData.annualBaseIncome) ? +formData.annualBaseIncome : ''}
                  thousandSeparator
                  allowNegative={false}
                  decimalScale={0}
                  id="annualBaseIncome"
                  name="annualBaseIncome"
                  data-testid="annualBaseIncome"
                  prefix="$"
                  className={`${style.nonVoltronField} ${formErrors.annualBaseIncome ? style.error : ''}`}
                  required
                  disabled={formData.isEditIncomeMOdal ? true : data?.isRemoveIncomeOption}
                  onValueChange={(values) => {
                    const { value } = values;
                    if (!formData.isEditIncomeMOdal) {
                      if (!formData.isEditBaseIncom) {
                        setFormData({
                          ...formData,
                          annualBaseIncome: parseInt(value, 10),
                          isEditBaseIncom: true,
                          isEditIncomeMOdal: true,
                          enable: '3',
                        });
                      } else {
                        setFormData({
                          ...formData,
                          annualBaseIncome: parseInt(value, 10),
                          isEditBaseIncom: true,
                        });
                      }
                      setFormErrors({
                        ...formErrors,
                        annualBaseIncome: value === '' ? 'annualBaseIncome' : undefined,
                      });
                    }
                  }}
                />
              </span>
              <div className="currencyErrorMessage">
                {formErrors.annualBaseIncome ? 'Please enter a value' : '\u00A0'}
              </div>
            </Grid>
            <Grid item xs={6}>
              <span className="formLabel currencyinvalid" htmlFor="averageVariableIncome">
                Average Annual Variable Income (commission, bonus, etc.)
              </span>
              <span className={style.currencyInput}>
                <CurrencyFormat
                  value={Number.isInteger(+formData.averageVariableIncome) ? +formData.averageVariableIncome : ''}
                  thousandSeparator
                  id="averageVariableIncome"
                  name="averageVariableIncome"
                  data-testid="averageVariableIncome"
                  prefix="$"
                  allowNegative={false}
                  decimalScale={0}
                  className={`${style.nonVoltronField} ${formErrors.averageVariableIncome ? style.error : ''}`}
                  required
                  disabled={formData.isEditIncomeMOdal ? true : data?.isRemoveIncomeOption}
                  onValueChange={(values) => {
                    const { value } = values;
                    if (!formData.isEditIncomeMOdal) {
                      if (!formData.isEditIncome) {
                        setFormData({
                          ...formData,
                          averageVariableIncome: parseInt(value, 10),
                          isEditIncome: true,
                          isEditIncomeMOdal: true,
                          enable: '1',
                        });
                      } else {
                        setFormData({
                          ...formData,
                          averageVariableIncome: parseInt(value, 10),
                          isEditIncome: true,
                        });
                      }
                      setFormErrors({
                        ...formErrors,
                        averageVariableIncome: value === '' ? 'averageVariableIncome' : undefined,
                      });
                    }
                  }}
                />
                <div className="currencyErrorMessage">
                  {formErrors.averageVariableIncome ? 'Please enter a value' : '\u00A0'}
                </div>
              </span>
            </Grid>
            <Grid item xs={12} justifyContent="flex-end" container>
              <Button onClick={handleSubmit}>SAVE & CONTINUE</Button>
            </Grid>
          </Grid>
        </form>
      </div>
      <Modal
        open={Boolean(formData.isEditIncomeMOdal)}
        handleToggle={() => {
          setFormData({ ...formData, isEditIncomeMOdal: false });
        }}
        title={modalTitle}
      >
        {(formData.enable === '1' || formData.enable === '3') && (
          <p>
            The change in income may impact the benefits and premium of your offer. Please contact your agent with
            questions or concerns.
          </p>
        )}

        {formData.enable === '2' && (
          <p>
            The change in occupation may impact the benefits and premium of your offer. Please contact your agent with
            questions or concerns.
          </p>
        )}

        <Button
          onClick={() => {
            setFormData({
              ...formData,
              isEditIncomeMOdal: false,
            });
          }}
        >
          OK
        </Button>
      </Modal>
    </>
  );
};

export default Work;
