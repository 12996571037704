import bowser from 'bowser';

class BrowserService {
  constructor() {
    this.supported = {
      android: '>=1',
      chrome: '>=35',
      edge: '>=14',
      firefox: '>=45',
      ie: '>=11',
      ios: '>=5', // ?
      opera: '>=39',
      safari: '>=10', // 9?
    };
    this.browser = bowser.getParser(window.navigator.userAgent);
  }

  checkBrowser() {
    if (!this.browser.satisfies(this.supported)) {
      window.location = '/unsupported.html';
      return false;
    }
    return true;
  }

  whatDevice() {
    const platformType = this.browser.parsedResult.platform.type;

    if (platformType === 'mobile' || window.innerWidth <= 480) {
      return 'mobile';
    }
    if (platformType === 'tablet' || window.innerWidth <= 768) {
      return 'tablet';
    }
    if (platformType === 'desktop' || window.innerWidth <= 1024) {
      return 'desktop';
    }
    return null;
  }

  whatBrowser() {
    return this.browser.getBrowser();
  }
}

export default new BrowserService();
