import { SET_ERROR, CLEAR_ERROR } from '../actions/error';

const initialError = {
  isCriticalError: false,
  message: {},
};

const errorReducer = (state = initialError, action) => {
  switch (action.type) {
    case SET_ERROR:
      return {
        ...state,
        isCriticalError: true,
        message: { ...action.payload },
      };
    case CLEAR_ERROR:
      return {
        ...state,
        ...initialError,
      };
    default:
      return state;
  }
};

export default errorReducer;
