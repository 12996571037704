import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';
import { CloudDownload } from '@mui/icons-material';
import { Button, Modal } from 'voltron';

import { generateBatchReport } from 'services/Services';
import { pageLoaded, pageLoading } from 'state/actions/page_loading';
import { setCriticalError } from 'state/actions/error';
import { formatAPIMessage } from 'utils/utils';
import styles from './RecentActivities.module.scss';
import mainStyles from '../EmployerApplicantsDashboard.module.scss';

const RecentActivities = ({ nonEmptyEnrollments = [] }) => {
  const { oktaAuth } = useOktaAuth();
  const dispatch = useDispatch();
  const [isGenerateReportSuccessModalOpen, setIsGenerateReportSuccessModalOpen] = useState(false);

  const accessToken = oktaAuth.getAccessToken();

  return (
    <>
      <header className={mainStyles.header}>Recent Activity</header>
      <div className={mainStyles.itemButtons}>
        {nonEmptyEnrollments.map((enrollment) => (
          <Button
            kind="link"
            key={`enrollment-${enrollment.id}-activities`}
            data-testid={`generate-report-enrollment-${enrollment.id}-button`}
            className={mainStyles.itemButton}
            onClick={async () => {
              try {
                dispatch(pageLoading());
                await generateBatchReport(accessToken, enrollment.id);
                setIsGenerateReportSuccessModalOpen(true);
              } catch (error) {
                dispatch(
                  setCriticalError({
                    title: 'Generate batch report error',
                    body: formatAPIMessage(error),
                    button: 'OK',
                  }),
                );
              } finally {
                dispatch(pageLoaded());
              }
            }}
          >
            <div className={mainStyles.itemButtonContent}>
              <CloudDownload />
              <div className={mainStyles.buttonText}>
                <h3>{enrollment.periodRange}</h3>
                <h4>{enrollment.enrollmentType}</h4>
              </div>
            </div>
          </Button>
        ))}
      </div>
      <Modal
        open={isGenerateReportSuccessModalOpen}
        title="Success"
        handleToggle={() => setIsGenerateReportSuccessModalOpen(false)}
      >
        <span tabIndex="0">Report has been generated to the Corporate Y drive</span>
        <div className={styles.modalButtonContainer}>
          <Button
            kind="link"
            data-testid="acknowledge-generate-report-success-button"
            className={styles.modalButton}
            onClick={() => setIsGenerateReportSuccessModalOpen(false)}
          >
            OK
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default RecentActivities;
