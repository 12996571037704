import React from 'react';
import { Table } from 'voltron';

const PaginatedTable = ({
  columns = [],
  data = [],
  numOfDisplayedItemsOptions = [
    { label: 'Show 10', value: 10 },
    { label: 'Show 25', value: 25 },
    { label: 'Show 50', value: 50 },
    { label: 'Show 100', value: 100 },
  ],
  isDataLoading = false,
  ...tableProps
}) => (
  <Table
    dataLoading={isDataLoading}
    columns={[{ hasLevel: true, columns }]}
    data={data}
    paginatorProps={{
      showNoOfItemsPerPageSelectBox: true,
      showPageInfo: true,
      showFirstAndLastPageIcons: true,
      options: numOfDisplayedItemsOptions?.length === 0 ? undefined : numOfDisplayedItemsOptions,
    }}
    perPageLimit={numOfDisplayedItemsOptions?.length === 0 ? undefined : numOfDisplayedItemsOptions[0].value}
    {...tableProps}
  />
);

export default PaginatedTable;
