import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';
import { withTransaction } from '@elastic/apm-rum-react';
import { CardContent, Card, Box, Container, Grid } from '@mui/material';

import RouteLeaveGuard from 'components/RouteLeaveGuard';
import NavigationTab from 'components/NavigationTab/NavigationTab';
import Employer from './employer/Employer';
import Agent from './agent/Agent';
import Enrollment from './enrollment/Enrollment';
import Wholesaler from './wholesaler/Wholesaler';
import Class from './class/Class';
import CensusSubmission from './censusSubmission/CensusSubmission';
import CensusSummary from './censusSummary/CensusSummary';

import { getUploadCensusData, CLEAR_FORM_DATA } from '../../state/actions/upload';
import styles from './Uploads.module.scss';

const setCurrentTabAsNextOne = (value, setCurrentActiveTab) => {
  setCurrentActiveTab(value);
};

const isValidFormValue = (formValue) => formValue === undefined;

const isCurrentTab = (tab, previouslySelectedId) => tab.id === previouslySelectedId;

const isValidTab = (navTab, identifier) => navTab.id > identifier && navTab.isValid;

const isInvalidTab = (formValue) => formValue === false || formValue === null;

const isPreviousTabValid = (formValue, navTabs, previouslySelectedId) =>
  isValidFormValue(formValue) && isCurrentTab(navTabs, previouslySelectedId);

const isEnabledTab = (formValue, navTabs, identifier) => isValidFormValue(formValue) && isValidTab(navTabs, identifier);

const isDisabledTab = (formValue, navTabs, identifier) => !isValidFormValue(formValue) && navTabs.id > identifier;

const enableTab = (identifier, formValue, tabs, setTabs) => {
  const navTabs = [...tabs];
  const previouslySelectedId = identifier - 1;
  for (let index = 0; index < navTabs.length; index++) {
    const previousIndex = index > 0 ? index - 1 : index;
    if (isInvalidTab(formValue) && isCurrentTab(navTabs[index], previouslySelectedId)) {
      navTabs[previouslySelectedId].isValid = false;
      navTabs[identifier].disabled = true;
    } else if (isPreviousTabValid(formValue, navTabs[index], previouslySelectedId)) {
      navTabs[previouslySelectedId].isValid = true;
      navTabs[identifier].disabled = false;
    } else if (isEnabledTab(formValue, navTabs[index], identifier)) {
      navTabs[index].disabled = false;
    } else if (isDisabledTab(formValue, navTabs[index], identifier)) {
      navTabs[index].disabled = true;
    } else if (isValidFormValue(formValue) && navTabs[previousIndex].isValid) {
      navTabs[index].disabled = false;
    }
  }
  setTabs([...navTabs]);
};

const submitTab = {
  id: 5,
  label: 'Submit',
  content: <CensusSubmission />,
  disabled: true,
  isValid: false,
};

const getUploadsContent = ({ tabs, currentActiveTab, loading }) => (
  <Box sx={{ width: '100%' }}>
    <Container maxWidth="xl" className={styles.container}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card className={styles.card}>
            <CardContent>
              <h2>Upload Census</h2>
              <Grid container spacing={2}>
                <Grid item xs={2.4}>
                  <h3 data-testid="census-summary" className={styles.summaryTitle}>
                    Census Summary
                  </h3>
                  <CensusSummary />
                </Grid>
                <Grid item xs={9}>
                  <NavigationTab tabs={tabs} activeTab={currentActiveTab} showSpinner={loading} />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  </Box>
);

const Uploads = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { oktaAuth } = useOktaAuth();
  const { inProgress, loading } = useSelector((state) => state.upload);
  const [currentActiveTab, setCurrentActiveTab] = useState();
  const [tabs, setTabs] = useState([
    {
      id: 0,
      label: 'Employer',
      content: (
        <Employer
          proceedToTab={(value) => setCurrentTabAsNextOne(value, setCurrentActiveTab)}
          enableNextTab={(identifier, value) => enableTab(identifier, value, tabs, setTabs)}
        />
      ),
      disabled: false,
      isValid: false,
    },
    {
      id: 1,
      label: 'Agent',
      content: (
        <Agent
          proceedToTab={(value) => setCurrentTabAsNextOne(value, setCurrentActiveTab)}
          enableNextTab={(identifier, value) => enableTab(identifier, value, tabs, setTabs)}
        />
      ),
      disabled: true,
      isValid: false,
    },
    {
      id: 2,
      label: 'Enrollment',
      content: (
        <Enrollment
          proceedToTab={(value) => setCurrentTabAsNextOne(value, setCurrentActiveTab)}
          enableNextTab={(identifier, value) => enableTab(identifier, value, tabs, setTabs)}
        />
      ),
      disabled: true,
      isValid: false,
    },
    {
      id: 3,
      label: 'Wholesaler',
      content: (
        <Wholesaler
          proceedToTab={(value) => setCurrentTabAsNextOne(value, setCurrentActiveTab)}
          enableNextTab={(identifier, value) => enableTab(identifier, value, tabs, setTabs)}
        />
      ),
      disabled: true,
      isValid: false,
    },
    {
      id: 4,
      label: 'Plan/Class Design/Files',
      content: (
        <Class
          proceedToTab={(value) => setCurrentTabAsNextOne(value, setCurrentActiveTab)}
          enableNextTab={(identifier, value) => enableTab(identifier, value, tabs, setTabs)}
        />
      ),
      disabled: true,
      isValid: false,
    },
    submitTab,
  ]);

  const leaveUpload = (path) => {
    dispatch({
      type: CLEAR_FORM_DATA,
    });
    history.push(path);
  };

  useEffect(() => {
    const accessToken = oktaAuth.getAccessToken();
    dispatch(getUploadCensusData(accessToken));
  }, []);

  return (
    <>
      <RouteLeaveGuard
        when={inProgress}
        message="Your enrollment set-up is not complete. Are you sure you want to do this?"
        navigate={leaveUpload}
        shouldBlockNavigation={() => !!inProgress}
      />
      {getUploadsContent({ tabs, currentActiveTab, loading })}
    </>
  );
};

export default withTransaction('CensusUpload', 'component')(Uploads);
