import { useOktaAuth } from '@okta/okta-react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, TextArea, Select, CheckBox } from 'voltron';

import { pageLoaded, pageLoading } from 'state/actions/page_loading';
import styles from './AdditionalForms.module.scss';
import { saveAndRefreshData } from '../../existingDisabilityInsurance/formUtils';

export const accountTypeList = [
  { label: 'Checking', value: 'Checking' },
  { label: 'Savings', value: 'Savings' },
];
export const institutionTypeList = [
  { label: 'Bank', value: 'Bank' },
  { label: 'Credit Union', value: 'Credit Union' },
];

const FORM_FIELD_NAMES = {
  eftAccountType: 'eftAccountType',
  eftInstitutionType: 'eftInstitutionType',
  existingEftBankAccountHolder: 'existingEftBankAccountHolder',
  existingEftBankAndBranchName: 'existingEftBankAndBranchName',
  existingEftRoutingNumber: 'existingEftRoutingNumber',
  existingEftBankAccountNumber: 'existingEftBankAccountNumber',
  eftCheckbox: 'eftCheckBox',
  existingEftPolicyNumber: 'existingEftPolicyNumber',
  existingEftNameOfInsured: 'existingEftNameOfInsured',
};

const labelPosition = 'top';
const textareaStyle = { resize: 'vertical' };

const getInvalidFormElements = (inputData) => {
  const invalidFormElements = [];

  if (!inputData.eftAccountType) {
    invalidFormElements.push(FORM_FIELD_NAMES.eftAccountType);
  }
  if (!inputData.eftInstitutionType) {
    invalidFormElements.push(FORM_FIELD_NAMES.eftInstitutionType);
  }
  if (!inputData.existingEftBankAccountHolder?.trim()) {
    invalidFormElements.push(FORM_FIELD_NAMES.existingEftBankAccountHolder);
  }
  if (!inputData.existingEftBankAndBranchName?.trim()) {
    invalidFormElements.push(FORM_FIELD_NAMES.existingEftBankAndBranchName);
  }
  if (inputData.existingEftRoutingNumber?.length !== 9) {
    invalidFormElements.push(FORM_FIELD_NAMES.existingEftRoutingNumber);
  }
  if (!inputData.existingEftBankAccountNumber) {
    invalidFormElements.push(FORM_FIELD_NAMES.existingEftBankAccountNumber);
  }

  if (inputData.eftCheckBox) {
    if (!inputData.existingEftPolicyNumber?.trim()) {
      invalidFormElements.push(FORM_FIELD_NAMES.existingEftPolicyNumber);
    }
    if (!inputData.existingEftNameOfInsured?.trim()) {
      invalidFormElements.push(FORM_FIELD_NAMES.existingEftNameOfInsured);
    }
  }

  return invalidFormElements;
};

const getAdditionalFieldChanges = (inputName, value) => {
  let additionalFieldChanges = {};
  if (inputName === FORM_FIELD_NAMES.eftCheckbox && !value) {
    additionalFieldChanges = {
      existingEftPolicyNumber: '',
      existingEftNameOfInsured: '',
    };
  }
  return additionalFieldChanges;
};

const getBackUpFieldValues = (inputData) => ({
  eftCheckBoxBackValue: inputData.eftCheckBox ? 'Yes' : 'No',
  existingEftNameOfInsuredBackUpValue: `${inputData.firstName} ${inputData.middleInitial} ${inputData.lastName}`,
  existingEftPolicyNumberBackUpValue:
    inputData.existingEftPolicyNumber?.trim() && inputData.existingEftNameOfInsured?.trim()
      ? `${inputData.existingEftPolicyNumber} ${inputData.existingEftNameOfInsured}`
      : '',
});

const getExistingEftFields = ({
  eftCheckBox,
  isOnSubmit,
  invalidInputs,
  existingEftPolicyNumber,
  existingEftNameOfInsured,
  onInputChange,
}) => (
  <>
    <div>
      <CheckBox
        label="Add to existing EFT?"
        defaultChecked={eftCheckBox}
        onClick={() => onInputChange(FORM_FIELD_NAMES.eftCheckbox, !eftCheckBox)}
        inputProps={{
          'data-testid': 'eft-checkbox',
        }}
        labelProps={{
          kind: 'bold',
        }}
      />
      <p className={styles.checkboxDescription}>
        <strong>
          If you have an insurance policy with Ameritas and premiums are already being automatically withdrawn via EFT
          from a financial institution and you want to add the premium for this coverage to that EFT, provide a policy
          number and the name of the insured below.
        </strong>
      </p>
    </div>
    <Input
      label="Policy Number:"
      data-testid="existing-eft-policy-number"
      material={false}
      autoComplete={false}
      disabled={!eftCheckBox}
      required={eftCheckBox}
      forceErrorMessage={isOnSubmit && eftCheckBox && invalidInputs.includes(FORM_FIELD_NAMES.existingEftPolicyNumber)}
      value={existingEftPolicyNumber}
      onChange={(e) => onInputChange(FORM_FIELD_NAMES.existingEftPolicyNumber, e.target.value)}
    />
    <Input
      label="Name of Insured:"
      data-testid="existing-eft-name-of-insured"
      material={false}
      autoComplete={false}
      disabled={!eftCheckBox}
      required={eftCheckBox}
      forceErrorMessage={isOnSubmit && eftCheckBox && invalidInputs.includes(FORM_FIELD_NAMES.existingEftNameOfInsured)}
      value={existingEftNameOfInsured}
      onChange={(e) => onInputChange(FORM_FIELD_NAMES.existingEftNameOfInsured, e.target.value)}
    />
  </>
);

const getBankNumberFields = ({
  existingEftRoutingNumber,
  existingEftBankAccountNumber,
  invalidInputs,
  isOnSubmit,
  onInputChange,
}) => (
  <div className={styles.formRow}>
    <Input
      label="Transit/ABA Routing Number"
      kind="number"
      data-testid="existing-eft-routing-number"
      material={false}
      autoComplete={false}
      containerClass={styles.questionItem}
      required
      minLength={9}
      maxLength={9}
      errormessage="Please enter a 9-digit routing number"
      forceErrorMessage={isOnSubmit && invalidInputs.includes(FORM_FIELD_NAMES.existingEftRoutingNumber)}
      value={existingEftRoutingNumber}
      onChange={(e) => onInputChange(FORM_FIELD_NAMES.existingEftRoutingNumber, e.target.value)}
    />
    <Input
      label="Bank Account Number"
      kind="number"
      data-testid="existing-eft-bank-account-number"
      material={false}
      autoComplete={false}
      containerClass={styles.questionItem}
      required
      forceErrorMessage={isOnSubmit && invalidInputs.includes(FORM_FIELD_NAMES.existingEftBankAccountNumber)}
      value={existingEftBankAccountNumber}
      onChange={(e) => onInputChange(FORM_FIELD_NAMES.existingEftBankAccountNumber, e.target.value)}
    />
  </div>
);

const getForm = ({ invalidInputs, formInputData, isOnSubmit, onInputChange }) => {
  const {
    eftAccountType,
    eftInstitutionType,
    existingEftBankAccountHolder,
    existingEftBankAndBranchName,
    existingEftRoutingNumber,
    existingEftBankAccountNumber,
    eftCheckBox,
    existingEftPolicyNumber,
    existingEftNameOfInsured,
  } = formInputData;

  return (
    <div className={styles.formBody}>
      <div className={styles.formRow}>
        <Select
          label="Indicate type of account"
          labelPosition={labelPosition}
          name="eft-account-type"
          options={accountTypeList}
          clearable={false}
          searchable={false}
          material={false}
          containerClassName={styles.questionItem}
          required
          forceErrorMessage={isOnSubmit && invalidInputs.includes(FORM_FIELD_NAMES.eftAccountType)}
          selectedValue={accountTypeList.find((option) => option.value === eftAccountType)}
          onChange={(e) => onInputChange(FORM_FIELD_NAMES.eftAccountType, e.value)}
        />
        <Select
          label="Indicate type of financial institution"
          labelPosition={labelPosition}
          name="eft-institution-type"
          options={institutionTypeList}
          clearable={false}
          searchable={false}
          material={false}
          containerClassName={styles.questionItem}
          required
          forceErrorMessage={isOnSubmit && invalidInputs.includes(FORM_FIELD_NAMES.eftInstitutionType)}
          selectedValue={institutionTypeList.find((option) => option.value === eftInstitutionType)}
          onChange={(e) => onInputChange(FORM_FIELD_NAMES.eftInstitutionType, e.value)}
        />
      </div>
      <div className={styles.formRow}>
        <span className={styles.questionItem}>
          <TextArea
            groupLabel={
              <>
                <strong>Bank Account Holder - </strong>provide name and address as shown on Bank Records.
              </>
            }
            groupLabelKind="regular"
            required
            forceErrorMessage={isOnSubmit && invalidInputs.includes(FORM_FIELD_NAMES.existingEftBankAccountHolder)}
            defaultValue={existingEftBankAccountHolder}
            textAreaProps={{
              'data-testid': 'existing-eft-bank-account-holder',
              style: textareaStyle,
              onChange: (e) => onInputChange(FORM_FIELD_NAMES.existingEftBankAccountHolder, e.target.value),
            }}
          />
        </span>
        <span className={styles.questionItem}>
          <TextArea
            groupLabel="Name of Bank and Branch Name where account is maintained."
            required
            forceErrorMessage={isOnSubmit && invalidInputs.includes(FORM_FIELD_NAMES.existingEftBankAndBranchName)}
            defaultValue={existingEftBankAndBranchName}
            textAreaProps={{
              'data-testid': 'existing-eft-bank-and-branch-name',
              style: textareaStyle,
              onChange: (e) => onInputChange(FORM_FIELD_NAMES.existingEftBankAndBranchName, e.target.value),
            }}
          />
        </span>
      </div>
      {getBankNumberFields({
        existingEftRoutingNumber,
        existingEftBankAccountNumber,
        invalidInputs,
        isOnSubmit,
        onInputChange,
      })}
      {getExistingEftFields({
        eftCheckBox,
        isOnSubmit,
        invalidInputs,
        existingEftPolicyNumber,
        existingEftNameOfInsured,
        onInputChange,
      })}
    </div>
  );
};

const AdditionalForms = ({ setSelectedTabIndex }) => {
  const { oktaAuth } = useOktaAuth();
  const {
    data: { applicationId, input: existingInputData },
  } = useSelector((state) => state.applicantForm);
  const dispatch = useDispatch();
  const [formInputData, setFormInputData] = useState({
    ...existingInputData,
    eftCheckBox: existingInputData.eftCheckBox === 'true' || existingInputData.eftCheckBox === true,
  });
  const [invalidInputs, setInvalidInputs] = useState([]);
  const [isOnSubmit, setIsOnSubmit] = useState(false);

  useEffect(() => {
    dispatch(applicationId > 0 ? pageLoaded() : pageLoading());
  }, [applicationId]);

  useEffect(() => {
    setFormInputData({
      ...existingInputData,
      eftCheckBox: existingInputData.eftCheckBox === 'true' || existingInputData.eftCheckBox === true,
    });
  }, [existingInputData]);

  useEffect(() => {
    const invalidFormElements = getInvalidFormElements(formInputData);
    setInvalidInputs(invalidFormElements);
  }, [formInputData]);

  const onInputChange = (inputName, value) => {
    setFormInputData((formData) => ({
      ...formData,
      [inputName]: value,
      ...getAdditionalFieldChanges(inputName, value),
    }));
  };

  const onSubmit = async () => {
    setIsOnSubmit(true);
    if (invalidInputs.length) {
      return;
    }

    const formData = {
      userFormId: applicationId,
      input: {
        ...formInputData,
        ...getBackUpFieldValues(formInputData),
      },
    };
    const accessToken = oktaAuth.getAccessToken();
    await saveAndRefreshData({ accessToken, formData, applicationId, dispatch, setSelectedTabIndex, tabIndex: 6 });
  };

  return (
    <div className={styles.additionalForms}>
      <header>
        <h3>Electronic Funds Transfer (EFT)</h3>
        <p>
          The monthly premium for your coverage will be automatically withdrawn from the financial institution
          designated by you below and it will be withdrawn on the 1<sup>st</sup> of each month. However, your initial
          premium may be withdrawn on a different day of the month depending on when your application is approved and
          the policy is issued.
        </p>
      </header>
      <form autoComplete="off">
        {getForm({ invalidInputs, formInputData, isOnSubmit, onInputChange })}
        <div className={styles.submitButton}>
          <Button onClick={onSubmit}>SAVE & CONTINUE</Button>
        </div>
      </form>
    </div>
  );
};

export default AdditionalForms;
