import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';
import { withTransaction } from '@elastic/apm-rum-react';
import { Box, Container, Grid } from '@mui/material';
import { CheckCircle, NotInterested } from '@mui/icons-material';
import { Button } from 'voltron';

import {
  getNonApplicantUsers,
  exportAllNonApplicants,
  toggleNonApplicantUserIsActive,
  CLEAR_USER_FORM_DATA,
} from 'state/actions/nonApplicantUsers';
import PaginatedTable from 'components/PaginatedTable/PaginatedTable';
// eslint-disable-next-line max-len
import ToggleUserActiveStatusConfirmModal from 'components/ToggleUserActiveStatusConfirm/ToggleUserActiveStatusConfirmModal';

const NameEmailComponent = ({ name, email }) => (
  <>
    <div>Name: {name}</div>
    <div>Email: {email}</div>
  </>
);

const UserTypeDescriptionComponent = ({ userType, description }) => (
  <>
    <div>{userType}</div>
    <div>{description}</div>
  </>
);

const getNameEmailComponent = (_nameAndEmail, _disabled, { name, email }) => (
  <NameEmailComponent name={name} email={email} />
);
const getUserTypeDescriptionComponent = (_userTypeAndDescription, _disabled, { userType, description }) => (
  <UserTypeDescriptionComponent userType={userType} description={description} />
);
const getIsActiveComponent =
  (setIsToggleActiveConfirmModalOpen, setUserToBeToggledActive) =>
  ({ id, isActive }) => (
    <Button
      kind="link"
      data-testid={`isActiveNonApplicantUser${id}`}
      aria-label={`${isActive ? 'deactivate' : 'activate'}-user`}
      className="actionButton"
      onClick={() => {
        setIsToggleActiveConfirmModalOpen(true);
        setUserToBeToggledActive({ id, isActive });
      }}
    >
      {isActive ? <CheckCircle /> : <NotInterested />}
    </Button>
  );

const NonApplicantUsers = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { oktaAuth } = useOktaAuth();
  const { nonApplicants, nonApplicantsLoading } = useSelector((state) => state.nonApplicantUsers);
  const [isToggleActiveConfirmModalOpen, setIsToggleActiveConfirmModalOpen] = useState(false);
  const [userToBeToggledActive, setUserToBeToggledActive] = useState({ id: 0 });

  const accessToken = oktaAuth.getAccessToken();

  useEffect(() => {
    dispatch({
      type: CLEAR_USER_FORM_DATA,
    });
    dispatch(getNonApplicantUsers(accessToken));
  }, []);

  const COLUMN_SEPARATOR = '///';

  const nonApplicantsTableData = nonApplicants.map((nonApplicant) => ({
    ...nonApplicant,
    nameAndEmail: `${nonApplicant.name}${COLUMN_SEPARATOR}${nonApplicant.email}`,
    userTypeAndDescription: `${nonApplicant.userType}${COLUMN_SEPARATOR}${nonApplicant.description}`,
  }));

  const tableColumns = [
    {
      Header: 'Users',
      width: 40,
      accessor: 'nameAndEmail',
      isSearchable: true,
      component: getNameEmailComponent,
    },
    {
      Header: 'User Name',
      width: 25,
      accessor: 'userName',
      isSearchable: true,
    },
    {
      Header: 'User Type',
      width: 50,
      accessor: 'userTypeAndDescription',
      isSearchable: true,
      component: getUserTypeDescriptionComponent,
    },
    {
      Header: 'Is Active',
      width: 15,
      isAction: true,
      component: getIsActiveComponent(setIsToggleActiveConfirmModalOpen, setUserToBeToggledActive),
    },
  ];

  const handleExport = () => {
    dispatch(exportAllNonApplicants(accessToken));
  };

  return (
    <Box sx={{ width: '82vw', marginTop: '4rem' }}>
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="adminDashboardPage">
              <h2 className="pageTitle">Users (Non Applicant)</h2>
              <div className="contentBody">
                <div className="actions">
                  <p className="actionsTitle">Actions</p>
                  <Button
                    className="actionButton"
                    data-testid="addNonApplicantUser"
                    onClick={() => history.push('/user/-1')}
                  >
                    ADD
                  </Button>
                  <Button data-testid="exportNonApplicantUsers" className="actionButton" onClick={handleExport}>
                    EXPORT
                  </Button>
                </div>
                <div className="tableContainer">
                  <PaginatedTable
                    className="dashboardTable"
                    bubbleOnClick={(item) => history.push(`/user/${item.id}`)}
                    sortColumn="nameAndEmail"
                    sortOrder="ASC"
                    columns={tableColumns}
                    data={nonApplicantsTableData}
                    isDataLoading={nonApplicantsLoading}
                  />
                  <ToggleUserActiveStatusConfirmModal
                    isToggleActiveConfirmModalOpen={isToggleActiveConfirmModalOpen}
                    userToBeToggledActive={userToBeToggledActive}
                    setIsToggleActiveConfirmModalOpen={setIsToggleActiveConfirmModalOpen}
                    setUserToBeToggledActive={setUserToBeToggledActive}
                    toggleAction={toggleNonApplicantUserIsActive}
                  />
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default withTransaction('NonApplicantUsers', 'component')(NonApplicantUsers);
