export const BASE_URL = `${window.location.origin}/api/`;

export const yesNoOptionList = [
  { label: 'Yes', value: 'Y' },
  { label: 'No', value: 'N' },
];
export const genderOptionList = [
  { label: 'Male', value: 'M' },
  { label: 'Female', value: 'F' },
];
export const YES_OPTION = yesNoOptionList[0].value;
export const YES_LABEL = yesNoOptionList[0].label;
export const NO_OPTION = yesNoOptionList[1].value;
export const NO_LABEL = yesNoOptionList[1].label;

export const formCodes = {
  FOUNDATION: 'Foundation',
  FUNDAMENTAL: 'Fundamental',
  CORNERSTONE: 'CornerStone',
  COMBINED: 'Combined',
  COMBINED_CORNERSTONE: 'CombinedCorner',
};

export const DATE_FORMAT = 'MM/DD/YYYY';

export const API_COMMON_ERROR_MESSAGE =
  'Something went wrong. Please try again later or contact support at 1-877-500-4445.';
