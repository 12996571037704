import { useState, useEffect } from 'react';
import { Select, TextArea } from 'voltron';
import { yesNoOptionList, YES_OPTION } from 'constants/index';

import ExistingCoverages from '../existingCoverages/ExistingCoverages';
import { onInputChange, onInputDataChange } from '../formUtils';

const FORM_FIELD_NAMES = {
  appliedForInsuranceFoundation: 'appliedForInsuranceFoundation',
  appliedForInsuranceExplainFoundation: 'appliedForInsuranceExplainFoundation',
};

const getInvalidFormElements = (inputData) => {
  const invalidFormElements = [];

  if (!inputData.appliedForInsuranceFoundation) {
    invalidFormElements.push(FORM_FIELD_NAMES.appliedForInsuranceFoundation);
  } else if (
    inputData.appliedForInsuranceFoundation === YES_OPTION &&
    !inputData.appliedForInsuranceExplainFoundation?.trim()
  ) {
    invalidFormElements.push(FORM_FIELD_NAMES.appliedForInsuranceExplainFoundation);
  }

  return invalidFormElements;
};

const getAdditionalFieldChanges = (inputName, value) => {
  let additionalFieldChanges = {};
  if (inputName === FORM_FIELD_NAMES.appliedForInsuranceFoundation && value !== YES_OPTION) {
    additionalFieldChanges = {
      appliedForInsuranceExplainFoundation: '',
    };
  }
  return additionalFieldChanges;
};

const FoundationQuestions = ({
  existingInputData,
  setFormInputData,
  setIsFormInvalidMap,
  isOnSubmit = false,
  isCombinedWithAnotherProduct = false,
}) => {
  const [inputData, setInputData] = useState(existingInputData);
  const [invalidInputs, setInvalidInputs] = useState([]);
  const { appliedForInsuranceFoundation, appliedForInsuranceExplainFoundation } = inputData;

  useEffect(() => {
    onInputDataChange(inputData, 'foundation', getInvalidFormElements, setInvalidInputs, setIsFormInvalidMap);
  }, [inputData]);

  return (
    <div>
      {isCombinedWithAnotherProduct && (
        <p data-testid="combined-text-foundation">
          <strong>For Foundation Application</strong>
        </p>
      )}
      <ExistingCoverages
        formType="foundation"
        existingInputData={existingInputData}
        setFormInputData={setFormInputData}
        setIsFormInvalidMap={setIsFormInvalidMap}
        isOnSubmit={isOnSubmit}
      />
      <br />
      <Select
        label="Other than noted above, have you applied for insurance in the past 12 months?"
        labelPosition="top"
        name="applied-for-insurance-foundation"
        options={yesNoOptionList}
        clearable={false}
        searchable={false}
        material={false}
        required
        forceErrorMessage={isOnSubmit && invalidInputs.includes(FORM_FIELD_NAMES.appliedForInsuranceFoundation)}
        selectedValue={yesNoOptionList.find((option) => option.value === appliedForInsuranceFoundation)}
        onChange={(e) =>
          onInputChange(
            FORM_FIELD_NAMES.appliedForInsuranceFoundation,
            e.value,
            getAdditionalFieldChanges,
            setInputData,
            setFormInputData,
          )
        }
      />
      {appliedForInsuranceFoundation === YES_OPTION && (
        <TextArea
          groupLabel="If Yes, explain."
          maxLength={500}
          placeholderText="Explanation"
          required
          forceErrorMessage={
            isOnSubmit && invalidInputs.includes(FORM_FIELD_NAMES.appliedForInsuranceExplainFoundation)
          }
          defaultValue={appliedForInsuranceExplainFoundation}
          textAreaProps={{
            'data-testid': 'applied-for-insurance-explain-foundation',
            style: { resize: 'vertical' },
            onChange: (e) =>
              onInputChange(
                FORM_FIELD_NAMES.appliedForInsuranceExplainFoundation,
                e.target.value,
                getAdditionalFieldChanges,
                setInputData,
                setFormInputData,
              ),
          }}
        />
      )}
    </div>
  );
};

export default FoundationQuestions;
