import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Employer from 'views/dashboard/applicant/forms/primary/applicant/ApplicantForm';
import { useSelector } from 'react-redux';
import { AntTab, AntTabs, TabPanel } from 'utils/utils';
import Work from '../work/Work';
import ExistingDisabilityInsurance from '../../existingDisabilityInsurance/ExistingDisabilityInsurance';
import HealthInfo from '../healthInfo/HealthInfo';
import ElectronicTransactions from '../../electronicTransactions/ElectronicTransactions';
import AdditionalForms from '../additionalForms/AdditionalForms';
import Summary from '../summary/Summary';
import Submission from '../../submission/Submission';

function NavigationTabs() {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const showEft = useSelector((state) => state.applicantForm?.data?.showEft);
  const summaryTabIndex = showEft ? 6 : 5;
  const submissionTabIndex = showEft ? 7 : 6;

  const handleChange = (_event, newValue) => {
    setSelectedTabIndex(newValue);
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <AntTabs
          value={selectedTabIndex}
          onChange={handleChange}
          aria-label="primary enrollment tabs"
          variant="scrollable"
          scrollButtons="auto"
          className="nav12"
        >
          <AntTab label="Applicant" tabIndex={0} />
          <AntTab label="Employment Information" disabled={selectedTabIndex < 1} tabIndex={0} />
          <AntTab label="Existing Disability Insurance" disabled={selectedTabIndex < 2} tabIndex={0} />
          <AntTab label="Health Information" disabled={selectedTabIndex < 3} tabIndex={0} />
          <AntTab
            label="Electronic Transactions and Delivery of Documents"
            disabled={selectedTabIndex < 4}
            tabIndex={0}
          />
          {showEft && <AntTab label="Additional Forms" disabled={selectedTabIndex < 5} tabIndex={0} />}
          <AntTab label="Summary" disabled={selectedTabIndex < summaryTabIndex} tabIndex={0} />
          <AntTab label="Submission" disabled={selectedTabIndex < submissionTabIndex} tabIndex={0} />
        </AntTabs>
      </Box>
      <TabPanel value={selectedTabIndex} index={0}>
        <Employer setSelectedTabIndex={setSelectedTabIndex} />
      </TabPanel>
      <TabPanel value={selectedTabIndex} index={1}>
        <Work setSelectedTabIndex={setSelectedTabIndex} />
      </TabPanel>
      <TabPanel value={selectedTabIndex} index={2}>
        <ExistingDisabilityInsurance setSelectedTabIndex={() => setSelectedTabIndex(selectedTabIndex + 1)} />
      </TabPanel>
      <TabPanel value={selectedTabIndex} index={3}>
        <HealthInfo setSelectedTabIndex={setSelectedTabIndex} />
      </TabPanel>
      <TabPanel value={selectedTabIndex} index={4}>
        <ElectronicTransactions isPrimary setSelectedTabIndex={() => setSelectedTabIndex(5)} />
      </TabPanel>
      {showEft && (
        <TabPanel value={selectedTabIndex} index={5}>
          <AdditionalForms setSelectedTabIndex={setSelectedTabIndex} />
        </TabPanel>
      )}
      <TabPanel value={selectedTabIndex} index={summaryTabIndex}>
        <Summary setSelectedTabIndex={() => setSelectedTabIndex(selectedTabIndex + 1)} />
      </TabPanel>
      <TabPanel value={selectedTabIndex} index={submissionTabIndex}>
        <Submission />
      </TabPanel>
    </>
  );
}

export default NavigationTabs;
