import { useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useDispatch } from 'react-redux';
import { Button, Input, Modal } from 'voltron';
import { formatAPIMessage, getInvalidEmailAndEmailVerify } from 'utils/utils';
import { refuseOverallCoverage } from 'services/Services';
import { pageLoaded, pageLoading } from 'state/actions/page_loading';
import { setCriticalError } from 'state/actions/error';

const FORM_FIELD_NAMES = {
  emailAddress: 'emailAddress',
  emailAddressVerify: 'emailAddressVerify',
};

const EMAIL_PLACEHOLDER = 'Email';
const emailInputKind = 'email';
const emailMaxLength = 40;

const getFormInputs = ({
  emailAddress,
  emailAddressVerify,
  setIsValidEmailAddress,
  setIsValidEmailAddressVerify,
  onInputChange,
}) => (
  <div>
    <Input
      label="Your email address"
      placeholder={EMAIL_PLACEHOLDER}
      kind={emailInputKind}
      maxLength={emailMaxLength}
      data-testid="email-address"
      material={false}
      autoComplete={false}
      required
      value={emailAddress}
      onChange={(e) => {
        setIsValidEmailAddress(e.target.isValid);
        onInputChange(FORM_FIELD_NAMES.emailAddress, e.target.value);
      }}
    />
    <Input
      label="Verify email address"
      placeholder={EMAIL_PLACEHOLDER}
      kind={emailInputKind}
      maxLength={emailMaxLength}
      data-testid="email-address-verify"
      material={false}
      autoComplete={false}
      required
      value={emailAddressVerify}
      onChange={(e) => {
        setIsValidEmailAddressVerify(e.target.isValid);
        onInputChange(FORM_FIELD_NAMES.emailAddressVerify, e.target.value);
      }}
    />
  </div>
);

const getModalContent = ({ isIncreases }) => (
  <p tabIndex="0">
    {`I acknowledge that information regarding an individual disability income protection plan from Ameritas
      has been explained to me, including cost of the plan. However, I have elected not to apply for coverage
      at this time.${
        isIncreases
          ? ''
          : ` I understand that
      if I decide to participate at a later date, I may be required to prove medical and financial insurability.`
      }`}
  </p>
);

const RefuseOverallCoverage = ({ formData, applicantId, isIncreases }) => {
  const dispatch = useDispatch();
  const { oktaAuth } = useOktaAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formInputData, setFormInputData] = useState({
    [FORM_FIELD_NAMES.emailAddress]: '',
    [FORM_FIELD_NAMES.emailAddressVerify]: '',
  });
  const [invalidInputs, setInvalidInputs] = useState([]);
  const [isValidEmailAddress, setIsValidEmailAddress] = useState(false);
  const [isValidEmailAddressVerify, setIsValidEmailAddressVerify] = useState(false);

  const { emailAddress, emailAddressVerify } = formInputData;
  const cannotSubmit = invalidInputs.length || emailAddress?.trim() !== emailAddressVerify?.trim();

  useEffect(() => {
    const invalidFormElements = getInvalidEmailAndEmailVerify(
      emailAddress,
      emailAddressVerify,
      isValidEmailAddress,
      isValidEmailAddressVerify,
    );
    setInvalidInputs(invalidFormElements);
  }, [emailAddress, emailAddressVerify, isValidEmailAddress, isValidEmailAddressVerify]);

  const resetModal = () => {
    setIsModalOpen(false);
    setFormInputData({
      [FORM_FIELD_NAMES.emailAddress]: '',
      [FORM_FIELD_NAMES.emailAddressVerify]: '',
    });
  };

  const onInputChange = (inputName, value) => {
    setFormInputData((prevFormData) => ({
      ...prevFormData,
      [inputName]: value,
    }));
  };

  const onSubmit = async () => {
    const refusalRequest = {
      model: formData,
      email: emailAddress,
      applicantId,
    };
    const accessToken = oktaAuth.getAccessToken();
    let signingUrl;
    try {
      dispatch(pageLoading());
      const adobeResponse = await refuseOverallCoverage(accessToken, refusalRequest);
      signingUrl = adobeResponse.signingUrl;
      resetModal();
      oktaAuth.tokenManager.clear();
      await oktaAuth.signOut();
      sessionStorage.clear();
    } catch (error) {
      dispatch(
        setCriticalError({
          title: 'Error refusing coverage and signing out',
          body: formatAPIMessage(error),
          button: 'OK',
        }),
      );
    } finally {
      dispatch(pageLoaded());
      if (signingUrl) {
        window.location = signingUrl;
      }
    }
  };

  return (
    <>
      <Button disabled={!formData?.showRefuseOverall} onClick={() => setIsModalOpen(true)}>
        REFUSE OVERALL COVERAGE
      </Button>
      <Modal open={isModalOpen} title="Refusal of Coverage" handleToggle={resetModal}>
        {getModalContent({ isIncreases })}
        <form autoComplete="off">
          {getFormInputs({
            emailAddress,
            emailAddressVerify,
            setIsValidEmailAddress,
            setIsValidEmailAddressVerify,
            onInputChange,
          })}
          <div className="modalButtons">
            <Button kind="invert" onClick={resetModal}>
              CANCEL
            </Button>
            <Button disabled={cannotSubmit} onClick={onSubmit}>
              CONTINUE
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default RefuseOverallCoverage;
