import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import appState from './appStateReducer';
import page from './pageReducer';
import upload from './uploadReducer';
import dashboard from './dashboardReducer';
import adminEntities from './adminEntitiesReducer';
import employers from './employersReducer';
import applicantForm from './applicantFormReducer';
import nonApplicantUsers from './nonApplicantUsersReducer';
import error from './errorReducer';

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    appState,
    page,
    upload,
    dashboard,
    adminEntities,
    employers,
    applicantForm,
    nonApplicantUsers,
    error,
  });

export default createRootReducer;
