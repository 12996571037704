import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';

function FormHeader() {
  const { data } = useSelector((state) => state.applicantForm);
  return (
    <Grid container spacing={0} className="innerHeader">
      <Grid item xs={12}>
        <h3>Applicant Information</h3>
      </Grid>
      {data?.isVoluntaryOfferedAndRefused || data?.isBuyUpOfferedAndRefused ? (
        <Grid item xs={12}>
          <p>
            Please verify the information below and make any necessary updates to complete the application <br /> for
            the coverage your Employer has chosen to provide for you.
          </p>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <p>
            Please verify the information below and make any necessary updates.
            <br /> You will also need to complete any blank fields.
          </p>
        </Grid>
      )}
    </Grid>
  );
}

export default FormHeader;
