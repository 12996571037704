import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid } from '@mui/material';
import { Input, Select, Button, CheckBox } from 'voltron';

import { DATE_FORMAT } from 'constants';
import { validateEmail, validatePhone, validateDOB } from 'utils/validator';
import { UPDATE_UPLOAD_CENSUS_DATA } from '../../../state/actions/upload';
import styles from '../Uploads.module.scss';

const labelKind = 'regular';
const NEW_WHOLESALER_OPTION = [
  {
    label: 'New Wholesaler',
    value: 0,
  },
];
const EMPTY_WHOLESALER = {
  firstName: '',
  middleInitial: null,
  lastName: '',
  suffix: null,
  phone: '',
  email: '',
  region: '',
  dob: '',
};

const resetPhoneField = () => {
  const phoneInput = document.querySelectorAll('[data-testid="phone"]')[0];
  phoneInput.focus();
  phoneInput.blur();
  window.scrollTo(0, 0);
};

const validate = (values) => {
  const errors = {};
  if ([null, '', undefined].includes(values?.id)) {
    errors.wholesaler = 'wholesaler';
  }
  if (!values.firstName) {
    errors.firstName = 'firstName';
  }
  if (!values.lastName) {
    errors.lastName = 'lastName';
  }
  if (!validateDOB(values?.dob)) {
    errors.dob = 'dob';
  }
  if (!validatePhone(values?.phone)) {
    errors.phone = 'phone';
  }
  if (!validateEmail(values?.email)) {
    errors.email = 'email';
  }
  if (!values.region) {
    errors.region = 'region';
  }
  return errors;
};

const handleWholesalerChange = (value, wholesalers, formData, setFormErrors, dispatch) => {
  setFormErrors({
    wholesaler: value === '',
  });
  let updatedState;

  if (+value === 0) {
    updatedState = {
      id: 0,
      ...EMPTY_WHOLESALER,
    };
  } else {
    const wholesalerObj = wholesalers?.find((wholesaler) => +wholesaler.id === +value);
    updatedState = {
      ...wholesalerObj,
      id: +value,
      firstName: wholesalerObj?.firstName,
      lastName: wholesalerObj?.lastName,
      email: wholesalerObj?.email,
      phone: wholesalerObj?.phone,
      region: wholesalerObj?.region,
      dob: wholesalerObj?.dob,
      middleInitial: wholesalerObj?.middleInitial || null,
      suffix: wholesalerObj?.suffix || null,
    };
  }

  dispatch({
    type: UPDATE_UPLOAD_CENSUS_DATA,
    payload: {
      formData: {
        ...formData,
        wholesaler: {
          ...formData.wholesaler,
          ...updatedState,
        },
        hideWholesaler: false,
      },
    },
  });
};

const handleWholesalerInputChange = (name, value, formData, dispatch) => {
  dispatch({
    type: UPDATE_UPLOAD_CENSUS_DATA,
    payload: {
      formData: {
        ...formData,
        wholesaler: {
          ...formData.wholesaler,
          [name]: value,
        },
      },
    },
  });
};

const checkboxHandler = (e, formData, dispatch) => {
  const { name, checked } = e.target;
  dispatch({
    type: UPDATE_UPLOAD_CENSUS_DATA,
    payload: {
      formData: {
        ...formData,
        [name]: checked,
      },
    },
  });
};

const handleSubmit = (formData, setFormErrors, proceedToTab) => {
  const errors = validate(formData.wholesaler);
  setFormErrors(errors);
  if (Object.keys(errors).length === 0) {
    proceedToTab(4);
  }
};

const getWholesalerNameFields = ({ formData, formErrors, dispatch }) => (
  <>
    <Grid item xs={8}>
      <Input
        key={`firstName-${formData.wholesaler?.id}`}
        name="firstName"
        id="firstName"
        label="First Name"
        labelKind={labelKind}
        placeholder="First Name"
        material={false}
        autoComplete={false}
        onChange={(e) => handleWholesalerInputChange('firstName', e.target.value, formData, dispatch)}
        value={formData.wholesaler.firstName}
        data-testid="first-name"
        required
        kind="name"
        forceErrorMessage={formErrors.firstName}
        maxLength={40}
      />
    </Grid>
    <Grid item xs={4}>
      <Input
        key={`middleInitial-${formData.wholesaler?.id}`}
        name="middleInitial"
        id="middleInitial"
        label="Middle Name"
        labelKind={labelKind}
        placeholder="Middle Name"
        material={false}
        autoComplete={false}
        onChange={(e) => handleWholesalerInputChange('middleInitial', e.target.value, formData, dispatch)}
        value={formData.wholesaler.middleInitial}
        data-testid="middle-name"
        kind="name"
        maxLength={25}
      />
    </Grid>
    <Grid item xs={8}>
      <Input
        key={`lastName-${formData.wholesaler?.id}`}
        name="lastName"
        id="lastName"
        label="Last Name"
        labelKind={labelKind}
        placeholder="Last Name"
        material={false}
        autoComplete={false}
        onChange={(e) => handleWholesalerInputChange('lastName', e.target.value, formData, dispatch)}
        value={formData.wholesaler.lastName}
        data-testid="last-name"
        required
        kind="name"
        forceErrorMessage={formErrors.lastName}
        maxLength={40}
      />
    </Grid>
    <Grid item xs={4}>
      <Input
        key={`suffix-${formData.wholesaler?.id}`}
        name="suffix"
        id="suffix"
        label="Suffix"
        labelKind={labelKind}
        placeholder="Suffix"
        material={false}
        autoComplete={false}
        onChange={(e) => handleWholesalerInputChange('suffix', e.target.value, formData, dispatch)}
        value={formData.wholesaler.suffix}
        data-testid="suffix"
        kind="name"
        forceErrorMessage={formErrors.suffix}
        maxLength={40}
      />
    </Grid>
  </>
);

const getWholesalerInfoFields = ({ formData, formErrors, dispatch }) => (
  <>
    <Grid item xs={12}>
      <Input
        kind="date"
        data-testid="dob"
        name="dob"
        id="dob"
        labelKind={labelKind}
        label="Date of Birth"
        material={false}
        autoComplete={false}
        placeholder={DATE_FORMAT}
        value={formData.wholesaler.dob}
        onChange={(e) => handleWholesalerInputChange('dob', e.target.value, formData, dispatch)}
        required
        forceErrorMessage={formErrors?.dob}
      />
    </Grid>
    <Grid item xs={12}>
      <Input
        name="region"
        id="region"
        label="Region Code"
        labelKind={labelKind}
        placeholder="R Code"
        material={false}
        autoComplete={false}
        onChange={(e) => handleWholesalerInputChange('region', e.target.value, formData, dispatch)}
        value={formData.wholesaler.region}
        required
        kind="text"
        data-testid="region"
        maxLength={5}
        forceErrorMessage={formErrors.region}
      />
    </Grid>
    <Grid item xs={12}>
      <Input
        name="phone"
        id="phone"
        key={`phone-${formData.wholesaler?.id}`}
        required
        label="Phone"
        labelKind={labelKind}
        placeholder="Phone"
        material={false}
        autoComplete={false}
        onChange={(e) => handleWholesalerInputChange('phone', e.target.value, formData, dispatch)}
        value={formData.wholesaler.phone}
        kind="phone"
        data-testid="phone"
        forceErrorMessage={formErrors?.phone}
      />
    </Grid>
    <Grid item xs={12}>
      <Input
        name="email"
        id="email"
        key={`email-${formData.wholesaler?.id}`}
        label="Email Address"
        labelKind={labelKind}
        placeholder="Email"
        material={false}
        autoComplete={false}
        onChange={(e) => handleWholesalerInputChange('email', e.target.value, formData, dispatch)}
        value={formData.wholesaler.email}
        data-testid="email"
        kind="email"
        required
        forceErrorMessage={formErrors?.email}
        maxLength={50}
      />
    </Grid>
  </>
);

const Wholesaler = ({ proceedToTab, enableNextTab }) => {
  const {
    wholesalers = [],
    formData = {},
    inProgress: wholesalerUpdateInProgress,
  } = useSelector((state) => state.upload);
  const dispatch = useDispatch();
  const [formErrors, setFormErrors] = useState({});
  const [phoneNumberEdit, setPhoneNumberEdit] = useState();
  const wholesalerList = wholesalers.map((wholesaler) => ({
    label: wholesaler.name,
    value: wholesaler.id,
  }));

  useEffect(() => {
    proceedToTab(3);
  }, []);

  useEffect(() => {
    const errors = validate(formData.wholesaler);
    if (Object.keys(errors).length === 0) {
      setFormErrors({});
      enableNextTab(4);
    } else {
      enableNextTab(4, false);
    }
  }, [formData.wholesaler]);

  useEffect(() => {
    if (wholesalerUpdateInProgress && !phoneNumberEdit) {
      setPhoneNumberEdit(true);
    }
  }, [wholesalerUpdateInProgress]);

  useEffect(() => {
    let getPhoneTimeout;
    if (phoneNumberEdit && +(formData?.wholesaler?.id || 0)) {
      // need to prevent Voltron from treating potential valid phone values as invalid initially
      getPhoneTimeout = setTimeout(resetPhoneField, 500);
    }
    return () => clearTimeout(getPhoneTimeout);
  }, [phoneNumberEdit, formData?.wholesaler?.id]);

  return (
    <div>
      <Grid container spacing={0} className="innerHeader">
        <Grid item xs={12}>
          <h3>Wholesaler Information</h3>
        </Grid>
        <Grid item xs={12}>
          <p>Select or enter the wholesaler information for the census being uploaded</p>
        </Grid>
      </Grid>
      <form>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Select
              label="Select Wholesaler"
              labelKind={labelKind}
              id="wholesaler"
              labelPosition="top"
              name="wholesaler"
              required
              options={[...NEW_WHOLESALER_OPTION, ...wholesalerList]}
              clearable={false}
              searchable={false}
              material={false}
              selectedValue={[...NEW_WHOLESALER_OPTION, ...wholesalerList].find(
                (option) => option.value === formData.wholesaler?.id,
              )}
              onChange={(wholesaler) =>
                handleWholesalerChange(wholesaler.value, wholesalers, formData, setFormErrors, dispatch)
              }
              forceErrorMessage={formErrors.wholesaler}
            />
            <span>If new wholesaler, select &apos;New Wholesaler&apos; to enter wholesaler information</span>
          </Grid>
          {getWholesalerNameFields({ formData, formErrors, dispatch })}
          {getWholesalerInfoFields({ formData, formErrors, dispatch })}
          <Grid item xs={12}>
            <legend className={styles.formLegend} htmlFor="hideWholesaler">
              Hide Wholesaler Info from Employee view?
            </legend>
            <CheckBox
              key={`hideWholesaler-${formData.wholesaler?.id}`}
              name="hideWholesaler"
              label="Hide Wholesaler Info from Employee view"
              id="hideWholesaler"
              checked={formData.hideWholesaler}
              onChange={(e) => checkboxHandler(e, formData, dispatch)}
              inputProps={{
                'data-testid': 'hide-wholesaler',
              }}
            />
          </Grid>
          <Grid item xs={12} justifyContent="flex-end" container>
            <Button kind="primary" onClick={() => handleSubmit(formData, setFormErrors, proceedToTab)}>
              CONTINUE
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default Wholesaler;
