import { useState } from 'react';
import Styles from '../Offers.module.scss';
import { GlossaryDialog, Text1, Text2 } from '../common/Common';

export default function CornerstoneGlossary() {
  const [open, setOpen] = useState(false);

  return (
    <GlossaryDialog styles={Styles} open={open} setOpen={setOpen}>
      <p>
        This is a partial explanation of features and riders for the Cornerstone individual disability income policy.
        Additional state variations may apply. See policy for complete details.
      </p>
      <table className={Styles.offersTable}>
        <thead>
          <tr>
            <th>Policy Feature</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div>Noncancelable</div>
            </td>
            <td>
              <div>
                Until the policy becomes conditionally renewable (age 65 or 67), the policy cannot be canceled nor
                restrictions added, and premiums cannot be increased, as long as premiums are paid as due.
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <div>Guaranteed Renewable</div>
            </td>
            <td>
              <div>
                Until the policy becomes conditionally renewable (age 65 or 67), the policy cannot be canceled nor
                restrictions added, as long as premiums are paid as due. Rates may be changed by class.
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>Conditionally Renewable for Life</div>
            </td>
            <td>
              <div>
                If the Benefit Period is To Age 67, the policy conditionally renews at age 67; the policy renews at age
                65 for all other benefit periods. You may renew this policy on each policy anniversary for life after
                the noted age, if you are actively working at least 30 hours each week, you are not receiving a benefit
                under this policy or any attached rider, and the policy is in force with no premiums in default. (Not
                available in North Dakota).
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>Definition of Total Disability (Own Occupation for the length of the benefit period)</div>
            </td>
            <td>
              <div>{Text1()}.</div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                Definition of Total Disability (Own Occupation and Not Working for the length of the benefit period)
              </div>
            </td>
            <td>
              <div>
                {Text1()} and you are not working in any occupation for wage or profit. (Florida - not working
                requirement does not apply in first 12 months; California - any reasonable occupation applies for ages
                61+).
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                Definition of Total Disability (2 Year Own Occupation and then Any Reasonable Occupation thereafter)
              </div>
            </td>
            <td>
              <div>
                {Text1()}. However, after you have received 24 months of base monthly benefit due to the same
                disability, total disability or totally disabled means that, solely due to sickness or injury, you are
                not able to perform the material and substantial duties of any reasonable occupation based on your
                education, training and experience, and you are not working in any occupation for wage or profit.
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>Elimination Period</div>
            </td>
            <td>
              <div>The length of time you must be disabled before you can start receiving benefits.</div>
            </td>
          </tr>
          <tr>
            <td>
              <div>Benefit Period</div>
            </td>
            <td>
              <div>The maximum length of time for which a benefit may be paid.</div>
            </td>
          </tr>
          <tr>
            <td>
              <div>Enhanced Plus Residual Disability Rider</div>
            </td>
            <td>
              <div>
                {Text2()} During the elimination period, the 15% loss of income requirement will not apply. For the
                first six months, the minimum benefit will be 50% of the base monthly benefit. [California - residual
                (partial) disability coverage is included in the base policy].
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>Enhanced Residual Disability Rider</div>
            </td>
            <td>
              <div>
                A residual benefit may be paid if you suffer a loss of, at least, 20% of your net earned income, and you
                are able to perform one or more, but not all, of the material and substantial duties of your occupation
                or you are unable to engage in your occupation for more than 80% of the usual time. [California -
                residual (partial) disability coverage is included in the base policy].
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>Basic Residual Disability Rider</div>
            </td>
            <td>
              <div>
                A residual benefit may be paid if you suffer a loss of, at least, 20% of your net earned income, and you
                are able to perform one or more, but not all, of the material and substantial duties of your occupation
                or you are unable to engage in your occupation for more than 80% of the usual time. The maximum benefit
                will be 50% of the base monthly benefit. [California - residual (partial) disability coverage is
                included in the base policy].
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>Catastrophic Rider</div>
            </td>
            <td>
              <div>
                Pays an additional monthly benefit for each month you are catastrophically disabled (unable to perform
                two or more activities of daily living without stand-by assistance; or require substantial supervision
                due to severe cognitive impairment). Activities of daily living are: dressing, toileting, transferring,
                continence, eating and bathing. (California - not available)
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>Cost of Living Adjustment Rider (2 - 6% Compound)</div>
            </td>
            <td>
              <div>
                During a continuing disability, the base monthly benefit will be increased by the lesser of 6%
                compounded annually or the change in the Consumer Price Index (CPI-U), on each anniversary of the date
                of disability. In no event will the increase be less than 2% compounded annually.
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>Cost of Living Adjustment Rider (3% Compound)</div>
            </td>
            <td>
              <div>
                During a continuing disability, the base monthly benefit will be increased by the lesser of 3%
                compounded annually or the change in the Consumer Price Index (CPI-U), on each anniversary of the date
                of disability.
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>Future Increase Option</div>
            </td>
            <td>
              <div>Allows the purchase of additional coverage with only financial evidence of insurability.</div>
            </td>
          </tr>
          <tr>
            <td>
              <div>Benefit Increase Rider</div>
            </td>
            <td>
              <div>Allows the purchase of additional coverage with only financial evidence of insurability.</div>
            </td>
          </tr>
          <tr>
            <td>
              <div>Social Insurance Substitute Rider</div>
            </td>
            <td>
              <div>
                While you are totally disabled, an additional monthly benefit will be paid, reduced by any Social
                Insurance benefits being received by you.
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>Lump Sum Savings Rider</div>
            </td>
            <td>
              <div>
                Helps save for retirement during a disability when contributions may no longer be going into a
                retirement savings plan. If the rider is still in force at age 60, it pays a lump sum at age 60 equal to
                25% of qualifying monthly benefits paid over the life of the policy
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>Student Loan Repayment Rider</div>
            </td>
            <td>
              <div>
                Reimburses monthly payments for student loan debt during a disability, as long as the loan obligation
                remains, not to exceed the termination date of the rider.
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>Waiver of Premium</div>
            </td>
            <td>
              <div>Premiums will be waived during an approved disability.</div>
            </td>
          </tr>
          <tr>
            <td>
              <div>Survivor Benefit</div>
            </td>
            <td>
              <div>
                If you die while monthly disability benefits are being paid, an additional three months of base monthly
                benefit will be paid to your designated beneficiary or estate.
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>Presumptive Total Disability</div>
            </td>
            <td>
              <div>
                Total disability benefits will be paid, while you sustain the complete loss of hearing in both ears,
                sight of both eyes, speech, or the use of any two limbs. The loss does not need to be permanent or
                irrecoverable.
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>Exceptions and Limitations</div>
            </td>
            <td>
              <div>
                Benefits will not be paid for a disability: (a) caused by war or incident of war, or from active
                military service, (b) resulting from an intentionally self-inflicted injury, (c) during any period of
                incarceration, (d) resulting from suspension/termination of a professional or occupational license,
                except as a direct result of total disability, (e) before 90 days for normal pregnancy or childbirth,
                (f) for more than 12 months during the lifetime of the policy, if you reside outside of the United
                States.
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </GlossaryDialog>
  );
}
