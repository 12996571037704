import { SET_ENROLLMENTS, SET_ENROLLMENTS_LOADING } from 'state/actions/dashboard';

const initialState = {
  enrollments: [],
  isSetEnrollmentsLoading: false,
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ENROLLMENTS:
      return {
        ...state,
        enrollments: action.payload,
      };
    case SET_ENROLLMENTS_LOADING:
      return {
        ...state,
        isSetEnrollmentsLoading: action.payload,
      };
    default:
      return state;
  }
};

export default dashboardReducer;
