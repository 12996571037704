import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { AntTab, AntTabs, TabPanel } from 'utils/utils';
import Introduction from '../introduction/Introduction';
import DizTabContent from '../needForDIZ/DizTabContent';
import Offers from '../offers/Offers';

function NavigationTabs({ setAgentData }) {
  const [currentTab, setCurrentTab] = useState(0);
  const [data, setData] = useState(0);
  const [isDisabled, setIsDisabled] = useState(true);

  const showNonIntroductionTabs = !(data?.alreadySigned || data?.applicationCompleted);

  const handleChange = (_event, newValue) => {
    setCurrentTab(newValue);
  };

  const OffersContinue = (a) => {
    setCurrentTab(a);
    setIsDisabled(false);
  };

  React.useEffect(() => {
    setAgentData(data);
  }, [data]);

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <AntTabs value={currentTab} onChange={handleChange} aria-label="applicant dashboard tabs">
          <AntTab label="Introduction" tabIndex={0} />
          {showNonIntroductionTabs && <AntTab label="The Need for DI" tabIndex={0} />}
          {showNonIntroductionTabs && <AntTab label="Offer" disabled={isDisabled} tabIndex={0} />}
        </AntTabs>
      </Box>
      <TabPanel value={currentTab} index={0}>
        <Introduction setData={setData} setCurrentTab={setCurrentTab} />
      </TabPanel>
      <TabPanel value={currentTab} index={1}>
        <DizTabContent Introduction={data} setCurrentTab={OffersContinue} />
      </TabPanel>
      <TabPanel value={currentTab} index={2}>
        <Offers Introduction={data} setData={setData} setCurrentTab={setCurrentTab} />
      </TabPanel>
    </>
  );
}

export default NavigationTabs;
