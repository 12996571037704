import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import { formCodes } from 'constants/index';
import { AntTab, AntTabs, TabPanel } from 'utils/utils';
import Applicant from '../applicant/Applicant';
import ElectronicTransactions from '../../electronicTransactions/ElectronicTransactions';
import Submission from '../../submission/Submission';
import ExistingDisabilityInsurance from '../../existingDisabilityInsurance/ExistingDisabilityInsurance';
import Summary from '../summary/Summary';

function NavigationTabs() {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const { applicationId, formCode } = useSelector((state) => state.applicantForm.data || {});
  const [height, setHeight] = useState(500);
  const [loading, setLoading] = useState(true);

  const isACornerstoneForm = [formCodes.CORNERSTONE, formCodes.COMBINED_CORNERSTONE].includes(formCode);
  const electronicTransactionsTabIndex = isACornerstoneForm ? 2 : 1;
  const summaryTabIndex = isACornerstoneForm ? 3 : 2;
  const submissionTabIndex = isACornerstoneForm ? 4 : 3;

  const handleChange = (_event, newValue) => {
    setSelectedTabIndex(newValue);
  };

  useEffect(() => {
    setHeight(document.body.scrollHeight);
    if (applicationId) {
      setLoading(false);
    }
  }, [applicationId]);

  return (
    <>
      {loading && (
        <div className="globalloader" style={{ height: `${height}px` }}>
          <div className="loadertext">Please wait ...</div>
        </div>
      )}
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <AntTabs
          value={selectedTabIndex}
          onChange={handleChange}
          aria-label="increases enrollment tabs"
          variant="scrollable"
          scrollButtons="auto"
          className="nav12"
        >
          <AntTab label="Applicant" tabIndex={0} />
          {isACornerstoneForm && (
            <AntTab label="Existing Disability Insurance" disabled={selectedTabIndex < 1} tabIndex={0} />
          )}
          <AntTab
            label="Electronic Transactions and Delivery of Documents"
            disabled={selectedTabIndex < electronicTransactionsTabIndex}
            tabIndex={0}
          />
          <AntTab label="Summary" disabled={selectedTabIndex < summaryTabIndex} tabIndex={0} />
          <AntTab label="Submission" disabled={selectedTabIndex < submissionTabIndex} tabIndex={0} />
        </AntTabs>
      </Box>
      <TabPanel value={selectedTabIndex} index={0}>
        <Applicant setSelectedTabIndex={setSelectedTabIndex} />
      </TabPanel>
      {isACornerstoneForm && (
        <TabPanel value={selectedTabIndex} index={1}>
          <ExistingDisabilityInsurance
            setSelectedTabIndex={() => setSelectedTabIndex(selectedTabIndex + 1)}
            isIncreases
          />
        </TabPanel>
      )}
      <TabPanel value={selectedTabIndex} index={electronicTransactionsTabIndex}>
        <ElectronicTransactions
          isPrimary={false}
          setSelectedTabIndex={() => setSelectedTabIndex(selectedTabIndex + 1)}
        />
      </TabPanel>
      <TabPanel value={selectedTabIndex} index={summaryTabIndex}>
        <Summary setSelectedTabIndex={() => setSelectedTabIndex(selectedTabIndex + 1)} />
      </TabPanel>
      <TabPanel value={selectedTabIndex} index={submissionTabIndex}>
        <Submission />
      </TabPanel>
    </>
  );
}

export default NavigationTabs;
