import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useSelector } from 'react-redux';
import { formatPhoneNumber } from 'utils/utils';
import styles from './agentinfo.module.scss';

function AgentInformation() {
  const { data } = useSelector((state) => state.applicantForm);

  return (
    <Card>
      <CardContent>
        <div className={styles.content}>
          <h4>Agent Information</h4>
          <h3>{data?.agent?.name}</h3>
          <p>
            {data?.agent?.email} <br />
            {formatPhoneNumber(data?.agent?.phone)}
          </p>
        </div>
        {!data?.hideWholesaler && (
          <div className={styles.content}>
            <h4>Wholesaler Information</h4>
            <h3>{data?.wholesaler?.name}</h3>
            <p>
              {data?.wholesaler?.email} <br />
              {formatPhoneNumber(data?.wholesaler?.phone)}
            </p>
          </div>
        )}
        <div className={styles.content}>
          <h4>Enrollment Period</h4>
          <h3>&nbsp;</h3>
          <p> {data?.enrollmentPeriod} (11:00 PM CST)</p>
        </div>
      </CardContent>
    </Card>
  );
}

export default AgentInformation;
