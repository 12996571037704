import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ErrorBoundary } from 'react-error-boundary';
import createStore from './state/store/createStore';
import history from './history';
import BrowserService from './services/BrowserService';
import App from './app';
import ErrorFallback from './components/errorFallback';

import './assets/styles/index.css';

// test browser compatibility
BrowserService.checkBrowser();

const store = createStore();

const rootElement = document.getElementById('app');

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <App />
      </ErrorBoundary>
    </ConnectedRouter>
  </Provider>,
  rootElement,
);
