import { NO_LABEL, YES_LABEL, YES_OPTION, formCodes } from 'constants/index';
import { formatDate, formatPhoneNumber, formatSSN, getApplicantAddressSummary } from 'utils/utils';

import mainStyles from '../Summary.module.scss';

const getFundamentalQuestions = ({ formCode, input }) => {
  if (![formCodes.FUNDAMENTAL, formCodes.COMBINED].includes(formCode)) {
    return null;
  }
  return (
    <>
      <tr>
        <td>Beneficiary (in the event benefits are payable after death)</td>
        <td>{input.beneficiary}</td>
      </tr>
      <tr>
        <td>Relationship of Beneficiary to Proposed Insured</td>
        <td>{input.beneficiaryRelationship}</td>
      </tr>
    </>
  );
};

const ApplicantSummary = ({ formCode, input = { ssn: '' } }) => {
  const isCornerstoneFormIncluded = [formCodes.CORNERSTONE, formCodes.COMBINED_CORNERSTONE].includes(formCode);
  return (
    <>
      <p className={mainStyles.sectionHeader}>
        <strong>Applicant</strong>
      </p>
      <table className={mainStyles.summaryTable}>
        <tbody>
          <tr>
            <td>First Name</td>
            <td>{input.firstName}</td>
          </tr>
          {input.middleInitial && (
            <tr>
              <td>Middle Name</td>
              <td>{input.middleInitial}</td>
            </tr>
          )}
          <tr>
            <td>Last Name</td>
            <td>{input.lastName}</td>
          </tr>
          {input.suffix && (
            <tr>
              <td>Suffix</td>
              <td>{input.suffix}</td>
            </tr>
          )}
          <tr>
            <td>Gender</td>
            <td>{input.gender === 'M' ? 'Male' : 'Female'}</td>
          </tr>
          {isCornerstoneFormIncluded && (
            <tr>
              <td>Email</td>
              <td>{input.email}</td>
            </tr>
          )}
          {getApplicantAddressSummary(input)}
          {input.phone && (
            <tr>
              <td>Phone</td>
              <td>{formatPhoneNumber(input.phone)}</td>
            </tr>
          )}
          <tr>
            <td>Social Security Number</td>
            <td>{formatSSN(input.ssn)}</td>
          </tr>
          <tr>
            <td>{isCornerstoneFormIncluded ? 'State of Birth (or Country if other than USA)' : 'Place of Birth'}</td>
            <td>{input.birthPlace}</td>
          </tr>
          <tr>
            <td>Date of Birth</td>
            <td>{formatDate(new Date(input.dob))}</td>
          </tr>
          <tr>
            <td>Are you a U.S. citizen or permanent resident?</td>
            <td>{input.usCitizen === YES_OPTION ? YES_LABEL : NO_LABEL}</td>
          </tr>
          {input.citizenCountry && (
            <tr>
              <td>Citizenship</td>
              <td>{input.citizenCountry}</td>
            </tr>
          )}
          {input.visaType && (
            <tr>
              <td>Visa Type</td>
              <td>{input.visaType}</td>
            </tr>
          )}
          {getFundamentalQuestions({ formCode, input })}
        </tbody>
      </table>
    </>
  );
};

export default ApplicantSummary;
