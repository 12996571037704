import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';
import { Button, Select, TextArea } from 'voltron';
import { YES_OPTION, formCodes, yesNoOptionList } from 'constants/index';

import { pageLoaded, pageLoading } from 'state/actions/page_loading';
import styles from './HealthInfo.module.scss';
import { saveAndRefreshData } from '../../existingDisabilityInsurance/formUtils';

const FORM_FIELD_NAMES = {
  useTobacco: 'useTobacco',
  useTobaccoForCornerstone: 'useTobaccoForCornerStone',
  healthComments: 'healthComments',
  healthCommentsFoundation: 'healthCommentsFoundation',
  healthCommentsFundamental: 'healthCommentsFundamental',
  healthCommentsCornerstone: 'healthCommentsCornerStone',
  healthCommentsCornerstoneForTobacco: 'healthCommentsCornerStoneForTobaco',
};

const HEALTH_COMMENTS_LABEL = 'If the answer to any question is "Yes," please provide details in the space below.';
const HEALTH_COMMENTS_CORNERSTONE_LABEL = `If the answer to any question a) through d) above is "Yes",
  please provide details (description of disorder/diagnosis, duration, dates, treatment, etc.) in the space below.`;
const COMMENTS_PLACEHOLDER = 'Explanation';

const labelPosition = 'top';
const labelKind = 'regular';
const textareaStyle = {
  resize: 'vertical',
};

const setHealthCommentsFoundationInvalid = (isMultipleProducts, products, inputData, invalidFormElements) => {
  const healthCommentsForFoundationFieldName = isMultipleProducts
    ? FORM_FIELD_NAMES.healthCommentsFoundation
    : FORM_FIELD_NAMES.healthComments;
  if (
    products.some((product) => product === formCodes.FOUNDATION) &&
    inputData[FORM_FIELD_NAMES.useTobacco] === YES_OPTION &&
    !inputData[healthCommentsForFoundationFieldName]?.trim()
  ) {
    invalidFormElements.push(healthCommentsForFoundationFieldName);
  }
};

const setInvalidHealthComments = (products, healthQuestions, invalidFormElements, inputData) => {
  const healthCommentsFieldName = FORM_FIELD_NAMES.healthComments;
  const isMultipleProducts = products.length > 1;

  const isAnyStateProductQuestionAnsweredYes = (productType) =>
    healthQuestions.some(
      (question) => question.productType === productType && inputData[question.fieldName] === YES_OPTION,
    );

  if (isMultipleProducts) {
    const healthCommentsFieldName1 = `${healthCommentsFieldName}${products[0]}`;
    const healthCommentsFieldName2 = `${healthCommentsFieldName}${products[1]}`;

    if (isAnyStateProductQuestionAnsweredYes(products[0]) && !inputData[healthCommentsFieldName1]?.trim()) {
      invalidFormElements.push(healthCommentsFieldName1);
    }
    if (isAnyStateProductQuestionAnsweredYes(products[1]) && !inputData[healthCommentsFieldName2]?.trim()) {
      invalidFormElements.push(healthCommentsFieldName2);
    }
  } else if (isAnyStateProductQuestionAnsweredYes(products[0]) && !inputData[healthCommentsFieldName]?.trim()) {
    invalidFormElements.push(healthCommentsFieldName);
  }

  setHealthCommentsFoundationInvalid(isMultipleProducts, products, inputData, invalidFormElements);
};

const setHealthQuestionsInvalid = (healthQuestions, inputData, invalidFormElements) => {
  healthQuestions.forEach((healthQuestion) => {
    if (!inputData[healthQuestion.fieldName]) {
      invalidFormElements.push(healthQuestion.fieldName);
    }
  });
};

const getInvalidFormElements = (formCode, inputData, healthQuestions) => {
  const invalidFormElements = [];
  let products = [formCode];
  if (formCode === formCodes.COMBINED) {
    products = [formCodes.FOUNDATION, formCodes.FUNDAMENTAL];
  } else if (formCode === formCodes.COMBINED_CORNERSTONE) {
    products = [formCodes.CORNERSTONE, formCodes.FUNDAMENTAL];
  }
  setInvalidHealthComments(products, healthQuestions, invalidFormElements, inputData);
  setHealthQuestionsInvalid(healthQuestions, inputData, invalidFormElements);

  if (products.some((product) => product === formCodes.FOUNDATION) && !inputData[FORM_FIELD_NAMES.useTobacco]) {
    invalidFormElements.push(FORM_FIELD_NAMES.useTobacco);
  }

  if (
    products.some((product) => product === formCodes.CORNERSTONE) &&
    !inputData[FORM_FIELD_NAMES.useTobaccoForCornerstone]
  ) {
    invalidFormElements.push(FORM_FIELD_NAMES.useTobaccoForCornerstone);
  } else if (
    products.some((product) => product === formCodes.CORNERSTONE) &&
    inputData[FORM_FIELD_NAMES.useTobaccoForCornerstone] === YES_OPTION &&
    !inputData[FORM_FIELD_NAMES.healthCommentsCornerstoneForTobacco]?.trim()
  ) {
    invalidFormElements.push(FORM_FIELD_NAMES.healthCommentsCornerstoneForTobacco);
  }

  return invalidFormElements;
};

const getStateProductQuestions = ({
  product,
  stateProductQuestions,
  formInputData,
  invalidInputs,
  isOnSubmit,
  onInputChange,
}) =>
  stateProductQuestions.map((question) => {
    const questionId = `health-question-${question.id}-${product}`;
    return (
      <Select
        key={questionId}
        label={question.label}
        labelKind={labelKind}
        labelPosition={labelPosition}
        name={questionId}
        options={yesNoOptionList}
        clearable={false}
        searchable={false}
        material={false}
        required
        forceErrorMessage={isOnSubmit && invalidInputs.includes(question.fieldName)}
        selectedValue={yesNoOptionList.find((option) => option.value === formInputData[question.fieldName])}
        onChange={(e) => onInputChange(question.fieldName, e.value)}
      />
    );
  });

const getFoundationQuestions = ({
  stateProductQuestions,
  formInputData,
  onInputChange,
  invalidInputs,
  isOnSubmit,
  isCombinedWithAnotherProduct = false,
}) => {
  const { useTobacco, healthComments, healthCommentsFoundation } = formInputData;
  const healthCommentsFieldName = isCombinedWithAnotherProduct
    ? FORM_FIELD_NAMES.healthCommentsFoundation
    : FORM_FIELD_NAMES.healthComments;
  const isAnyStateProductQuestionAnsweredYes = stateProductQuestions.some(
    (question) => formInputData[question.fieldName] === YES_OPTION,
  );
  const isHealthCommentsRequired = useTobacco === YES_OPTION || isAnyStateProductQuestionAnsweredYes;
  return (
    <div>
      {isCombinedWithAnotherProduct && (
        <p>
          <strong>For Foundation Application</strong>
        </p>
      )}
      <Select
        label="Have you used any tobacco or nicotine products (including patches, gum, etc.) in the past 12 months?"
        labelKind={labelKind}
        labelPosition={labelPosition}
        name="use-tobacco-foundation"
        options={yesNoOptionList}
        clearable={false}
        searchable={false}
        material={false}
        required
        forceErrorMessage={isOnSubmit && invalidInputs.includes(FORM_FIELD_NAMES.useTobacco)}
        selectedValue={yesNoOptionList.find((option) => option.value === useTobacco)}
        onChange={(e) => onInputChange(FORM_FIELD_NAMES.useTobacco, e.value)}
      />
      {getStateProductQuestions({
        product: stateProductQuestions[0]?.productType,
        stateProductQuestions,
        formInputData,
        invalidInputs,
        isOnSubmit,
        onInputChange,
      })}
      <TextArea
        groupLabel={HEALTH_COMMENTS_LABEL}
        groupLabelKind={labelKind}
        maxLength={500}
        placeholderText={COMMENTS_PLACEHOLDER}
        required={isHealthCommentsRequired}
        forceErrorMessage={isOnSubmit && invalidInputs.includes(healthCommentsFieldName)}
        defaultValue={isCombinedWithAnotherProduct ? healthCommentsFoundation : healthComments}
        textAreaProps={{
          'data-testid': 'health-comments-foundation',
          style: textareaStyle,
          onChange: (e) => onInputChange(healthCommentsFieldName, e.target.value),
        }}
        removeMargins
      />
      <br />
    </div>
  );
};

const getFundamentalQuestions = ({
  stateProductQuestions,
  formInputData,
  onInputChange,
  invalidInputs,
  isOnSubmit,
  isCombinedWithAnotherProduct = false,
}) => {
  const { healthComments, healthCommentsFundamental } = formInputData;
  const healthCommentsFieldName = isCombinedWithAnotherProduct
    ? FORM_FIELD_NAMES.healthCommentsFundamental
    : FORM_FIELD_NAMES.healthComments;
  const isAnyStateProductQuestionAnsweredYes = stateProductQuestions.some(
    (question) => formInputData[question.fieldName] === YES_OPTION,
  );
  return (
    <div>
      {isCombinedWithAnotherProduct && (
        <p>
          <strong>For Fundamental Application</strong>
        </p>
      )}
      {getStateProductQuestions({
        product: stateProductQuestions[0]?.productType,
        stateProductQuestions,
        formInputData,
        invalidInputs,
        isOnSubmit,
        onInputChange,
      })}
      <TextArea
        groupLabel={HEALTH_COMMENTS_LABEL}
        groupLabelKind={labelKind}
        maxLength={500}
        placeholderText={COMMENTS_PLACEHOLDER}
        required={isAnyStateProductQuestionAnsweredYes}
        forceErrorMessage={isOnSubmit && invalidInputs.includes(healthCommentsFieldName)}
        defaultValue={isCombinedWithAnotherProduct ? healthCommentsFundamental : healthComments}
        textAreaProps={{
          'data-testid': 'health-comments-fundamental',
          style: textareaStyle,
          onChange: (e) => onInputChange(healthCommentsFieldName, e.target.value),
        }}
        removeMargins
      />
      <br />
    </div>
  );
};

const getCornerstoneQuestions = ({
  stateProductQuestions,
  formInputData,
  onInputChange,
  invalidInputs,
  isOnSubmit,
  isCombinedWithAnotherProduct = false,
}) => {
  const { useTobaccoForCornerStone, healthCommentsCornerStoneForTobaco, healthComments, healthCommentsCornerStone } =
    formInputData;
  const healthCommentsFieldName = isCombinedWithAnotherProduct
    ? FORM_FIELD_NAMES.healthCommentsCornerstone
    : FORM_FIELD_NAMES.healthComments;
  const isUseTobaccoRequired = useTobaccoForCornerStone === YES_OPTION;
  const isAnyStateProductQuestionAnsweredYes = stateProductQuestions.some(
    (question) => formInputData[question.fieldName] === YES_OPTION,
  );

  return (
    <div>
      {isCombinedWithAnotherProduct && (
        <p>
          <strong>For Cornerstone Application</strong>
        </p>
      )}
      <Select
        label={`In the past 12 months, have you used any tobacco or nicotine products, and/or nicotine delivery systems
        (such as cigarettes, cigars, cigarillos, vape, e-cigarettes, pipe, chewing tobacco, nicotine patches,
        nicotine gum)?`}
        labelPosition="top"
        labelKind={labelKind}
        name="use-tobacco-cornerstone"
        options={yesNoOptionList}
        clearable={false}
        searchable={false}
        material={false}
        required
        forceErrorMessage={isOnSubmit && invalidInputs.includes(FORM_FIELD_NAMES.useTobaccoForCornerstone)}
        selectedValue={yesNoOptionList.find((option) => option.value === useTobaccoForCornerStone)}
        onChange={(e) => onInputChange(FORM_FIELD_NAMES.useTobaccoForCornerstone, e.value)}
      />
      <TextArea
        groupLabel='If "Yes," list type and frequency in the space below.'
        groupLabelKind={labelKind}
        maxLength={500}
        placeholderText={COMMENTS_PLACEHOLDER}
        required={isUseTobaccoRequired}
        forceErrorMessage={
          isOnSubmit &&
          isUseTobaccoRequired &&
          invalidInputs.includes(FORM_FIELD_NAMES.healthCommentsCornerstoneForTobacco)
        }
        defaultValue={healthCommentsCornerStoneForTobaco}
        textAreaProps={{
          'data-testid': 'health-comments-use-tobacco-cornerstone',
          style: textareaStyle,
          onChange: (e) => onInputChange(FORM_FIELD_NAMES.healthCommentsCornerstoneForTobacco, e.target.value),
        }}
        removeMargins
      />
      <br />
      {getStateProductQuestions({
        product: stateProductQuestions[0]?.productType,
        stateProductQuestions,
        formInputData,
        invalidInputs,
        isOnSubmit,
        onInputChange,
      })}
      <TextArea
        groupLabel={HEALTH_COMMENTS_CORNERSTONE_LABEL}
        groupLabelKind={labelKind}
        maxLength={500}
        placeholderText={COMMENTS_PLACEHOLDER}
        required={isAnyStateProductQuestionAnsweredYes}
        forceErrorMessage={
          isOnSubmit && isAnyStateProductQuestionAnsweredYes && invalidInputs.includes(healthCommentsFieldName)
        }
        defaultValue={isCombinedWithAnotherProduct ? healthCommentsCornerStone : healthComments}
        textAreaProps={{
          'data-testid': 'health-comments-cornerstone',
          style: textareaStyle,
          onChange: (e) => onInputChange(healthCommentsFieldName, e.target.value),
        }}
        removeMargins
      />
      <br />
    </div>
  );
};

const produceForm = ({ formCode, healthQuestions, formInputData, onInputChange, invalidInputs, isOnSubmit }) => {
  switch (formCode) {
    case formCodes.FOUNDATION: {
      const foundationQuestions = healthQuestions.filter((question) => question.productType === formCodes.FOUNDATION);
      return (
        <>
          {getFoundationQuestions({
            formInputData,
            onInputChange,
            invalidInputs,
            isOnSubmit,
            stateProductQuestions: foundationQuestions,
          })}
        </>
      );
    }
    case formCodes.FUNDAMENTAL: {
      const fundamentalQuestions = healthQuestions.filter((question) => question.productType === formCodes.FUNDAMENTAL);
      return (
        <>
          {getFundamentalQuestions({
            formInputData,
            onInputChange,
            invalidInputs,
            isOnSubmit,
            stateProductQuestions: fundamentalQuestions,
          })}
        </>
      );
    }
    case formCodes.CORNERSTONE: {
      const cornerstoneQuestions = healthQuestions.filter((question) => question.productType === formCodes.CORNERSTONE);
      return (
        <>
          {getCornerstoneQuestions({
            formInputData,
            onInputChange,
            invalidInputs,
            isOnSubmit,
            stateProductQuestions: cornerstoneQuestions,
          })}
        </>
      );
    }
    case formCodes.COMBINED: {
      const foundationQuestions = healthQuestions.filter((question) => question.productType === formCodes.FOUNDATION);
      const fundamentalQuestions = healthQuestions.filter((question) => question.productType === formCodes.FUNDAMENTAL);
      return (
        <>
          {getFoundationQuestions({
            formInputData,
            onInputChange,
            invalidInputs,
            isOnSubmit,
            stateProductQuestions: foundationQuestions,
            isCombinedWithAnotherProduct: true,
          })}
          {getFundamentalQuestions({
            formInputData,
            onInputChange,
            invalidInputs,
            isOnSubmit,
            stateProductQuestions: fundamentalQuestions,
            isCombinedWithAnotherProduct: true,
          })}
        </>
      );
    }
    case formCodes.COMBINED_CORNERSTONE: {
      const cornerstoneQuestions = healthQuestions.filter((question) => question.productType === formCodes.CORNERSTONE);
      const fundamentalQuestions = healthQuestions.filter((question) => question.productType === formCodes.FUNDAMENTAL);
      return (
        <>
          {getCornerstoneQuestions({
            formInputData,
            onInputChange,
            invalidInputs,
            isOnSubmit,
            stateProductQuestions: cornerstoneQuestions,
            isCombinedWithAnotherProduct: true,
          })}
          {getFundamentalQuestions({
            formInputData,
            onInputChange,
            invalidInputs,
            isOnSubmit,
            stateProductQuestions: fundamentalQuestions,
            isCombinedWithAnotherProduct: true,
          })}
        </>
      );
    }
    default:
      return null;
  }
};

const HealthInfo = ({ setSelectedTabIndex }) => {
  const { oktaAuth } = useOktaAuth();
  const {
    data: { applicationId, formCode, input: existingInputData, healthQuestions },
  } = useSelector((state) => state.applicantForm);
  const dispatch = useDispatch();
  const [formInputData, setFormInputData] = useState(existingInputData);
  const [invalidInputs, setInvalidInputs] = useState([]);
  const [isOnSubmit, setIsOnSubmit] = useState(false);

  useEffect(() => {
    dispatch(applicationId > 0 ? pageLoaded() : pageLoading());
  }, [applicationId]);

  useEffect(() => {
    setFormInputData(existingInputData);
  }, [existingInputData]);

  useEffect(() => {
    const invalidFormElements = getInvalidFormElements(formCode, formInputData, healthQuestions);
    setInvalidInputs(invalidFormElements);
  }, [formCode, formInputData, healthQuestions]);

  const onInputChange = (inputName, value) => {
    setFormInputData((formData) => ({
      ...formData,
      [inputName]: value,
    }));
  };

  const onSubmit = async () => {
    setIsOnSubmit(true);
    if (invalidInputs.length) {
      return;
    }

    const formData = {
      userFormId: applicationId,
      input: formInputData,
    };
    const accessToken = oktaAuth.getAccessToken();
    await saveAndRefreshData({ accessToken, formData, applicationId, dispatch, setSelectedTabIndex, tabIndex: 4 });
  };

  return (
    <div className={styles.healthInfoForm}>
      <header>
        <h3>Health Information</h3>
      </header>
      <form autoComplete="off">
        <div className={styles.formBody}>
          {produceForm({
            formCode,
            healthQuestions,
            formInputData,
            onInputChange,
            invalidInputs,
            isOnSubmit,
          })}
        </div>
        <div className={styles.submitButton}>
          <Button onClick={onSubmit}>SAVE & CONTINUE</Button>
        </div>
      </form>
    </div>
  );
};

export default HealthInfo;
