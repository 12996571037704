import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { useOktaAuth } from '@okta/okta-react';
import { withTransaction } from '@elastic/apm-rum-react';
import {
  Box,
  CardContent,
  Card,
  Container,
  Grid,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { Input, Select, CheckBox, Button } from 'voltron';

import { validateEmail, validatePhone, validateDOB, validateZip } from 'utils/validator';
import { statesData } from 'constants/states';
import { DATE_FORMAT } from 'constants';
import {
  getUserFormData,
  submitUserFormData,
  UPDATE_USER_FORM_DATA,
  CLEAR_USER_FORM_DATA,
} from '../../../state/actions/nonApplicantUsers';
import style from './UserForm.module.scss';

const labelKind = 'regular';

const UserForm = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const { oktaAuth } = useOktaAuth();
  const history = useHistory();
  const { owningCompanies = [] } = useSelector((state) => state.adminEntities);
  const {
    updateUserInProgress,
    formData,
    userRoles = [],
    employers = [],
    agencies = [],
  } = useSelector((state) => state.nonApplicantUsers);
  const [phoneNumberEdit, setPhoneNumberEdit] = useState();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formError, setFormError] = useState({});
  const [employersList, setEmployersList] = useState([]);
  const [agencyList, setAgencyList] = useState([]);
  const isNewUser = params.id === '-1';
  const userRoleList = userRoles.map((role) => ({
    label: role.name,
    value: role.name,
  }));
  const owningCompanyList = owningCompanies.map((company) => ({
    label: company.name,
    value: company.id,
  }));

  const validateForm = () => {
    const errors = {};
    if (!formData?.firstName) {
      errors.firstName = 'firstName';
    }
    if (!formData?.lastName) {
      errors.lastName = 'lastName';
    }
    if (!validateDOB(formData?.dob)) {
      errors.dob = 'dob';
    }
    if (!validateEmail(formData?.email)) {
      errors.email = 'email';
    }
    if (formData?.zipCode && !validateZip(formData?.zipCode)) {
      errors.zipCode = 'zipCode';
    }
    if (!validatePhone(formData?.phone)) {
      errors.phone = 'phone';
    }
    if (formData?.altPhone && !validatePhone(formData?.altPhone)) {
      errors.altPhone = 'altPhone';
    }
    if (!formData.userRole) {
      errors.userRole = 'userRole';
    }
    if (formData.userRole === 'Wholesaler' && !formData.region) {
      errors.region = 'region';
    }
    if (formData.userRole === 'Owning Company User' && !formData.owningCompanyId) {
      errors.owningCompanyId = 'owningCompanyId';
    }
    if (
      (formData.userRole === 'Agent' && !agencyList.filter((agent) => agent.isSelected).length) ||
      (formData.userRole === 'HR (Employer)' && !employersList.filter((employer) => employer.isSelected).length)
    ) {
      errors.invalidListItem = 'invalidListItem';
    }
    return errors;
  };

  const handleInputChange = (name, value) => {
    setFormError({
      ...formError,
      [name]: value === '' && name,
    });
    dispatch({
      type: UPDATE_USER_FORM_DATA,
      payload: {
        formData: {
          ...formData,
          [name]: value,
        },
      },
    });
  };

  const checkValidView = (e, id) => {
    if (formData.userRole === 'HR (Employer)') {
      const tempEmpList = [...employersList].map((emp) => {
        const employer = { ...emp };
        if (+employer.id === +id) {
          employer.isSelected = e.target.checked;
        }
        return employer;
      });
      setEmployersList(tempEmpList);
    }
    if (formData.userRole === 'Agent') {
      const tempAgencyList = [...agencyList].map((agt) => {
        const agent = { ...agt };
        if (+agent.id === +id) {
          agent.isSelected = e.target.checked;
        }
        return agent;
      });
      setAgencyList(tempAgencyList);
    }
  };

  const handleSave = () => {
    setFormSubmitted(true);
    const errors = validateForm();
    setFormError(errors);
    if (Object.keys(errors).length === 0) {
      const accessToken = oktaAuth.getAccessToken();
      const data = { ...formData };
      data.agencies = agencyList.map((agency) => ({
        id: agency.id,
        name: agency.name,
        isSelected: !!agency.isSelected,
      }));
      data.employers = employersList.map((employer) => ({
        id: employer.id,
        name: employer.name,
        isSelected: !!employer.isSelected,
      }));
      data.id = isNewUser ? 0 : params.id;
      dispatch(submitUserFormData(accessToken, { ...data }, history));
    }
  };

  const handleCancel = () => {
    dispatch({
      type: CLEAR_USER_FORM_DATA,
    });
    history.push('/users');
  };

  useEffect(() => {
    const accessToken = oktaAuth.getAccessToken();
    dispatch(getUserFormData(accessToken, params.id));
  }, []);

  useEffect(() => {
    if (updateUserInProgress && !phoneNumberEdit) {
      setPhoneNumberEdit(true);
    }
  }, [updateUserInProgress]);

  useEffect(() => {
    let getPhoneTimeout;
    if (!isNewUser && phoneNumberEdit) {
      // need to prevent Voltron from treating potential valid phone values as invalid initially
      getPhoneTimeout = setTimeout(() => {
        const phoneInput = document.querySelectorAll('[data-testid="user-phone"]')[0];
        const altPhoneInput = document.querySelectorAll('[data-testid="user-altPhone"]')[0];
        phoneInput.focus();
        phoneInput.blur();
        altPhoneInput.focus();
        altPhoneInput.blur();
      }, 500);
    }
    return () => clearTimeout(getPhoneTimeout);
  }, [isNewUser, phoneNumberEdit]);

  useEffect(() => {
    if (agencyList.length !== agencies.length) {
      setAgencyList([...agencies]);
    }
  }, [agencies]);

  useEffect(() => {
    if (employersList.length !== employers.length) {
      setEmployersList([...employers]);
    }
  }, [employers]);

  return (
    <Box sx={{ width: '100%' }}>
      <Container maxWidth="xl" className={style.container}>
        {!updateUserInProgress && (
          <div className="globalloader" style={{ height: `${document.body.scrollHeight}px` }}>
            <div className="loadertext">Please wait ...</div>
          </div>
        )}
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Card className={style.card}>
              <CardContent>
                <Grid container spacing={0} className="innerHeader">
                  <Grid item xs={12}>
                    <h3>User Information</h3>
                  </Grid>
                  <Grid item xs={12}>
                    <p>Create or Edit a Non-Applicant User</p>
                  </Grid>
                </Grid>
                <form className={style.userForm}>
                  <Grid container spacing={2}>
                    <Grid item xs={9}>
                      <Input
                        name="firstName"
                        label="First Name"
                        labelKind={labelKind}
                        id="firstName"
                        placeholder="First Name"
                        material={false}
                        autoComplete={false}
                        onChange={(e) => handleInputChange('firstName', e.target.value)}
                        value={formData?.firstName || ''}
                        data-testid="user-firstname"
                        required
                        kind="name"
                        forceErrorMessage={formSubmitted && formError.firstName}
                        maxLength={40}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Input
                        name="middleInitial"
                        label="Middle Name"
                        labelKind={labelKind}
                        id="middleInitial"
                        placeholder="Middle Name"
                        material={false}
                        autoComplete={false}
                        onChange={(e) => handleInputChange('middleInitial', e.target.value)}
                        value={formData?.middleInitial || ''}
                        data-testid="user-middleInitial"
                        kind="name"
                        maxLength={25}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={9}>
                      <Input
                        name="lastName"
                        id="lastName"
                        label="Last Name"
                        labelKind={labelKind}
                        placeholder="Last Name"
                        material={false}
                        autoComplete={false}
                        onChange={(e) => handleInputChange('lastName', e.target.value)}
                        value={formData?.lastName || ''}
                        data-testid="user-lastName"
                        required
                        kind="name"
                        forceErrorMessage={formSubmitted && formError.lastName}
                        maxLength={40}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Input
                        name="suffix"
                        id="suffix"
                        label="Suffix"
                        labelKind={labelKind}
                        placeholder="Suffix"
                        material={false}
                        autoComplete={false}
                        onChange={(e) => handleInputChange('suffix', e.target.value)}
                        value={formData?.suffix || ''}
                        data-testid="applicant-suffix"
                        kind="name"
                        maxLength={40}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs>
                      <Input
                        kind="date"
                        id="dob"
                        labelKind={labelKind}
                        label="Date of Birth"
                        data-testid="user-dob"
                        name="dob"
                        material={false}
                        autoComplete={false}
                        placeholder={DATE_FORMAT}
                        value={formData.dob || ''}
                        onChange={(e) => handleInputChange('dob', e.target.value)}
                        required
                        forceErrorMessage={formSubmitted && formError.dob}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs>
                      <Input
                        name="email"
                        id="email"
                        label="Email Address"
                        labelKind={labelKind}
                        placeholder="Email"
                        material={false}
                        autoComplete={false}
                        onChange={(e) => handleInputChange('email', e.target.value)}
                        value={formData?.email || ''}
                        data-testid="user-email"
                        required
                        kind="email"
                        forceErrorMessage={formSubmitted && formError.email}
                        maxLength={50}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Input
                        name="address"
                        id="address"
                        label="Address"
                        labelKind={labelKind}
                        placeholder="Address"
                        material={false}
                        autoComplete={false}
                        onChange={(e) => handleInputChange('address', e.target.value)}
                        value={formData?.address || ''}
                        kind="text"
                        data-testid="user-address"
                        maxLength={40}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Input
                        name="city"
                        id="city"
                        label="City"
                        labelKind={labelKind}
                        kind="text"
                        placeholder="City"
                        material={false}
                        autoComplete={false}
                        onChange={(e) => handleInputChange('city', e.target.value)}
                        value={formData?.city || ''}
                        data-testid="user-city"
                        maxLength={30}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={8}>
                      <Select
                        label="State"
                        labelKind={labelKind}
                        id="stateCd"
                        labelPosition="top"
                        name="stateCd"
                        options={statesData}
                        clearable={false}
                        searchable={false}
                        material={false}
                        selectedValue={statesData.find((option) => option.label === formData.stateCd)}
                        onChange={(state) => handleInputChange('stateCd', state.label)}
                      />
                    </Grid>
                    <Grid item xs>
                      <Input
                        name="zipCode"
                        kind="zip"
                        id="zipCode"
                        label="Zip"
                        labelKind={labelKind}
                        placeholder="Zip"
                        material={false}
                        autoComplete={false}
                        onChange={(e) => handleInputChange('zipCode', e.target.value)}
                        maxLength={10}
                        value={formData?.zipCode || ''}
                        data-testid="user-zipcode"
                        forceErrorMessage={formSubmitted && formError.zipCode}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Input
                        name="phone"
                        id="phone"
                        label="Phone"
                        labelKind={labelKind}
                        placeholder="Phone"
                        material={false}
                        autoComplete={false}
                        onChange={(e) => handleInputChange('phone', e.target.value)}
                        value={formData?.phone || ''}
                        kind="phone"
                        required
                        data-testid="user-phone"
                        forceErrorMessage={formSubmitted && formError.phone}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Input
                        name="altPhone"
                        id="altPhone"
                        label="Alternate Phone"
                        labelKind={labelKind}
                        placeholder="Alternate Phone"
                        material={false}
                        autoComplete={false}
                        onChange={(e) => handleInputChange('altPhone', e.target.value)}
                        value={formData?.altPhone || ''}
                        kind="phone"
                        data-testid="user-altPhone"
                        forceErrorMessage={formSubmitted && formError.altPhone}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Input
                        name="usrName"
                        label="User Name"
                        labelKind={labelKind}
                        placeholder="User Name"
                        material={false}
                        autoComplete={false}
                        disabled
                        value={formData?.usrName || ''}
                        kind="text"
                        data-testid="user-usrName"
                      />
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Select
                        label="Select User Role"
                        labelPosition="top"
                        labelKind={labelKind}
                        name="userRole"
                        id="userRole"
                        options={userRoleList}
                        clearable={false}
                        searchable={false}
                        material={false}
                        disabled={!isNewUser}
                        selectedValue={userRoleList.find((option) => option.label === formData.userRole)}
                        required
                        onChange={(role) => handleInputChange('userRole', role.value)}
                        forceErrorMessage={formSubmitted && formError.userRole}
                      />
                    </Grid>
                  </Grid>
                  {formData.userRole === 'Agent' && (
                    <Grid container spacing={2} className={style.listContainer}>
                      <Grid item xs={12} className={style.listItem}>
                        <List>
                          {agencyList.map((agency, index) => (
                            <ListItem className={style.list} disablePadding key={`agency-${agency.id}`}>
                              <ListItemButton dense>
                                <ListItemText id={`${agency.id}-${agency.name}`} primary={agency.name} />
                                <CheckBox
                                  checked={agency.isSelected}
                                  onChange={(e) => checkValidView(e, agency.id)}
                                  inputProps={{
                                    'data-testid': `agency-checkbox-${index}`,
                                  }}
                                />
                              </ListItemButton>
                            </ListItem>
                          ))}
                        </List>
                      </Grid>
                    </Grid>
                  )}
                  {formData.userRole === 'HR (Employer)' && (
                    <Grid container spacing={2} className={style.listContainer}>
                      <Grid item xs={12} className={style.listItem}>
                        <List>
                          {employersList.map((employer, index) => (
                            <ListItem disablePadding className={style.list} key={`employer-${employer.id}`}>
                              <ListItemButton dense>
                                <ListItemText id={`${employer.id}-${employer.name}`} primary={employer.name} />
                                <CheckBox
                                  checked={employer.isSelected}
                                  onChange={(e) => checkValidView(e, employer.id)}
                                  inputProps={{
                                    'data-testid': `employer-checkbox-${index}`,
                                  }}
                                />
                              </ListItemButton>
                            </ListItem>
                          ))}
                        </List>
                      </Grid>
                    </Grid>
                  )}
                  {formData.userRole === 'Owning Company User' && (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Select
                          label="Select Owning Company"
                          labelPosition="top"
                          labelKind={labelKind}
                          id="owningCompanyId"
                          name="owningCompanyId"
                          options={owningCompanyList}
                          clearable={false}
                          searchable={false}
                          material={false}
                          selectedValue={owningCompanyList.find((option) => option.value === formData.owningCompanyId)}
                          required
                          onChange={(company) => {
                            handleInputChange('owningCompanyId', company.value);
                          }}
                          forceErrorMessage={formSubmitted && formError.owningCompanyId}
                        />
                      </Grid>
                    </Grid>
                  )}
                  {formData.userRole === 'Wholesaler' && (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Input
                          name="region"
                          id="region"
                          label="R Code"
                          labelKind={labelKind}
                          kind="text"
                          placeholder="R Code"
                          material={false}
                          autoComplete={false}
                          onChange={(e) => handleInputChange('region', e.target.value)}
                          value={formData?.region || ''}
                          data-testid="user-region"
                          forceErrorMessage={formSubmitted && formError.region}
                          maxLength={5}
                        />
                      </Grid>
                    </Grid>
                  )}
                  {formSubmitted && formError.invalidListItem && (
                    <div className={style.listError}>Please select a value</div>
                  )}
                  <br />
                  <Grid container spacing={2}>
                    <Grid item xs={12} justifyContent="flex-end" container>
                      <Button kind="invert" onClick={handleCancel} className={style.cancelBtn}>
                        CANCEL
                      </Button>
                      <Button kind="primary" onClick={handleSave}>
                        SAVE CHANGES
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default withTransaction('NonApplicantUserForm', 'component')(UserForm);
