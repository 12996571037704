import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import routes from 'constants/routes';
import { setCriticalError } from 'state/actions/error';
import { useDispatch } from 'react-redux';
import { formatAPIMessage } from 'utils/utils';
import { updateLastLoginDate } from 'services/Services';
import OktaSignInWidget from '../../components/Okta/OktaSignInWidget';

const Login = ({ config }) => {
  const { oktaAuth, authState } = useOktaAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    /*
      a hack to remove phone and Okta Verify options and verify with something else links from the appropriate screens:
      if Okta ever provides a way to customize these options, we can do that and remove the logic below
    */
    const observer = new MutationObserver(() => {
      const signInOptions = document.querySelectorAll('.authenticator-row');

      for (let i = 0; i < signInOptions.length; i++) {
        const signInOption = signInOptions[i];
        if (
          ['Phone', 'Get a push notification\n\nOkta Verify'].includes(signInOption.children[1].children[0].innerText)
        ) {
          signInOption.remove();
          break;
        }
      }

      if (
        ['Get a verification email', 'Verify with your email'].includes(
          document.querySelector('.okta-form-title')?.innerHTML,
        )
      ) {
        document.getElementsByClassName('js-switchAuthenticator')[0]?.remove();
      }
    });

    observer.observe(document.body, {
      subtree: true,
      childList: true,
    });
  }, []);

  const onSuccess = (tokens) => {
    updateLastLoginDate(tokens.accessToken.accessToken);
    oktaAuth.handleLoginRedirect(tokens);
  };

  const onError = (err) => {
    // occasional Okta error can just show in browser's dev tool
    if (!(err?.error === 'invalid_grant' && err?.error_description === 'PKCE verification failed.')) {
      dispatch(
        setCriticalError({
          title: 'Sign in error',
          body: formatAPIMessage(err),
          button: 'OK',
        }),
      );
    }
  };

  if (!authState) {
    return <div>Loading ... </div>;
  }

  return authState.isAuthenticated ? (
    <Redirect to={{ pathname: routes.home }} />
  ) : (
    <OktaSignInWidget config={config} onSuccess={onSuccess} onError={onError} />
  );
};

export default Login;
