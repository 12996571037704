import { getApplicantInfo, saveFormInputs } from 'services/Services';
import { updateApplicantData } from 'state/actions/applicantForm';
import { setCriticalError } from 'state/actions/error';
import { pageLoaded, pageLoading } from 'state/actions/page_loading';
import { formatAPIMessage } from 'utils/utils';

export const onInputDataChange = (
  inputData,
  formName,
  getInvalidFormElements,
  setInvalidInputs,
  setIsFormInvalidMap,
) => {
  const invalidFormElements = getInvalidFormElements(inputData);
  setInvalidInputs(invalidFormElements);
  setIsFormInvalidMap((map) => {
    map.set(formName, invalidFormElements.length > 0);
    return map;
  });
};

export const onInputChange = (inputName, value, getAdditionalFieldChanges, setInputData, setFormInputData) => {
  const dataToModify = {
    [inputName]: value,
    ...getAdditionalFieldChanges(inputName, value),
  };
  setInputData((data) => ({
    ...data,
    ...dataToModify,
  }));
  setFormInputData((formData) => ({
    ...formData,
    ...dataToModify,
  }));
};

export const saveAndRefreshData = async ({
  accessToken,
  formData,
  applicationId,
  dispatch,
  setSelectedTabIndex,
  tabIndex = null,
}) => {
  try {
    dispatch(pageLoading());
    await saveFormInputs(accessToken, formData);
    setSelectedTabIndex(tabIndex);
    const applicantInfoResponse = await getApplicantInfo(accessToken, applicationId);
    dispatch(updateApplicantData(applicantInfoResponse || null));
  } catch (error) {
    dispatch(
      setCriticalError({
        title: 'Save and update applicant form data',
        body: formatAPIMessage(error),
        button: 'OK',
      }),
    );
  } finally {
    dispatch(pageLoaded());
  }
};
