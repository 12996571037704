import { APPLICANT_DATA } from '../actions/applicantForm';

const initialReceipt = {
  data: null,
};

const applicantFormReducer = (state = initialReceipt, action) => {
  if (action.type === APPLICANT_DATA) {
    return {
      ...state,
      data: action.payload,
    };
  }
  return state;
};

export default applicantFormReducer;
