import { useState } from 'react';
import Styles from '../Offers.module.scss';
import { GlossaryDialog, Text, Text1, Text2 } from '../common/Common';

export default function FoundationGlossary() {
  const [open, setOpen] = useState(false);

  return (
    <GlossaryDialog styles={Styles} open={open} setOpen={setOpen}>
      <p>
        This is a partial explanation of features and riders for the DInamic Foundation individual disability income
        policy. Additional state variations may apply. See policy for complete details.
      </p>
      <table className={Styles.offersTable}>
        <thead>
          <tr>
            <th>Policy Feature</th>
            <th>Description</th>
          </tr>
        </thead>
        <tr>
          <td>
            <div>Noncancelable and Guaranteed Renewable to Age 65</div>
          </td>
          <td>
            <div>{Text()} and premiums cannot be increased, as long as premiums are paid as due.</div>
          </td>
        </tr>

        <tr>
          <td>
            <div>Guaranteed Renewable</div>
          </td>
          <td>
            <div>{Text()} as long as premiums are paid as due. Rates may be changed by class.</div>
          </td>
        </tr>
        <tr>
          <td>
            <div>Conditionally Renewable for Life</div>
          </td>
          <td>
            <div>
              You may renew this policy on each policy anniversary after age 65 for life, if you are actively working at
              least 30 hours each week, you are not receiving a benefit under this policy or any attached rider, and the
              policy is in force with no premiums in default. (Not available in North Dakota and Vermont).
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div>Definition of Total Disability (Own Occupation for the length of the benefit period)</div>
          </td>
          <td>
            <div>{Text1()}.</div>
          </td>
        </tr>
        <tr>
          <td>
            <div>
              Definition of Total Disability (Own Occupation and Not Working for the length of the benefit period)
            </div>
          </td>
          <td>
            <div>
              {Text1()} and you are not working in any occupation for wage or profit. (Florida - not working requirement
              does not apply in first 12 months; California - any reasonable occupation applies for ages 61+).
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div>
              Definition of Total Disability (5 Year Own Occupation and then Not Working for the remainder of the
              benefit period)
            </div>
          </td>
          <td>
            <div>
              {Text1()}. However, after you have received 60 months of base monthly benefit due to the same disability,
              you will only be considered totally disabled if you are also not working in any occupation for wage or
              profit.
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div>Elimination Period</div>
          </td>
          <td>
            <div>The length of time you must be disabled before you can start receiving benefits.</div>
          </td>
        </tr>
        <tr>
          <td>
            <div>Benefit Period</div>
          </td>
          <td>
            <div>The maximum length of time for which a benefit may be paid.</div>
          </td>
        </tr>
        <tr>
          <td>
            <div>Enhanced Residual Disability Rider</div>
          </td>
          <td>
            <div>{Text2()} [California - residual (partial) disability coverage is included in the base policy].</div>
          </td>
        </tr>
        <tr>
          <td>
            <div>Basic Residual Disability Rider</div>
          </td>
          <td>
            <div>
              {Text2()} The maximum benefit will be 50% of the base monthly benefit. [California - residual (partial)
              disability coverage is included in the base policy].
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div>Catastrophic Rider</div>
          </td>
          <td>
            <div>
              Pays an additional monthly benefit for each month you are catastrophically disabled (unable to perform two
              or more activities of daily living without stand-by assistance; or require substantial supervision due to
              severe cognitive impairment). Activities of daily living are: dressing, toileting, transferring,
              continence, eating and bathing. (California, Connecticut - not available; Georgia - Severe Impairment
              Rider; Texas - presumptive total disability replaces activities of daily living)
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div>Cost of Living Adjustment Rider (6% Compound)</div>
          </td>
          <td>
            <div>
              During a continuing disability, the base monthly benefit will be increased by the lesser of 6% compounded
              annually or the change in the Consumer Price Index (CPI-U), on each anniversary of the date of disability.
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div>Cost of Living Adjustment Rider (3% Simple)</div>
          </td>
          <td>
            <div>
              During a continuing disability, an additional 3% of the base monthly benefit will be paid on each
              anniversary of the date of disability.
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div>Future Increase Option</div>
          </td>
          <td>
            <div>Allows the purchase of additional coverage with only financial evidence of insurability.</div>
          </td>
        </tr>
        <tr>
          <td>
            <div>Social Insurance Substitute Rider</div>
          </td>
          <td>
            <div>
              While you are totally disabled, an additional monthly benefit will be paid, reduced by any Social
              Insurance benefits being received by you.
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div>Waiver of Premium</div>
          </td>
          <td>
            <div>Premiums will be waived during an approved disability.</div>
          </td>
        </tr>
        <tr>
          <td>
            <div>Survivor Benefit</div>
          </td>
          <td>
            <div>
              If you die while monthly disability benefits are being paid, an additional three months of base monthly
              benefit will be paid to your designated beneficiary or estate.
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div>Presumptive Total Disability</div>
          </td>
          <td>
            <div>
              Total disability benefits will be paid, while you sustain the complete loss of hearing in both ears, sight
              of both eyes, speech, or the use of any two limbs. The loss does not need to be permanent or
              irrecoverable.
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div>Exceptions and Limitations</div>
          </td>
          <td>
            <div>
              Benefits will not be paid for a disability: (a) caused by war or incident of war, or from active military
              service, (b) resulting from an intentionally self-inflicted injury, (c) during any period of
              incarceration, (d) resulting from suspension/termination of a professional or occupational license, except
              as a direct result of total disability, (e) before 90 days for normal pregnancy or childbirth, (f) for
              more than 12 months during the lifetime of the policy, if you reside outside of the United States.
            </div>
          </td>
        </tr>
      </table>
    </GlossaryDialog>
  );
}
