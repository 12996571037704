import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'voltron';

import { NO_LABEL, YES_LABEL, YES_OPTION } from 'constants/index';
import { ElectronicTransactionsSummary, formatDate } from 'utils/utils';
import { pageLoaded, pageLoading } from 'state/actions/page_loading';
import styles from './Summary.module.scss';
import ApplicantSummary from './ApplicantSummary/ApplicantSummary';
import HealthInfoSummary from './HealthInfoSummary/HealthInfoSummary';
// eslint-disable-next-line max-len
import ExistingDisabilityInsuranceSummary from './ExistingDisabilityInsuranceSummary/ExistingDisabilityInsuranceSummary';

const EmploymentInfoSummary = ({ input = {} }) => {
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  });
  return (
    <>
      <p className={styles.sectionHeader}>
        <strong>Employment Information</strong>
      </p>
      <table className={styles.summaryTable}>
        <tbody>
          <tr>
            <td>Are you actively at work on a full-time basis? (30 hours or more per week)</td>
            <td>{input.fullTimeWork === YES_OPTION ? YES_LABEL : NO_LABEL}</td>
          </tr>
          {input.fullTimeWorkDetails && (
            <tr>
              <td>Please provide details</td>
              <td>{input.fullTimeWorkDetails}</td>
            </tr>
          )}
          <tr>
            <td>Date of Hire</td>
            <td>{formatDate(new Date(input.dateOfHire))}</td>
          </tr>
          {input.occupation && (
            <tr>
              <td>Occupation</td>
              <td>{input.occupation}</td>
            </tr>
          )}
          {input.annualBaseIncome && (
            <tr>
              <td>Annual Base Income</td>
              <td>{currencyFormatter.format(input.annualBaseIncome)}</td>
            </tr>
          )}
          {input.averageVariableIncome && (
            <tr>
              <td>Average Annual Variable Income (commission, bonus, etc.)</td>
              <td>{currencyFormatter.format(input.averageVariableIncome)}</td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};

const AdditionalFormsSummary = ({ input = {} }) => (
  <>
    <p className={styles.sectionHeader}>
      <strong>Additional Forms</strong>
    </p>
    <table className={styles.summaryTable}>
      <tbody>
        {input.eftAccountType && (
          <tr>
            <td>Indicate type of account</td>
            <td>{input.eftAccountType}</td>
          </tr>
        )}
        {input.eftInstitutionType && (
          <tr>
            <td>Indicate type of financial institution</td>
            <td>{input.eftInstitutionType}</td>
          </tr>
        )}
        {input.existingEftBankAccountHolder && (
          <tr>
            <td>Bank Account Holder - provide name and address as shown on Bank Records.</td>
            <td>{input.existingEftBankAccountHolder}</td>
          </tr>
        )}
        {input.existingEftBankAndBranchName && (
          <tr>
            <td>Name of Bank and Branch Name where account is maintained.</td>
            <td>{input.existingEftBankAndBranchName}</td>
          </tr>
        )}
        {input.existingEftRoutingNumber && (
          <tr>
            <td>Transit/ABA Routing Number</td>
            <td>{input.existingEftRoutingNumber}</td>
          </tr>
        )}
        {input.existingEftBankAccountNumber && (
          <tr>
            <td>Bank Account Number</td>
            <td>{input.existingEftBankAccountNumber}</td>
          </tr>
        )}
        {input.existingEftPolicyNumber && (
          <tr>
            <td>Policy Number</td>
            <td>{input.existingEftPolicyNumber}</td>
          </tr>
        )}
        {input.existingEftNameOfInsured && (
          <tr>
            <td>Name of Insured</td>
            <td>{input.existingEftNameOfInsured}</td>
          </tr>
        )}
      </tbody>
    </table>
  </>
);

const Summary = ({ setSelectedTabIndex }) => {
  const dispatch = useDispatch();
  const {
    data: { applicationId, formCode, input, healthQuestions, showEft },
  } = useSelector((state) => state.applicantForm);

  useEffect(() => {
    dispatch(applicationId > 0 ? pageLoaded() : pageLoading());
  }, [applicationId]);

  if (!input) {
    return null;
  }

  return (
    <div className={styles.summary}>
      <header>
        <h3>Application Summary</h3>
        <p>Please review the information you have entered. If all information is correct, click continue.</p>
      </header>
      <main>
        <div className={styles.mainContent}>
          <ApplicantSummary formCode={formCode} input={input} />
          <EmploymentInfoSummary input={input} />
          <ExistingDisabilityInsuranceSummary formCode={formCode} input={input} />
          <HealthInfoSummary formCode={formCode} input={input} healthQuestions={healthQuestions} />
          <ElectronicTransactionsSummary input={input} styles={styles} />
          {showEft && <AdditionalFormsSummary input={input} />}
        </div>
        <div className={styles.submitButton}>
          <Button onClick={setSelectedTabIndex}>CONTINUE</Button>
        </div>
      </main>
    </div>
  );
};

export default Summary;
