import { useCallback } from 'react';
import { CloudDownload } from '@mui/icons-material';
import { useOktaAuth } from '@okta/okta-react';
import { useDispatch } from 'react-redux';
import { Button } from 'voltron';

import {
  exportAllApplicants,
  exportAllInactiveApplicants,
  exportAllRefusedCoverageApplicants,
  exportAllSubmittedApplicants,
} from 'services/Services';
import { downloadFile, formatAPIMessage } from 'utils/utils';
import { pageLoaded, pageLoading } from 'state/actions/page_loading';
import { setCriticalError } from 'state/actions/error';
import mainStyles from '../EmployerApplicantsDashboard.module.scss';

const reportProps = {
  ALL_APPLICANTS: {
    reportFunction: exportAllApplicants,
    reportType: 'applicants',
    dataTestId: 'all-applicants-report-button',
    headerText: 'All Applicants',
    description: 'Generates an Excel for all applicants',
  },
  ALL_SUBMITTED_APPLICANTS: {
    reportFunction: exportAllSubmittedApplicants,
    reportType: 'submitted applicants',
    dataTestId: 'submitted-applicants-report-button',
    headerText: 'All Submitted Applicants',
    description: "Generates an Excel for all applicants who've submitted applications",
  },
  ALL_APPLICANT_REFUSALS: {
    reportFunction: exportAllRefusedCoverageApplicants,
    reportType: 'applicant refusals',
    dataTestId: 'applicant-refusals-report-button',
    headerText: 'All Applicant Refusals',
    description: "Generates an Excel for all applicants who've refused coverage",
  },
  ALL_INACTIVE_APPLICANTS: {
    reportFunction: exportAllInactiveApplicants,
    reportType: 'applicants who have not logged in',
    dataTestId: 'inactive-applicants-report-button',
    headerText: "All Applicants Who Haven't Logged In",
    description: 'Generates an Excel for all applicants who have not logged in',
  },
};

const EmployerReports = ({ employerId }) => {
  const { oktaAuth } = useOktaAuth();
  const dispatch = useDispatch();

  const accessToken = oktaAuth.getAccessToken();

  const exportReport = useCallback(
    async (reportFunction, reportType) => {
      try {
        dispatch(pageLoading());
        const { data, headers } = await reportFunction(accessToken, employerId);
        downloadFile(data, headers);
      } catch (error) {
        dispatch(
          setCriticalError({
            title: `Export all ${reportType} report failed`,
            body: formatAPIMessage(error),
            button: 'OK',
          }),
        );
      } finally {
        dispatch(pageLoaded());
      }
    },
    [accessToken, employerId],
  );

  return (
    <>
      <header className={mainStyles.header}>Employer Reports</header>
      <div className={mainStyles.itemButtons}>
        {Object.keys(reportProps).map((reportName) => {
          const report = reportProps[reportName];
          return (
            <Button
              kind="link"
              key={reportName}
              data-testid={report.dataTestId}
              className={mainStyles.itemButton}
              onClick={() => exportReport(report.reportFunction, report.reportType)}
            >
              <div className={mainStyles.itemButtonContent}>
                <CloudDownload />
                <div className={mainStyles.buttonText}>
                  <h4>{report.headerText}</h4>
                  <p>{report.description}</p>
                </div>
              </div>
            </Button>
          );
        })}
      </div>
    </>
  );
};

export default EmployerReports;
