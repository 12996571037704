export const validateZip = (value) => {
  if (value) {
    if (value.length < 5 || (value?.substring(5, 6) === '-' && value.length !== 10)) {
      return false;
    }
    const regex = /^\d{5}(?:[-\s]\d{4})?$/;
    return value.match(regex);
  }
  return false;
};

export const validatePhone = (value) => {
  if (value) {
    const regex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
    return value.match(regex);
  }
  return false;
};

export const validateEmail = (value) => {
  if (value) {
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return value.match(regex);
  }
  return false;
};

export const validateDOB = (value) => {
  if (value) {
    // eslint-disable-next-line no-useless-escape
    const regex = /^(0?[1-9]|1[012])[\/-](0?[1-9]|[12][0-9]|3[01])[\/-]\d{4}$/;
    return value.match(regex);
  }
  return false;
};

export const validateSSN = (value) => {
  if (value) {
    const dValidate = value.split('-').join('').split('_').join('');
    return dValidate.length === 9;
  }
  return false;
};
