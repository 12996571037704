import { useState, useEffect } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import PropTypes from 'prop-types';
import { Button, Icon, Modal } from 'voltron';

import routes from 'constants/routes';
import { isAdminUser, isApplicantUser, isNonApplicantUser } from 'utils/utils';
import { Grid } from '@mui/material';
import { CLEAR_FORM_DATA } from 'state/actions/upload';
import { FocusScope } from 'react-aria';
import Styles from './UserOptionsMenu.module.scss';

const SIGN_OUT_OPTION = 'Sign Out';
const NEED_HELP_OPTION = 'Need Help/Have Questions?';
const TAB_KEY = 'Tab';
const CLOSE_ICON_ID = 'close-icon';
const SIGN_OUT_CONFIRM_BUTTON_ID = 'sign-out-confirm-button';
const BEFORE_UNLOAD_EVENT = 'beforeunload';

const onMenuItemKeyDown = (e, options, index, history, onClickAction) => {
  if (e.code === 'Space' || e.key === 'Enter') {
    onClickAction();
    const option = options[index];
    if (option.path !== null) {
      history.push(option.path);
    }
  } else if (e.key === TAB_KEY) {
    let nextItemIndex = e.shiftKey ? index - 1 : index + 1;
    if (nextItemIndex === options.length) {
      nextItemIndex = 0;
    } else if (nextItemIndex < 0) {
      nextItemIndex = options.length - 1;
    }
    document.getElementById(`menu-item-${nextItemIndex}`)?.focus();
  }
};

const onModalKeyDown = (e, resetModal) => {
  if (e.key === 'Escape') {
    e.preventDefault();
    resetModal();
  }
};

const onCloseIconKeyDown = (e) => {
  if (e.key === TAB_KEY && e.shiftKey) {
    e.preventDefault();
    document.getElementById(SIGN_OUT_CONFIRM_BUTTON_ID).focus();
  }
};

const onConfirmButtonKeyDown = (e) => {
  if (e.key === TAB_KEY && !e.shiftKey) {
    e.preventDefault();
    document.getElementById(CLOSE_ICON_ID).children[0].focus();
  }
};

const beforeUnloadHandler = (e) => {
  e.preventDefault();
  e.returnValue = '';
};

export default function UserOptionsMenu({ handleLogout, userInfo, disabled }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [options, setOptions] = useState([]);
  const [isNeedHelpModalOpen, setIsNeedHelpModalOpen] = useState(false);
  const [isSignOutConfirmModalOpen, setIsSignOutConfirmModalOpen] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (location.pathname === routes.uploads) {
      window.addEventListener(BEFORE_UNLOAD_EVENT, beforeUnloadHandler);

      return () => window.removeEventListener(BEFORE_UNLOAD_EVENT, beforeUnloadHandler);
    }
    return () => {};
  }, [location.pathname]);

  useEffect(() => {
    const signOutMenuOption = `${SIGN_OUT_OPTION} (${userInfo?.preferred_username})`;
    if (!userInfo) {
      setOptions([{ name: 'Sign In', path: '' }]);
    } else if (isApplicantUser(userInfo)) {
      setOptions([
        { name: NEED_HELP_OPTION, path: '#' },
        { name: signOutMenuOption, path: '' },
      ]);
    } else if (isNonApplicantUser(userInfo)) {
      setOptions([
        { name: 'Dashboard', path: routes.userDashboard },
        { name: signOutMenuOption, path: '' },
      ]);
    } else if (isAdminUser(userInfo)) {
      setOptions([
        { name: 'Dashboard', path: routes.userDashboard },
        { name: 'Upload Census', path: routes.uploads },
        { name: 'Reports', path: routes.adminReports },
        { name: 'Employers', path: routes.businesses },
        { name: 'Agencies', path: routes.agencies },
        { name: 'Owning Companies', path: routes.owningCompanies },
        { name: 'Users (Non Applicants)', path: routes.users },
        { name: signOutMenuOption, path: null },
      ]);
    }
  }, [userInfo]);

  useEffect(() => {
    if (isSignOutConfirmModalOpen) {
      const keyDownEvent = 'keydown';
      const closeButton = document.getElementById(CLOSE_ICON_ID);
      if (closeButton) {
        closeButton.children[0].focus();
        closeButton.addEventListener(keyDownEvent, onCloseIconKeyDown);
        return () => closeButton.removeEventListener(keyDownEvent, onCloseIconKeyDown);
      }
    }
    return () => {};
  }, [isSignOutConfirmModalOpen]);

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        disabled={disabled}
        data-testid="menu-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        className={disabled ? Styles.disabledHamburger : Styles.hamburger}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        data-testid="account-menu"
        open={open}
        onClose={(_e, reason) => {
          if (!open || reason !== 'tabKeyDown') {
            handleClose();
          }
        }}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {options.map((option, index) => {
          let onClickAction = handleClose;
          if (option.name?.startsWith(SIGN_OUT_OPTION)) {
            onClickAction = () => {
              handleClose();
              if (location.pathname === routes.uploads) {
                setIsSignOutConfirmModalOpen(true);
              } else {
                handleLogout();
              }
            };
          } else if (option.name === NEED_HELP_OPTION) {
            onClickAction = () => {
              handleClose();
              setIsNeedHelpModalOpen(true);
            };
          }
          return (
            <MenuItem
              key={option.name}
              onClick={onClickAction}
              id={`menu-item-${index}`}
              data-testid={`menu-item-${index}`}
              tabIndex={0}
              onKeyDown={(e) => onMenuItemKeyDown(e, options, index, history, onClickAction)}
              className={Styles.menuNav}
            >
              {option.path === null ? (
                <span id={option.name.toLowerCase().replace(' ', '-')} className={Styles.menuItemLink}>
                  {option.name}
                </span>
              ) : (
                <Link className={Styles.menuItemLink} to={option.path}>
                  {option.name}
                </Link>
              )}
            </MenuItem>
          );
        })}
      </Menu>
      <Modal
        open={isNeedHelpModalOpen}
        title="Need Help/Have Questions"
        handleToggle={() => setIsNeedHelpModalOpen(false)}
      >
        <p tabIndex="0">
          <strong>
            If you are in need of assistance regarding your disability insurance offer, please contact your agent at the
            number shown. If you have questions about how to enroll, please contact Ameritas at 1-877-500-4445.
          </strong>
        </p>
        <div className={Styles.modalButton}>
          <Button kind="link" onClick={() => setIsNeedHelpModalOpen(false)}>
            OK
          </Button>
        </div>
      </Modal>
      {/* Cannot use Voltron Modal here because of a focus trap issue with the `contain` flag
        interacting with the menu items */}
      {isSignOutConfirmModalOpen && (
        <FocusScope autoFocus>
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
          <div
            role="dialog"
            className="modalContainerInitialCenter modalContainerCenter"
            onClick={(e) => e.stopPropagation()}
            onKeyDown={(e) => onModalKeyDown(e, () => setIsSignOutConfirmModalOpen(false))}
            aria-labelledby="modal-title"
          >
            <div className="modalTitleContainer">
              <div tabIndex={0} />
              <div id={CLOSE_ICON_ID} tabIndex={0} className="modalClose">
                <Icon
                  kind="close"
                  className="modalIcon"
                  clickable
                  onClick={() => setIsSignOutConfirmModalOpen(false)}
                />
              </div>
            </div>
            <div className="modalContentInitial modalContentOpen">
              <div className="modalChildren">
                <p id="modal-title" tabIndex={0}>
                  Your enrollment set-up is not complete. Are you sure you want to do this?
                </p>
                <Grid justifyContent="flex-end" container>
                  <Button
                    id={SIGN_OUT_CONFIRM_BUTTON_ID}
                    data-testid={SIGN_OUT_CONFIRM_BUTTON_ID}
                    onClick={() => {
                      window.removeEventListener(BEFORE_UNLOAD_EVENT, beforeUnloadHandler);
                      dispatch({
                        type: CLEAR_FORM_DATA,
                      });
                      setIsSignOutConfirmModalOpen(false);
                      handleLogout();
                    }}
                    onKeyDown={onConfirmButtonKeyDown}
                  >
                    CONFIRM
                  </Button>
                  <div tabIndex={0} />
                </Grid>
              </div>
            </div>
          </div>
          <div role="presentation" className="modal" onClick={() => setIsSignOutConfirmModalOpen(false)} />
        </FocusScope>
      )}
    </div>
  );
}

UserOptionsMenu.propTypes = {
  handleLogout: PropTypes.any,
  userInfo: PropTypes.any,
};
