import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Grid } from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';
import { Button } from 'voltron';

import { handleSubmitForCensus } from '../../../state/actions/upload';

const CensusSubmission = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { oktaAuth } = useOktaAuth();
  const { formData = {} } = useSelector((state) => state.upload);

  const handleSubmit = () => {
    const accessToken = oktaAuth.getAccessToken();
    dispatch(handleSubmitForCensus(accessToken, formData, history));
  };

  return (
    <>
      <Grid container spacing={0} className="innerHeader">
        <Grid item xs={12}>
          <h3>Ready to Submit?</h3>
        </Grid>
        <Grid item xs={12}>
          <p>
            Please check over all your data to ensure it&apos;s accurate. When ready, click Submit Census Information.
          </p>
          <br />
        </Grid>
      </Grid>
      <Grid justifyContent="flex-end" container>
        <Button onClick={handleSubmit}>SUBMIT CENSUS INFORMATION</Button>
      </Grid>
    </>
  );
};

export default CensusSubmission;
