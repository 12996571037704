import React, { useEffect } from 'react';
import { Button, CheckBox } from 'voltron';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Grid } from '@mui/material';
import { setApplication } from 'services/Services';
import { useOktaAuth } from '@okta/okta-react';
import { useDispatch } from 'react-redux';
import { setCriticalError } from 'state/actions/error';
import { formatAPIMessage } from 'utils/utils';
import Styles from './Offers.module.scss';
import CornerstoneGlossary from './cornerstoneGlossary/CornerstoneGlossary';
import FoundationGlossary from './foundationGlossary/FoundationGlossary';
import FundamentalGlossary from './fundamentalGlossary/FundamentalGlossary';
import RefuseOverallCoverage from './refuseOverallCoverage/RefuseOverallCoverage';

function Offers({ Introduction: applicantInfo, setData }) {
  const dispatch = useDispatch();
  const { oktaAuth } = useOktaAuth();
  const isIncreases = applicantInfo.enrollmentType === 'Increases';

  let plan1Text = 'Core (Employer Paid)';
  let plan2Text = 'Buy Up (Voluntary)';

  if (applicantInfo.offers[0]?.canRefuse) {
    const plan1ProductCode = applicantInfo.offers[0].productCode;
    const plan2ProductCode = applicantInfo.offers[1].productCode;

    const getProductText = (productCode) => {
      switch (productCode) {
        case 'DI09':
          return 'Foundation';
        case 'DI21':
          return 'Cornerstone';
        default:
          return 'Fundamental';
      }
    };
    plan1Text = getProductText(plan1ProductCode);
    plan2Text = getProductText(plan2ProductCode);
  }

  useEffect(() => {
    applicantInfo.offers.forEach((res, i) => {
      let HTML = '';
      res.rows.forEach((offerrow) => {
        HTML += `<tr key={j}>
                    <td>${offerrow.columns[0]}</td>
                    <td>${offerrow.columns[1]}</td>
                </tr>`;
      });
      document.getElementById(`tableBody${i}`).innerHTML = HTML;
    });
  }, []);
  const updateSelection = (changed) => {
    const finalData = applicantInfo;
    switch (changed) {
      case 'a1':
        finalData.formData.accept1 = !finalData.formData.accept1;
        if (finalData.formData.accept1) {
          finalData.formData.refuse1 = false;
        }
        if (finalData.applicantMustSelectOne && finalData.formData.accept1) {
          finalData.formData.accept2 = false;
          finalData.formData.refuse2 = true;
        }
        break;
      case 'a2':
        finalData.formData.accept2 = !finalData.formData.accept2;
        if (finalData.formData.accept2) {
          finalData.formData.refuse2 = false;
        }
        if (finalData.applicantMustSelectOne && finalData.formData.accept2) {
          finalData.formData.accept1 = false;
          finalData.formData.refuse1 = true;
        }
        break;
      case 'r1':
        finalData.formData.refuse1 = !finalData.formData.refuse1;
        if (finalData.formData.refuse1) {
          finalData.formData.accept1 = false;
        }
        break;
      case 'r2':
        finalData.formData.refuse2 = !finalData.formData.refuse2;
        if (finalData.formData.refuse2) {
          finalData.formData.accept2 = false;
        }
        break;
      default:
    }
    // Validate
    let isValid = false;
    if (finalData.offers.length === 1) {
      isValid = true;
    } else {
      const hasApp1Response = finalData.formData.refuse1 || finalData.formData.accept1;
      const hasApp2Response = finalData.formData.refuse2 || finalData.formData.accept2;
      const acceptedPlan = finalData.formData.accept2 || finalData.formData.accept1;
      if (!finalData.offers[0].canRefuse) {
        // Make sure one of the 2nd offers is selected
        isValid = hasApp2Response;
      } else {
        isValid = hasApp1Response && hasApp2Response && acceptedPlan;
      }
      if (finalData.applicantMustSelectOne) {
        isValid = acceptedPlan;
      }
    }

    finalData.formData.allowEnroll = isValid;
    finalData.formData.showRefuseOverall = !!(finalData.formData.refuse1 && finalData.formData.refuse2);

    setData({ ...finalData });
  };

  const enroll = () => {
    const accessToken = oktaAuth.getAccessToken();
    setApplication(accessToken, {
      model: applicantInfo.formData,
      applicantId: applicantInfo.applicantId,
    })
      .then((res) => {
        if (isIncreases) {
          window.location.href = `/forms/increases/${res.applicationId}`;
        } else {
          window.location.href = `/forms/primary/${res.applicationId}`;
        }
      })
      .catch((err) => {
        dispatch(
          setCriticalError({
            title: 'Set application error',
            body: formatAPIMessage(err),
            button: 'OK',
          }),
        );
      });
  };

  return (
    <div>
      {applicantInfo !== 0 && applicantInfo.offers.length === 1 && (
        <div>
          <div className={Styles.tableHead}>Coverage and Premium Summary</div>
          <table className={Styles.offersTable}>
            <tbody id="tableBody0" />
          </table>
          {applicantInfo.offers[0].productCode === 'DI09' || applicantInfo.offers[0].productCode === 'DI21' ? (
            <p>
              Note: See Policy Features Summary for further details.
              <br />
              *In California, residual (partial) disability coverage is required and not available by rider.
            </p>
          ) : null}
          {applicantInfo.offers[0].productCode === 'DI09' || applicantInfo.offers[0].productCode === 'DI21' ? null : (
            <p>Note: See Policy Features Summary for further details.</p>
          )}
          <p>
            {applicantInfo.offers[0].productCode === 'LS12' && <FundamentalGlossary />}
            {applicantInfo.offers[0].productCode === 'DI09' && <FoundationGlossary />}
            {applicantInfo.offers[0].productCode === 'DI21' && <CornerstoneGlossary />}
          </p>
        </div>
      )}
      {applicantInfo && applicantInfo.offers.length === 2 && (
        <div>
          <Grid container spacing={2}>
            <Grid item xs>
              <h3>{plan1Text}</h3>
              <div className={Styles.tableHead}>Coverage and Premium Summary</div>
              <table className={Styles.offersTable}>
                <tbody id="tableBody0" />
              </table>
              {applicantInfo.offers[0].productCode === 'DI09' || applicantInfo.offers[0].productCode === 'DI21' ? (
                <p>
                  Note: See Policy Features Summary for further details.
                  <br />
                  *In California, residual (partial) disability coverage is required and not available by rider.
                </p>
              ) : null}
              {applicantInfo.offers[0].productCode === 'DI09' ||
              applicantInfo.offers[0].productCode === 'DI21' ? null : (
                <p>Note: See Policy Features Summary for further details.</p>
              )}
              {applicantInfo.offers[0].productCode === 'LS12' && <FundamentalGlossary />}
              {applicantInfo.offers[0].productCode === 'DI09' && <FoundationGlossary />}
              {applicantInfo.offers[0].productCode === 'DI21' && <CornerstoneGlossary />}
            </Grid>
            <Grid item xs>
              <h3>{plan2Text}</h3>
              <div className={Styles.tableHead}>Coverage and Premium Summary</div>
              <table className={Styles.offersTable}>
                <tbody id="tableBody1" />
              </table>
              {applicantInfo.offers[1].productCode === 'DI09' || applicantInfo.offers[0].productCode === 'DI21' ? (
                <p>
                  Note: See Policy Features Summary for further details.
                  <br />
                  *In California, residual (partial) disability coverage is required and not available by rider.
                </p>
              ) : null}
              {applicantInfo.offers[1].productCode === 'DI09' ||
              applicantInfo.offers[0].productCode === 'DI21' ? null : (
                <p>Note: See Policy Features Summary for further details.</p>
              )}
              {applicantInfo.offers[0].productCode !== applicantInfo.offers[1].productCode && (
                <p>
                  {applicantInfo.offers[1].productCode === 'LS12' && <FundamentalGlossary />}
                  {applicantInfo.offers[1].productCode === 'DI09' && <FoundationGlossary />}
                  {applicantInfo.offers[1].productCode === 'DI21' && <CornerstoneGlossary />}
                </p>
              )}
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={2}>
            <Grid item xs>
              <FormControlLabel
                disabled={!applicantInfo.offers[0].canRefuse}
                control={<CheckBox checked={applicantInfo.formData.accept1} onChange={() => updateSelection('a1')} />}
                label="Apply"
                data-testid="a1-apply"
                className="columnLeft"
              />
              {applicantInfo.offers[0].canRefuse && (
                <FormControlLabel
                  control={<CheckBox checked={applicantInfo.formData.refuse1} onChange={() => updateSelection('r1')} />}
                  label="Refuse"
                  data-testid="r1-refuse"
                  className="columnLeft"
                />
              )}
            </Grid>
            <Grid item xs>
              <FormControlLabel
                control={<CheckBox checked={applicantInfo.formData.accept2} onChange={() => updateSelection('a2')} />}
                label="Apply"
                data-testid="a2-apply"
                className="columnLeft"
              />
              <FormControlLabel
                control={<CheckBox checked={applicantInfo.formData.refuse2} onChange={() => updateSelection('r2')} />}
                label="Refuse"
                data-testid="r2-refuse"
                className="columnLeft"
              />
            </Grid>
          </Grid>
        </div>
      )}
      <div className={Styles.continueButton}>
        <RefuseOverallCoverage
          formData={applicantInfo.formData}
          applicantId={applicantInfo.applicantId}
          isIncreases={isIncreases}
        />
        <Button data-testid="enroll" disabled={!applicantInfo.formData?.allowEnroll} onClick={() => enroll()}>
          ENROLL
        </Button>
      </div>
    </div>
  );
}

export default Offers;
