import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import { createLogger } from 'redux-logger';

import history from '../../history';

import createRootReducer from '../reducers';

/* eslint-disable no-underscore-dangle */
const isProd = process.env.NODE_ENV === 'production';
const loggerMiddleware = createLogger();
const middlewares = [thunk, routerMiddleware(history)];

if (!isProd) {
  middlewares.push(loggerMiddleware);
}

const createStore = (preloadedState) =>
  configureStore({
    preloadedState,
    reducer: createRootReducer(history),
    devTools: !isProd,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middlewares),
  });

export default createStore;
