import React, { useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { withTransaction } from '@elastic/apm-rum-react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Box, Container, Grid } from '@mui/material';

import NavigationTabs from 'views/dashboard/applicant/tabs/navigation/NavigationTabs';
import AgentInformation from 'components/agent-information/AgentInformation';

const Applicant = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);
  const [agentData, setAgentData] = useState(0);

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth
        .getUser()
        .then((info) => {
          setUserInfo(info);
        })
        .catch((err) => {
          console.error('Sign in error', err);
        });
    }
  }, [authState, oktaAuth]); // Update if authState changes

  /**
   * If User info is being fetched, return early
   */
  if (!userInfo) {
    return (
      <div className="globalloader">
        <div data-testid="hello" className="loadertext">
          Please wait ...
        </div>
      </div>
    );
  }

  return (
    <Box sx={{ width: '100%' }}>
      {agentData === 0 && (
        <div className="globalloader">
          <div className="loadertext">Please wait ...</div>
        </div>
      )}
      <Container maxWidth="xl">
        <h1>Welcome to Ameritas Online Enrollment </h1>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Card>
              <CardContent>
                <NavigationTabs setAgentData={setAgentData} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <AgentInformation />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default withTransaction('Applicant', 'component')(Applicant);
