import { Dialog, DialogTitle, DialogContent, IconButton, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export const Text = () => ' Until age 65, the policy cannot be canceled nor restrictions added, ';

export const Text1 = () =>
  ` Solely due to sickness or injury, you are not able to perform the material
    and substantial duties of your occupation `;

export const Text2 = () =>
  `A residual benefit may be paid if you suffer a loss of, at least, 15% of your net earned income,
  and you are able to perform one or more, but not all, of the material and substantial duties of your
  occupation or you are unable to engage in your occupation for more than 80% of the usual time.`;

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          data-testid="close-btn"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export const GlossaryDialog = ({ children, styles, open, setOpen }) => {
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div>
      <div
        role="button"
        tabIndex={0}
        className={styles.policy}
        onClick={handleClickOpen}
        onKeyDown={(e) => {
          if (['Space', 'Enter'].includes(e.code)) {
            e.preventDefault();
            handleClickOpen();
          }
        }}
        data-testid="policy-feature-glossary"
      >
        <HelpOutlineIcon /> &nbsp;Policy Features Glossary
      </div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        aria-describedby="dialog-content"
        open={open}
        className={styles.maxwidth}
        maxWidth="lg"
      >
        <BootstrapDialogTitle id="customized-dialog-title" className={styles.header} onClose={handleClose}>
          <ErrorOutlineIcon />
          <div className={styles.contenth1}>Policy Features</div>
        </BootstrapDialogTitle>
        <DialogContent dividers id="dialog-content">
          {children}
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};
