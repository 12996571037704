import React, { useEffect, useRef } from 'react';
import OktaSignIn from '@okta/okta-signin-widget';
// eslint-disable-next-line import/no-relative-packages
import '../../../node_modules/@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import config from '../../okta_config';

/**
 * Okta Sign In Widget
 * @param {*} onSuccess
 * @param {*} onError
 * @returns
 */
const OktaSignInWidget = ({ onSuccess, onError }) => {
  const widgetRef = useRef();

  /**
   * useEffect()
   */
  useEffect(() => {
    if (!widgetRef.current) {
      return () => {};
    }

    const widget = new OktaSignIn(config.widget);

    // Search for URL Parameters to see if a user is being routed to the application to recover password
    const { searchParams } = new URL(window.location.href);
    widget.otp = searchParams.get('otp');
    widget.state = searchParams.get('state');

    widget
      .showSignInToGetTokens({
        el: widgetRef.current,
      })
      .then(onSuccess)
      .catch(onError);

    return () => widget.remove();
  }, [onSuccess, onError]);

  return <div ref={widgetRef} />;
};

export default OktaSignInWidget;
