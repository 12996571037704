import { Toast } from 'voltron';
import {
  deleteAgency,
  deleteOwningCompany,
  getAgencies,
  getOwnerCompanies,
  saveAgency,
  saveOwningCompany,
} from 'services/Services';
import { setCriticalError } from 'state/actions/error';
import { formatAPIMessage } from 'utils/utils';
import routes from 'constants/routes';
import { pageLoaded, pageLoading } from './page_loading';

export const SET_AGENCIES = 'SET_AGENCIES';
export const SET_AGENCIES_LOADING = 'SET_AGENCIES_LOADING';
export const SET_OWNING_COMPANIES = 'SET_OWNING_COMPANIES';
export const SET_OWNING_COMPANIES_LOADING = 'SET_OWNING_COMPANIES_LOADING';

export const fetchAgencies = (accessToken) => async (dispatch) => {
  dispatch({
    type: SET_AGENCIES_LOADING,
    payload: true,
  });
  try {
    const agenciesResponse = await getAgencies(accessToken);
    dispatch({
      type: SET_AGENCIES,
      payload: agenciesResponse.agencies || [],
    });
  } catch (error) {
    dispatch(
      setCriticalError({
        title: 'Get agencies failed',
        body: formatAPIMessage(error),
        button: 'OK',
      }),
    );
  } finally {
    dispatch({
      type: SET_AGENCIES_LOADING,
      payload: false,
    });
  }
};

export const removeAgency = (accessToken, agency) => async (dispatch) => {
  try {
    await deleteAgency(accessToken, agency);
    Toast.success(`${agency.name} Has Been Deleted.`);
    dispatch(fetchAgencies(accessToken));
  } catch (error) {
    dispatch(
      setCriticalError({
        title: 'Delete agency failed',
        body: formatAPIMessage(error),
        button: 'OK',
      }),
    );
  }
};

export const editAgency = (accessToken, agency, history) => async (dispatch) => {
  dispatch(pageLoading());
  try {
    await saveAgency(accessToken, agency);
    Toast.success('Agency Has Been Updated.');
    history.push(routes.agencies);
  } catch (error) {
    dispatch(
      setCriticalError({
        title: 'Save agency failed',
        body: formatAPIMessage(error),
        button: 'OK',
      }),
    );
  } finally {
    dispatch(pageLoaded());
  }
};

export const fetchOwningCompanies = (accessToken) => async (dispatch) => {
  dispatch({
    type: SET_OWNING_COMPANIES_LOADING,
    payload: true,
  });
  try {
    const owningCompaniesResponse = await getOwnerCompanies(accessToken);
    dispatch({
      type: SET_OWNING_COMPANIES,
      payload: owningCompaniesResponse.ownerCompanies || [],
    });
  } catch (error) {
    dispatch(
      setCriticalError({
        title: 'Get owning companies failed',
        body: formatAPIMessage(error),
        button: 'OK',
      }),
    );
  } finally {
    dispatch({
      type: SET_OWNING_COMPANIES_LOADING,
      payload: false,
    });
  }
};

export const removeOwningCompany = (accessToken, owningCompany) => async (dispatch) => {
  try {
    await deleteOwningCompany(accessToken, owningCompany);
    Toast.success(`${owningCompany.name} Has Been Deleted.`);
    dispatch(fetchOwningCompanies(accessToken));
  } catch (error) {
    dispatch(
      setCriticalError({
        title: 'Delete owning company failed',
        body: formatAPIMessage(error),
        button: 'OK',
      }),
    );
  }
};

export const editOwningCompany = (accessToken, owningCompany, history) => async (dispatch) => {
  dispatch(pageLoading());
  try {
    await saveOwningCompany(accessToken, owningCompany);
    Toast.success('Owning Company Has Been Updated.');
    history.push(routes.owningCompanies);
  } catch (error) {
    dispatch(
      setCriticalError({
        title: 'Save owning company failed',
        body: formatAPIMessage(error),
        button: 'OK',
      }),
    );
  } finally {
    dispatch(pageLoaded());
  }
};
