import React from 'react';
import moment from 'moment';
import Styles from './footer.module.scss';
import oktaConfig from '../../../okta_config';

const links = [
  {
    href: 'https://www.ameritas.com/',
    label: 'Ameritas Mutual  Holding Company',
  },
  {
    href: 'https://www.ameritas.com/wps/wcm/connect/corp/ameritas/about-us/legal',
    label: 'Legal Statement & Terms of Use',
  },
  {
    href: 'https://www.ameritas.com/wps/wcm/connect/corp/ameritas/about-us/privacy',
    label: 'Privacy Policy',
  },
];

// ©2023

function Footer() {
  const oktaVal = `OKTA: [${oktaConfig.oidc.issuer}][${oktaConfig.oidc.clientId}]`;
  return (
    <div className={Styles.bg}>
      <input type="hidden" name="OktaURL" value={oktaVal} />
      {links.map((res, index) => (
        <React.Fragment key={`footer-link-${res.label}`}>
          <a href={res.href}>{index === 0 ? `©${moment().year()} ${res.label}` : res.label}</a>
          {index !== 2 && <span className="p-0">/</span>}
        </React.Fragment>
      ))}
    </div>
  );
}

export default Footer;
