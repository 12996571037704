import { useState } from 'react';
import Styles from '../Offers.module.scss';
import { GlossaryDialog, Text } from '../common/Common';

export default function FundamentalGlossary() {
  const [open, setOpen] = useState(false);

  return (
    <GlossaryDialog styles={Styles} open={open} setOpen={setOpen}>
      <p>
        This is a partial explanation of features for the DInamic Fundamental individual disability insurance policy.
        Additional state variations may apply. See policy for complete details.
      </p>
      <table className={Styles.offersTable}>
        <thead>
          <tr>
            <th>Policy Feature</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div>Guaranteed Renewable to Age 65</div>
            </td>
            <td>
              <div>
                Subject to the termination conditions below, {Text()} as long as premiums are paid as due. Rates may be
                changed by class.
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <div>Definition of Total Disability</div>
            </td>
            <td>
              <div>
                Solely due to sickness or injury, you are not able to perform the material and substantial duties of any
                occupation for which you are reasonably suited based on your education, training, and experience.
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>Benefit for Total Disability</div>
            </td>
            <td>
              <div>
                {`Benefit for Total Disability: Policy pays the Total Disability Benefit
                      for a total disability that: `}
                <br />
                1. Is expected to last a minimum of 365 days; or
                <br />
                2. Results in death more than 30 days after, and within 365 days of the onset of disability.
                <br />
                No total disability benefits are payable under the policy if you die within the first 30 days following
                the onset of a total disability.The Total Disability Benefit is payable only once during the life of the
                policy.
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>Policy Termination</div>
            </td>
            <td>
              <div>
                The policy terminates on the earliest of the following events: once the Total Disability Benefit is paid
                under the policy, unless there are in force riders on the policy; on the anniversary date following your
                65th birthday; for nonpayment of premium; upon the owners written request; or upon the insured&apos;s
                death.
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>Exceptions and Limitations</div>
            </td>
            <td>
              <div>
                Benefits will not be paid for a disability: (a) caused by war or act of war, or from active military
                service, (b) resulting from an intentionally self-inflicted injury, (c) during any period of
                incarceration, (d) commission of a felony, (e) participation in a riot, insurrection or illegal
                occupation, (f) resulting from suspension/termination of a professional or occupational license, except
                as a direct result of total disability. The benefit for a compensable total disability resulting from
                mental/nervous disorders and/or drug or alcohol abuse is limited to one-half the Total Disability
                Benefit. If there are in force riders on the policy, other exceptions and limitations may apply to
                benefits payable under those riders.
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>Pre-Existing Condition Limitation</div>
            </td>
            <td>
              <div>
                Benefits are not payable for a disability beginning in the 12 months after the policy issue date if it
                is caused by a Pre-existing Condition (a condition for which you sought, or for which a person would
                have sought, medical treatment during the 6 months prior to the issue date).
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>Critical Illness Rider</div>
            </td>
            <td>
              <div>
                The critical illness Lump Sum Amount may be paid, if you are diagnosed with a critical illness. Critical
                illnesses are cancer, heart attack, kidney failure, major organ failure with transplant, and stroke, as
                defined in the rider. During the lifetime of the policy, payment will not be made more than once for
                each critical illness. Payment will also not be made for a critical illness that is diagnosed within 180
                days of another critical illness covered under this rider.
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>Accident Only Disability Rider</div>
            </td>
            <td>
              <div>
                Following the Elimination Period for this rider, the accident only Monthly Benefit may be paid, for each
                month that, solely due to an injury, you are not able to perform the material and substantial duties of
                your own occupation. Benefits continue as long as you remain disabled, not to exceed the Maximum Benefit
                Period for this rider.
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>Catastrophic Disability Rider</div>
            </td>
            <td>
              <div>
                Following the Elimination Period for this rider, the catastrophic disability Monthly Benefit may be paid
                for each month that you are catastrophically disabled due to injury or sickness. Catastrophically
                disabled means you are unable to perform two or more activities of daily living without stand-by
                assistance; or require substantial supervision due to severe cognitive impairment. Activities of daily
                living are: dressing, toileting, transferring, continence, eating and bathing. Benefits continue as long
                as you remain catastrophically disabled, not to exceed the Maximum Benefit Period for this rider.
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </GlossaryDialog>
  );
}
