import {
  SET_AGENCIES,
  SET_AGENCIES_LOADING,
  SET_OWNING_COMPANIES,
  SET_OWNING_COMPANIES_LOADING,
} from '../actions/adminEntities.js';

const initialState = {
  agencies: [],
  isSetAgenciesLoading: false,
  owningCompanies: [],
  isSetOwningCompaniesLoading: false,
};

const adminEntitiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AGENCIES:
      return {
        ...state,
        agencies: action.payload,
      };
    case SET_AGENCIES_LOADING:
      return {
        ...state,
        isSetAgenciesLoading: action.payload,
      };
    case SET_OWNING_COMPANIES:
      return {
        ...state,
        owningCompanies: action.payload,
      };
    case SET_OWNING_COMPANIES_LOADING:
      return {
        ...state,
        isSetOwningCompaniesLoading: action.payload,
      };
    default:
      return state;
  }
};

export default adminEntitiesReducer;
