import React from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';

import { formatPhoneNumber } from 'utils/utils';
import styles from './CensusSummary.module.scss';

const getEmployerSummary = ({ formData }) => {
  const { employer } = formData;

  if (!employer) {
    return null;
  }

  return (
    <Grid item xs={12} className={styles.summaryWrap}>
      <p className={styles.summaryItem} data-testid="employer">
        Employer
      </p>
      <div className={styles.details}>
        {employer.name && <p>{employer.name}</p>}
        {employer.address && <p>{employer.address}</p>}
        {employer.city && <p>{employer.city}</p>}
        {employer.stateCd && employer.zipCode && (
          <p>
            {employer.stateCd}, {employer.zipCode}
          </p>
        )}
        {employer.phone && <p>{formatPhoneNumber(employer.phone) || employer.phone}</p>}
        {employer.email && <p>{employer.email}</p>}
      </div>
    </Grid>
  );
};

const getAgentSummary = ({ formData }) => {
  const { agent, agent2, agent3 } = formData;

  const getOptionalAgentInformation = (agt, index) => (
    <Grid item xs={12} className={styles.summaryWrap}>
      <p className={styles.summaryItem} data-testid={`agent${index}`}>
        Agent {index}
      </p>
      <div className={styles.details}>
        {agt.firstName && <p>{agt.firstName}</p>}
        {agt.lastName && <p>{agt.lastName}</p>}
      </div>
    </Grid>
  );

  return (
    <>
      <Grid item xs={12} className={styles.summaryWrap}>
        <p className={styles.summaryItem} data-testid="agent">
          Agent
        </p>
        <div className={styles.details}>
          {agent?.firstName && <p>{agent.firstName}</p>}
          {agent?.lastName && <p>{agent.lastName}</p>}
          {agent.agency?.name && <p>{agent.agency.name}</p>}
          {agent.agency?.code && <p>{agent.agency.code}</p>}
        </div>
      </Grid>
      {agent2?.id >= 0 && getOptionalAgentInformation(agent2, 2)}
      {agent3?.id >= 0 && getOptionalAgentInformation(agent3, 3)}
    </>
  );
};

const getEnrollmentSummary = ({ formData }) => {
  const { enrollment, skipAppQuestions, removeIncomeOption, manualActivation } = formData;
  return (
    <Grid item xs={12} className={styles.summaryWrap}>
      <p className={styles.summaryItem} data-testid="enrollment">
        Enrollment
      </p>
      <div className={styles.details}>
        {enrollment?.enrollmentType && <p>{enrollment.enrollmentType}</p>}
        {enrollment?.startDate && enrollment?.endDate && (
          <p>
            {enrollment.startDate} - {enrollment.endDate}
          </p>
        )}
        {skipAppQuestions && <p>Skip Gatekeeper Questions</p>}
        {removeIncomeOption && <p>Remove Income</p>}
        {manualActivation && <p>Skip Automatic User Activation</p>}
      </div>
    </Grid>
  );
};

const CensusSummary = () => {
  const { formData = {} } = useSelector((state) => state.upload);

  const classTypes = [];
  formData.censusFiles.forEach((file) => {
    if (file.classPlan.classes) {
      classTypes.push(...file.classPlan.classes);
    }
  });

  return (
    <Grid container spacing={1} data-testid="census-summary">
      {getEmployerSummary({ formData })}
      {getAgentSummary({ formData })}
      {getEnrollmentSummary({ formData })}
      <Grid item xs={12} className={styles.summaryWrap}>
        <p className={styles.summaryItem} data-testid="wholesaler">
          Wholesaler
        </p>
        <div className={styles.details}>
          {formData?.wholesaler?.firstName && formData?.wholesaler?.lastName && (
            <p>
              {formData?.wholesaler?.firstName} {formData?.wholesaler?.lastName}
            </p>
          )}
        </div>
      </Grid>
      <Grid item xs={12} className={styles.summaryWrap}>
        <p className={styles.summaryItem} data-testid="design">
          Plan/Class Design/File
        </p>
        <div className={styles.details}>
          {formData?.numberOfFiles && <p>FileCount: {formData?.numberOfFiles}</p>}
          {classTypes?.map((itemClass) => (
            <p key={`class-type-${itemClass.classType}`}>{itemClass.classType}</p>
          ))}
        </div>
      </Grid>
    </Grid>
  );
};

export default CensusSummary;
