import React from 'react';
import { Prompt } from 'react-router';
import { useLocation } from 'react-router-dom';
import { Modal, Button } from 'voltron';
import { Grid } from '@mui/material';

const RouteLeaveGuard = ({ when, navigate, shouldBlockNavigation, message }) => {
  const location = useLocation();
  const [modalVisible, setModalVisible] = React.useState(false);
  const [lastLocation, setLastLocation] = React.useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = React.useState(false);

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleBlockedNavigation = (nextLocation) => {
    const isLogOut = nextLocation?.pathname === '/';
    if (!isLogOut && !confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      const isCurrentRoute = location.pathname === nextLocation.pathname;
      if (!isCurrentRoute) {
        setModalVisible(true);
        setLastLocation(nextLocation);
      }
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  React.useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <Modal open={modalVisible} handleToggle={closeModal}>
        <p tabIndex="0">{message}</p>
        <Grid justifyContent="flex-end" container>
          <Button name="modal-close-btn" onClick={handleConfirmNavigationClick}>
            CONFIRM
          </Button>
        </Grid>
      </Modal>
    </>
  );
};

export default RouteLeaveGuard;
