import axios from 'axios';
import { BASE_URL } from 'constants/index';

function post(url, headers, data) {
  return axios
    .post(url, data, headers)
    .then((r) => r.data)
    .catch((error) => {
      if (error.response?.data) {
        throw error.response.data;
      }
      throw error;
    });
}

function postWithResponseType(url, headers, data, responseType) {
  const options = { headers: headers.headers, responseType };
  return axios.post(url, data, options).then((r) => ({ data: r.data, headers: r.headers }));
}

function get(url, headers) {
  const options = { method: 'GET', headers: headers.headers };
  return axios.get(url, options).then((r) => r.data);
}

function getWithResponseType(url, headers, responseType) {
  const options = { method: 'GET', headers: headers.headers, responseType };
  return axios.get(url, options).then((r) => ({ data: r.data, headers: r.headers }));
}

function doCall(type, url, headers = {}, data = {}, responseType = '') {
  const endpointUrl = `${BASE_URL + url}`;
  switch (type) {
    case 'POST':
      if (responseType) {
        return postWithResponseType(endpointUrl, headers, data, responseType);
      }
      return post(endpointUrl, headers, data);
    case 'GET':
      if (responseType) {
        return getWithResponseType(endpointUrl, headers, responseType);
      }
      return get(endpointUrl, headers);
    default:
      return new Promise();
  }
}

const ApiCalls = { doCall, post, get };

export default ApiCalls;
