import { useOktaAuth } from '@okta/okta-react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, Select } from 'voltron';
import { YES_OPTION, yesNoOptionList } from 'constants/index';

import { pageLoaded, pageLoading } from 'state/actions/page_loading';
import styles from './ElectronicTransactions.module.scss';
import { saveAndRefreshData } from '../existingDisabilityInsurance/formUtils';

const FORM_FIELD_NAMES = {
  yesOrNoTransactions: 'yesOrNoTransactions',
  emailForETD: 'emailForETD',
  email: 'email',
};

const labelKind = 'regular';
const labelPosition = 'top';

const getInvalidFormElements = (yesOrNoTransactions, emailForETD, isValidEmail) => {
  const invalidFormElements = [];

  if (!yesOrNoTransactions) {
    invalidFormElements.push(FORM_FIELD_NAMES.yesOrNoTransactions);
  } else if ((emailForETD?.trim() && !isValidEmail) || (yesOrNoTransactions === YES_OPTION && !emailForETD?.trim())) {
    invalidFormElements.push(FORM_FIELD_NAMES.email);
  }

  return invalidFormElements;
};

const getForm = ({
  invalidInputs,
  yesOrNoTransactions,
  emailForETD,
  email,
  isPrimary,
  isOnSubmit,
  onInputChange,
  setIsValidEmail,
  onSubmit,
}) => (
  <div className={styles.electronicTransactionsForm}>
    <header>
      <h3>Electronic Transactions and Delivery of Documents</h3>
    </header>
    <form autoComplete="off">
      <div className={styles.formBody}>
        <Select
          label="Do you consent to the use of electronic transactions and electronic delivery of documents?"
          labelKind={labelKind}
          labelPosition={labelPosition}
          name="yes-no-transactions"
          options={yesNoOptionList}
          clearable={false}
          searchable={false}
          material={false}
          required
          forceErrorMessage={isOnSubmit && invalidInputs.includes(FORM_FIELD_NAMES.yesOrNoTransactions)}
          selectedValue={yesNoOptionList.find((option) => option.value === yesOrNoTransactions)}
          onChange={(e) => onInputChange(FORM_FIELD_NAMES.yesOrNoTransactions, e.value)}
        />
        <p>
          If consent is given, you may revoke your consent at any time by calling us at the phone number provided on
          your completed application. Your consent will be effective until you revoke it. Electronic delivery includes
          the policy and other documents as they become available in the future.
        </p>
        <Input
          label="E-mail address (if other than e-mail provided in the Applicant Tab)"
          labelKind={labelKind}
          placeholder="Email"
          kind="email"
          maxLength={38}
          data-testid="email-for-ETD"
          material={false}
          autoComplete={false}
          required={yesOrNoTransactions === YES_OPTION}
          forceErrorMessage={isOnSubmit && invalidInputs.includes(FORM_FIELD_NAMES.email)}
          value={isPrimary ? emailForETD : email}
          onChange={(e) => {
            setIsValidEmail(e.target.isValid);
            onInputChange(isPrimary ? FORM_FIELD_NAMES.emailForETD : FORM_FIELD_NAMES.email, e.target.value);
          }}
        />
        <p>
          You must notify the Company of any new e-mail address by contacting the Company&apos;s customer service
          center. You understand that if consent for electronic delivery is given, but a legible e-mail address is not
          provided, electronic delivery will not be initiated. When documents are ready to be viewed electronically, you
          will receive an e-mail notification. Electronic delivery requires that you have a personal computer with
          current browser software (such as Chrome or Microsoft Edge), a valid secure e-mail account and access to the
          internet. While the Company provides internet delivery free of charge, the size of the document(s) may be
          large. It is possible you could be charged by an Internet Service Provider or other party to receive or
          download such document(s) via the internet. Some documents are available as Portable Document Format (PDF)
          files requiring the use of Adobe Acrobat Reader software, which is available on our Company website free of
          charge. If you accept electronic delivery, you will always have the option to print a copy of the documents;
          or you may make a one-time request for a copy of any electronic document by contacting the Company in writing,
          at the address provided on your completed application. The Company will not charge a fee for this service.
        </p>
      </div>
      <div className={styles.submitButton}>
        <Button onClick={onSubmit}>SAVE & CONTINUE</Button>
      </div>
    </form>
  </div>
);

const ElectronicTransactions = ({ isPrimary, setSelectedTabIndex }) => {
  const { oktaAuth } = useOktaAuth();
  const {
    data: { applicationId, input: existingInputData },
  } = useSelector((state) => state.applicantForm);
  const dispatch = useDispatch();
  const [formInputData, setFormInputData] = useState(existingInputData);
  const [invalidInputs, setInvalidInputs] = useState([]);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isOnSubmit, setIsOnSubmit] = useState(false);

  const { yesOrNoTransactions, emailForETD, email } = formInputData;

  useEffect(() => {
    dispatch(applicationId > 0 ? pageLoaded() : pageLoading());
  }, [applicationId]);

  useEffect(() => {
    setFormInputData(existingInputData);
  }, [existingInputData]);

  useEffect(() => {
    const invalidFormElements = getInvalidFormElements(
      yesOrNoTransactions,
      isPrimary ? emailForETD : email,
      isValidEmail,
    );
    setInvalidInputs(invalidFormElements);
  }, [yesOrNoTransactions, emailForETD, email, isValidEmail]);

  const onInputChange = (inputName, value) => {
    setFormInputData((formData) => ({
      ...formData,
      [inputName]: value,
    }));
  };

  const onSubmit = async () => {
    setIsOnSubmit(true);
    if (invalidInputs.length) {
      return;
    }

    const formData = {
      userFormId: applicationId,
      input: {
        ...formInputData,
        emailForETD: isPrimary ? formInputData.emailForETD : formInputData.email,
      },
    };
    const accessToken = oktaAuth.getAccessToken();
    await saveAndRefreshData({ accessToken, formData, applicationId, dispatch, setSelectedTabIndex });
  };

  return getForm({
    invalidInputs,
    yesOrNoTransactions,
    emailForETD,
    email,
    isPrimary,
    isOnSubmit,
    onInputChange,
    setIsValidEmail,
    onSubmit,
  });
};

export default ElectronicTransactions;
