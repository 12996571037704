import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';
import { Grid } from '@mui/material';
import { Button, CheckBox, Input, Select, Uploader } from 'voltron';

import {
  handlePreviewFile,
  UPDATE_UPLOAD_CENSUS_DATA,
  UPDATE_UPLOAD_CENSUS_CLASSES,
  CANCEL_UPLOAD,
} from '../../../state/actions/upload';
import styles from './Class.module.scss';

const labelKind = 'regular';
const labelPosition = 'top';

const numOfFilesOptions = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
];

const fileSelectHandler = (e, formData, dispatch) => {
  const { value } = e;
  if (value) {
    const censusFiles = [];
    for (let j = 0; j < value; j++) {
      censusFiles.push({
        id: j + 1,
        fileName: '',
        selectedFile: '',
        fileType: '',
        classPlan: {},
      });
    }
    dispatch({
      type: UPDATE_UPLOAD_CENSUS_DATA,
      payload: {
        formData: {
          ...formData,
          numberOfFiles: value,
          censusFiles,
        },
      },
    });
  }
};

const validate = (formData) => {
  const errors = {};
  if (!formData.numberOfFiles) {
    errors.numberOfFiles = 'numberOfFiles';
  }
  if (formData.censusFiles.length === 0) {
    errors.censusFiles = 'censusFiles';
  } else {
    formData.censusFiles.forEach((census, index) => {
      if (!census.selectedFile) {
        errors.censusFiles = 'censusFiles';
      }
      if (census.classPlan?.classes) {
        census.classPlan.classes.forEach((classItem, classIndex) => {
          if (!classItem.classType) {
            errors[`classType-${index}-${classIndex}`] = 'classType';
          }
        });
      }
    });
  }
  return errors;
};

const handleCancelUpload = (fileItemActive, dispatch) => {
  dispatch({
    type: CANCEL_UPLOAD,
    payload: {
      fileItemActive,
    },
  });
};

const classSelectHandler = (e, parentIndex, classIndex, dispatch) => {
  const { value } = e;
  dispatch({
    type: UPDATE_UPLOAD_CENSUS_CLASSES,
    payload: {
      name: 'classType',
      value,
      parentIndex,
      classIndex,
    },
  });
};

const handleInputChange = (e, parentIndex, classIndex, dispatch) => {
  const { name, value } = e.target;
  dispatch({
    type: UPDATE_UPLOAD_CENSUS_CLASSES,
    payload: {
      value,
      name,
      parentIndex,
      classIndex,
    },
  });
};

const checkboxHandler = (e, formData, dispatch) => {
  const { name, checked } = e.target;
  dispatch({
    type: UPDATE_UPLOAD_CENSUS_DATA,
    payload: {
      formData: {
        ...formData,
        [name]: checked,
      },
    },
  });
};

const handleSubmit = (formData, setFormErrors, proceedToTab) => {
  const errors = validate(formData);
  setFormErrors(errors);
  if (Object.keys(errors).length === 0) {
    proceedToTab(5);
  }
};

const setCensusFiles = async (censusFiles, setSelectedFile1, setSelectedFile2) => {
  if (censusFiles.some((file) => Boolean(file.selectedFile.name))) {
    const fileObjects = censusFiles.map(async (censusFile) => {
      const { selectedFile } = censusFile;
      if (selectedFile.file) {
        const rawFile = await fetch(selectedFile.file);
        const fileBlob = await rawFile.blob();
        return new File([fileBlob], selectedFile.name, {
          type: selectedFile.type,
          lastModified: selectedFile.lastModified,
        });
      }
      return null;
    });
    const fileObject1 = await fileObjects[0];
    const fileObject2 = fileObjects[1] ? await fileObjects[1] : null;
    setSelectedFile1(fileObject1 || null);
    setSelectedFile2(fileObject2 || null);
  } else {
    setSelectedFile1(null);
    setSelectedFile2(null);
  }
};

const getCensusClassPlanElements = (parentIndex, formData, formErrors, classPlanTypes, dispatch) => {
  const classList = formData.censusFiles?.[parentIndex]?.classPlan?.classes;
  const classPlanTypeOptions = classPlanTypes.map((type) => ({ label: type.name, value: type.name }));
  return classList.map((item, index) => (
    <Grid container item xs={12} spacing={2} key={`classPlanItem-${parentIndex}-${item.id}`}>
      <Grid item xs={12}>
        <Select
          label="Select Class/Plan Design"
          labelKind={labelKind}
          labelPosition={labelPosition}
          name="class-plan-design"
          options={classPlanTypeOptions}
          clearable={false}
          searchable={false}
          material={false}
          required
          forceErrorMessage={Boolean(formErrors[`classType-${parentIndex}-${index}`])}
          selectedValue={classPlanTypeOptions.find((option) => option.value === classList?.[index]?.classType)}
          onChange={(e) => classSelectHandler(e, parentIndex, index, dispatch)}
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          label="Description"
          labelKind={labelKind}
          name="description"
          data-testid="description"
          maxLength={40}
          material={false}
          autoComplete={false}
          value={classList?.[index]?.description}
          onChange={(e) => handleInputChange(e, parentIndex, index, dispatch)}
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          label="Current LTD Insurance Co"
          labelKind={labelKind}
          name="existingCompanyName"
          data-testid="current-company"
          maxLength={40}
          material={false}
          autoComplete={false}
          value={classList?.[index]?.existingCompanyName}
          onChange={(e) => handleInputChange(e, parentIndex, index, dispatch)}
        />
      </Grid>
    </Grid>
  ));
};

const getFileQuestions = ({
  formData,
  formErrors,
  classPlanTypes,
  selectedFile1,
  selectedFile2,
  dispatch,
  handleFileUploadSuccess,
  setSelectedFile1,
  setSelectedFile2,
  refreshingUploader,
}) =>
  [...Array(+formData.numberOfFiles).keys()].map((fileIndex) => {
    const CSV_FILE_TYPE = 'text/csv';
    const uploaderAlignment = 'center';
    const selectedFile = formData.censusFiles?.[fileIndex]?.selectedFile;
    const files = [];
    if (fileIndex === 0 && selectedFile1) {
      files.push(selectedFile1);
    } else if (fileIndex === 1 && selectedFile2) {
      files.push(selectedFile2);
    }
    return (
      <Grid item container spacing={2} xs={12} key={`file-${fileIndex}-form`}>
        <Grid item xs={12}>
          <hr />
        </Grid>
        <Grid item xs={12}>
          <p className={styles.fileSelectedText}>
            File Selected:{' '}
            <span className={styles.fileRequired}>
              {selectedFile ? <span>{selectedFile.name}</span> : 'None Selected'}
            </span>
            <br />
            {!selectedFile && formErrors.censusFiles && (
              <span className={styles.fileError}>Please upload a csv file</span>
            )}
          </p>
        </Grid>
        <Grid container item xs={12} justifyContent={uploaderAlignment} alignItems={uploaderAlignment}>
          {!refreshingUploader && (
            <Uploader
              files={files}
              acceptedFileTypes={[CSV_FILE_TYPE]}
              fileValidateTypeLabelExpectedTypesMap={{ [CSV_FILE_TYPE]: '.csv' }}
              className={styles.uploader}
              onAddFile={(_error, fileObj) => {
                const newFile = fileObj?.file;
                const existingFile = files[0];
                const isSameAsExistingFile =
                  newFile?.name === existingFile?.name &&
                  newFile?.type === existingFile?.type &&
                  newFile?.lastModified === existingFile?.lastModified &&
                  newFile?.size === existingFile?.size;
                if (existingFile && !isSameAsExistingFile) {
                  handleCancelUpload(fileIndex, dispatch);
                }
                handleFileUploadSuccess(newFile, fileIndex);
              }}
              onUpdateFiles={(fileItems) => {
                if (!fileItems.length) {
                  handleCancelUpload(fileIndex, dispatch);
                  if (fileIndex === 0) {
                    setSelectedFile1(null);
                  } else {
                    setSelectedFile2(null);
                  }
                }
              }}
            />
          )}
        </Grid>
        {formData.censusFiles?.[fileIndex]?.classPlan?.classes?.length > 0 &&
          getCensusClassPlanElements(fileIndex, formData, formErrors, classPlanTypes, dispatch)}
      </Grid>
    );
  });

const getCheckboxes = ({ formData, dispatch }) => (
  <>
    <Grid item xs={12}>
      <CheckBox
        label="Employee must select 1 of the offers, but not both"
        name="applicantMustSelectOne"
        data-testid="applicant-must-select-one"
        disabled={formData.applicantCanApplyForBoth}
        defaultChecked={formData.applicantMustSelectOne}
        onClick={(e) => checkboxHandler(e, formData, dispatch)}
        inputProps={{ 'data-testid': 'applicant-must-select-one' }}
      />
    </Grid>
    <Grid item xs={12} style={{ paddingTop: 20 }}>
      <CheckBox
        label="Employee may choose to apply for both offers"
        name="applicantCanApplyForBoth"
        data-testid="applicant-can-apply-for-both"
        disabled={formData.applicantMustSelectOne}
        defaultChecked={formData.applicantCanApplyForBoth}
        onClick={(e) => checkboxHandler(e, formData, dispatch)}
        inputProps={{ 'data-testid': 'applicant-can-apply-for-both' }}
      />
    </Grid>
  </>
);

const Class = ({ proceedToTab, enableNextTab }) => {
  const { classPlanTypes = [], formData = {} } = useSelector((state) => state.upload);
  const dispatch = useDispatch();
  const { oktaAuth } = useOktaAuth();
  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [refreshingUploader, setRefreshingUploader] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const handleFileUploadSuccess = useCallback((acceptedFile, fileItemActive) => {
    const accessToken = oktaAuth.getAccessToken();
    dispatch(
      handlePreviewFile(
        accessToken,
        acceptedFile,
        fileItemActive,
        fileItemActive === 0 ? setSelectedFile1 : setSelectedFile2,
      ),
    );
  }, []);

  useEffect(async () => {
    await setCensusFiles(formData.censusFiles, setSelectedFile1, setSelectedFile2);
  }, []);

  useEffect(() => proceedToTab(4), []);

  useEffect(() => {
    if (refreshingUploader) {
      setRefreshingUploader(false);
    }
  }, [refreshingUploader]);

  useEffect(() => {
    const errors = validate(formData);
    enableNextTab(5, Object.keys(errors).length === 0 ? undefined : null);
  }, [formData.censusFiles, formData.numberOfFiles]);

  return (
    <div>
      <Grid container spacing={0} className="innerHeader">
        <Grid item xs={12}>
          <h3>Class/Plan Design</h3>
        </Grid>
        <Grid item xs={12}>
          <p>Please enter the Class/Plan information for the census being uploaded</p>
        </Grid>
      </Grid>
      <form>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Select
              label="Number of Files to be Uploaded"
              labelKind={labelKind}
              labelPosition={labelPosition}
              name="number-of-files"
              options={numOfFilesOptions}
              clearable={false}
              searchable={false}
              material={false}
              required
              forceErrorMessage={Boolean(formErrors.numberOfFiles)}
              selectedValue={numOfFilesOptions.find((option) => option.value === formData.numberOfFiles)}
              onChange={(e) => {
                if (e.value !== formData.numberOfFiles) {
                  fileSelectHandler(e, formData, dispatch);
                  setSelectedFile1(null);
                  setSelectedFile2(null);
                  setRefreshingUploader(true);
                }
              }}
            />
          </Grid>
          {formData.numberOfFiles !== null &&
            getFileQuestions({
              formData,
              formErrors,
              classPlanTypes,
              selectedFile1,
              selectedFile2,
              dispatch,
              handleFileUploadSuccess,
              setSelectedFile1,
              setSelectedFile2,
              refreshingUploader,
            })}
          {getCheckboxes({ formData, dispatch })}
          <Grid item xs={12} justifyContent="flex-end" container>
            <Button onClick={() => handleSubmit(formData, setFormErrors, proceedToTab)}>CONTINUE</Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default Class;
