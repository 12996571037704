import { useState, useEffect } from 'react';
import { Select, TextArea } from 'voltron';
import { yesNoOptionList, YES_OPTION } from 'constants/index';

import ExistingCoverages from '../existingCoverages/ExistingCoverages';
import { onInputDataChange, onInputChange } from '../formUtils';

const FORM_FIELD_NAMES = {
  appliedForInsuranceCornerStone12Months: 'appliedForInsuranceCornerStone12Months',
  appliedForInsuranceExplainCornerStone12Months: 'appliedForInsuranceExplainCornerStone12Months',
  appliedForInsuranceExplainCornerStone12Months1: 'appliedForInsuranceExplainCornerStone12Months1',
  appliedForInsuranceExplainCornerStone12Months2: 'appliedForInsuranceExplainCornerStone12Months2',
  receivedDisabilityBenefitsForCornerStone6Months: 'receiveddisabilitybenefitsForCornerStone6Months',
  receivedDisabilityBenefitsExplainCornerStone6Months: 'receiveddisabilitybenefitsExplainCornerStone6Months',
  receivedDisabilityBenefitsExplainCornerStone6Months1: 'receiveddisabilitybenefitsExplainCornerStone6Months1',
  receivedDisabilityBenefitsExplainCornerStone6Months2: 'receiveddisabilitybenefitsExplainCornerStone6Months2',
};

const getInvalidFormElements = (inputData) => {
  const invalidFormElements = [];

  if (!inputData.appliedForInsuranceCornerStone12Months) {
    invalidFormElements.push(FORM_FIELD_NAMES.appliedForInsuranceCornerStone12Months);
  } else if (
    inputData.appliedForInsuranceCornerStone12Months === YES_OPTION &&
    !inputData.appliedForInsuranceExplainCornerStone12Months?.trim()
  ) {
    invalidFormElements.push(FORM_FIELD_NAMES.appliedForInsuranceExplainCornerStone12Months);
  }

  if (!inputData[FORM_FIELD_NAMES.receivedDisabilityBenefitsForCornerStone6Months]) {
    invalidFormElements.push(FORM_FIELD_NAMES.receivedDisabilityBenefitsForCornerStone6Months);
  } else if (
    inputData[FORM_FIELD_NAMES.receivedDisabilityBenefitsForCornerStone6Months] === YES_OPTION &&
    !inputData[FORM_FIELD_NAMES.receivedDisabilityBenefitsExplainCornerStone6Months]?.trim()
  ) {
    invalidFormElements.push(FORM_FIELD_NAMES.receivedDisabilityBenefitsExplainCornerStone6Months);
  }

  return invalidFormElements;
};

const getSplitFieldChanges = (inputName, value) => {
  let additionalFieldChanges = {};
  if (inputName === FORM_FIELD_NAMES.appliedForInsuranceExplainCornerStone12Months) {
    const isCutOffNeeded = value.length > 85;
    additionalFieldChanges = {
      appliedForInsuranceExplainCornerStone12Months1: isCutOffNeeded ? value.slice(0, 84) : value,
      appliedForInsuranceExplainCornerStone12Months2: isCutOffNeeded ? value.slice(84) : '',
    };
  } else if (inputName === FORM_FIELD_NAMES.receivedDisabilityBenefitsExplainCornerStone6Months) {
    const isCutOffNeeded = value.length > 52;
    additionalFieldChanges = {
      [FORM_FIELD_NAMES.receivedDisabilityBenefitsExplainCornerStone6Months1]: isCutOffNeeded
        ? value.slice(0, 51)
        : value,
      [FORM_FIELD_NAMES.receivedDisabilityBenefitsExplainCornerStone6Months2]: isCutOffNeeded ? value.slice(51) : '',
    };
  }

  return additionalFieldChanges;
};

const getAdditionalFieldChanges = (inputName, value) => {
  let additionalFieldChanges = {};
  if (inputName === FORM_FIELD_NAMES.appliedForInsuranceCornerStone12Months && value !== YES_OPTION) {
    additionalFieldChanges = {
      appliedForInsuranceExplainCornerStone12Months: '',
      appliedForInsuranceExplainCornerStone12Months1: '',
      appliedForInsuranceExplainCornerStone12Months2: '',
    };
  } else if (inputName === FORM_FIELD_NAMES.receivedDisabilityBenefitsForCornerStone6Months && value !== YES_OPTION) {
    additionalFieldChanges = {
      [FORM_FIELD_NAMES.receivedDisabilityBenefitsExplainCornerStone6Months]: '',
      [FORM_FIELD_NAMES.receivedDisabilityBenefitsExplainCornerStone6Months1]: '',
      [FORM_FIELD_NAMES.receivedDisabilityBenefitsExplainCornerStone6Months2]: '',
    };
  } else {
    additionalFieldChanges = getSplitFieldChanges(inputName, value);
  }

  return additionalFieldChanges;
};

const getYesNoQuestion = ({
  label,
  name,
  formFieldName,
  value,
  invalidInputs,
  isOnSubmit,
  setInputData,
  setFormInputData,
}) => {
  const labelPosition = 'top';
  return (
    <Select
      label={label}
      labelPosition={labelPosition}
      name={name}
      options={yesNoOptionList}
      clearable={false}
      searchable={false}
      material={false}
      required
      forceErrorMessage={isOnSubmit && invalidInputs.includes(formFieldName)}
      selectedValue={yesNoOptionList.find((option) => option.value === value)}
      onChange={(e) => onInputChange(formFieldName, e.value, getAdditionalFieldChanges, setInputData, setFormInputData)}
    />
  );
};

const getMainForm = ({
  appliedForInsuranceCornerStone12Months,
  appliedForInsuranceExplainCornerStone12Months,
  receivedDisabilityBenefitsForCornerStone6Months,
  receivedDisabilityBenefitsExplainCornerStone6Months,
  isOnSubmit,
  invalidInputs,
  setInputData,
  setFormInputData,
}) => {
  const EXPLAIN_PLACEHOLDER = 'Explanation';
  const textareaStyle = { resize: 'vertical' };
  return (
    <>
      {getYesNoQuestion({
        label: 'In the past 12 months, have you been declined or postponed for disability insurance?',
        name: 'applied-for-insurance-cornerstone',
        formFieldName: FORM_FIELD_NAMES.appliedForInsuranceCornerStone12Months,
        value: appliedForInsuranceCornerStone12Months,
        invalidInputs,
        isOnSubmit,
        setInputData,
        setFormInputData,
      })}
      {appliedForInsuranceCornerStone12Months === YES_OPTION && (
        <TextArea
          groupLabel="If Yes, provide details."
          maxLength={500}
          placeholderText={EXPLAIN_PLACEHOLDER}
          required
          forceErrorMessage={
            isOnSubmit && invalidInputs.includes(FORM_FIELD_NAMES.appliedForInsuranceExplainCornerStone12Months)
          }
          defaultValue={appliedForInsuranceExplainCornerStone12Months}
          textAreaProps={{
            'data-testid': 'applied-for-insurance-explain-cornerstone',
            style: textareaStyle,
            onChange: (e) =>
              onInputChange(
                FORM_FIELD_NAMES.appliedForInsuranceExplainCornerStone12Months,
                e.target.value,
                getAdditionalFieldChanges,
                setInputData,
                setFormInputData,
              ),
          }}
        />
      )}
      {getYesNoQuestion({
        label: `In the past 6 months, have you received disability benefits from any source,
          or do you currently have a claim for disability benefits pending?`,
        name: 'received-disability-benefits-cornerstone',
        formFieldName: FORM_FIELD_NAMES.receivedDisabilityBenefitsForCornerStone6Months,
        value: receivedDisabilityBenefitsForCornerStone6Months,
        invalidInputs,
        isOnSubmit,
        setInputData,
        setFormInputData,
      })}
      {receivedDisabilityBenefitsForCornerStone6Months === YES_OPTION && (
        <TextArea
          groupLabel="If Yes, provide details (cause of disability, dates, duration, etc.)"
          maxLength={500}
          placeholderText={EXPLAIN_PLACEHOLDER}
          required
          forceErrorMessage={
            isOnSubmit && invalidInputs.includes(FORM_FIELD_NAMES.receivedDisabilityBenefitsExplainCornerStone6Months)
          }
          defaultValue={receivedDisabilityBenefitsExplainCornerStone6Months}
          textAreaProps={{
            'data-testid': 'received-disability-benefits-explain-cornerstone',
            style: textareaStyle,
            onChange: (e) =>
              onInputChange(
                FORM_FIELD_NAMES.receivedDisabilityBenefitsExplainCornerStone6Months,
                e.target.value,
                getAdditionalFieldChanges,
                setInputData,
                setFormInputData,
              ),
          }}
        />
      )}
    </>
  );
};

const CornerstoneQuestions = ({
  existingInputData,
  setFormInputData,
  setIsFormInvalidMap,
  isOnSubmit = false,
  isCombinedWithAnotherProduct = false,
}) => {
  const [inputData, setInputData] = useState(existingInputData);
  const [invalidInputs, setInvalidInputs] = useState([]);
  const {
    appliedForInsuranceCornerStone12Months,
    appliedForInsuranceExplainCornerStone12Months,
    receiveddisabilitybenefitsForCornerStone6Months: receivedDisabilityBenefitsForCornerStone6Months,
    receiveddisabilitybenefitsExplainCornerStone6Months: receivedDisabilityBenefitsExplainCornerStone6Months,
  } = inputData;

  useEffect(() => {
    onInputDataChange(inputData, 'cornerstone', getInvalidFormElements, setInvalidInputs, setIsFormInvalidMap);
  }, [inputData]);

  return (
    <div>
      {isCombinedWithAnotherProduct && (
        <p data-testid="combined-text-cornerstone">
          <strong>For Cornerstone Application</strong>
        </p>
      )}
      <ExistingCoverages
        formType="cornerstone"
        existingInputData={existingInputData}
        setFormInputData={setFormInputData}
        setIsFormInvalidMap={setIsFormInvalidMap}
        isOnSubmit={isOnSubmit}
      />
      {getMainForm({
        appliedForInsuranceCornerStone12Months,
        appliedForInsuranceExplainCornerStone12Months,
        receivedDisabilityBenefitsForCornerStone6Months,
        receivedDisabilityBenefitsExplainCornerStone6Months,
        isOnSubmit,
        invalidInputs,
        setInputData,
        setFormInputData,
      })}
    </div>
  );
};

export default CornerstoneQuestions;
