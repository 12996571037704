import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { withTransaction } from '@elastic/apm-rum-react';
import { Box, Container, Grid } from '@mui/material';
import { Select } from 'voltron';

import { getEnrollments } from 'state/actions/dashboard';
import PaginatedTable from 'components/PaginatedTable/PaginatedTable';
import { isAdminUser } from 'utils/utils';
import styles from './EnrollmentsDashboard.module.scss';

const statuses = {
  IS_ACTIVE: 'isActive',
  IS_FUTURE: 'isFuture',
  HAS_PASSED: 'hasPassed',
};

const statusOptions = [
  {
    label: 'Active',
    value: statuses.IS_ACTIVE,
  },
  {
    label: 'Future',
    value: statuses.IS_FUTURE,
  },
  {
    label: 'Closed',
    value: statuses.HAS_PASSED,
  },
  {
    label: 'All',
    value: '',
  },
];

const StatusFilter = ({ selectedFilterStatus, setSelectedFilterStatus }) => (
  <Select
    name="enrollment-status-filter"
    options={statusOptions}
    clearable={false}
    searchable={false}
    material={false}
    selectedValue={statusOptions.find((option) => option.value === selectedFilterStatus)}
    onChange={(e) => setSelectedFilterStatus(e.value)}
  />
);

const NameTypeComponent = ({ name, enrollmentType }) => (
  <>
    <div>{name}</div>
    <div>{enrollmentType}</div>
  </>
);

const getNameTypeComponent = (_name, _disabled, { name, enrollmentType }) => (
  <NameTypeComponent name={name} enrollmentType={enrollmentType} />
);

const EnrollmentsDashboard = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { oktaAuth } = useOktaAuth();
  const { enrollments, isSetEnrollmentsLoading } = useSelector((state) => state.dashboard);
  const { userInfo } = useSelector((state) => state.appState);
  const [selectedFilterStatus, setSelectedFilterStatus] = useState(statuses.IS_ACTIVE);

  const isAdmin = isAdminUser(userInfo);

  useEffect(() => {
    const accessToken = oktaAuth.getAccessToken();
    dispatch(getEnrollments(accessToken));
  }, []);

  const tableColumns = [
    {
      Header: 'Name',
      width: 40,
      accessor: 'name',
      isSearchable: true,
      component: getNameTypeComponent,
    },
    {
      Header: 'Enrollment Period',
      width: 40,
      accessor: 'periodRange',
    },
    {
      Header: 'Active Users',
      width: 40,
      accessor: 'activeUsers',
    },
    {
      Header: 'Apps Waiting For Signature',
      width: 40,
      accessor: 'appsWaitingForSignature',
    },
    {
      Header: 'Apps Submitted',
      width: 40,
      accessor: 'appsSubmitted',
    },
    {
      Header: 'Apps Refused',
      width: 40,
      accessor: 'appsRefused',
    },
  ];

  const enrollmentData = selectedFilterStatus
    ? enrollments.filter(({ hasPassed, isFuture }) => {
        switch (selectedFilterStatus) {
          case statuses.IS_ACTIVE:
            return !hasPassed && !isFuture;
          case statuses.IS_FUTURE:
            return !hasPassed && isFuture;
          case statuses.HAS_PASSED:
            return !isFuture && hasPassed;
          default:
            return false;
        }
      })
    : enrollments;

  return (
    <Box sx={{ width: '82vw', marginTop: '4rem' }}>
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="adminDashboardPage">
              <h2 className={`pageTitle ${styles.pageTitle}`}>Enrollments</h2>
              <div className={styles.contentBody}>
                <div>
                  <PaginatedTable
                    bubbleOnClick={(item) =>
                      history.push(`/dashboard/${isAdmin ? 'business' : 'user'}/${item.businessId}`)
                    }
                    sortColumn="name"
                    sortOrder="ASC"
                    columns={tableColumns}
                    data={enrollmentData}
                    isDataLoading={isSetEnrollmentsLoading}
                    filterComponent={
                      <StatusFilter
                        selectedFilterStatus={selectedFilterStatus}
                        setSelectedFilterStatus={setSelectedFilterStatus}
                      />
                    }
                  />
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default withTransaction('EnrollmentsDashboard', 'component')(EnrollmentsDashboard);
