import {
  UPDATE_UPLOAD_CENSUS_DATA,
  UPDATE_CENSUS_CLASS_COUNT,
  UPDATE_UPLOAD_CENSUS_CLASSES,
  CANCEL_UPLOAD,
  CLEAR_FORM_DATA,
  SET_UPLOAD_LOADING_STATE,
} from '../actions/upload';

const initialFormData = {
  numberOfFiles: null,
  applicantMustSelectOne: null,
  applicantCanApplyForBoth: null,
  skipAppQuestions: false,
  removeIncomeOption: false,
  manualActivation: false,
  censusFiles: [],
  employer: {},
  agent: {},
  agent2: {},
  agent3: {},
  enrollment: {},
  assignmentOfOwnership: {},
  wholesaler: {},
};

const initialUploadCensus = {
  inProgress: false,
  loading: false,
  uploadError: {},
  agencies: [],
  agents: [],
  classPlanTypes: [],
  enrollmentTypes: [],
  wholesalers: [],
  formData: { ...initialFormData },
};

const updateCensusClassCount = (action, censusFiles, state) => {
  const { activeFileItem, classCount, fileName, fileType, file } = action.payload;
  const activeCensusFile = JSON.parse(JSON.stringify(censusFiles[activeFileItem]));
  const selectedFile = censusFiles[activeFileItem]?.selectedFile;
  const isSameFile =
    selectedFile?.name === fileName &&
    selectedFile?.type === fileType &&
    selectedFile?.lastModified === file.lastModified;
  if (isSameFile) {
    return state;
  }
  if (classCount && censusFiles[activeFileItem]) {
    activeCensusFile.selectedFile = {
      name: fileName,
      type: fileType,
      lastModified: file.lastModified,
      file,
    };
    activeCensusFile.classPlan.classCount = classCount;
    activeCensusFile.classPlan.classes = [];
    for (let j = 0; j < classCount; j++) {
      activeCensusFile.classPlan.classes.push({ id: j + 1 });
    }
    censusFiles.splice(activeFileItem, 1, activeCensusFile);
  }
  return {
    ...state,
    formData: {
      ...state.formData,
      censusFiles,
    },
  };
};

const uploadReducer = (state = initialUploadCensus, action) => {
  const censusFiles = [...state.formData.censusFiles];
  switch (action.type) {
    case SET_UPLOAD_LOADING_STATE:
      return {
        ...state,
        loading: action.payload,
      };
    case UPDATE_UPLOAD_CENSUS_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_CENSUS_CLASS_COUNT:
      return updateCensusClassCount(action, censusFiles, state);
    case UPDATE_UPLOAD_CENSUS_CLASSES: {
      const { classIndex, parentIndex, name, value } = action.payload;
      const classes = [...censusFiles[parentIndex].classPlan.classes];
      const updateClass = JSON.parse(JSON.stringify(classes[classIndex]));
      updateClass[name] = value;
      classes.splice(classIndex, 1, updateClass);
      const mergedCensusFiles = [...censusFiles];
      const updateCensusFile = JSON.parse(JSON.stringify(mergedCensusFiles[parentIndex]));
      updateCensusFile.classPlan.classes = classes;
      mergedCensusFiles.splice(parentIndex, 1, updateCensusFile);
      return {
        ...state,
        formData: {
          ...state.formData,
          censusFiles: [...mergedCensusFiles],
        },
      };
    }
    case CANCEL_UPLOAD: {
      const { fileItemActive } = action.payload;
      const cancelFile = JSON.parse(JSON.stringify(censusFiles[fileItemActive]));
      cancelFile.selectedFile = '';
      cancelFile.classPlan = {};
      return {
        ...state,
        formData: {
          ...state.formData,
          censusFiles: [...censusFiles.toSpliced(fileItemActive, 1, cancelFile)],
        },
      };
    }
    case CLEAR_FORM_DATA: {
      return {
        ...state,
        inProgress: false,
        formData: { ...initialFormData },
      };
    }
    default:
      return state;
  }
};

export default uploadReducer;
