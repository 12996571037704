import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useOktaAuth } from '@okta/okta-react';
import { withTransaction } from '@elastic/apm-rum-react';
import { Box, Container, Divider, Grid } from '@mui/material';
import { CheckCircle, CloudDownload, Create, Delete, NotInterested } from '@mui/icons-material';
import { Button, Modal, Tooltip } from 'voltron';

import PaginatedTable from 'components/PaginatedTable/PaginatedTable';
import {
  clearCurrentEmployerInfo,
  getCurrentEmployerInfo,
  removeApplicantUser,
  toggleApplicantUserIsActive,
} from 'state/actions/employers';
import { setCriticalError } from 'state/actions/error';
import { downloadFile, formatAPIMessage, isAdminUser } from 'utils/utils';
import { downloadApplicantPdf, getSigningUrl } from 'services/Services';
import { pageLoading, pageLoaded } from 'state/actions/page_loading';
// eslint-disable-next-line max-len
import ToggleUserActiveStatusConfirmModal from 'components/ToggleUserActiveStatusConfirm/ToggleUserActiveStatusConfirmModal';
import EmployerReports from './employerReports/EmployerReports';
import RecentActivities from './recentActivities/RecentActivities';
import EditEnrollmentPeriods from './editEnrollmentPeriods/EditEnrollmentPeriods';
import styles from './EmployerApplicantsDashboard.module.scss';

const NameUserNameComponent = ({ name, username, forUser }) => (
  <>
    <div>{name}</div>
    {!forUser && <div>(UserName: {username})</div>}
  </>
);

const getNameUserNameComponent =
  (forUser) =>
  (_nameAndUsername, _disabled, { name, userName }) => (
    <NameUserNameComponent name={name} username={userName} forUser={forUser} />
  );

const SignComponent = (accessToken, dispatch) => (applicant) => (
  <Button
    kind="link"
    data-testid={`sign-applicant-pdf-${applicant.id}`}
    aria-label="sign-application"
    className={styles.actionButton}
    disabled={!applicant.canSign}
    onClick={async () => {
      try {
        dispatch(pageLoading());
        const signingUrl = await getSigningUrl(accessToken, { documentKey: applicant.applicationUrl });
        window.open(signingUrl?.latestUrl);
      } catch (error) {
        dispatch(
          setCriticalError({
            title: 'Get signing URL failed',
            body: formatAPIMessage(error),
            button: 'OK',
          }),
        );
      } finally {
        dispatch(pageLoaded());
      }
    }}
  >
    {applicant.canSign ? <Create /> : <NotInterested />}
  </Button>
);

const ToggleActiveComponent =
  (setIsToggleActiveConfirmModalOpen, setApplicantToBeToggledActive) =>
  ({ id, isActive, enrollmentPeriodHasPassed }) => {
    const renderToggleActive = (children) => {
      if (enrollmentPeriodHasPassed) {
        return <Tooltip text="Applicant cannot be activated past their enrollment end date">{children}</Tooltip>;
      }
      return <div>{children}</div>;
    };

    return renderToggleActive(
      <Button
        kind="link"
        data-testid={`toggle-active-applicant-user-${id}`}
        aria-label={`${isActive ? 'deactivate' : 'activate'}-user${enrollmentPeriodHasPassed ? '-disabled' : ''}`}
        className={styles.actionButton}
        disabled={enrollmentPeriodHasPassed}
        onClick={() => {
          setIsToggleActiveConfirmModalOpen(true);
          setApplicantToBeToggledActive({ id, isActive });
        }}
      >
        {isActive ? <CheckCircle /> : <NotInterested />}
      </Button>,
    );
  };

const DeleteComponent = (setIsDeleteConfirmModalOpen, setApplicantToBeDeleted) => (applicant) => (
  <Button
    kind="link"
    data-testid={`delete-applicant-user-${applicant.id}`}
    aria-label="delete-user"
    className={styles.actionButton}
    disabled={!applicant.canDelete}
    onClick={() => {
      setIsDeleteConfirmModalOpen(true);
      setApplicantToBeDeleted(applicant);
    }}
  >
    {applicant.canDelete ? <Delete /> : <NotInterested />}
  </Button>
);

const DownloadComponent = (accessToken, dispatch) => (applicant) => (
  <Button
    kind="link"
    data-testid={`download-pdf-applicant-user-${applicant.id}`}
    aria-label="download-application"
    className={styles.actionButton}
    disabled={!applicant.canDownload}
    onClick={async () => {
      try {
        dispatch(pageLoading());
        const { data, headers } = await downloadApplicantPdf(accessToken, { model: applicant });
        downloadFile(data, headers, true);
      } catch (error) {
        dispatch(
          setCriticalError({
            title: 'Download applicant PDF failed',
            body: formatAPIMessage(error),
            button: 'OK',
          }),
        );
      } finally {
        dispatch(pageLoaded());
      }
    }}
  >
    {applicant.canDownload ? <CloudDownload /> : <NotInterested />}
  </Button>
);

const getDeleteApplicantModal = ({
  isDeleteConfirmModalOpen,
  applicantToBeDeleted,
  accessToken,
  employerId,
  closeDeleteModal,
  dispatch,
}) => (
  <Modal open={isDeleteConfirmModalOpen} title="Delete Applicant" handleToggle={closeDeleteModal}>
    <span tabIndex="0">
      Delete the <b>Applicant</b> &apos;{applicantToBeDeleted.name}&apos;
    </span>
    <br />
    <span tabIndex="0">
      &nbsp;&nbsp;<b>Enrollment Period</b> &apos;{applicantToBeDeleted.enrollmentPeriod}&apos;?
    </span>
    <div className={styles.modalButtons}>
      <Button kind="link" className={styles.modalButton} data-testid="delete-applicant-no" onClick={closeDeleteModal}>
        NO
      </Button>
      <Button
        kind="link"
        className={styles.modalButton}
        data-testid="delete-applicant-confirm"
        onClick={() => {
          dispatch(removeApplicantUser(accessToken, applicantToBeDeleted, employerId));
          closeDeleteModal();
        }}
      >
        YES
      </Button>
    </div>
  </Modal>
);

const getTableColumns = ({
  forUser,
  userInfo,
  canSign,
  canDownload,
  accessToken,
  setIsToggleActiveConfirmModalOpen,
  setApplicantToBeToggledActive,
  setIsDeleteConfirmModalOpen,
  setApplicantToBeDeleted,
  dispatch,
}) => {
  let tableColumns = [
    {
      Header: 'Applicant',
      width: 60,
      accessor: forUser ? 'name' : 'nameAndUserName',
      isSearchable: true,
      component: getNameUserNameComponent(forUser),
    },
    {
      Header: 'Enrollment Type',
      width: 30,
      accessor: 'enrollmentType',
    },
    {
      Header: 'Enrollment Period',
      width: 30,
      accessor: 'enrollmentPeriod',
    },
    {
      Header: 'Activation Type',
      width: 28,
      accessor: 'activationType',
    },
    {
      Header: 'Status',
      width: 43,
      accessor: 'applicationStatus',
    },
  ];

  const isActiveColumn = {
    Header: 'Is Active',
    width: 18,
    isAction: true,
    component: ToggleActiveComponent(setIsToggleActiveConfirmModalOpen, setApplicantToBeToggledActive),
  };
  const deleteColumn = {
    Header: 'Delete',
    width: 18,
    isAction: true,
    component: DeleteComponent(setIsDeleteConfirmModalOpen, setApplicantToBeDeleted),
  };
  const signColumn = {
    Header: 'Sign',
    width: 12,
    isAction: true,
    component: SignComponent(accessToken, dispatch),
  };
  const downloadColumn = {
    Header: 'Download',
    width: 25,
    isAction: true,
    component: DownloadComponent(accessToken, dispatch),
  };

  if (forUser) {
    if (canSign) {
      tableColumns.push(signColumn);
    }
    if (canDownload) {
      tableColumns.push(downloadColumn);
    }
  } else if (isAdminUser(userInfo)) {
    tableColumns = [...tableColumns, isActiveColumn, deleteColumn, downloadColumn];
  }

  return tableColumns;
};

const getSideItems = ({ employerId, forUser, userInfo, futureCurrentEnrollments, nonEmptyEnrollments }) => (
  <div className={styles.reportContent}>
    <Grid item className={styles.reportContentItem}>
      <EmployerReports employerId={employerId} />
    </Grid>
    {!forUser && (
      <>
        <Grid item className={styles.reportContentItem}>
          <EditEnrollmentPeriods
            isAdmin={isAdminUser(userInfo)}
            employerId={employerId}
            futureCurrentEnrollments={futureCurrentEnrollments}
          />
        </Grid>
        <Grid item className={styles.reportContentItem}>
          <RecentActivities nonEmptyEnrollments={nonEmptyEnrollments} />
        </Grid>
      </>
    )}
  </div>
);

const EmployerApplicantsDashboard = ({ forUser = false }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.appState);
  const { currentEmployer, isSetCurrentEmployerLoading } = useSelector((state) => state.employers);
  const { name, applicants, canSign, canDownload, futureCurrentEnrollments, nonEmptyEnrollments, pastEnrollments } =
    currentEmployer;
  const [isToggleActiveConfirmModalOpen, setIsToggleActiveConfirmModalOpen] = useState(false);
  const [applicantToBeToggledActive, setApplicantToBeToggledActive] = useState({ id: 0 });
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] = useState(false);
  const [applicantToBeDeleted, setApplicantToBeDeleted] = useState({});
  const { oktaAuth } = useOktaAuth();
  const accessToken = oktaAuth.getAccessToken();
  const employerId = params.id;

  const closeDeleteModal = useCallback(() => {
    setIsDeleteConfirmModalOpen(false);
    setApplicantToBeDeleted({});
  }, []);

  useEffect(() => {
    dispatch(clearCurrentEmployerInfo());
    dispatch(getCurrentEmployerInfo(accessToken, employerId));
  }, []);

  const applicantsTableData = applicants.map((applicant) => ({
    ...applicant,
    nameAndUserName: forUser ? null : `${applicant.name}///${applicant.userName}`,
    enrollmentPeriodHasPassed: forUser
      ? null
      : pastEnrollments.some((enrollment) => enrollment.id === applicant.enrollmentPeriodId),
    activationType: applicant.manualActivation ? 'Manual' : 'Automatic',
  }));

  const tableColumns = getTableColumns({
    forUser,
    userInfo,
    canSign,
    canDownload,
    accessToken,
    setIsToggleActiveConfirmModalOpen,
    setIsDeleteConfirmModalOpen,
    setApplicantToBeDeleted,
    setApplicantToBeToggledActive,
    dispatch,
  });

  return (
    <Box sx={{ width: '100vw', marginTop: '3rem' }}>
      <Container maxWidth="false" sx={{ maxWidth: '1628px' }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className={styles.page}>
              <h1 className={styles.pageTitle}>Dashboard - {name}</h1>
              <Divider />
              <Grid container className={styles.pageContent}>
                <Grid item className={styles.tableContentBody}>
                  <div className={styles.applicantsTableContainer}>
                    <div className={styles.tableTitle}>All Applicants</div>
                    <PaginatedTable
                      className={styles.applicantsTable}
                      sortColumn={forUser ? 'name' : 'nameAndUserName'}
                      sortOrder="ASC"
                      columns={tableColumns}
                      data={applicantsTableData}
                      isDataLoading={isSetCurrentEmployerLoading || !userInfo}
                    />
                    <ToggleUserActiveStatusConfirmModal
                      isToggleActiveConfirmModalOpen={isToggleActiveConfirmModalOpen}
                      userToBeToggledActive={applicantToBeToggledActive}
                      setIsToggleActiveConfirmModalOpen={setIsToggleActiveConfirmModalOpen}
                      setUserToBeToggledActive={setApplicantToBeToggledActive}
                      toggleAction={toggleApplicantUserIsActive}
                      employerId={employerId}
                    />
                    {getDeleteApplicantModal({
                      isDeleteConfirmModalOpen,
                      applicantToBeDeleted,
                      accessToken,
                      employerId,
                      closeDeleteModal,
                      dispatch,
                    })}
                  </div>
                </Grid>
                {getSideItems({ employerId, forUser, userInfo, futureCurrentEnrollments, nonEmptyEnrollments })}
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default withTransaction('EmployerApplicantsDashboard', 'component')(EmployerApplicantsDashboard);
